import { useEffect, useState } from "react";
import '../../../components/Geral/geral.css';
import './TurismoEditar.css'
import carregando from '../../../components/Geral/loading.gif';
import { verificaLogin } from '../../../context/LoginContext';
import { TextEditor } from "../../../components/TextEditor/TextEditor";
import {base_url, midia_url, foto_default} from '../../../environment'
import axios from 'axios';
import Sidebar from '../../../components/Menu/Sidebar';
import { useParams } from "react-router-dom";

import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

var unavez = true, umavez = true;

function TurismoEditar(){
    setInterval(function () {verificaLogin();}, 1000);

    const [refreshKey, setRefreshKey] = useState(0);
    
    function atualiza() {
        setRefreshKey(oldKey => oldKey +1); 
    }

    const [alertAberto, setAlertAberto] = React.useState(false);
    const [alertMensagem, setAlertMensagem] = React.useState('');
    const [tipoAlerta, setTipoAlerta] = React.useState('');

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    function meuAlert(texto, tipo) {
        setTipoAlerta(tipo);
        setAlertMensagem(texto);
        window.location = `#root`;
        setAlertAberto(true);
    }

    function loading() {return <div id='loading'><img src={carregando} alt="Carregando"/></div>; }    
    setInterval(function () {verificaLogin();}, 1000);
    
    var [texto, setTexto] = useState('<p>Escreva seu texto aqui. Aumente o tamanho da área de texto no canto inferior direito.</p>');    

    // Clicar no primeiro botao e colocar texto inicial
    useEffect(() => {
        document.getElementById(0).click();
    },[]);

    const {id} = useParams();
    var token = localStorage.getItem('LOCAL_STORAGE_KEY__ACCESS_TOKEN');
 
    // pegar dados do categoria-ponto-turistico
    useEffect(() => {
    axios.get(`${base_url}/categoria-ponto-turistico`, {
        "headers": {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 

            // eslint-disable-next-line
            if((response.data.statusCode != undefined) && (response.data.statusCode != 200)){
                //window.location.pathname = `mensagem/${response.data.message}`;
                meuAlert(`${response.data.message}`, "error");
            }

            // eslint-disable-next-line 
            if(response.data.length == 0)
            {
                meuAlert("Não há categorias.", "error");         
            }
            else
            {
                //console.log(response.data);

                var pessoa = document.getElementById("categoria-ponto-turistico");                
                for(let i=0; i<response.data.length; i++){
                    const option = document.createElement("option");
                    option.value = response.data[i].id;
                    option.innerHTML = response.data[i].nome;                    
                    pessoa.appendChild(option);
                }

            }            
        })    
        .catch(error => { 
            console.log(error.message);
            if(error.response)
            {
                // eslint-disable-next-line
                if(error.response.data.statusCode == 401)
                {
                    window.location.pathname = `login`;
                }
                else{
                    meuAlert(`${error.message}`, "error");
                }  
            }
            else{
                meuAlert(`${error.message}`, "error");
            }            
        });

    },[]); 

    // pegar dados se não for novo
    useEffect(() => {
        
        if(id != "novo")
        {
            axios.get(`${base_url}/ponto-turistico/${id}`, 
            { headers: {
                'Authorization': `Bearer ${token}`,
            }})
            .then(async response => {             
                //console.log("Resposta ok");
                //console.log(response);
                //console.log(response.data);

                document.getElementById("titulo").value = response.data.titulo;        
                document.getElementById("email").value = response.data.email; 
                document.getElementById("fone").value = response.data.fone;         
                document.getElementById("celular").value = response.data.celular;
                document.getElementById("endereco").value = response.data.endereco;
                document.getElementById("cep").value = response.data.cep;                
                document.getElementById("link_maps").value = (response.data.link_maps)
                document.getElementById("site").value = (response.data.site)
                
                if(response.data.conteudo){setTexto(response.data.conteudo)};
                if(response.data.capa){document.getElementById("capa").src = midia_url+response.data.capa;}
                else{document.getElementById("capa").src = foto_default}   

                if(response.data.status == true){
                    document.getElementById("turismoativo").checked = true;
                }
                else
                {
                    document.getElementById("turismoinativo").checked = true;
                }                
                
                await categoriaPT(response.data.categoria_id);
                
                //var arrayInicial = [];        

            })
            .catch(error => { 
                console.log(error.message);
                if(error.response)
                {
                    if((error.response.data.statusCode == 404) || (error.response.data.statusCode == 400))
                    {
                        if(unavez)
                        {
                            meuAlert("Pessoa não existente.", "warning");
                            //window.location.pathname = `turismo/editar/novo`;
                            unavez = false;
                        }
                        
                    }
                    else{
                        meuAlert(`${error.message}`, "error");
                    }
                    if(error.response.data.statusCode == 401)
                    {
                        window.location.pathname = `login`;
                    }
                }   
                else{
                    meuAlert(`${error.message}`, "error");
                }            
            });
        }
        else{
            document.getElementById("capa").src = foto_default;
        }
        document.getElementById("notifica").innerHTML = "Processo em andamento, aguarde...";
               
    },[refreshKey]); 


    function verificaVazios() {

        if(document.getElementById("titulo").value == ""){meuAlert("Nome do ponto turístico não preenchido.", "warning");}
        else if(document.getElementById("capa").value == ""){meuAlert("Capa do ponto turístico não preenchido.", "warning");}
        else if(document.getElementById("email").value == ""){meuAlert("Email do ponto turístico não preenchida.", "warning");}
        else if(document.getElementById("celular").value == ""){meuAlert("Celular do ponto turístico não preenchido.", "warning");}
        else if(document.getElementById("fone").value == ""){meuAlert("Fone do ponto turístico não preenchido.", "warning");}
        else if(document.getElementById("endereco").value == ""){meuAlert("Endereco do ponto turístico não preenchido.", "warning");}
        else if(document.getElementById("cep").value == ""){meuAlert("CEP do ponto turístico não preenchido.", "warning");}
        else if(document.getElementById("link_maps").value == ""){meuAlert("Link Mapa do ponto turístico não preenchido.", "warning");}
        else if(document.getElementById("site").value == ""){meuAlert("Site do ponto turístico não preenchido.", "warning");}
        else if(id == "novo"){
            if(document.getElementById("InputCapa").value == "")
            {meuAlert("Capa do ponto turístico não preenchida.", "warning");}
            else{return true;}
        }
        else
        {
            return true;
        }    
    }

    function selectFuncao(){
        if(id == "novo")
        {
            if(verificaVazios())
            {
                cadastraPonto();
            };            
        }
        else
        {
            if(verificaVazios())
            {
                atualizaPonto();
            };    
        }
    }

    // Cadastra ponto-turistico (sem docs)
    async function cadastraPonto() {

        {handleOpen()} 

        var input = document.querySelector('input[type="file"]')

        //filtrando o link do iframe e enviando somente a url
        var link_mapsEditado = document.getElementById("link_maps").value.split(" ")[1].substr(4).replace('"', '').replace('"', '');
        //console.log(link_mapsEditado);

        var data = new FormData()
        data.append('titulo', String(document.getElementById("titulo").value))     
        data.append('conteudo', texto)
        data.append('capa', input.files[0])       
        data.append('status', document.querySelector('input[name="statusPessoa"]:checked').value)
        data.append('email', String(document.getElementById("email").value))
        data.append('celular', String(document.getElementById("celular").value))
        data.append('fone', String(document.getElementById("fone").value))
        data.append('endereco', String(document.getElementById("endereco").value))
        data.append('cep', String(document.getElementById("cep").value))
        data.append('link_maps', String(link_mapsEditado))
        data.append('site', String(document.getElementById("site").value))
        data.append('categoria_id', String(document.getElementById("categoria-ponto-turistico").value))
        // NÂO PRECISA
        //data.append('cargo', String(document.getElementById("cargo").value))
        //data.append('contratante_id', localStorage.getItem('contratante_id'))      

        //console.log(data);
        
        const url = `${base_url}/ponto-turistico`;
         await axios.post(url, data, {
        "headers": {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 
            //console.log(response);
            //console.log(response.data.id);

            document.getElementById("notifica").innerHTML = "Cadastrado com sucesso!";
            const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

            meuAlert("Cadastrado com sucesso!", "success");
            const redireciona = setInterval(function () {
                clearInterval(redireciona);
                window.location.pathname = `turismo/editar/${response.data.id}`;
            }, 3000);     
           
        })
        .catch(error => {
            {handleClose()}
            meuAlert(`${error.response.data.message}`, "warning");
            console.log(error.message);
            console.log(error.response.data.message);
            //console.log("Erro "+error.response.data.statusCode);
        })     
    }
    // Atualiza ponto-turistico (sem docs)
    async function atualizaPonto() {
          
        {handleOpen()} 

        var input = document.querySelector('input[type="file"]')

        //filtrando o link do iframe e enviando somente a url
        var link_mapsEditado = document.getElementById("link_maps").value.split(" ")[1].substr(4).replace('"', '').replace('"', '');
        //console.log(link_mapsEditado);

        var data = new FormData()
        data.append('titulo', String(document.getElementById("titulo").value))     
        data.append('conteudo', texto)
        data.append('capa', input.files[0])       
        data.append('status', document.querySelector('input[name="statusPessoa"]:checked').value)
        data.append('email', String(document.getElementById("email").value))
        data.append('celular', String(document.getElementById("celular").value))
        data.append('fone', String(document.getElementById("fone").value))
        data.append('endereco', String(document.getElementById("endereco").value))
        data.append('cep', String(document.getElementById("cep").value))
        data.append('link_maps', String(link_mapsEditado))
        data.append('site', String(document.getElementById("site").value))
        data.append('categoria_id', String(document.getElementById("categoria-ponto-turistico").value))
        //NÂO PRECISA
        //data.append('cargo', String(document.getElementById("cargo").value))
        //data.append('contratante_id', localStorage.getItem('contratante_id'))      

        //console.log(data);
        
        const url = `${base_url}/ponto-turistico/${id}`;
        await axios.patch(url, data, {
        "headers": {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 
            //console.log(response);
            //console.log(response.data.id);

            document.getElementById("notifica").innerHTML = "Alterado com sucesso!";
            const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

            meuAlert("Alterado com sucesso!", "success");
            const redireciona = setInterval(function () {
                //setRefreshKey(oldKey => oldKey +1);
                window.location.reload();
                clearInterval(redireciona);
            }, 2000);     

        })
        .catch(error => { 
            {handleClose()}
            meuAlert(`${error.response.data.message}`, "warning");
            console.log(error.message);
            console.log(error.response.data.message);
            //console.log("Erro "+error.response.data.statusCode);
        })     
        
    }    
    // Excluir ponto-turistico
    async function ExcluirPonto() {  

        if (window.confirm("Tem certeza que deseja excluir este ponto turístico?")) {        
            
            {handleOpen()} 
         
            const url = `${base_url}/ponto-turistico/${id}`;
            await axios.delete(url, {
            "headers": {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            }})
            .then(response => { 
                //console.log(response);
                //console.log(response.data.id);    
               
                document.getElementById("notifica").innerHTML = "Excluído com sucesso!";
                const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

                meuAlert("Excluído com sucesso!", "success");
                const redireciona = setInterval(function () {
                    clearInterval(redireciona);
                    window.location.pathname = `turismo/editar/novo`;
                }, 2000);     

            })
            .catch(error => { 
                {handleClose()}
                meuAlert(`${error.message}`, "error");
                console.log(error.message);
                //console.log("Erro "+error.response.data.statusCode);
            })  
        }           
    }

    function categoriaPT(valor){
        for(let i=0; i<document.getElementById("categoria-ponto-turistico").children.length; i++){
            if(document.getElementById("categoria-ponto-turistico").children[i].value == valor){
                document.getElementById("categoria-ponto-turistico").children[i].selected = true;
            }        
        }
    }

    return(
        <>   
            <Sidebar page={{ name: 'Pontos Turísticos', route: '/turismo' }}>
                <Box sx={{ width: '100%'}}>
                    <Collapse in={alertAberto}>
                        <Alert
                        severity={tipoAlerta}
                        variant="filled"
                        action={
                            <IconButton
                            aria-label="close"
                            size="small"
                            color="inherit"
                            onClick={() => {
                                setAlertAberto(false);
                            }}
                            >
                            <CloseIcon/>
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        {alertMensagem}
                        </Alert>
                    </Collapse>
                </Box>
                
                <div className="tituloPagina"><p id="titu" >Editar Ponto Turístico</p></div>   

                <div className="botaoExcluirEspec" >
                    {id !== "novo" && (
                        <div className="botaoExcluirEspec">
                            <button onClick={ExcluirPonto}>Excluir Ponto Turístico</button>
                        </div>
                    )}

                </div>


                <div className="semLinha menuPadrao">
                    <li>
                        <button id="0" >Cadastro Geral</button>       
                    </li>  
                </div>

                <div className="formulario" >
                    <div id="cadastroGaleria">
                        <div className="formularioGeral">                                                       
                            <div className="alinhaDireita">

                                <div className="formularioGeralTitulo"><p>Nome do ponto turístico</p></div>                            
                                <input type="text" name="titulo" id="titulo" placeholder="Insira o titulo do ponto turístico" autoComplete="off"  ></input>
                                <div className="formularioGeralTitulo"><p>Email do ponto turístico</p></div>   
                                <input type="text" name="email" id="email" placeholder="Insira o email do ponto turístico" autoComplete="off"  ></input>
                                                                
                                <div className="novoRadio statusRadio"> 
                                    <div className="formularioGeralTitulo"><p>Status</p></div> 
                                    <input type="radio" id="turismoativo" name="statusPessoa" value="true"checked></input>
                                    <label for="ativo">Ativo</label>

                                    <input type="radio" id="turismoinativo" name="statusPessoa" value="false"></input>
                                    <label for="inativo">Inativo</label>
                                </div>

                                <div className="formularioGeralTitulo"><p>Categoria Ponto Turístico</p></div>
                                <select name="categoria-ponto-turistico" id="categoria-ponto-turistico">        
                                </select>
                                
                                <div className="espaco2LadosB1"></div>

                                <div className="formularioGeralTitulo"><p>Celular do ponto turístico</p></div>   
                                <input type="text" name="celular" id="celular" placeholder="Insira o celular do ponto turístico" autoComplete="off"  ></input>
                                <div className="formularioGeralTitulo"><p>Fone do ponto turístico</p></div>   
                                <input type="text" name="fone" id="fone" placeholder="Insira o telefone do ponto turístico" autoComplete="off"  ></input>
                                <div className="formularioGeralTitulo"><p>CEP do ponto turístico</p></div>   
                                <input type="text" name="cep" id="cep" placeholder="Insira o CEP do ponto turístico" autoComplete="off"  ></input>
                                        
                            </div>

                            <div className="alinhaEsquerda">
                                <img id="capa" name="capa" src={carregando} ></img>
                                
                                <div className="formularioGeralTitulo"><p>Capa do Ponto Turístico</p></div>
                                <input type="file" name="InputCapa" id="InputCapa" autoComplete="off"  ></input>
                                   
                                <div className="formularioGeralTitulo"><p>Link mapa do ponto turístico</p></div>   
                                <input type="text" name="link_maps" id="link_maps" placeholder="Insira o Link mapa do ponto turístico" autoComplete="off"  ></input>
                                <div className="formularioGeralTitulo"><p>Site do ponto turístico</p></div>  
                                <input type="text" name="site" id="site" placeholder="Insira o Site do ponto turístico" autoComplete="off"  ></input>
                                
                            </div>    

                            <div className="bloqueiaDiv formularioGeralTitulo"><p>Endereço do ponto turístico</p></div>   
                            <input type="text" name="endereco" id="endereco" placeholder="Insira o endereço do ponto turístico" autoComplete="off"  ></input>
                                                        
                        </div>

                        <div className="formularioConteudo">
                            <span>Descrição do ponto turístico</span>
                            <TextEditor value={texto} onChange={setTexto} />
                            <button onClick={selectFuncao}>Salvar alterações</button>
                        </div>
                    </div>                    
                </div>
            </Sidebar>
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                ><p id="notifica" >vghfgh</p>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </>   
    );
}

export default TurismoEditar;