import React from "react";
import './404.css'
import {Link} from "react-router-dom";
import {BiErrorCircle} from "react-icons/bi"

function Page404(){
      
    return(
        <>   
            <div className="pagina404"> 
                <button className="erru" >< BiErrorCircle /></button>
                <h1>Oops! Aparentemente essa página não existe.</h1>
                <Link to={'/'}> <p>Ir para página inicial </p></Link>
            </div>   
        </>   
    );
}

export default Page404;