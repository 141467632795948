import { useEffect, useRef, useState } from "react";
import '../../../components/Geral/geral.css';
import './VideoEditar.css'
import carregando from '../../../components/Geral/loading.gif';
import { verificaLogin, fazerLogout } from '../../../context/LoginContext';
import { Editor } from '@tinymce/tinymce-react';
import {base_url, midia_url, foto_default} from '../../../environment'
import axios from 'axios';
import Sidebar from '../../../components/Menu/Sidebar';
import { useParams } from "react-router-dom";

import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
//import Button from '@mui/material/Button';

var unavez = true, umavez = true;
var optionClicado = "background-color: var(--mainColor); color: var(--textColor2);"


function VideoEditar(){
    setInterval(function () {verificaLogin();}, 1000);

    const [refreshKey, setRefreshKey] = useState(0);
    
    function atualiza() {
        setRefreshKey(oldKey => oldKey +1); 
    }

    const [alertAberto, setAlertAberto] = React.useState(false);
    const [alertMensagem, setAlertMensagem] = React.useState('');
    const [tipoAlerta, setTipoAlerta] = React.useState('');

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    function meuAlert(texto, tipo) {
        setTipoAlerta(tipo);
        setAlertMensagem(texto);
        window.location = `#root`;
        setAlertAberto(true);
    }

    function loading() {return <div id='loading'><img src={carregando} alt="Carregando"/></div>; }    
    setInterval(function () {verificaLogin();}, 1000);
    
    const {id} = useParams();
    var token = localStorage.getItem('LOCAL_STORAGE_KEY__ACCESS_TOKEN');

    // pegar dados se não for novo
    useEffect(() => {
        
        if(id != "novo")
        {
            axios.get(`${base_url}/video/${id}`, 
            { headers: {
                'Authorization': `Bearer ${token}`,
            }})
            .then(async response => {             
                //console.log("Resposta ok");
                //console.log(response);
                //console.log(response.data);
                
                document.getElementById("titulo").value = response.data.titulo;
                document.getElementById("descricao").value = response.data.descricao;
                document.getElementById("link").value = "https://www.youtube.com/watch?v="+response.data.link;
                document.getElementById("data").value = new Date(response.data.data).toISOString().split('T')[0];
                
                if(response.data.status == true){
                    document.getElementById("radioativo").checked = true;
                }
                else
                {
                    document.getElementById("radioinativo").checked = true;
                }
                
            })
            .catch(error => { 
                console.log(error.message);
                if(error.response)
                {
                    if((error.response.data.statusCode == 404) || (error.response.data.statusCode == 400))
                    {
                        if(unavez)
                        {
                            meuAlert("Vídeo não existente.", "warning");
                            //window.location.pathname = `noticia/editar/novo`;
                            unavez = false;
                        }
                        
                    }
                    else{
                        meuAlert(`${error.message}`, "error");
                    }
                    if(error.response.data.statusCode == 401)
                    {
                        window.location.pathname = `login`;
                    }
                }   
                else{
                    meuAlert(`${error.message}`, "error");
                }            
            });
        }
        document.getElementById("notifica").innerHTML = "Processo em andamento, aguarde...";
               
    },[refreshKey]); 

    function verificaVazios() {

        if(document.getElementById("titulo").value == ""){meuAlert("Título não preenchido.", "warning");}
        else if(document.getElementById("descricao").value == ""){meuAlert("Descrição não preenchida.", "warning");}
        else if(document.getElementById("data").value == ""){meuAlert("Data não preenchida.", "warning");}
        else if(document.getElementById("link").value == ""){meuAlert("Link não preenchido.", "warning");}
        else
        {
            return true;
        }    
    }
    
    // Função salvar começa aqui
    const editorRef = useRef(null);
    const pegaConteudo = () => {
        if (editorRef.current) {
        console.log(editorRef.current.getContent());
    }}

    function selectFuncao(){
        if(id == "novo")
        {
            if(verificaVazios())
            {
                cadastraVideo();
            };            
        }
        else
        {
            if(verificaVazios())
            {
                atualizaVideo();
            };    
        }
    }

    // Cadastra noticia (sem docs)
    async function cadastraVideo() {

        {handleOpen()} 

        var input = document.querySelector('input[type="file"]')

        var data = new FormData()
        data.append('titulo', String(document.getElementById("titulo").value))
        data.append('descricao', String(document.getElementById("descricao").value))
        data.append('data', new Date(document.getElementById("data").value).toISOString())   
        data.append('link', String(document.getElementById("link").value))
        data.append('status', document.querySelector('input[name="statusRadio"]:checked').value)
        ///data.append('contratante_id', localStorage.getItem('contratante_id'))      

        console.log(data);
        
        const url = `${base_url}/video`;
         await axios.post(url, data, {
        "headers": {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 
            //console.log(response);
            //console.log(response.data.id);

            document.getElementById("notifica").innerHTML = "Cadastrado com sucesso!";
            const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

            meuAlert("Cadastrado com sucesso!", "success");
            const redireciona = setInterval(function () {
                clearInterval(redireciona);
                window.location.pathname = `video/editar/${response.data.id}`;
            }, 3000);     
           
        })
        .catch(error => {
            {handleClose()}
            meuAlert(`${error.response.data.message}`, "warning");
            console.log(error.message);
            console.log(error.response.data.message);
            //console.log("Erro "+error.response.data.statusCode);
        })     
    }
    // Atualiza noticia (sem docs)
    async function atualizaVideo() {
          
        {handleOpen()} 

        var input = document.querySelector('input[type="file"]')

        var data = new FormData()
        data.append('titulo', String(document.getElementById("titulo").value))
        data.append('descricao', String(document.getElementById("descricao").value))
        data.append('data', new Date(document.getElementById("data").value).toISOString())   
        data.append('link', String(document.getElementById("link").value))
        data.append('status', document.querySelector('input[name="statusRadio"]:checked').value)
        //data.append('contratante_id', localStorage.getItem('contratante_id'))      

        //console.log(data);
        
        const url = `${base_url}/video/${id}`;
        await axios.patch(url, data, {
        "headers": {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 
            //console.log(response);
            //console.log(response.data.id);

            document.getElementById("notifica").innerHTML = "Alterado com sucesso!";
            const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

            meuAlert("Alterado com sucesso!", "success");
            const redireciona = setInterval(function () {
                //setRefreshKey(oldKey => oldKey +1);
                window.location.reload();
                clearInterval(redireciona);
            }, 2000);     

        })
        .catch(error => { 
            {handleClose()}
            meuAlert(`${error.response.data.message}`, "warning");
            console.log(error.message);
            console.log(error.response.data.message);
            //console.log("Erro "+error.response.data.statusCode);
        })     
        
    }
    
    // Excluir noticia
    async function ExcluirVideo() {  

        if (window.confirm("Tem certeza que deseja excluir esse vídeo?")) {        
            
            {handleOpen()} 
         
            const url = `${base_url}/video/${id}`;
            await axios.delete(url, {
            "headers": {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            }})
            .then(response => { 
                //console.log(response);
                //console.log(response.data.id);    
               
                document.getElementById("notifica").innerHTML = "Excluído com sucesso!";
                const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

                meuAlert("Excluído com sucesso!", "success");
                const redireciona = setInterval(function () {
                    clearInterval(redireciona);
                    window.location.pathname = `video/editar/novo`;
                }, 2000);     

            })
            .catch(error => { 
                {handleClose()}
                meuAlert(`${error.message}`, "error");
                console.log(error.message);
                //console.log("Erro "+error.response.data.statusCode);
            })  
        }           
    }

    return(
        <>   
            <Sidebar page={{ name: 'Vídeos', route: '/video' }}>
                <Box sx={{ width: '100%'}}>
                    <Collapse in={alertAberto}>
                        <Alert
                        severity={tipoAlerta}
                        variant="filled"
                        action={
                            <IconButton
                            aria-label="close"
                            size="small"
                            color="inherit"
                            onClick={() => {
                                setAlertAberto(false);
                            }}
                            >
                            <CloseIcon/>
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        {alertMensagem}
                        </Alert>
                    </Collapse>
                </Box>
                
                <div className="tituloPagina"><p id="titu" >Editar Vídeo</p></div>   

                <div className="botaoExcluirEspec">
                    {id !== "novo" && (
                        <button onClick={ExcluirVideo}>Excluir Vídeo</button>
                    )}
                </div>

                <div className="semLinha menuPadrao">
                    <li>
                        <button id="0" >Cadastro Geral</button>      
                    </li>  
                </div>

                <div className="formulario" >
                    <div id="cadastroGaleria">
                        <div className="formRedondo formularioGeral">                                  
                            
                            <div className="alinhaDireita">    
                                <div className="formularioGeralTitulo"><p>Data</p></div>
                                <input type="date" name="data" id="data" autoComplete="off"  ></input>

                                <div className="novoRadio statusRadio"> 
                                    <div className="formularioGeralTitulo"><p>Status do vídeo</p></div> 
                                    <input type="radio" id="radioativo" name="statusRadio" value="true"checked></input>
                                    <label for="ativo">Ativo</label>

                                    <input type="radio" id="radioinativo" name="statusRadio" value="false"></input>
                                    <label for="inativo">Inativo</label>
                                </div>
                            </div>                                             
                            
                            <div className="alinhaEsquerda">
                                <div className="formularioGeralTitulo"><p>Título do vídeo</p></div>                            
                                <input type="text" name="titulo" id="titulo" placeholder="Insira o título do vídeo" autoComplete="off"  ></input>
                                
                                <div className="formularioGeralTitulo"><p>Link do vídeo</p></div>
                                <input type="text" name="link" id="link" placeholder="Insira o link do vídeo" autoComplete="off"  ></input>
                            </div>
                          
                            <div className="formularioGeralTitulo"><p>Descrição do vídeo</p></div>                            
                            <input type="text" name="descricao" id="descricao" placeholder="Insira a descrição do vídeo" autoComplete="off"  ></input>
                                                      
                            <button onClick={selectFuncao}>Salvar alterações</button>
                        </div>

                    </div>                    
                </div>
            </Sidebar>
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                ><p id="notifica" >vghfgh</p>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </>   
    );
}

export default VideoEditar;