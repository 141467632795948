import { useEffect, useState } from "react";
import '../../../components/Geral/geral.css';
import './NoticiaEditar.css'
import carregando from '../../../components/Geral/loading.gif';
import { verificaLogin } from '../../../context/LoginContext';
import { TextEditor } from "../../../components/TextEditor/TextEditor.js";
import { base_url, midia_url, foto_default } from '../../../environment'
import axios from 'axios';
import Sidebar from '../../../components/Menu/Sidebar';
import FileUploadMultiple from './uploadNoticia.tsx'
import { useParams } from "react-router-dom";

import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

var unavez = true, umavez = true;
var optionClicado = "background-color: var(--mainColor); color: var(--textColor2);"


function NoticiaEditar() {
    setInterval(function () { verificaLogin(); }, 1000);

    const [refreshKey, setRefreshKey] = useState(0);

    function atualiza() {
        setRefreshKey(oldKey => oldKey + 1);
    }

    const [alertAberto, setAlertAberto] = React.useState(false);
    const [alertMensagem, setAlertMensagem] = React.useState('');
    const [tipoAlerta, setTipoAlerta] = React.useState('');

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    function meuAlert(texto, tipo) {
        setTipoAlerta(tipo);
        setAlertMensagem(texto);
        window.location = `#root`;
        setAlertAberto(true);
    }

    function loading() { return <div id='loading'><img src={carregando} alt="Carregando" /></div>; }
    setInterval(function () { verificaLogin(); }, 1000);

    var [texto, setTexto] = useState('<p>Escreva seu texto aqui. Aumente o tamanho da área de texto no canto inferior direito.</p>');

    // Clicar no primeiro botao e colocar texto inicial
    useEffect(() => {
        document.getElementById(0).click();
    }, []);

    const { id } = useParams();
    var token = localStorage.getItem('LOCAL_STORAGE_KEY__ACCESS_TOKEN');

    // pegar dados dos orgaos
    useEffect(() => {
        axios.get(`${base_url}/orgao`, {
            "headers": {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {

                // eslint-disable-next-line
                if ((response.data.statusCode != undefined) && (response.data.statusCode != 200)) {
                    //window.location.pathname = `mensagem/${response.data.message}`;
                    meuAlert(`${response.data.message}`, "error");
                }

                // eslint-disable-next-line 
                if (response.data.length == 0) {
                    meuAlert("Não há órgãos.", "error");
                }
                else {
                    //console.log(response.data);

                    var orgao = document.getElementById("orgao");
                    for (let i = 0; i < response.data.length; i++) {
                        const option = document.createElement("option");
                        option.value = response.data[i].id;
                        option.innerHTML = response.data[i].nome;
                        orgao.appendChild(option);
                    }

                    //console.log(document.getElementById("orgao").value);
                }
            })
            .catch(error => {
                console.log(error.message);
                if (error.response) {
                    // eslint-disable-next-line
                    if (error.response.data.statusCode == 401) {
                        window.location.pathname = `login`;
                    }
                    else {
                        meuAlert(`${error.message}`, "error");
                    }
                }
                else {
                    meuAlert(`${error.message}`, "error");
                }
            });

    }, []);

    // pegar dados se não for novo
    useEffect(() => {

        if (id != "novo") {
            axios.get(`${base_url}/noticia/${id}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                })
                .then(async response => {
                    //console.log("Resposta ok");
                    //console.log(response);
                    //console.log(response.data);

                    document.getElementById("titulo").value = response.data.titulo;
                    document.getElementById("subtitulo").value = response.data.subtitulo;
                    document.getElementById("data").value = new Date(response.data.data).toISOString().split('T')[0];
                    document.getElementById("capa").src = midia_url + response.data.capa;
                    document.getElementById("video").value = "https://www.youtube.com/watch?v=" + response.data.link_youtube;
                    setTexto(response.data.conteudo);

                    if (response.data.status == true) {
                        document.getElementById("radioativo").checked = true;
                    }
                    else {
                        document.getElementById("radioinativo").checked = true;
                    }

                    if (response.data.destaque == true) {
                        document.getElementById("destaqueativo").checked = true;
                    }
                    else {
                        document.getElementById("destaqueinativo").checked = true;
                    }

                    await orgaoID(response.data.orgao_id);

                    //var arrayInicial = [];        

                    if (umavez) {
                        document.getElementById("noticiaImagens").innerHTML = null;
                        for (let i = 0; i < response.data.Imagem.length; i++) {

                            //arrayInicial.push(String('<div><p>'+response.data.Imagem[i].titulo+'</p><a target="_blank" href='+midia_url+response.data.Imagem[i].anexo+'>Baixar</a><a target="_blank" href="#" >Excluir</a></div>')); 

                            var divGeral = document.getElementById("noticiaImagens");

                            const divContainter = document.createElement("div");

                            const p = document.createElement("p");
                            p.innerHTML = response.data.Imagem[i].id.substring(0, 10);

                            const img = document.createElement("img");
                            img.src = String(midia_url + response.data.Imagem[i].imagem);

                            const a = document.createElement("a");
                            a.innerHTML = "Excluir";
                            a.id = response.data.Imagem[i].id;
                            a.addEventListener("click", (e) => ExcluirImagem(e.target.id));

                            const a2 = document.createElement("a");
                            a2.target = "_blank";
                            a2.href = String(midia_url + response.data.Imagem[i].imagem);
                            a2.innerHTML = "Baixar";

                            divContainter.appendChild(img);
                            divContainter.appendChild(p);
                            divContainter.appendChild(a);
                            divContainter.appendChild(a2);

                            divGeral.appendChild(divContainter);

                        };
                        umavez = false;
                    }
                })
                .catch(error => {
                    console.log(error.message);
                    if (error.response) {
                        if ((error.response.data.statusCode == 404) || (error.response.data.statusCode == 400)) {
                            if (unavez) {
                                meuAlert("Concurso não existente.", "warning");
                                //window.location.pathname = `noticia/editar/novo`;
                                unavez = false;
                            }

                        }
                        else {
                            meuAlert(`${error.message}`, "error");
                        }
                        if (error.response.data.statusCode == 401) {
                            window.location.pathname = `login`;
                        }
                    }
                    else {
                        meuAlert(`${error.message}`, "error");
                    }
                });
        }
        else {
            document.getElementById("capa").src = foto_default;
        }
        document.getElementById("notifica").innerHTML = "Processo em andamento, aguarde...";

    }, [refreshKey]);

    function menuSec(menuId) {
        for (let i = 0; i <= 1; i++) {
            document.getElementById(i).style = null;
        }
        document.getElementById(menuId).style = optionClicado;
        //console.log("Selecionou o botão " + id);

        // eslint-disable-next-line
        if (menuId != 0) {
            document.getElementById("cadastroGaleria").setAttribute('hidden', '');
        }
        else {
            document.getElementById("cadastroGaleria").removeAttribute('hidden');
            document.getElementById("cadastroDocConcurso").setAttribute('hidden', '');
        }

        // eslint-disable-next-line
        if (menuId != 1) {
            document.getElementById("cadastroDocConcurso").setAttribute('hidden', '');
        }
        else {
            if (id == "novo") {
                meuAlert("Cadastre e salve a noticia antes de inserir imagens.", "info");
                //alert("Cadastre e salve a noticia antes de inserir anexos.")
                document.getElementById(0).click();
            }
            else {
                document.getElementById("cadastroDocConcurso").removeAttribute('hidden');
                document.getElementById("cadastroGaleria").setAttribute('hidden', '');
            }
        }

    }

    function verificaVazios() {

        if (document.getElementById("titulo").value == "") { meuAlert("Título não preenchido.", "warning"); }
        else if (document.getElementById("subtitulo").value == "") { meuAlert("Subtitulo não preenchido.", "warning"); }
        else if (document.getElementById("data").value == "") { meuAlert("Data da noticia não preenchida.", "warning"); }
        else if (document.getElementById("video").value == "") { meuAlert("Vídeo da noticia não preenchida.", "warning"); }
        else if (document.getElementById("data").value == "") { meuAlert("Data da noticia não preenchida.", "warning"); }
        else if (id == "novo") {
            if (document.getElementById("InputCapa").value == "") { meuAlert("Imagem da capa não preenchida.", "warning"); }
            else { return true; }
        }
        else {
            return true;
        }
    }

    function selectFuncao() {
        if (id == "novo") {
            if (verificaVazios()) {
                cadastraNoticia();
            };
        }
        else {
            if (verificaVazios()) {
                atualizaNoticia();
            };
        }
    }

    // Cadastra noticia (sem docs)
    async function cadastraNoticia() {

        { handleOpen() }

        var input = document.querySelector('input[type="file"]')

        var data = new FormData()
        data.append('titulo', String(document.getElementById("titulo").value))
        data.append('subtitulo', String(document.getElementById("subtitulo").value))
        data.append('data', new Date(document.getElementById("data").value).toISOString())
        data.append('capa', input.files[0])
        data.append('link_youtube', String(document.getElementById("video").value))
        data.append('conteudo', texto)
        data.append('destaque', document.querySelector('input[name="statusDestaque"]:checked').value)
        data.append('status', document.querySelector('input[name="statusRadio"]:checked').value)
        data.append('orgao_id', document.getElementById("orgao").value)
        ///data.append('contratante_id', localStorage.getItem('contratante_id'))      

        console.log(data);

        const url = `${base_url}/noticia`;
        await axios.post(url, data, {
            "headers": {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                //console.log(response);
                //console.log(response.data.id);

                document.getElementById("notifica").innerHTML = "Cadastrado com sucesso!";
                const mostrar = setInterval(function () { { handleClose() }; clearInterval(mostrar); }, 2000);

                meuAlert("Cadastrado com sucesso!", "success");
                const redireciona = setInterval(function () {
                    clearInterval(redireciona);
                    window.location.pathname = `noticia/editar/${response.data.id}`;
                }, 3000);

            })
            .catch(error => {
                { handleClose() }
                meuAlert(`${error.response.data.message}`, "warning");
                console.log(error.message);
                console.log(error.response.data.message);
                //console.log("Erro "+error.response.data.statusCode);
            })
    }
    // Atualiza noticia (sem docs)
    async function atualizaNoticia() {

        { handleOpen() }

        var input = document.querySelector('input[type="file"]')

        var data = new FormData()
        data.append('titulo', document.getElementById("titulo").value)
        data.append('subtitulo', document.getElementById("subtitulo").value)
        data.append('data', new Date(document.getElementById("data").value).toISOString())
        data.append('capa', input.files[0])
        data.append('link_youtube', document.getElementById("video").value)
        data.append('conteudo', texto)
        data.append('destaque', document.querySelector('input[name="statusDestaque"]:checked').value)
        data.append('status', document.querySelector('input[name="statusRadio"]:checked').value)
        data.append('orgao_id', document.getElementById("orgao").value)
        //data.append('contratante_id', localStorage.getItem('contratante_id'))      

        //console.log(data);

        const url = `${base_url}/noticia/${id}`;
        await axios.patch(url, data, {
            "headers": {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                //console.log(response);
                //console.log(response.data.id);

                document.getElementById("notifica").innerHTML = "Alterado com sucesso!";
                const mostrar = setInterval(function () { { handleClose() }; clearInterval(mostrar); }, 2000);

                meuAlert("Alterado com sucesso!", "success");
                const redireciona = setInterval(function () {
                    //setRefreshKey(oldKey => oldKey +1);
                    window.location.reload();
                    clearInterval(redireciona);
                }, 2000);

            })
            .catch(error => {
                { handleClose() }
                meuAlert(`${error.response.data.message}`, "warning");
                console.log(error.message);
                console.log(error.response.data.message);
                //console.log("Erro "+error.response.data.statusCode);
            })

    }

    // Excluir noticia
    async function ExcluirNoticia() {

        if (window.confirm("Tem certeza que deseja excluir esse noticia?")) {

            { handleOpen() }

            const url = `${base_url}/noticia/${id}`;
            await axios.delete(url, {
                "headers": {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            })
                .then(response => {
                    //console.log(response);
                    //console.log(response.data.id);    

                    document.getElementById("notifica").innerHTML = "Excluído com sucesso!";
                    const mostrar = setInterval(function () { { handleClose() }; clearInterval(mostrar); }, 2000);

                    meuAlert("Excluído com sucesso!", "success");
                    const redireciona = setInterval(function () {
                        clearInterval(redireciona);
                        window.location.pathname = `noticia/editar/novo`;
                    }, 2000);

                })
                .catch(error => {
                    { handleClose() }
                    meuAlert(`${error.message}`, "error");
                    console.log(error.message);
                    //console.log("Erro "+error.response.data.statusCode);
                })
        }
    }
    // Excluir anexo
    async function ExcluirImagem(idImagem) {

        if (window.confirm("Tem certeza que deseja excluir esta imagem?")) {

            document.getElementById("notifica").innerHTML = "Processo em andamento, aguarde...";

            { handleOpen() }

            const url = `${base_url}/noticia/imagem/${idImagem}`;
            await axios.delete(url, {
                "headers": {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            })
                .then(response => {
                    //console.log(response);
                    //console.log(response.data.id);
                    //window.location.pathname = `noticia/editar/${id}`;

                    document.getElementById("notifica").innerHTML = "Imagem excluída com sucesso!";
                    const mostrar = setInterval(function () { { handleClose() }; clearInterval(mostrar); }, 2000);

                    meuAlert("Imagem excluída com sucesso!", "success");
                    const redireciona = setInterval(function () {
                        //setRefreshKey(oldKey => oldKey +1); 
                        clearInterval(redireciona);
                        window.location.reload();
                    }, 2000);

                })
                .catch(error => {
                    { handleClose() }
                    meuAlert(`${error.message}`, "error");
                    console.log(error.message);
                    //console.log("Erro "+error.response.data.statusCode);
                })
        }
    }

    function orgaoID(valor) {
        for (let i = 0; i < document.getElementById("orgao").children.length; i++) {
            if (document.getElementById("orgao").children[i].value == valor) {
                document.getElementById("orgao").children[i].selected = true;
            }
        }
    }

    return (
        <>
            <Sidebar page={{ name: 'Notícias', route: '/noticia' }}>
                <Box sx={{ width: '100%' }}>
                    <Collapse in={alertAberto}>
                        <Alert
                            severity={tipoAlerta}
                            variant="filled"
                            action={
                                <IconButton
                                    aria-label="close"
                                    size="small"
                                    color="inherit"
                                    onClick={() => {
                                        setAlertAberto(false);
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                        >
                            {alertMensagem}
                        </Alert>
                    </Collapse>
                </Box>

                <div className="tituloPagina"><p id="titu" >Editar Notícia</p></div>

                <div className="botaoExcluirEspec">
                    {id !== "novo" && (
                        <button onClick={ExcluirNoticia}>Excluir Notícia</button>
                    )}
                </div>


                <div className="semLinha menuPadrao">
                    <li>
                        <button id="0" onClick={(e) => menuSec(e.target.id)}>Cadastro Geral</button>
                        <button id="1" onClick={(e) => menuSec(e.target.id)}>Imagens</button>
                    </li>
                </div>

                <div className="formulario" >
                    <div id="cadastroGaleria">
                        <div className="formularioGeral">
                            <div className="alinhaDireita">

                                <div className="formularioGeralTitulo"><p>Título da notícia</p></div>
                                <input type="text" name="titulo" id="titulo" placeholder="Insira o título da noticia" autoComplete="off"  ></input>
                                <div className="formularioGeralTitulo"><p>Subtítulo da notícia</p></div>
                                <input type="text" name="subtitulo" id="subtitulo" placeholder="Insira o subtítulo da noticia" autoComplete="off"  ></input>

                                <div className="novoRadio statusRadio">
                                    <div className="formularioGeralTitulo"><p>Status da notícia</p></div>
                                    <input type="radio" id="radioativo" name="statusRadio" value="true" checked></input>
                                    <label for="ativo">Ativo</label>

                                    <input type="radio" id="radioinativo" name="statusRadio" value="false"></input>
                                    <label for="inativo">Inativo</label>

                                    <div className="formularioGeralTitulo"><p>Destaque</p></div>
                                    <input type="radio" id="destaqueativo" name="statusDestaque" value="true" checked></input>
                                    <label for="ativo">Sim</label>

                                    <input type="radio" id="destaqueinativo" name="statusDestaque" value="false"></input>
                                    <label for="inativo">Não</label>
                                </div>

                                <div className="espaco2LadosB_Noticia"></div>

                                <div className="formularioGeralTitulo"><p>Link vídeo</p></div>
                                <input type="text" name="video" id="video" autoComplete="off"  ></input>

                                <div className="formularioGeralTitulo"><p>Órgão responsável</p></div>
                                <select name="orgao" id="orgao">
                                </select>

                            </div>

                            <div className="alinhaEsquerda">
                                <img id="capa" name="capa" src={carregando} ></img>

                                <div className="formularioGeralTitulo"><p>Capa</p></div>
                                <input type="file" name="InputCapa" id="InputCapa" autoComplete="off"  ></input>

                                <div className="formularioGeralTitulo"><p>Data</p></div>
                                <input type="date" name="data" id="data" autoComplete="off"  ></input>

                            </div>

                            <div className="bloqueiaDiv formularioGeralTitulo"></div>

                        </div>

                        <div className="formularioConteudo">
                            <span>Descrição da noticia</span>
                            <TextEditor value={texto} onChange={setTexto} />
                            <button onClick={selectFuncao}>Salvar alterações</button>
                        </div>
                    </div>
                </div>

                <div className="cadastroDoc">
                    <div id="cadastroDocConcurso">
                        <div className="cadastroDocInterno">
                            <p>Imagens salvas na notícia</p>
                            <div id="noticiaImagens" className="anexos">
                                {loading()}
                            </div>
                            <span>Clique ou solte as imagens abaixo para adicionar</span>
                            <FileUploadMultiple />
                        </div>
                    </div>
                </div>
            </Sidebar>
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                ><p id="notifica" >vghfgh</p>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </>
    );
}

export default NoticiaEditar;