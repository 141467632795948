import React, { useEffect, useState } from "react";
import '../../../components/Geral/geral.css';
import '../../../components/Geral/tabela.css';
import './Contratos.css'
import carregando from '../../../components/Geral/loading.gif';
import { base_url } from '../../../environment'
import Sidebar from '../../../components/Menu/Sidebar';
import axios from 'axios';
import { verificaLogin } from '../../../context/LoginContext';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { BiEdit } from "react-icons/bi"
import { MdDeleteForever } from "react-icons/md"
import { BsFillCheckCircleFill } from "react-icons/bs"
import { GoCircleSlash } from "react-icons/go"
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// Importe a biblioteca 'uuid' para validar UUIDs
import { v4 as uuidv4 } from 'uuid';

function Contratos() {
    useEffect(() => {
        setInterval(() => verificaLogin(), 1000);
    }, []);

    const [tables, setTables] = useState();
    const [refreshKey, setRefreshKey] = useState(0);
    const [alertAberto, setAlertAberto] = useState(false);
    const [alertMensagem, setAlertMensagem] = useState('');
    const [tipoAlerta, setTipoAlerta] = useState('');
    const [open, setOpen] = useState(false);

    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);

    const token = localStorage.getItem('LOCAL_STORAGE_KEY__ACCESS_TOKEN');

    const loading = () => <div id='loading'><img src={carregando} alt="Carregando" /></div>;

    // Função para validar se uma string é um UUID válido
    const isValidUUID = (uuid) => uuidv4.validate(uuid);

    // Função para validar o campo titulo
    const validarTitulo = (titulo) => {
        if (!titulo || typeof titulo !== 'string') {
            meuAlert("O campo título é obrigatório e deve ser uma string", "error");
            return false;
        }
        if (titulo.length > 200) {
            meuAlert("O campo título não deve ultrapassar 200 caracteres", "error");
            return false;
        }
        return true;
    }

    // Função para validar os campos tipo_documento_id e grupo_documento_id
    const validarDocumentos = (tipoDocumentoId, grupoDocumentoId) => {
        if (!tipoDocumentoId || !isValidUUID(tipoDocumentoId)) {
            meuAlert("O campo tipo_documento_id é obrigatório e deve ser um UUID válido", "error");
            return false;
        }
        if (!grupoDocumentoId || !isValidUUID(grupoDocumentoId)) {
            meuAlert("O campo grupo_documento_id é obrigatório e deve ser um UUID válido", "error");
            return false;
        }
        return true;
    }

    // Função para validar o campo contratante_id
    const validarContratanteId = (contratanteId) => {
        if (!contratanteId || !isValidUUID(contratanteId)) {
            meuAlert("O campo contratante_id é obrigatório e deve ser um UUID válido", "error");
            return false;
        }
        return true;
    }

    // pegar dados da contrato
    useEffect(() => {
        axios.get(`${base_url}/contrato`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                if (response.data && response.data.length === 0) {
                    document.getElementById("contratos").innerHTML = '<button><p>Não há contratos.</p></button>';
                } else {
                    setTables(response.data);
                    document.getElementById("contratos").innerHTML = null;
                }
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    window.location.pathname = `login`;
                } else {
                    meuAlert(`${error.message}`, "error");
                }
            });

        document.getElementById("mensagem").innerHTML = "Processo em andamento, aguarde...";
    }, [refreshKey]);

    // Excluir contrato
    const ExcluirContrato = async (id) => {
        if (window.confirm("Tem certeza que deseja excluir este contrato?")) {
            handleOpen();
            try {
                await axios.delete(`${base_url}/contrato/${id}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    }
                });
                document.getElementById("mensagem").innerHTML = "Operação finalizada!";
                const mostrar = setInterval(() => { handleClose(); clearInterval(mostrar); }, 2000);
                meuAlert("Excluído com sucesso!", "success");
                const redireciona = setInterval(() => { setRefreshKey(oldKey => oldKey + 1); clearInterval(redireciona); }, 2000);
            } catch (error) {
                handleClose();
                meuAlert(`${error.message}`, "error");
                console.log(error.message);
            }
        }
    }

    const mostraStatus = (status) => status ? <a className="true"><BsFillCheckCircleFill /></a> : <a className="false"><GoCircleSlash /></a>;

    const marcaTodos = () => {
        var itens = 0;
        if (document.getElementById("checkGeral").checked) {
            while (document.getElementById(itens)) {
                document.getElementById(itens).checked = true;
                itens++;
            }
        } else {
            while (document.getElementById(itens)) {
                document.getElementById(itens).checked = false;
                itens++;
            }
        }
    }

    const opcoesMarcados = (acao) => {
        handleOpen();
        var itens = 0;
        var passadas = 0;
        while (document.getElementById(itens)) { itens++; }

        for (var valor = 0; valor < itens; valor++) {
            if ((acao === "excluir") && document.getElementById(valor).checked) {
                // Remova o contrato
                ExcluirContrato(tables[valor].id);
            }
            if ((acao === "inativar" || acao === "ativar") && document.getElementById(valor).checked) {
                // Ative ou inative o contrato
                const status = acao === "inativar" ? false : true;
                const params = JSON.stringify({ "status": status });
                const url = `${base_url}/contrato/${tables[valor].id}`;
                axios.patch(url, params, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    }
                })
                    .then(() => {
                        passadas++;
                        if (itens === passadas) {
                            document.getElementById("mensagem").innerHTML = "Operação finalizada!";
                            const mostrar = setInterval(() => { handleClose(); clearInterval(mostrar); }, 2000);
                            meuAlert("Operação finalizada!", "success");
                            const redireciona = setInterval(() => { setRefreshKey(oldKey => oldKey + 1); clearInterval(redireciona); }, 2000);
                            document.getElementById("checkGeral").checked = false;
                            marcaTodos();
                        }
                    })
                    .catch(error => {
                        handleClose();
                        meuAlert(`${error.message}`, "error");
                        console.log(error.message);
                        passadas = -100;
                    });
            }
        }
    }
    const meuAlert = (texto, tipo) => {
        setTipoAlerta(tipo);
        setAlertMensagem(texto);
        window.location = `#root`;
        setAlertAberto(true);
    };


    return (
        <>
            <Sidebar>
                <Box sx={{ width: '100%' }}>
                    <Collapse in={alertAberto}>
                        <Alert
                            severity={tipoAlerta}
                            variant="filled"
                            action={
                                <IconButton
                                    aria-label="close"
                                    size="small"
                                    color="inherit"
                                    onClick={() => {
                                        setAlertAberto(false);
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                        >
                            {alertMensagem}
                        </Alert>
                    </Collapse>
                </Box>

                <div className="tituloPagina">
                    <p id="titu" >Contratos</p>
                </div>
                <div className="optionNovo" >
                    <button onClick={() => window.location.pathname = `contratos/editar/novo`}>Novo Contrato</button>
                </div>

                <div className="optionsBotoes" >
                    <button onClick={() => opcoesMarcados("ativar")}>Ativar</button>
                    <button onClick={() => opcoesMarcados("inativar")}>Inativar</button>
                    <button onClick={() => opcoesMarcados("excluir")}>Excluir</button>
                </div>

                <div className="tabelaPadrao">
                    <TableContainer className="modal-content">
                        <Table sx={{ minWidth: 650 }} arial-label="caption table" className="table">
                            <TableHead>
                                <TableRow>
                                    <TableCell><input type="checkbox" id="checkGeral" onClick={() => marcaTodos()}></input></TableCell>
                                    <TableCell>Número</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Data Início</TableCell>
                                    <TableCell>Tipo</TableCell>
                                    <TableCell>Editar</TableCell>
                                    <TableCell>Excluir</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tables && tables.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell><input type="checkbox" id={index} ></input></TableCell>
                                        <TableCell>{item.numero || '--'}</TableCell>
                                        <TableCell>{mostraStatus(item.status) || '--'}</TableCell>
                                        <TableCell>{new Date(item.data_inicio).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).toString() || '--'}</TableCell>
                                        <TableCell>{item.Tipo["nome"] || '--'}</TableCell>
                                        <TableCell><a target="_self" href={"contratos/editar/" + item.id}><BiEdit /></a></TableCell>
                                        <TableCell><a target="_self" href="#" onClick={() => ExcluirContrato(item.id)}><MdDeleteForever /></a></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>

                <div className="loading" >
                    <div id="contratos">{loading()}</div>
                </div>
            </Sidebar>
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                ><p id="mensagem" ></p>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </>
    );
}

export default Contratos;


