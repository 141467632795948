import { useEffect, useState } from "react";
import '../../../components/Geral/geral.css';
import './PessoaEditar.css'
import carregando from '../../../components/Geral/loading.gif';
import { verificaLogin } from '../../../context/LoginContext';
import { TextEditor } from "../../../components/TextEditor/TextEditor";
import {base_url, midia_url, foto_default} from '../../../environment'
import axios from 'axios';
import Sidebar from '../../../components/Menu/Sidebar';
import { useParams } from "react-router-dom";

import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

var unavez = true, umavez = true;

function PessoaEditar(){
    setInterval(function () {verificaLogin();}, 1000);

    const [refreshKey, setRefreshKey] = useState(0);
    
    function atualiza() {
        setRefreshKey(oldKey => oldKey +1); 
    }

    const [alertAberto, setAlertAberto] = React.useState(false);
    const [alertMensagem, setAlertMensagem] = React.useState('');
    const [tipoAlerta, setTipoAlerta] = React.useState('');

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    function meuAlert(texto, tipo) {
        setTipoAlerta(tipo);
        setAlertMensagem(texto);
        window.location = `#root`;
        setAlertAberto(true);
    }

    function loading() {return <div id='loading'><img src={carregando} alt="Carregando"/></div>; }    
    setInterval(function () {verificaLogin();}, 1000);
    
    var [texto, setTexto] = useState('<p>Escreva seu texto aqui. Aumente o tamanho da área de texto no canto inferior direito.</p>');    

    // Clicar no primeiro botao e colocar texto inicial
    useEffect(() => {
        document.getElementById(0).click();    
    },[]);

    const {id} = useParams();
    var token = localStorage.getItem('LOCAL_STORAGE_KEY__ACCESS_TOKEN');

    // pegar dados do cargo
    useEffect(() => {
    axios.get(`${base_url}/cargo`, {
        "headers": {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 

            // eslint-disable-next-line
            if((response.data.statusCode != undefined) && (response.data.statusCode != 200)){
                //window.location.pathname = `mensagem/${response.data.message}`;
                meuAlert(`${response.data.message}`, "error");
            }

            // eslint-disable-next-line 
            if(response.data.length == 0)
            {
                meuAlert("Não há cargos.", "error");           
            }
            else
            {
                //console.log(response.data);

                var pessoa = document.getElementById("cargo");                
                for(let i=0; i<response.data.length; i++){
                    const option = document.createElement("option");
                    option.value = response.data[i].id;
                    option.innerHTML = response.data[i].titulo;                    
                    pessoa.appendChild(option);
                }

            }            
        })    
        .catch(error => { 
            console.log(error.message);
            if(error.response)
            {
                // eslint-disable-next-line
                if(error.response.data.statusCode == 401)
                {
                    window.location.pathname = `login`;
                }
                else{
                    meuAlert(`${error.message}`, "error");
                }  
            }
            else{
                meuAlert(`${error.message}`, "error");
            }            
        });

    },[]); 

    // pegar dados do periodo admin
    useEffect(() => {
        axios.get(`${base_url}/periodo-admin`, {
            "headers": {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            }})
            .then(response => { 
    
                // eslint-disable-next-line
                if((response.data.statusCode != undefined) && (response.data.statusCode != 200)){
                    //window.location.pathname = `mensagem/${response.data.message}`;
                    meuAlert(`${response.data.message}`, "error");
                }
    
                // eslint-disable-next-line 
                if(response.data.length == 0)
                {
                    meuAlert("Não há períodos administrativos.", "error");           
                }
                else
                {
                    //console.log(response.data);
    
                    var periodo_admin_id = document.getElementById("periodo_admin_id");                
                    for(let i=0; i<response.data.length; i++){
                        const option = document.createElement("option");
                        option.value = response.data[i].id;
                        option.innerHTML = response.data[i].ano_inicio+" - "+response.data[i].ano_fim;                    
                        periodo_admin_id.appendChild(option);
                    }
    
                }            
            })    
            .catch(error => { 
                console.log(error.message);
                if(error.response)
                {
                    // eslint-disable-next-line
                    if(error.response.data.statusCode == 401)
                    {
                        window.location.pathname = `login`;
                    }
                    else{
                        meuAlert(`${error.message}`, "error");
                    }  
                }
                else{
                    meuAlert(`${error.message}`, "error");
                }            
            });
    
    },[]); 

    // pegar dados se não for novo
    useEffect(() => {
        
        if(id != "novo")
        {
            axios.get(`${base_url}/pessoa/${id}`, 
            { headers: {
                'Authorization': `Bearer ${token}`,
            }})
            .then(async response => {             
                //console.log("Resposta ok");
                //console.log(response);
                //console.log(response.data);

                document.getElementById("nome").value = response.data.nome;            
                document.getElementById("cpf").value = response.data.cpf; 
                document.getElementById("email").value = response.data.email;                  
                document.getElementById("facebook").value = (response.data.facebook || 'Não informado')
                document.getElementById("instagram").value = (response.data.instagram || 'Não informado')
                document.getElementById("twitter").value = (response.data.twitter || 'Não informado')
                document.getElementById("genero").value = response.data.genero;
                document.getElementById("celular").value = response.data.celular;
                document.getElementById("fone").value = response.data.fone;
                document.getElementById("endereco").value = response.data.endereco;
                document.getElementById("cep").value = response.data.cep;
                document.getElementById("periodo_admin_id").value = response.data.periodo_admin_id;

                if(response.data.sobre){setTexto(response.data.sobre)};
                if(response.data.foto){document.getElementById("foto").src = midia_url+response.data.foto;}
                else{document.getElementById("foto").src = foto_default}   

                if(response.data.status == true){
                    document.getElementById("pessoaativo").checked = true;
                }
                else
                {
                    document.getElementById("pessoainativo").checked = true;
                }                
                
                await cargo(response.data.cargo_id);
                await periodo(response.data.periodo_admin_id);
                
                //var arrayInicial = [];        

            })
            .catch(error => { 
                console.log(error.message);
                if(error.response)
                {
                    if((error.response.data.statusCode == 404) || (error.response.data.statusCode == 400))
                    {
                        if(unavez)
                        {
                            meuAlert("Pessoa não existente.", "warning");
                            //window.location.pathname = `pessoa/editar/novo`;
                            unavez = false;
                        }
                        
                    }
                    else{
                        meuAlert(`${error.message}`, "error");
                    }
                    if(error.response.data.statusCode == 401)
                    {
                        window.location.pathname = `login`;
                    }
                }   
                else{
                    meuAlert(`${error.message}`, "error");
                }            
            });
        }
        else{
            document.getElementById("foto").src = foto_default;
            document.getElementById("facebook").value = ('Não informado')
            document.getElementById("instagram").value = ('Não informado')
            document.getElementById("twitter").value = ('Não informado')
        }
        document.getElementById("notifica").innerHTML = "Processo em andamento, aguarde...";
               
    },[refreshKey]); 


    function verificaVazios() {

        if(document.getElementById("nome").value == ""){meuAlert("Nome da pessoa não preenchido.", "warning");}
        else if(document.getElementById("cpf").value == ""){meuAlert("CPF da pessoa não preenchido.", "warning");}
        else if(document.getElementById("facebook").value == ""){meuAlert("Facebook da pessoa não preenchido.", "warning");}
        else if(document.getElementById("instagram").value == ""){meuAlert("Instagram da pessoa não preenchido.", "warning");}
        else if(document.getElementById("twitter").value == ""){meuAlert("Twitter da pessoa não preenchido.", "warning");}
        else if(document.getElementById("genero").value == ""){meuAlert("Gênero da pessoa não preenchido.", "warning");}
        else if(document.getElementById("email").value == ""){meuAlert("Email da pessoa não preenchida.", "warning");}
        else if(document.getElementById("celular").value == ""){meuAlert("Celular da pessoa não preenchido.", "warning");}
        else if(document.getElementById("fone").value == ""){meuAlert("Fone da pessoa não preenchido.", "warning");}
        else if(document.getElementById("endereco").value == ""){meuAlert("Endereco da pessoa não preenchido.", "warning");}
        else if(document.getElementById("cep").value == ""){meuAlert("CEP da pessoa não preenchido.", "warning");}
        else if(id == "novo"){
            if(document.getElementById("InputCapa").value == "")
            {meuAlert("Foto da pessoa não preenchida.", "warning");}
            else{return true;}
        }
        else
        {
            return true;
        }    
    }

    function selectFuncao(){
        if(id == "novo")
        {
            if(verificaVazios())
            {
                cadastraPessoa();
            };            
        }
        else
        {
            if(verificaVazios())
            {
                atualizaPessoa();
            };    
        }
    }

    // Cadastra pessoa (sem docs)
    async function cadastraPessoa() {

        {handleOpen()} 

        var input = document.querySelector('input[type="file"]')

        var data = new FormData()
        data.append('nome', String(document.getElementById("nome").value))      
        data.append('cpf', String(document.getElementById("cpf").value))     
        data.append('sobre', texto)
        data.append('foto', input.files[0])       
        data.append('facebook', String(document.getElementById("facebook").value))
        data.append('instagram', String(document.getElementById("instagram").value))
        data.append('twitter', String(document.getElementById("twitter").value))
        data.append('genero', String(document.getElementById("genero").value))
        data.append('status', document.querySelector('input[name="statusPessoa"]:checked').value)
        data.append('email', String(document.getElementById("email").value))
        data.append('celular', String(document.getElementById("celular").value))
        data.append('fone', String(document.getElementById("fone").value))
        data.append('endereco', String(document.getElementById("endereco").value))
        data.append('cep', String(document.getElementById("cep").value))
        data.append('periodo_admin_id', String(document.getElementById("periodo_admin_id").value))
        data.append('cargo_id', String(document.getElementById("cargo").value))
        // NÂO PRECISA
        //data.append('cargo', String(document.getElementById("cargo").value))
        //data.append('contratante_id', localStorage.getItem('contratante_id'))      

        //console.log(data);
        
        const url = `${base_url}/pessoa`;
         await axios.post(url, data, {
        "headers": {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 
            //console.log(response);
            //console.log(response.data.id);

            document.getElementById("notifica").innerHTML = "Cadastrado com sucesso!";
            const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

            meuAlert("Cadastrado com sucesso!", "success");
            const redireciona = setInterval(function () {
                clearInterval(redireciona);
                window.location.pathname = `pessoa/editar/${response.data.id}`;
            }, 3000);     
           
        })
        .catch(error => {
            {handleClose()}
            meuAlert(`${error.response.data.message}`, "warning");
            console.log(error.message);
            console.log(error.response.data.message);
            //console.log("Erro "+error.response.data.statusCode);
        })     
    }
    // Atualiza pessoa (sem docs)
    async function atualizaPessoa() {
          
        {handleOpen()} 

        var input = document.querySelector('input[type="file"]')

        var data = new FormData()
        data.append('nome', String(document.getElementById("nome").value))      
        data.append('cpf', String(document.getElementById("cpf").value))     
        data.append('sobre', texto)
        data.append('foto', input.files[0])       
        data.append('facebook', String(document.getElementById("facebook").value))
        data.append('instagram', String(document.getElementById("instagram").value))
        data.append('twitter', String(document.getElementById("twitter").value))
        data.append('genero', String(document.getElementById("genero").value))
        data.append('status', document.querySelector('input[name="statusPessoa"]:checked').value)
        data.append('email', String(document.getElementById("email").value))
        data.append('celular', String(document.getElementById("celular").value))
        data.append('fone', String(document.getElementById("fone").value))
        data.append('endereco', String(document.getElementById("endereco").value))
        data.append('cep', String(document.getElementById("cep").value))
        data.append('periodo_admin_id', String(document.getElementById("periodo_admin_id").value))
        data.append('cargo_id', String(document.getElementById("cargo").value))
        //NÂO PRECISA
        //data.append('cargo', String(document.getElementById("cargo").value))
        //data.append('contratante_id', localStorage.getItem('contratante_id'))      

        //console.log(data);
        
        const url = `${base_url}/pessoa/${id}`;
        await axios.patch(url, data, {
        "headers": {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 
            //console.log(response);
            //console.log(response.data.id);

            document.getElementById("notifica").innerHTML = "Alterado com sucesso!";
            const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

            meuAlert("Alterado com sucesso!", "success");
            const redireciona = setInterval(function () {
                //setRefreshKey(oldKey => oldKey +1);
                window.location.reload();
                clearInterval(redireciona);
            }, 2000);     

        })
        .catch(error => { 
            {handleClose()}
            meuAlert(`${error.response.data.message}`, "warning");
            console.log(error.message);
            console.log(error.response.data.message);
            //console.log("Erro "+error.response.data.statusCode);
        })     
        
    }    
    // Excluir pessoa
    async function ExcluirPessoa() {  

        if (window.confirm("Tem certeza que deseja excluir esta pessoa?")) {        
            
            {handleOpen()} 
         
            const url = `${base_url}/pessoa/${id}`;
            await axios.delete(url, {
            "headers": {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            }})
            .then(response => { 
                //console.log(response);
                //console.log(response.data.id);    
               
                document.getElementById("notifica").innerHTML = "Excluído com sucesso!";
                const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

                meuAlert("Excluído com sucesso!", "success");
                const redireciona = setInterval(function () {
                    clearInterval(redireciona);
                    window.location.pathname = `pessoa/editar/novo`;
                }, 2000);     

            })
            .catch(error => { 
                {handleClose()}
                meuAlert(`${error.message}`, "error");
                console.log(error.message);
                //console.log("Erro "+error.response.data.statusCode);
            })  
        }           
    }

    function cargo(valor){
        for(let i=0; i<document.getElementById("cargo").children.length; i++){
            if(document.getElementById("cargo").children[i].value == valor){
                document.getElementById("cargo").children[i].selected = true;
            }        
        }
    }

    function periodo(valor){
        for(let i=0; i<document.getElementById("periodo_admin_id").children.length; i++){
            if(document.getElementById("periodo_admin_id").children[i].value == valor){
                document.getElementById("periodo_admin_id").children[i].selected = true;
            }        
        }
    }

    return(
        <>   
            <Sidebar page={{ name: 'Pessoas', route: '/pessoa' }}>
                <Box sx={{ width: '100%'}}>
                    <Collapse in={alertAberto}>
                        <Alert
                        severity={tipoAlerta}
                        variant="filled"
                        action={
                            <IconButton
                            aria-label="close"
                            size="small"
                            color="inherit"
                            onClick={() => {
                                setAlertAberto(false);
                            }}
                            >
                            <CloseIcon/>
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        {alertMensagem}
                        </Alert>
                    </Collapse>
                </Box>
                
                <div className="tituloPagina"><p id="titu" >Editar Pessoa</p></div>   

                <div className="botaoExcluirEspec">
                    {id !== "novo" && (
                        <button onClick={ExcluirPessoa}>Excluir Pessoa</button>
                    )}
                </div>

                <div className="semLinha menuPadrao">
                    <li>
                        <button id="0" >Cadastro Geral</button>       
                    </li>  
                </div>

                <div className="formulario" >
                    <div id="cadastroGaleria">
                        <div className="formularioGeral">                                                       
                            <div className="alinhaDireita">

                                <div className="formularioGeralTitulo"><p>Nome da pessoa</p></div>                            
                                <input type="text" name="nome" id="nome" placeholder="Insira o nome da pessoa" autoComplete="off"  ></input>
                                <div className="formularioGeralTitulo"><p>Email da pessoa</p></div>   
                                <input type="text" name="email" id="email" placeholder="Insira o email da pessoa" autoComplete="off"  ></input>
                                                                
                                <div className="novoRadio statusRadio"> 
                                    <div className="formularioGeralTitulo"><p>Status</p></div> 
                                    <input type="radio" id="pessoaativo" name="statusPessoa" value="true"checked></input>
                                    <label for="ativo">Ativo</label>

                                    <input type="radio" id="pessoainativo" name="statusPessoa" value="false"></input>
                                    <label for="inativo">Inativo</label>
                                </div>

                                <div className="formularioGeralTitulo"><p>Gênero da pessoa</p></div>   
                                <input type="text" name="genero" id="genero" placeholder="Insira o gênero da pessoa" autoComplete="off"  ></input>
                                                                
                                <div className="espaco2LadosB_Pessoa"></div>

                                <div className="formularioGeralTitulo"><p>CPF da pessoa</p></div>   
                                <input type="text" name="cpf" id="cpf" placeholder="Insira o CPF da pessoa" autoComplete="off"  ></input>
                                <div className="formularioGeralTitulo"><p>Celular da pessoa</p></div>   
                                <input type="text" name="celular" id="celular" placeholder="Insira o celular da pessoa" autoComplete="off"  ></input>
                                <div className="formularioGeralTitulo"><p>Fone da pessoa</p></div>   
                                <input type="text" name="fone" id="fone" placeholder="Insira o telefone da pessoa" autoComplete="off"  ></input>
                                <div className="formularioGeralTitulo"><p>CEP da pessoa</p></div>   
                                <input type="text" name="cep" id="cep" placeholder="Insira o CEP da pessoa" autoComplete="off"  ></input>
                                <div className="formularioGeralTitulo"><p>Período administrativo da pessoa</p></div>   
                                <select name="periodo_admin_id" id="periodo_admin_id">      
                                </select>

                            </div>

                            <div className="alinhaEsquerda">
                                <img id="foto" name="foto" src={carregando} ></img>
                                
                                <div className="formularioGeralTitulo"><p>Foto da Pessoa</p></div>
                                <input type="file" name="InputCapa" id="InputCapa" autoComplete="off"  ></input>
                                                         
                                <div className="formularioGeralTitulo"><p>Cargo da Pessoa</p></div>
                                <select name="cargo" id="cargo">        
                                </select>
                                
                                <div className="formularioGeralTitulo"><p>Facebook da pessoa</p></div>   
                                <input type="text" name="facebook" id="facebook" placeholder="Insira o Facebook da pessoa" autoComplete="off"  ></input>
                                <div className="formularioGeralTitulo"><p>Instagram da pessoa</p></div>  
                                <input type="text" name="instagram" id="instagram" placeholder="Insira o Instagram da pessoa" autoComplete="off"  ></input>
                                <div className="formularioGeralTitulo"><p>Twitter da pessoa</p></div>   
                                <input type="text" name="twitter" id="twitter" placeholder="Insira o Twitter da pessoa" autoComplete="off"  ></input>
                                
                            </div>    

                            <div className="bloqueiaDiv formularioGeralTitulo"><p>Endereço da pessoa</p></div>   
                            <input type="text" name="endereco" id="endereco" placeholder="Insira o endereço da pessoa" autoComplete="off"  ></input>
                                                                
                        </div>

                        <div className="formularioConteudo">
                            <span>Descrição da pessoa</span>
                            <TextEditor value={texto} onChange={setTexto} />
                            <button onClick={selectFuncao}>Salvar alterações</button>
                        </div>
                    </div>                    
                </div>
            </Sidebar>
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                ><p id="notifica" >vghfgh</p>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </>   
    );
}

export default PessoaEditar;