import { useEffect, useRef, useState } from "react";
import '../../../components/Geral/geral.css';
import './DocsEditar.css'
import carregando from '../../../components/Geral/loading.gif';
import { verificaLogin, fazerLogout } from '../../../context/LoginContext';
import { Editor } from '@tinymce/tinymce-react';
import {base_url, midia_url, foto_default} from '../../../environment'
import axios from 'axios';
import Sidebar from '../../../components/Menu/Sidebar';
import FileUploadMultiple from './uploadDocs.tsx'
import { useParams } from "react-router-dom";

import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
//import Button from '@mui/material/Button';

var unavez = true, umavez = true;
var optionClicado = "background-color: var(--mainColor); color: var(--textColor2);"

function DocsEditar(){
    setInterval(function () {verificaLogin();}, 1000);

    const [refreshKey, setRefreshKey] = useState(0);
    
    function atualiza() {
        setRefreshKey(oldKey => oldKey +1); 
    }

    const [alertAberto, setAlertAberto] = React.useState(false);
    const [alertMensagem, setAlertMensagem] = React.useState('');
    const [tipoAlerta, setTipoAlerta] = React.useState('');

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    function meuAlert(texto, tipo) {
        setTipoAlerta(tipo);
        setAlertMensagem(texto);
        window.location = `#root`;
        setAlertAberto(true);
    }

    function loading() {return <div id='loading'><img src={carregando} alt="Carregando"/></div>; }    
    setInterval(function () {verificaLogin();}, 1000);
    
    var [textoInicial, setTextoInicial] = useState('');    

    // Clicar no primeiro botao e colocar texto inicial
    useEffect(() => {
        
        document.getElementById(0).click();
        setTextoInicial("<p>Escreva seu texto aqui. Aumente o tamanho da área de texto no canto inferior direito.</p>");
    
    },[]);

    const {id} = useParams();
    var token = localStorage.getItem('LOCAL_STORAGE_KEY__ACCESS_TOKEN');
 
    // pegar dados tipo_documento
    useEffect(() => {
    axios.get(`${base_url}/tipo-documento`, {
        "headers": {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 

            // eslint-disable-next-line
            if((response.data.statusCode != undefined) && (response.data.statusCode != 200)){
                //window.location.pathname = `mensagem/${response.data.message}`;
                meuAlert(`${response.data.message}`, "error");
            }

            // eslint-disable-next-line 
            if(response.data.length == 0)
            {
                meuAlert("Não há tipos de documentos.", "error");         
            }
            else
            {
                //console.log(response.data);

                var pessoa = document.getElementById("tipo_documento");                
                for(let i=0; i<response.data.length; i++){
                    const option = document.createElement("option");
                    option.value = response.data[i].id;
                    option.innerHTML = response.data[i].nome;                    
                    pessoa.appendChild(option);
                }

            }            
        })    
        .catch(error => { 
            console.log(error.message);
            if(error.response)
            {
                // eslint-disable-next-line
                if(error.response.data.statusCode == 401)
                {
                    window.location.pathname = `login`;
                }
                else{
                    meuAlert(`${error.message}`, "error");
                }  
            }
            else{
                meuAlert(`${error.message}`, "error");
            }            
        });

    },[]); 

    // pegar dados orgao
    useEffect(() => {
    axios.get(`${base_url}/orgao`, {
        "headers": {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 

            // eslint-disable-next-line
            if((response.data.statusCode != undefined) && (response.data.statusCode != 200)){
                //window.location.pathname = `mensagem/${response.data.message}`;
                meuAlert(`${response.data.message}`, "error");
            }

            // eslint-disable-next-line 
            if(response.data.length == 0)
            {
                meuAlert("Não há órgãos.", "error");           
            }
            else
            {
                //console.log(response.data);

                var pessoa = document.getElementById("orgao");                
                for(let i=0; i<response.data.length; i++){
                    const option = document.createElement("option");
                    option.value = response.data[i].id;
                    option.innerHTML = response.data[i].nome;                    
                    pessoa.appendChild(option);
                }

            }            
        })    
        .catch(error => { 
            console.log(error.message);
            if(error.response)
            {
                // eslint-disable-next-line
                if(error.response.data.statusCode == 401)
                {
                    window.location.pathname = `login`;
                }
                else{
                    meuAlert(`${error.message}`, "error");
                }  
            }
            else{
                meuAlert(`${error.message}`, "error");
            }            
        });

    },[]); 


    // pegar dados grupo_documento
    useEffect(() => {
    axios.get(`${base_url}/grupo-documento`, {
        "headers": {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 

            // eslint-disable-next-line
            if((response.data.statusCode != undefined) && (response.data.statusCode != 200)){
                //window.location.pathname = `mensagem/${response.data.message}`;
                meuAlert(`${response.data.message}`, "error");
            }

            // eslint-disable-next-line 
            if(response.data.length == 0)
            {
                meuAlert("Não há grupos de documentos.", "error");          
            }
            else
            {
                //console.log(response.data);

                var pessoa = document.getElementById("grupo_documento");                
                for(let i=0; i<response.data.length; i++){
                    const option = document.createElement("option");
                    option.value = response.data[i].id;
                    option.innerHTML = response.data[i].nome;                    
                    pessoa.appendChild(option);
                }

            }            
        })    
        .catch(error => { 
            console.log(error.message);
            if(error.response)
            {
                // eslint-disable-next-line
                if(error.response.data.statusCode == 401)
                {
                    window.location.pathname = `login`;
                }
                else{
                    meuAlert(`${error.message}`, "error");
                }  
            }
            else{
                meuAlert(`${error.message}`, "error");
            }            
        });

    },[]); 

    // pegar dados se não for novo
    useEffect(() => {
        
        if(id != "novo")
        {
            axios.get(`${base_url}/documento/${id}`, 
            { headers: {
                'Authorization': `Bearer ${token}`,
            }})
            .then(async response => {             
                //console.log("Resposta ok");
                //console.log(response);
                //console.log(response.data);

                document.getElementById("numero").value = response.data.numero; 

                if(response.data.status == true){document.getElementById("radioativo").checked = true;}
                else{document.getElementById("radioinativo").checked = true;}

                document.getElementById("titulo").value = response.data.titulo;        
                document.getElementById("ementa").value = response.data.ementa; 
                document.getElementById("data").value = new Date(response.data.data).toISOString().split('T')[0];    
                               
                await TipoDoc(response.data.tipo_documento_id);                
                await TipoOrgao(response.data.orgao_id);
                await GrupoDoc(response.data.grupo_documento_id);
                
                //var arrayInicial = [];        

                //arquivo
                if(umavez){
                    document.getElementById("anexoUnico").innerHTML = null;   
                
                    //arrayInicial.push(String('<div><p>'+response.data.Anexo[i].titulo+'</p><a target="_blank" href='+midia_url+response.data.Anexo[i].anexo+'>Baixar</a><a target="_blank" href="#" >Excluir</a></div>')); 
                        
                    var divGeral = document.getElementById("anexoUnico");
                    
                    const divContainter = document.createElement("div");
    
                    const p = document.createElement("p");
                    p.innerHTML = response.data.arquivo.substring(32);
            
                    const a2 = document.createElement("a");
                    a2.target = "_blank";
                    a2.href = String(midia_url+response.data.arquivo);
                    a2.innerHTML = "Baixar";
                    
                    divContainter.appendChild(p);              
                    divContainter.appendChild(a2); 
                        
                    divGeral.appendChild(divContainter);
                       
                    umavez = false;
                }

                //anexos
                if(unavez){
                    document.getElementById("documentosAnexos").innerHTML = null;   
                    for(let i=0; i<response.data.Anexo.length; i++){
                
                        //arrayInicial.push(String('<div><p>'+response.data.Anexo[i].titulo+'</p><a target="_blank" href='+midia_url+response.data.Anexo[i].anexo+'>Baixar</a><a target="_blank" href="#" >Excluir</a></div>')); 
                            
                        var divGeral = document.getElementById("documentosAnexos");
                        
                        const divContainter = document.createElement("div");
        
                        const p = document.createElement("p");
                        p.innerHTML = response.data.Anexo[i].titulo;
        
                        const a = document.createElement("a");
                        a.innerHTML = "Excluir";
                        a.id = response.data.Anexo[i].id;
                        a.addEventListener("click", (e) => ExcluirAnexo(e.target.id));
        
                        const a2 = document.createElement("a");
                        a2.target = "_blank";
                        a2.href = String(midia_url+response.data.Anexo[i].anexo);
                        a2.innerHTML = "Baixar";
                        
                        divContainter.appendChild(p); 
                        divContainter.appendChild(a);                 
                        divContainter.appendChild(a2); 
                            
                        divGeral.appendChild(divContainter);
                        
                    };
                    unavez = false;
                } 

            })
            .catch(error => { 
                console.log(error.message);
                if(error.response)
                {
                    if((error.response.data.statusCode == 404) || (error.response.data.statusCode == 400))
                    {
                        if(unavez)
                        {
                            meuAlert("Documento não existente.", "warning");
                            //window.location.pathname = `documentos/editar/novo`;
                            unavez = false;
                        }
                        
                    }
                    else{
                        meuAlert(`${error.message}`, "error");
                    }
                    if(error.response.data.statusCode == 401)
                    {
                        window.location.pathname = `login`;
                    }
                }   
                else{
                    meuAlert(`${error.message}`, "error");
                }            
            });
        }
        else{
            document.getElementById("cadastroDocRelatorio").innerHTML = null; 
        }
        document.getElementById("notifica").innerHTML = "Processo em andamento, aguarde...";
               
    },[refreshKey]); 

    
    function menuSec(menuId) {
        for(let i=0; i<=1; i++){
            document.getElementById(i).style = null;
        }
        document.getElementById(menuId).style = optionClicado;       
        //console.log("Selecionou o botão " + id);

        // eslint-disable-next-line
        if(menuId != 0)
        {
            document.getElementById("cadastroDocumento").setAttribute('hidden', '');
        }
        else
        {
            document.getElementById("cadastroDocumento").removeAttribute('hidden');
            document.getElementById("cadastroDocDocumento").setAttribute('hidden', '');            
        }

        // eslint-disable-next-line
        if(menuId != 1){
            document.getElementById("cadastroDocDocumento").setAttribute('hidden', '');
        }
        else{
            if(id == "novo")
            {
                meuAlert("Cadastre e salve o documento antes de inserir anexos.", "info");
                //alert("Cadastre e salve o documento antes de inserir anexos.")
                document.getElementById(0).click();   
            }
            else
            {
               document.getElementById("cadastroDocDocumento").removeAttribute('hidden');
                document.getElementById("cadastroDocumento").setAttribute('hidden', ''); 
            }            
        }

    }  

    function verificaVazios() {

        if(document.getElementById("numero").value == ""){meuAlert("Número do documento não preenchido.", "warning");}
        else if(document.getElementById("data").value == ""){meuAlert("Data do documento não preenchido.", "warning");}
        else if(document.getElementById("titulo").value == ""){meuAlert("Título do documento não preenchido.", "warning");}
        else if(document.getElementById("ementa").value == ""){meuAlert("Ementa do documento não preenchido.", "warning");}
       else if(id == "novo"){
            if(document.getElementById("arquivo").value == "")
            {meuAlert("Arquivo não inserido.", "warning");}
            else{return true;}
        }
        else
        {
            return true;
        }    
    }
    
    function selectFuncao(){
        if(id == "novo")
        {
            if(verificaVazios())
            {
                cadastraDoc();
            };            
        }
        else
        {
            if(verificaVazios())
            {
                atualizaDoc();
            };    
        }
    }
    // Cadastra p
    async function cadastraDoc() {

        {handleOpen()} 

        var input = document.getElementById("arquivo");

        var data = new FormData()
        data.append('numero', String(document.getElementById("numero").value))     
        data.append('data', new Date(document.getElementById("data").value).toISOString())   
        data.append('titulo', String(document.getElementById("titulo").value))   
        data.append('arquivo', input.files[0])  
        data.append('ementa', String(document.getElementById("ementa").value))   
        data.append('status', document.querySelector('input[name="statusDoc"]:checked').value)
        data.append('orgao_id', String(document.getElementById("orgao").value))   
        data.append('tipo_documento_id', String(document.getElementById("tipo_documento").value))   
        data.append('grupo_documento_id', String(document.getElementById("grupo_documento").value))  
        //data.append('contratante_id', localStorage.getItem('contratante_id'))     
        // NÂO PRECISA
        //data.append('contratante_id', localStorage.getItem('contratante_id'))      

        //console.log(data);
        console.log(localStorage.getItem('contratante_id'));

        const url = `${base_url}/documento`;
         await axios.post(url, data, {
        "headers": {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 
            //console.log(response);
            //console.log(response.data.id);

            document.getElementById("notifica").innerHTML = "Cadastrado com sucesso!";
            const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

            meuAlert("Cadastrado com sucesso!", "success");
            const redireciona = setInterval(function () {
                clearInterval(redireciona);
                window.location.pathname = `documentos/editar/${response.data.id}`;
            }, 3000);     
           
        })
        .catch(error => {
            {handleClose()}
            meuAlert(`${error.response.data.message}`, "warning");
            console.log(error.message);
            console.log(error.response.data.message);
            //console.log("Erro "+error.response.data.statusCode);
        })     
    }
    // Atualiza ponto-turistico (sem docs)
    async function atualizaDoc() {
          
        {handleOpen()} 

        var input = document.getElementById("arquivo");
        

        var data = new FormData()
        data.append('numero', String(document.getElementById("numero").value))     
        data.append('data', new Date(document.getElementById("data").value).toISOString())   
        data.append('titulo', String(document.getElementById("titulo").value))   
        data.append('arquivo', input.files[0])  
        data.append('ementa', String(document.getElementById("ementa").value))   
        data.append('status', document.querySelector('input[name="statusDoc"]:checked').value)
        data.append('orgao_id', String(document.getElementById("orgao").value))   
        data.append('tipo_documento_id', String(document.getElementById("tipo_documento").value))   
        data.append('grupo_documento_id', String(document.getElementById("grupo_documento").value))  
        //NÂO PRECISA
        //data.append('cargo', String(document.getElementById("cargo").value))
        //data.append('contratante_id', localStorage.getItem('contratante_id'))      

        //console.log(data);
        
        const url = `${base_url}/documento/${id}`;
        await axios.patch(url, data, {
        "headers": {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 
            //console.log(response);
            //console.log(response.data.id);

            document.getElementById("notifica").innerHTML = "Alterado com sucesso!";
            const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

            meuAlert("Alterado com sucesso!", "success");
            const redireciona = setInterval(function () {
                //setRefreshKey(oldKey => oldKey +1);
                window.location.reload();
                clearInterval(redireciona);
            }, 2000);     

        })
        .catch(error => { 
            {handleClose()}
            meuAlert(`${error.response.data.message}`, "warning");
            console.log(error.message);
            console.log(error.response.data.message);
            //console.log("Erro "+error.response.data.statusCode);
        })     
        
    }    
    // Excluir ponto-turistico
    async function ExcluirDoc() {  

        if (window.confirm("Tem certeza que deseja excluir este documento?")) {        
            
            {handleOpen()} 
         
            const url = `${base_url}/documento/${id}`;
            await axios.delete(url, {
            "headers": {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            }})
            .then(response => { 
                //console.log(response);
                //console.log(response.data.id);    
               
                document.getElementById("notifica").innerHTML = "Excluído com sucesso!";
                const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

                meuAlert("Excluído com sucesso!", "success");
                const redireciona = setInterval(function () {
                    clearInterval(redireciona);
                    window.location.pathname = `documentos/editar/novo`;
                }, 2000);     

            })
            .catch(error => { 
                {handleClose()}
                meuAlert(`${error.message}`, "error");
                console.log(error.message);
                //console.log("Erro "+error.response.data.statusCode);
            })  
        }           
    }

    function TipoDoc(valor){
        for(let i=0; i<document.getElementById("tipo_documento").children.length; i++){
            if(document.getElementById("tipo_documento").children[i].value == valor){
                document.getElementById("tipo_documento").children[i].selected = true;
            }        
        }
    }

    function GrupoDoc(valor){
        for(let i=0; i<document.getElementById("grupo_documento").children.length; i++){
            if(document.getElementById("grupo_documento").children[i].value == valor){
                document.getElementById("grupo_documento").children[i].selected = true;
            }        
        }
    }

    function TipoOrgao(valor){
        for(let i=0; i<document.getElementById("orgao").children.length; i++){
            if(document.getElementById("orgao").children[i].value == valor){
                document.getElementById("orgao").children[i].selected = true;
            }        
        }
    }

    // Excluir anexo
    async function ExcluirAnexo(idAnexo) {  

        if (window.confirm("Tem certeza que deseja excluir esse anexo?")) {     
            
            document.getElementById("notifica").innerHTML = "Processo em andamento, aguarde...";
            
            {handleOpen()}    

            const url = `${base_url}/documento/anexo/${idAnexo}`;
            await axios.delete(url, {
            "headers": {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            }})
            .then(response => { 
                //console.log(response);
                //console.log(response.data.id);
                //window.location.pathname = `documento/editar/${id}`;

                document.getElementById("notifica").innerHTML = "Anexo excluído com sucesso!";
                const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

                meuAlert("Anexo excluído com sucesso!", "success");
                const redireciona = setInterval(function () {
                    //setRefreshKey(oldKey => oldKey +1); 
                    clearInterval(redireciona);
                    window.location.reload();
                }, 2000);     

            })
            .catch(error => { 
                {handleClose()}
                meuAlert(`${error.message}`, "error");
                console.log(error.message);
                //console.log("Erro "+error.response.data.statusCode);
            })  
        }           
    }

    return(
        <>   
            <Sidebar page={{ name: 'Documentos', route: '/documentos' }}>
                <Box sx={{ width: '100%'}}>
                    <Collapse in={alertAberto}>
                        <Alert
                        severity={tipoAlerta}
                        variant="filled"
                        action={
                            <IconButton
                            aria-label="close"
                            size="small"
                            color="inherit"
                            onClick={() => {
                                setAlertAberto(false);
                            }}
                            >
                            <CloseIcon/>
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        {alertMensagem}
                        </Alert>
                    </Collapse>
                </Box>
                
                <div className="tituloPagina"><p id="titu" >Editar documento</p></div>   

                <div className="botaoExcluirEspec" >
                    {id !== "novo" && (
                        <div className="botaoExcluirEspec">
                            <button onClick={ExcluirDoc}>Excluir Documento</button>
                        </div>
                    )}

                </div>


                <div className="semLinha menuPadrao">
                    <li>
                        <button id="0" onClick={(e) => menuSec(e.target.id)}>Cadastro Geral</button>                        
                        <button id="1" onClick={(e) => menuSec(e.target.id)}>Anexos</button>   
                    </li>  
                </div>

                <div className="formulario" >
                    <div id="cadastroDocumento">
                        <div className="formRedondo formularioGeral ">                                                       
                            
                            <div className="alinhaDireita">
                                
                                <div className="novoRadio statusRadio"> 
                                    <div className="formularioGeralTitulo"><p>Status do documento</p></div> 
                                    <input type="radio" id="radioativo" name="statusDoc" value="true"checked></input>
                                    <label for="ativo">Ativo</label>

                                    <input type="radio" id="radioinativo" name="statusDoc" value="false"></input>
                                    <label for="inativo">Inativo</label>
                                </div>

                                <div className="espaco2LadosB_Docs"></div>
                                                                
                                <div className="formularioGeralTitulo"><p>Ementa</p></div>                            
                                <input type="text" name="ementa" id="ementa" placeholder="Insira a ementa do documento" autoComplete="off"  ></input>

                                
                                <div className="formularioGeralTitulo"><p>Órgão</p></div>   
                                <select name="orgao" id="orgao">
                                </select>

                                <div className="formularioGeralTitulo"><p>Tipo de Documento</p></div>   
                                <select name="tipo_documento" id="tipo_documento">
                                </select>


                            </div>    

                            <div className="alinhaEsquerda">

                                <div className="formularioGeralTitulo"><p>Número do documento</p></div>                            
                                <input type="number" name="numero" id="numero" placeholder="Insira o número do documento" autoComplete="off"  ></input>

                                <div className="formularioGeralTitulo"><p>Título do documento</p></div>                            
                                <input type="text" name="titulo" id="titulo" placeholder="Insira o título do documento" autoComplete="off"  ></input>

                                <div className="formularioGeralTitulo"><p>Data do documento</p></div>
                                <input type="date" name="data" id="data" autoComplete="off"  ></input>
                                                                
                                <div className="formularioGeralTitulo"><p>Grupo de Documento</p></div>   
                                <select name="grupo_documento" id="grupo_documento">
                                </select>
                                
                                <div className="formularioGeralTitulo"><p>Arquivo</p></div>
                                <input type="file" name="arquivo" id="arquivo" autoComplete="off"  ></input>
                                                                                   
                            </div>

                            <div id="cadastroDocRelatorio">
                                <div className="removeBordu cadastroDocInterno">
                                    <p>Arquivo cadastrado: Para substituir, insira novo arquivo acima</p>
                                    <div id="anexoUnico" className="anexos">
                                        {loading()}
                                    </div>       
                                </div>
                            </div>   
                                                         
                            <button onClick={selectFuncao}>Salvar alterações</button>

                        </div>

                    </div>                    
                </div>

                <div className="cadastroDoc">
                    <div id="cadastroDocDocumento">
                        <div className="cadastroDocInterno">
                            <p>Documentos Anexos</p>
                            <div id="documentosAnexos" className="anexos">
                                {loading()}
                            </div>       
                            <span>Clique no botão ou solte os arquivos abaixo para anexar.</span>
                            <FileUploadMultiple/>
                        </div>
                    </div>                        
                </div>
            </Sidebar>
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                ><p id="notifica" >vghfgh</p>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </>   
    );
}

export default DocsEditar;