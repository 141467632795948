import { useEffect, useState } from "react";
import '../../../components/Geral/geral.css';
import '../../../components/Geral/tabela.css';
import './Relatorio-lrf.css'
import carregando from '../../../components/Geral/loading.gif';
import { base_url, midia_url } from '../../../environment'
import Sidebar from '../../../components/Menu/Sidebar';
import axios from 'axios';
import { verificaLogin } from '../../../context/LoginContext';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { BiEdit } from "react-icons/bi"
import { MdDeleteForever } from "react-icons/md"
import { BsFillCheckCircleFill } from "react-icons/bs"
import { GoCircleSlash } from "react-icons/go"

import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

function RelatorioLrf() {
    setInterval(function () { verificaLogin(); }, 1000);

    const [tables, setTables] = useState([]);
    const [refreshKey, setRefreshKey] = useState(0);

    const [alertAberto, setAlertAberto] = React.useState(false);
    const [alertMensagem, setAlertMensagem] = React.useState('');
    const [tipoAlerta, setTipoAlerta] = React.useState('');


    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    function meuAlert(texto, tipo) {
        setTipoAlerta(tipo);
        setAlertMensagem(texto);
        window.location = `#root`;
        setAlertAberto(true);
    }

    var token = localStorage.getItem('LOCAL_STORAGE_KEY__ACCESS_TOKEN');

    function loading() {
        return <div id='loading'><img src={carregando} alt="Carregando" /></div>;
    }

    // pegar dados da relatorio-lrf
    useEffect(() => {

        axios.get(`${base_url}/relatorio-lrf`, {
            "headers": {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {

                if ((response.data.statusCode != undefined) && (response.data.statusCode != 200)) {
                    meuAlert(`${response.data.message}`, "error");
                }

                if (response.data.length === 0) {
                    document.getElementById("relatoriolrf").innerHTML = '<button><p>Não há relatórios.</p></button>';

                } else {
                    setTables(response.data);
                    document.getElementById("relatoriolrf").innerHTML = null;
                }
            })
            .catch(error => {
                console.log(error.message);
                if (error.response) {
                    if (error.response.data.statusCode === 401) {
                        window.location.pathname = `login`;
                    } else {
                        meuAlert(`${error.message}`, "error");
                    }
                } else {
                    meuAlert(`${error.message}`, "error");
                }
            });

        document.getElementById("mensagem").innerHTML = "Processo em andamento, aguarde...";
    }, [refreshKey]);

    // Excluir relatorio
    async function ExcluirRelatorio(id) {
        if (window.confirm("Tem certeza que deseja excluir esse relatório?")) {
            { handleOpen() }

            const url = `${base_url}/relatorio-lrf/${id}`;
            await axios.delete(url, {
                "headers": {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            })
                .then(response => {
                    document.getElementById("mensagem").innerHTML = "Operação finalizada!";
                    const mostrar = setInterval(function () { { handleClose() }; clearInterval(mostrar); }, 2000);
                    meuAlert("Excluído com sucesso!", "success");
                    const redireciona = setInterval(function () { setRefreshKey(oldKey => oldKey + 1); clearInterval(redireciona); }, 2000);

                    // Remove o registro excluído da tabela
                    setTables(prevTables => prevTables.filter(item => item.id !== id));

                    // Exibir mensagem quando a tabela estiver vazia
                    if (tables.length === 0) {
                        document.getElementById("relatoriolrf").innerHTML = '<button><p>Não há relatórios.</p></button>';
                    } else {
                        document.getElementById("relatoriolrf").innerHTML = null;
                    }
                })
                .catch(error => {
                    { handleClose() }
                    meuAlert(`${error.message}`, "error");
                    console.log(error.message);
                })
        }
    }


    function mostraStatus(status) {
        if (status) {
            return <a className="true">< BsFillCheckCircleFill /></a>;
        } else {
            return <a className="false">< GoCircleSlash /></a>;
        }
    }

    function marcaTodos() {

        var itens = 0;

        if (document.getElementById("checkGeral").checked) {
            while (document.getElementById(itens)) {
                document.getElementById(itens).checked = true;
                itens++;
            }
        } else {
            while (document.getElementById(itens)) {
                document.getElementById(itens).checked = false;
                itens++;
            }
        }
    }

    async function opcoesMarcados(acao) {

        var itens = 0;
        var passadas = 0;
        while (document.getElementById(itens)) { itens++; }

        { handleOpen() }

        for (var valor = 0; valor < itens; valor++) {
            if ((acao === "excluir") && document.getElementById(valor).checked) {
                const url = `${base_url}/relatorio-lrf/${tables[valor].id}`;
                await axios.delete(url, {
                    "headers": {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    }
                })
                    .then(response => { })
                    .catch(error => {
                        { handleClose() }
                        meuAlert(`${error.message}`, "error");
                        console.log(error.message);
                        passadas = -100;
                    })
            }
            passadas++;
            if ((acao === "inativar") && document.getElementById(valor).checked) {
                const params = JSON.stringify({
                    "status": false,
                });

                const url = `${base_url}/relatorio-lrf/${tables[valor].id}`;
                await axios.patch(url, params, {
                    "headers": {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    }
                })
                    .then(response => { })
                    .catch(error => {
                        { handleClose() }
                        meuAlert(`${error.message}`, "error");
                        console.log(error.message);
                        passadas = -100;
                    })
            }
            if ((acao === "ativar") && document.getElementById(valor).checked) {
                const params = JSON.stringify({
                    "status": true,
                });

                const url = `${base_url}/relatorio-lrf/${tables[valor].id}`;
                await axios.patch(url, params, {
                    "headers": {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    }
                })
                    .then(response => { })
                    .catch(error => {
                        { handleClose() }
                        meuAlert(`${error.message}`, "error");
                        console.log(error.message);
                        passadas = -100;
                    })
            }
            if (itens === passadas) {
                document.getElementById("mensagem").innerHTML = "Operação finalizada!";
                const mostrar = setInterval(function () { { handleClose() }; clearInterval(mostrar); }, 2000);
                meuAlert("Operação finalizada!", "success");
                const redireciona = setInterval(function () { setRefreshKey(oldKey => oldKey + 1); clearInterval(redireciona); }, 2000);

                document.getElementById("checkGeral").checked = false;
                marcaTodos();
            }
        }
    }

    return (
        <>
            <Sidebar>
                <Box sx={{ width: '100%' }}>
                    <Collapse in={alertAberto}>
                        <Alert
                            severity={tipoAlerta}
                            variant="filled"
                            action={
                                <IconButton
                                    aria-label="close"
                                    size="small"
                                    color="inherit"
                                    onClick={() => {
                                        setAlertAberto(false);
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                        >
                            {alertMensagem}
                        </Alert>
                    </Collapse>
                </Box>

                <div className="tituloPagina">
                    <p id="titu" >Relatório Lrf</p>
                </div>
                <div className="optionNovo" >
                    <button onClick={(e) => window.location.pathname = `relatoriolrf/editar/novo`}>Novo Relatório Lrf</button>

                </div>

                <div className="optionsBotoes" >
                    <button onClick={(e) => opcoesMarcados("ativar")}>Ativar</button>
                    <button onClick={(e) => opcoesMarcados("inativar")}>Inativar</button>
                    <button onClick={(e) => opcoesMarcados("excluir")}>Excluir</button>
                </div>

                <div className="tabelaPadrao">
                    <TableContainer className="modal-content">
                        <Table sx={{ minWidth: 650 }} arial-label="caption table" className="table">
                            <TableHead>
                                <TableRow>
                                    <TableCell><input type="checkbox" id="checkGeral" onClick={(e) => marcaTodos()}></input></TableCell>
                                    <TableCell>Título</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Data</TableCell>
                                    <TableCell>Referência</TableCell>
                                    <TableCell>Tipo</TableCell>
                                    <TableCell>Editar</TableCell>
                                    <TableCell>Excluir</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tables.map((item, index) => (
                                    <TableRow key={item.id}>
                                        <TableCell><input type="checkbox" id={index} ></input></TableCell>
                                        <TableCell>{item.titulo || '--'}</TableCell>
                                        <TableCell>{mostraStatus(item.status) || '--'}</TableCell>
                                        <TableCell>{new Date(item.data).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).toString() || '--'}</TableCell>
                                        <TableCell>{item.referencia || '--'}</TableCell>
                                        <TableCell>{item.Tipo.nome.substring(0, 4) || '--'}</TableCell>
                                        <TableCell><a target="_self" href={"relatoriolrf/editar/" + item.id}><BiEdit /></a></TableCell>
                                        <TableCell><a target="_self" href="#" onClick={(e) => ExcluirRelatorio(item.id)}><MdDeleteForever /></a></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>

                <div className="loading" >
                    <div id="relatoriolrf">{loading()}</div>
                </div>
            </Sidebar>
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                ><p id="mensagem" ></p>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </>
    );
}

export default RelatorioLrf;