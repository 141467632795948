import { useEffect, useRef, useState } from "react";
import '../../../components/Geral/geral.css';
import './DoseAplicadaEditar.css'
import carregando from '../../../components/Geral/loading.gif';
import { verificaLogin, fazerLogout } from '../../../context/LoginContext';
import {base_url, midia_url} from '../../../environment'
import axios from 'axios';
import Sidebar from '../../../components/Menu/Sidebar';
import { useParams } from "react-router-dom";

import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
//import Button from '@mui/material/Button';

var unavez = true, umavez = true;

function DoseAplicadaEditar(){
    setInterval(function () {verificaLogin();}, 1000);

    const [refreshKey, setRefreshKey] = useState(0);
    
    function atualiza() {
        setRefreshKey(oldKey => oldKey +1); 
    }

    const [alertAberto, setAlertAberto] = React.useState(false);
    const [alertMensagem, setAlertMensagem] = React.useState('');
    const [tipoAlerta, setTipoAlerta] = React.useState('');

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    function meuAlert(texto, tipo) {
        setTipoAlerta(tipo);
        setAlertMensagem(texto);
        window.location = `#root`;
        setAlertAberto(true);
    }

    function loading() {return <div id='loading'><img src={carregando} alt="Carregando"/></div>; }    
    setInterval(function () {verificaLogin();}, 1000);
    
    // Clicar no primeiro botao e colocar texto inicial
    useEffect(() => {
        
        document.getElementById(0).click();
        
    },[]);

    const {id} = useParams();
    var token = localStorage.getItem('LOCAL_STORAGE_KEY__ACCESS_TOKEN');

    // pegar dados grupo_vacinado
    useEffect(() => {
    axios.get(`${base_url}/grupo-vacinado`, {
        "headers": {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 

            // eslint-disable-next-line
            if((response.data.statusCode != undefined) && (response.data.statusCode != 200)){
                //window.location.pathname = `mensagem/${response.data.message}`;
                meuAlert(`${response.data.message}`, "error");
            }

            // eslint-disable-next-line 
            if(response.data.length == 0)
            {
                meuAlert("Não há grupos vacinados.", "error");            
            }
            else
            {
                //console.log(response.data);

                var pessoa = document.getElementById("grupo_vacinado");                
                for(let i=0; i<response.data.length; i++){
                    const option = document.createElement("option");
                    option.value = response.data[i].id;
                    option.innerHTML = response.data[i].nome;                    
                    pessoa.appendChild(option);
                }

            }            
        })    
        .catch(error => { 
            console.log(error.message);
            if(error.response)
            {
                // eslint-disable-next-line
                if(error.response.data.statusCode == 401)
                {
                    window.location.pathname = `login`;
                }
                else{
                    meuAlert(`${error.message}`, "error");
                }  
            }
            else{
                meuAlert(`${error.message}`, "error");
            }            
        });

    },[]); 

    // pegar dados dose
    useEffect(() => {
    axios.get(`${base_url}/dose`, {
        "headers": {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 

            // eslint-disable-next-line
            if((response.data.statusCode != undefined) && (response.data.statusCode != 200)){
                //window.location.pathname = `mensagem/${response.data.message}`;
                meuAlert(`${response.data.message}`, "error");
            }

            // eslint-disable-next-line 
            if(response.data.length == 0)
            {
                meuAlert("Não há doses.", "error");            
            }
            else
            {
                //console.log(response.data);

                var pessoa = document.getElementById("dose");                
                for(let i=0; i<response.data.length; i++){
                    const option = document.createElement("option");
                    option.value = response.data[i].id;
                    option.innerHTML = response.data[i].nome;                    
                    pessoa.appendChild(option);
                }

            }            
        })    
        .catch(error => { 
            console.log(error.message);
            if(error.response)
            {
                // eslint-disable-next-line
                if(error.response.data.statusCode == 401)
                {
                    window.location.pathname = `login`;
                }
                else{
                    meuAlert(`${error.message}`, "error");
                }  
            }
            else{
                meuAlert(`${error.message}`, "error");
            }            
        });

    },[]); 
        
    // pegar dados se não for novo
    useEffect(() => {
              
        if(id != "novo")
        {
            axios.get(`${base_url}/dose-aplicada/${id}`, 
            { headers: {
                'Authorization': `Bearer ${token}`,
            }})
            .then(async response => {             
                //console.log("Resposta ok");
                //console.log(response);
                //console.log(response.data);
                
                document.getElementById("quantidade").value = response.data.quantidade;

                await Dose(response.data.dose_id);
                await GrupoVacinado(response.data.grupo_vacinado_id);

            })
            .catch(error => { 
                console.log(error.message);
                if(error.response)
                {
                    if((error.response.data.statusCode == 404) || (error.response.data.statusCode == 400))
                    {
                        if(unavez)
                        {
                            meuAlert("Dose aplicada não existente.", "warning");
                            //window.location.pathname = `concurso/editar/novo`;
                            unavez = false;
                        }
                        
                    }
                    else{
                        meuAlert(`${error.message}`, "error");
                    }
                    if(error.response.data.statusCode == 401)
                    {
                        window.location.pathname = `login`;
                    }
                }   
                else{
                    meuAlert(`${error.message}`, "error");
                }            
            });
        }
        document.getElementById("notifica").innerHTML = "Processo em andamento, aguarde...";
               
    },[refreshKey]); 

    function verificaVazios() {
        if(document.getElementById("quantidade").value == ""){meuAlert("Quantidade não preenchida.", "warning");}
        else
        {
            return true;
        }   
    }
    
    function selectFuncao(){
        if(id == "novo")
        {
            if(verificaVazios())
            {
                cadastraDoseAplicada();
            };            
        }
        else
        {
            if(verificaVazios())
            {
                atualizaDoseAplicada();
            };    
        }
    }

    // Cadastra Cargo (sem docs)
    async function cadastraDoseAplicada() {

        {handleOpen()} 

        const params = JSON.stringify({            
            "quantidade": String(document.getElementById("quantidade").value),
            "dose_id": String(document.getElementById("dose").value),
            "grupo_vacinado_id": String(document.getElementById("grupo_vacinado").value),
            "contratante_id": localStorage.getItem('contratante_id'),
        });

        //console.log(params);
        
        const url = `${base_url}/dose-aplicada`;
         await axios.post(url, params, {
        "headers": {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 
            //console.log(response);
            //console.log(response.data.id);

            document.getElementById("notifica").innerHTML = "Cadastrado com sucesso!";
            const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

            meuAlert("Cadastrado com sucesso!", "success");
            const redireciona = setInterval(function () {
                clearInterval(redireciona);
                window.location.pathname = `dose-aplicada/editar/${response.data.id}`;
            }, 3000);     
           
        })
        .catch(error => {
            {handleClose()}
            meuAlert(`${error.response.data.message}`, "warning");
            console.log(error.message);
            console.log(error.response.data.message);
            //console.log("Erro "+error.response.data.statusCode);
        })     
    }

    // Atualiza Cargo (sem docs)
    async function atualizaDoseAplicada() {
          
        {handleOpen()} 

        const params = JSON.stringify({
            "quantidade": String(document.getElementById("quantidade").value),
            "dose_id": String(document.getElementById("dose").value),
            "grupo_vacinado_id": String(document.getElementById("grupo_vacinado").value)
        });

        //console.log(params);
        
        const url = `${base_url}/dose-aplicada/${id}`;
        await axios.patch(url, params, {
        "headers": {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 
            //console.log(response);
            //console.log(response.data.id);

            document.getElementById("notifica").innerHTML = "Alterado com sucesso!";
            const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

            meuAlert("Alterado com sucesso!", "success");
            const redireciona = setInterval(function () {atualiza(); clearInterval(redireciona);}, 2000);     

        })
        .catch(error => { 
            {handleClose()}
            meuAlert(`${error.response.data.message}`, "warning");
            console.log(error.message);
            console.log(error.response.data.message);
            //console.log("Erro "+error.response.data.statusCode);
        })     
        
    }
    
    // Excluir Cargo
    async function ExcluirTipoDoc() {  

        if (window.confirm("Tem certeza que deseja excluir esta Dose Aplicada?")) {        
            
            {handleOpen()} 
         
            const url = `${base_url}/dose-aplicada/${id}`;
            await axios.delete(url, {
            "headers": {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            }})
            .then(response => { 
                //console.log(response);
                //console.log(response.data.id);    
               
                document.getElementById("notifica").innerHTML = "Excluído com sucesso!";
                const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

                meuAlert("Excluído com sucesso!", "success");
                const redireciona = setInterval(function () {
                    clearInterval(redireciona);
                    window.location.pathname = `dose-aplicada/editar/novo`;
                }, 2000);     

            })
            .catch(error => { 
                {handleClose()}
                meuAlert(`${error.message}`, "error");
                console.log(error.message);
                //console.log("Erro "+error.response.data.statusCode);
            })  
        }           
    }

    function GrupoVacinado(valor){
        for(let i=0; i<document.getElementById("grupo_vacinado").children.length; i++){
            if(document.getElementById("grupo_vacinado").children[i].value == valor){
                document.getElementById("grupo_vacinado").children[i].selected = true;
            }        
        }
    }

    function Dose(valor){
        for(let i=0; i<document.getElementById("dose").children.length; i++){
            if(document.getElementById("dose").children[i].value == valor){
                document.getElementById("dose").children[i].selected = true;
            }        
        }
    }


    return(
        <>   
            <Sidebar page={{ name: 'Doses Aplicadas', route: '/dose-aplicada' }}>
                <Box sx={{ width: '100%'}}>
                    <Collapse in={alertAberto}>
                        <Alert
                        severity={tipoAlerta}
                        variant="filled"
                        action={
                            <IconButton
                            aria-label="close"
                            size="small"
                            color="inherit"
                            onClick={() => {
                                setAlertAberto(false);
                            }}
                            >
                            <CloseIcon/>
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        {alertMensagem}
                        </Alert>
                    </Collapse>
                </Box>
                
                <div className="tituloPagina"><p id="titu" >Editar Dose Aplicada</p></div>   

                <div className="botaoExcluirEspec" >
                    {id !== "novo" && (
                        <div className="botaoExcluirEspec">
                            <button onClick={ExcluirTipoDoc}>Excluir Dose Aplicada</button>
                        </div>
                    )}

                </div>


                <div className="semLinha menuPadrao">
                    <li>
                        <button id="0" >Cadastro Geral</button>
                    </li>  
                </div>

                <div className="formulario" >
                    <div id="cadastroCargo">
                        <div className="formRedondo DoseAplicadaEditar formularioGeral">
                            
                            <div className="formularioGeralTitulo"><p>Quantidade</p><p>Dose</p></div>
                            <input type="number" name="quantidade" id="quantidade" autoComplete="off"  ></input>
                            <select name="dose" id="dose">
                            </select>

                            <div className="formularioGeralTitulo"><p>Grupo Vacinado</p></div>
                            <select name="grupo_vacinado" id="grupo_vacinado">
                            </select>

                            <button onClick={(e) => selectFuncao()} >Salvar alterações</button>
                        </div>

                    </div>                    
                </div>
            </Sidebar>
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                ><p id="notifica" >vghfgh</p>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </>   
    );
}

export default DoseAplicadaEditar;