import React from "react";
import './Login.css'
import carregando from '../../components/Geral/loading.gif';
import {base_url} from '../../environment'
import axios from 'axios';
import { useParams } from "react-router-dom";

function Login(){
    var {mensagem} = useParams(); 

    const [email, setEmail] = React.useState("");
    const [senha, setSenha] = React.useState("");

    function loading() {
        return <div id='loading'><img src={carregando} alt="Carregando"/></div>;
    }

    function handleSubmit(event) {
        document.getElementById('mensagem').innerHTML = "";
        event.preventDefault();

        const params = JSON.stringify({
            "email": email,
            "senha": senha,
        });

        handleLogin(params);        
    }

    async function handleLogin(params) {
        document.getElementById('formularioLogin').setAttribute('hidden', '');
        document.getElementById('loadingLogin').removeAttribute('hidden');

        const url = `${base_url}/auth/login`;
        await axios.post(url, params, {
            "headers": {
                "content-type": "application/json",
                "Access-Control-Allow-Origin": "true",
            }
        }).then(response => {
            localStorage.setItem('LOCAL_STORAGE_KEY__ACCESS_TOKEN', response.data.token);
            localStorage.setItem('USUARIO_LOGADO', response.data.usuario.id);
            //console.log(response.data.usuario.id);
            window.location.pathname = `/`;
            //console.log(response.data);
        }).catch(error => {
            if (error.response) {
                if(error.response.data.statusCode === 401) {
                    window.location.pathname = `login/Login ou senha incorretos`;
                } else {
                    window.location.pathname = `login/${error}`;
                }
            } else {
                alert("Ocorreu um erro, verifique o log.")
            }                
        })        
    } 

    const styles = {
        backgroundImage: `url(https://www.pixelstalk.net/wp-content/uploads/2016/05/Beautiful-Gradient-Wallpaper-620x388.jpg`,
    }

    return(
        <>   
            <div className="fundoLogin" style={styles}>
                <div className="login"> 
                    <div className="tituloPagina"><p id="titu" >Administrador</p></div>  

                    <form id="formularioLogin" onSubmit={handleSubmit} method="post" encType="application/json">
                        <input type="text" name="email" id="email" placeholder="Email" autoComplete="off"  onChange={(e) => setEmail(e.target.value)} />
                        <input type="password" name="senha" id="senha" placeholder="Senha" autoComplete="off"  onChange={(e) => setSenha(e.target.value)} />
                        <button type="submit">Login</button>
                    </form>

                    <div hidden id="loadingLogin">{loading()} Fazendo login, aguarde... </div>

                    <h1 id="mensagem">{mensagem}</h1>
                </div>  
            </div>             
        </>   
    );
}

export default Login;