import { useEffect, useRef, useState } from "react";
import '../../../components/Geral/geral.css';
import './UsuarioEditar.css'
import carregando from '../../../components/Geral/loading.gif';
import { verificaLogin} from '../../../context/LoginContext';
import {base_url, midia_url, foto_default} from '../../../environment'
import axios from 'axios';
import Sidebar from '../../../components/Menu/Sidebar';
import { useParams } from "react-router-dom";

import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
//import Button from '@mui/material/Button';
var optionClicado = "background-color: var(--mainColor); color: var(--textColor2);"

var unavez = true, umavez = true;
const permissoes = [];

function UsuarioEditar(){
    setInterval(function () {verificaLogin();}, 1000);

    const [refreshKey, setRefreshKey] = useState(0);
    
    function atualiza() {
        setRefreshKey(oldKey => oldKey +1); 
    }

    const [alertAberto, setAlertAberto] = React.useState(false);
    const [alertMensagem, setAlertMensagem] = React.useState('');
    const [tipoAlerta, setTipoAlerta] = React.useState('');

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    function meuAlert(texto, tipo) {
        setTipoAlerta(tipo);
        setAlertMensagem(texto);
        window.location = `#root`;
        setAlertAberto(true);
    }

    function loading() {return <div id='loading'><img src={carregando} alt="Carregando"/></div>; }    
    setInterval(function () {verificaLogin();}, 1000);
    
    var [textoInicial, setTextoInicial] = useState('');    

    // Clicar no primeiro botao e colocar texto inicial
    useEffect(() => {
        
        document.getElementById(0).click();
        setTextoInicial("<p>Escreva seu texto aqui. Aumente o tamanho da área de texto no canto inferior direito.</p>");
    
    },[]);

    const {id} = useParams();
    var token = localStorage.getItem('LOCAL_STORAGE_KEY__ACCESS_TOKEN');
 
   

    // pegar dados roles
    useEffect(() => {
        axios.get(`${base_url}/roles`, {
        "headers": {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 

            // eslint-disable-next-line
            if((response.data.statusCode != undefined) && (response.data.statusCode != 200)){
                //window.location.pathname = `mensagem/${response.data.message}`;
                meuAlert(`${response.data.message}`, "error");
            }

            // eslint-disable-next-line 
            if(response.data.length == 0)
            {
                meuAlert("Não há roles.", "error");           
            }
            else
            {
                //console.log(response.data);
                //console.log(response.data.id);
                  
                for(let i=0; i<response.data.length; i++){
                    permissoes.push(response.data[i]);
                } 

                console.log({permissoes})
            }            
        })    
        .catch(error => { 
            console.log(error.message);
            if(error.response)
            {
                // eslint-disable-next-line
                if(error.response.data.statusCode == 401)
                {
                    window.location.pathname = `login`;
                }
                else{
                    meuAlert(`${error.message}`, "error");
                }  
            }
            else{
                meuAlert(`${error.message}`, "error");
            }            
        });

    },[]); 

    // pegar dados se não for novo
    useEffect(() => {
        
        if(id != "novo")
        {
            //document.getElementById("cadastraSenhaUsuario").innerHTML = null;      
                        
            //document.getElementById("contratante").disabled = true;

            axios.get(`${base_url}/usuario/${id}`, 
            { headers: {
                'Authorization': `Bearer ${token}`,
            }})
            .then(async response => {             
                //console.log("Resposta ok");
                //console.log(response);
                //console.log(response.data);

                document.getElementById("nome").value = response.data.nome;       
                document.getElementById("email").value = response.data.email;       
                document.getElementById("cpf").value = response.data.cpf;            

                if(response.data.foto){document.getElementById("capa").src = midia_url+response.data.foto;}
                else{document.getElementById("capa").src = foto_default}   

                //permissoes
                await MarcaAcessos();

                function MarcaAcessos(){
                    for(let i=0; i<permissoes.length; i++){
            
                        if(response.data.Roles.includes(permissoes[i])){document.getElementById(permissoes[i]).checked = true;}
                    }
                }

                //await QualContratante(response.data.contratante_id);

                if(response.data.status == true){
                    document.getElementById("radioativo").checked = true;
                }
                else
                {
                    document.getElementById("radioinativo").checked = true;
                }
                                     
            })
            .catch(error => { 
                console.log(error.message);
                if(error.response)
                {
                    if((error.response.data.statusCode == 404) || (error.response.data.statusCode == 400))
                    {
                        if(unavez)
                        {
                            meuAlert("Pessoa não existente.", "warning");
                            //window.location.pathname = `usuario/editar/novo`;
                            unavez = false;
                        }
                        
                    }
                    else{
                        meuAlert(`${error.message}`, "error");
                    }
                    if(error.response.data.statusCode == 401)
                    {
                        window.location.pathname = `login`;
                    }
                }   
                else{
                    meuAlert(`${error.message}`, "error");
                }            
            });
        }
        else{
            document.getElementById("capa").src = foto_default;
        
        }
        document.getElementById("notifica").innerHTML = "Processo em andamento, aguarde...";
               
    },[refreshKey]); 


    function menuSec(menuId) {
        for(let i=0; i<=1; i++){
            document.getElementById(i).style = null;
        }
        document.getElementById(menuId).style = optionClicado;       
        //console.log("Selecionou o botão " + id);

        // eslint-disable-next-line
        if(menuId != 0)
        {
            document.getElementById("geral").setAttribute('hidden', '');
        }
        else
        {
            document.getElementById("geral").removeAttribute('hidden');        
        }

        // eslint-disable-next-line
        if(menuId != 1)
        {
            document.getElementById("permissoes").setAttribute('hidden', '');
        }
        else
        {
            document.getElementById("permissoes").removeAttribute('hidden');        
        }


    }  

    function verificaVazios() {

        if(document.getElementById("nome").value == ""){meuAlert("Nome não preenchido.", "warning");}
        else if(document.getElementById("email").value == ""){meuAlert("Email não preenchido.", "warning");}
        else if(document.getElementById("cpf").value == ""){meuAlert("CPF não preenchido.", "warning");}
        else if(document.getElementById("capa").value == ""){meuAlert("Foto do usuário não preenchida.", "warning");}
        else if(id == "novo"){
            if(document.getElementById("InputCapa").value == "")
            {meuAlert("Foto do usuário não preenchida.", "warning");}
            else{return true;}
        }
        else
        {
            return true;
        }    
    }
    
    function selectFuncao(){
        if(id == "novo")
        {
            if(verificaVazios())
            {
                cadastraUsuario();
            };            
        }
        else
        {
            if(verificaVazios())
            {
                atualizaUsuario();
            };    
        }
    }
    // Cadastra ponto-turistico (sem docs)
    async function cadastraUsuario() {

        {handleOpen()} 

        var input = document.querySelector('input[type="file"]')

        const permissoesSelec = [];
        for(let i=0; i<permissoes.length; i++){
            if(document.getElementById(permissoes[i]).checked){permissoesSelec.push(permissoes[i]);}
            ///console.log(permissoesSelec);  
        }

        var data = new FormData() 
        data.append('nome', String(document.getElementById("nome").value)) 
        data.append('email', String(document.getElementById("email").value))   
        data.append('senha', String(document.getElementById("senha").value))   
        data.append('cpf', String(document.getElementById("cpf").value))   
        data.append('status', document.querySelector('input[name="statusUser"]:checked').value)
        data.append('roles', permissoesSelec)   
        data.append('foto', input.files[0])   
        //data.append('contratante_id', localStorage.getItem('contratante_id'))    
        // NÂO PRECISA
        //data.append('contratante_id', localStorage.getItem('contratante_id'))      

        //console.log(data);
        
        const url = `${base_url}/usuario`;
         await axios.post(url, data, {
        "headers": {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 
            //console.log(response);
            //console.log(response.data.id);

            document.getElementById("notifica").innerHTML = "Cadastrado com sucesso!";
            const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

            meuAlert("Cadastrado com sucesso!", "success");
            const redireciona = setInterval(function () {
                clearInterval(redireciona);
                window.location.pathname = `usuario/editar/${response.data.id}`;
            }, 3000);     
           
        })
        .catch(error => {
            {handleClose()}
            meuAlert(`${error.response.data.message}`, "warning");
            console.log(error.message);
            console.log(error.response.data.message);
            //console.log("Erro "+error.response.data.statusCode);
        })     
    }
    // Atualiza ponto-turistico (sem docs)
    async function atualizaUsuario() {
          
        {handleOpen()} 

        const permissoesSelec = [];
        for(let i=0; i<permissoes.length; i++){
            if(document.getElementById(permissoes[i]).checked){permissoesSelec.push(permissoes[i]);}
            ///console.log(permissoesSelec);  
        }
  
        var input = document.querySelector('input[type="file"]')

        var data = new FormData()

        if((document.getElementById("senha").value == null) || (document.getElementById("senha").value == ""))
        {
            data.append('nome', String(document.getElementById("nome").value)) 
            data.append('email', String(document.getElementById("email").value))   
            data.append('cpf', String(document.getElementById("cpf").value))   
            data.append('status', document.querySelector('input[name="statusUser"]:checked').value)
            data.append('roles', permissoesSelec)   
            data.append('foto', input.files[0])   
        }
        else
        {
            data.append('senha', String(document.getElementById("senha").value))   
            data.append('nome', String(document.getElementById("nome").value)) 
            data.append('email', String(document.getElementById("email").value))   
            data.append('cpf', String(document.getElementById("cpf").value))   
            data.append('status', document.querySelector('input[name="statusUser"]:checked').value)
            data.append('roles', permissoesSelec)   
        }
        //NÂO PRECISA
        //data.append('cargo', String(document.getElementById("cargo").value))
        //data.append('contratante_id', localStorage.getItem('contratante_id'))      

        //console.log();

        //pra mostrar o valor de cada item do post
        //for(const value of data.values()) {
        //    console.log(value);
       //}
        
        const url = `${base_url}/usuario/${id}`;
        await axios.patch(url, data, {
        "headers": {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 
            //console.log(response);
            //console.log(response.data.id);

            document.getElementById("notifica").innerHTML = "Alterado com sucesso!";
            const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

            meuAlert("Alterado com sucesso!", "success");
            //const redireciona = setInterval(function () {
            //    //setRefreshKey(oldKey => oldKey +1);
            //    window.location.reload();
            //    clearInterval(redireciona);
            //}, 2000);     

        })
        .catch(error => { 
            {handleClose()}
            meuAlert(`${error.response.data.message}`, "warning");
            console.log(error.message);
            console.log(error.response.data.message);
            //console.log("Erro "+error.response.data.statusCode);
        })     
        
    }    
    // Excluir ponto-turistico
    async function ExcluirUsuario() {  

        if (window.confirm("Tem certeza que deseja excluir este usuário?")) {        
            
            {handleOpen()} 
         
            const url = `${base_url}/usuario/${id}`;
            await axios.delete(url, {
            "headers": {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            }})
            .then(response => { 
                //console.log(response);
                //console.log(response.data.id);    
               
                document.getElementById("notifica").innerHTML = "Excluído com sucesso!";
                const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

                meuAlert("Excluído com sucesso!", "success");
                const redireciona = setInterval(function () {
                    clearInterval(redireciona);
                    window.location.pathname = `usuario/editar/novo`;
                }, 2000);     

            })
            .catch(error => { 
                {handleClose()}
                meuAlert(`${error.message}`, "error");
                console.log(error.message);
                //console.log("Erro "+error.response.data.statusCode);
            })  
        }           
    }    

    //function QualContratante(valor){
    //    for(let i=0; i<document.getElementById("contratante").children.length; i++){
    //        if(document.getElementById("contratante").children[i].value == valor){
   //             document.getElementById("contratante").children[i].selected = true;
    //        }        
    //    }
    //}
    
    
    return(
        <>   
            <Sidebar page={{ name: 'Usuários', route: '/usuario' }}>
                <Box sx={{ width: '100%'}}>
                    <Collapse in={alertAberto}>
                        <Alert
                        severity={tipoAlerta}
                        variant="filled"
                        action={
                            <IconButton
                            aria-label="close"
                            size="small"
                            color="inherit"
                            onClick={() => {
                                setAlertAberto(false);
                            }}
                            >
                            <CloseIcon/>
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        {alertMensagem}
                        </Alert>
                    </Collapse>
                </Box>
                
                <div className="tituloPagina"><p id="titu" >Editar usuário</p></div>   

                <div className="botaoExcluirEspec">
                    {id !== "novo" && (
                        <button onClick={ExcluirUsuario}>Excluir Usuário</button>
                    )}
                </div>

                <div className="semLinha menuPadrao">
                    <li>
                        <button id="0" onClick={(e) => menuSec(e.target.id)}>Cadastro Geral</button>   
                        <button id="1" onClick={(e) => menuSec(e.target.id)}>Acessos do usuário (Roles)</button>   
                    </li>   
                </div>

                <div className="formulario" >
                    <div id="cadastroUsuario">
                        <div className="formRedondo formularioGeral " id="geral">                                                       
                            
                            <div className="alinhaDireita">
                                
                                <div className="novoRadio statusRadio"> 
                                    <div className="formularioGeralTitulo"><p>Status do usuário</p></div> 
                                    <input type="radio" id="radioativo" name="statusUser" value="true"checked></input>
                                    <label for="radioativo">Ativo</label>

                                    <input type="radio" id="radioinativo" name="statusUser" value="false"></input>
                                    <label for="radioinativo">Inativo</label>
                                </div>


                                
                                <div className="formularioGeralTitulo"><p>Nome</p></div>                            
                                <input type="text" name="nome" id="nome" placeholder="Insira o nome" autoComplete="off"  ></input>
                                <div className="espaco2LadosB_Usuário"></div>

                                <div className="formularioGeralTitulo"><p>Email</p></div>                            
                                <input type="text" name="email" id="email" placeholder="Insira o email" autoComplete="off"  ></input>
                                <div className="espaco2LadosB_Usuário"></div>


                                <div className="formularioGeralTitulo"><p>CPF</p></div>
                                <input type="number" name="cpf" id="cpf" autoComplete="off"  ></input>
                                
                                <div className="espaco2LadosB_Usuário"></div>


                                <div className="formularioGeralTitulo"><p>Senha (Preencher pra mudar)</p></div>                            
                                <input type="password" name="senha" id="senha" ></input>
                                             
                            </div>    

                            <div className="alinhaEsquerda">

                                <img id="capa" name="capa" src={carregando} ></img>
                                
                                <div className="formularioGeralTitulo"><p>Foto do usuário</p></div>
                                <input type="file" name="InputCapa" id="InputCapa" autoComplete="off"  ></input>

                            </div>
                            
                            <button onClick={selectFuncao}>Salvar alterações</button>

                        </div>

                    </div>                    
                </div>

                <div className="formularioContato formulario" >
                    <div id="cadastroConcurso">
                        <div className="formRedondo formularioGeral" id="permissoes" hidden>

                            <div className="novoRadio statusRadio"> 
                                <div className="formularioGeralTitulo"><p>Selecione:</p></div> 
                                
                                    <input type="checkbox" id="MASTER" name="MASTER"></input>
                                    <label for="ativo">Master</label>
                                
                                    <input type="checkbox" id="SUPER_ADMIN" name="SUPER_ADMIN" ></input>
                                    <label for="ativo">Super Admin</label>

                                    <input type="checkbox" id="ADMIN_BASE" name="ADMIN_BASE" ></input>
                                    <label for="ativo">Admin Base</label>
                                
                                    <input type="checkbox" id="USUARIO_BASE" name="USUARIO_BASE" ></input>
                                    <label for="ativo">Usuário Base</label>
                                
                                    <input type="checkbox" id="ADMIN_SITE" name="ADMIN_SITE" ></input>
                                    <label for="ativo">Admin Site</label>
                                
                                    <input type="checkbox" id="USUARIO_SITE" name="USUARIO_SITE" ></input>
                                    <label for="ativo">Usuário Site</label>
                                
                                    <input type="checkbox" id="ADMIN_PORTAL" name="ADMIN_PORTAL" ></input>
                                    <label for="ativo">Admin Portal</label>

                                    <input type="checkbox" id="USUARIO_PORTAL" name="USUARIO_PORTAL" ></input>
                                    <label for="ativo">Usuário Portal</label>

                                    <input type="checkbox" id="ADMIN_DIARIO" name="ADMIN_DIARIO" ></input>
                                    <label for="ativo">Admin Diário</label>
                                    
                                    <input type="checkbox" id="USUARIO_DIARIO" name="USUARIO_DIARIO" ></input>
                                    <label for="ativo">Usuário Diário</label>
                                    
                                    <input type="checkbox" id="ADMIN_OUVIDORIA_ESIC" name="ADMIN_OUVIDORIA_ESIC" ></input>
                                    <label for="ativo">Admin Ouvidoria Esic</label>
                                    
                                    <input type="checkbox" id="USUARIO_OUVIDORIA_ESIC" name="USUARIO_OUVIDORIA_ESIC" ></input>
                                    <label for="ativo">Admin Ouvidoria Esic</label>
                                    
                                    <input type="checkbox" id="ADMIN_ZELADORIA" name="ADMIN_ZELADORIA" ></input>
                                    <label for="ativo">Admin Zeladoria</label>
                                    
                                    <input type="checkbox" id="USUARIO_ZELADORIA" name="USUARIO_ZELADORIA" ></input>
                                    <label for="ativo">Usuário Zeladoria</label>
                                    
                                    <input type="checkbox" id="ADMIN_CARTA_SERVICOS" name="ADMIN_CARTA_SERVICOS" ></input>
                                    <label for="ativo">Admin Carta de Serviços</label>
                                    
                                    <input type="checkbox" id="USUARIO_CARTA_SERVICOS" name="USUARIO_CARTA_SERVICOS" ></input>
                                    <label for="ativo">Usuário Carta de Serviços</label>
                                    
                                    <input type="checkbox" id="ADMIN_COVID" name="ADMIN_COVID" ></input>
                                    <label for="ativo">Admin Covid</label>
                                    
                                    <input type="checkbox" id="USUARIO_COVID" name="USUARIO_COVID" ></input>
                                    <label for="ativo">Usuário Covid</label>
                            </div>
                            
                            <button onClick={selectFuncao}>Salvar alterações</button>
                        </div>
                    </div>  
                </div>
            </Sidebar>
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                ><p id="notifica" >vghfgh</p>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </>   
    );
}

export default UsuarioEditar;