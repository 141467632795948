import { useEffect, useState } from "react";
import '../../../components/Geral/geral.css';
import './OrgaoEditar.css'
import carregando from '../../../components/Geral/loading.gif';
import { verificaLogin } from '../../../context/LoginContext';
import { TextEditor } from "../../../components/TextEditor/TextEditor";
import { base_url, midia_url, foto_default } from '../../../environment'
import axios from 'axios';
import Sidebar from '../../../components/Menu/Sidebar';
import { useParams } from "react-router-dom";

import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

var unavez = true, umavez = true;

function OrgaoEditar() {
    setInterval(function () { verificaLogin(); }, 1000);

    const [refreshKey, setRefreshKey] = useState(0);

    function atualiza() {
        setRefreshKey(oldKey => oldKey + 1);
    }

    const [alertAberto, setAlertAberto] = React.useState(false);
    const [alertMensagem, setAlertMensagem] = React.useState('');
    const [tipoAlerta, setTipoAlerta] = React.useState('');

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    function meuAlert(texto, tipo) {
        setTipoAlerta(tipo);
        setAlertMensagem(texto);
        window.location = `#root`;
        setAlertAberto(true);
    }

    function loading() { return <div id='loading'><img src={carregando} alt="Carregando" /></div>; }
    setInterval(function () { verificaLogin(); }, 1000);

    var [texto, setTexto] = useState('<p>Escreva seu texto aqui. Aumente o tamanho da área de texto no canto inferior direito.</p>');

    // Clicar no primeiro botao e colocar texto inicial
    useEffect(() => {
        document.getElementById(0).click();
    }, []);

    const { id } = useParams();
    var token = localStorage.getItem('LOCAL_STORAGE_KEY__ACCESS_TOKEN');

    // pegar dados tipo-orgao
    useEffect(() => {
        axios.get(`${base_url}/tipo-orgao`, {
            "headers": {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {

                // eslint-disable-next-line
                if ((response.data.statusCode != undefined) && (response.data.statusCode != 200)) {
                    //window.location.pathname = `mensagem/${response.data.message}`;
                    meuAlert(`${response.data.message}`, "error");
                }

                // eslint-disable-next-line 
                if (response.data.length == 0) {
                    meuAlert("Não há tipos de órgãos.", "error");
                }
                else {
                    //console.log(response.data);

                    var tipo_orgao = document.getElementById("tipo-orgao");
                    for (let i = 0; i < response.data.length; i++) {
                        const option = document.createElement("option");
                        option.value = response.data[i].id;
                        option.innerHTML = response.data[i].nome;
                        tipo_orgao.appendChild(option);
                    }

                }
            })
            .catch(error => {
                console.log(error.message);
                if (error.response) {
                    // eslint-disable-next-line
                    if (error.response.data.statusCode == 401) {
                        window.location.pathname = `login`;
                    }
                    else {
                        meuAlert(`${error.message}`, "error");
                    }
                }
                else {
                    meuAlert(`${error.message}`, "error");
                }
            });

    }, []);

    // pegar dados pessoa responsável
    useEffect(() => {
        axios.get(`${base_url}/pessoa`, {
            "headers": {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {

                // eslint-disable-next-line
                if ((response.data.statusCode != undefined) && (response.data.statusCode != 200)) {
                    //window.location.pathname = `mensagem/${response.data.message}`;
                    meuAlert(`${response.data.message}`, "error");
                }

                // eslint-disable-next-line 
                if (response.data.length == 0) {
                    meuAlert("Não há pessoas.", "error");
                }
                else {
                    //console.log(response.data);

                    var pessoa = document.getElementById("responsavel");
                    for (let i = 0; i < response.data.length; i++) {
                        const option = document.createElement("option");
                        option.value = response.data[i].id;
                        option.innerHTML = response.data[i].nome;
                        pessoa.appendChild(option);
                    }

                }
            })
            .catch(error => {
                console.log(error.message);
                if (error.response) {
                    // eslint-disable-next-line
                    if (error.response.data.statusCode == 401) {
                        window.location.pathname = `login`;
                    }
                    else {
                        meuAlert(`${error.message}`, "error");
                    }
                }
                else {
                    meuAlert(`${error.message}`, "error");
                }
            });

    }, []);

    // pegar dados se não for novo
    useEffect(() => {

        if (id != "novo") {
            axios.get(`${base_url}/orgao/${id}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                })
                .then(async response => {
                    //console.log("Resposta ok");
                    //console.log(response);
                    //console.log(response.data);

                    document.getElementById("nome").value = response.data.nome;
                    setTexto(response.data.sobre);
                    document.getElementById("endereco").value = response.data.endereco;
                    document.getElementById("cep").value = response.data.cep;
                    document.getElementById("email").value = response.data.email;
                    document.getElementById("celular").value = response.data.celular;
                    document.getElementById("fone").value = response.data.fone;
                    if (response.data.logo) { document.getElementById("logo").src = midia_url + response.data.logo; }
                    else { document.getElementById("logo").src = foto_default }
                    document.getElementById("sigla").value = response.data.sigla;
                    document.getElementById("expediente").value = response.data.expediente;


                    await orgaoID(response.data.tipo_orgao_id);
                    await responsavel(response.data.responsavel_id);

                    //var arrayInicial = [];        

                })
                .catch(error => {
                    console.log(error.message);
                    if (error.response) {
                        if ((error.response.data.statusCode == 404) || (error.response.data.statusCode == 400)) {
                            if (unavez) {
                                meuAlert("Concurso não existente.", "warning");
                                //window.location.pathname = `orgao/editar/novo`;
                                unavez = false;
                            }

                        }
                        else {
                            meuAlert(`${error.message}`, "error");
                        }
                        if (error.response.data.statusCode == 401) {
                            window.location.pathname = `login`;
                        }
                    }
                    else {
                        meuAlert(`${error.message}`, "error");
                    }
                });
        }
        else {
            document.getElementById("logo").src = foto_default;
        }
        document.getElementById("notifica").innerHTML = "Processo em andamento, aguarde...";

    }, [refreshKey]);


    function verificaVazios() {

        if (document.getElementById("nome").value == "") { meuAlert("Nome do órgão não preenchido.", "warning"); }
        else if (document.getElementById("endereco").value == "") { meuAlert("Endereço do órgão não preenchido.", "warning"); }
        else if (document.getElementById("cep").value == "") { meuAlert("CEP do órgão não preenchido.", "warning"); }
        else if (document.getElementById("email").value == "") { meuAlert("Email do órgão não preenchido.", "warning"); }
        else if (document.getElementById("celular").value == "") { meuAlert("Celular do órgão não preenchido.", "warning"); }
        else if (document.getElementById("fone").value == "") { meuAlert("Fone do órgão não preenchido.", "warning"); }
        else if (document.getElementById("sigla").value == "") { meuAlert("Sigla do órgão não preenchida.", "warning"); }
        else if (document.getElementById("expediente").value == "") { meuAlert("Expediente do órgão não preenchido.", "warning"); }
        else if (id == "novo") {
            if (document.getElementById("InputCapa").value == "") { meuAlert("Imagem da logo não preenchida.", "warning"); }
            else { return true; }
        }
        else {
            return true;
        }
    }

    function selectFuncao() {
        if (id == "novo") {
            if (verificaVazios()) {
                cadastraOrgao();
            };
        }
        else {
            if (verificaVazios()) {
                atualizaOrgao();
            };
        }
    }

    // Cadastra orgao (sem docs)
    async function cadastraOrgao() {

        { handleOpen() }

        var input = document.querySelector('input[type="file"]')

        var data = new FormData()
        data.append('nome', String(document.getElementById("nome").value))
        data.append('sobre', texto)
        data.append('endereco', String(document.getElementById("endereco").value))
        data.append('cep', String(document.getElementById("cep").value))
        data.append('email', String(document.getElementById("email").value))
        data.append('celular', String(document.getElementById("celular").value))
        data.append('fone', String(document.getElementById("fone").value))
        data.append('logo', input.files[0])
        data.append('sigla', String(document.getElementById("sigla").value))
        data.append('expediente', String(document.getElementById("expediente").value))
        data.append('tipo_orgao_id', String(document.getElementById("tipo-orgao").value))
        // NÂO PRECISA
        //data.append('responsavel', String(document.getElementById("responsavel").value))
        //data.append('contratante_id', localStorage.getItem('contratante_id'))      

        //console.log(data);

        const url = `${base_url}/orgao`;
        await axios.post(url, data, {
            "headers": {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                //console.log(response);
                //console.log(response.data.id);

                document.getElementById("notifica").innerHTML = "Cadastrado com sucesso!";
                const mostrar = setInterval(function () { { handleClose() }; clearInterval(mostrar); }, 2000);

                meuAlert("Cadastrado com sucesso!", "success");
                const redireciona = setInterval(function () {
                    clearInterval(redireciona);
                    window.location.pathname = `orgao/editar/${response.data.id}`;
                }, 3000);

            })
            .catch(error => {
                { handleClose() }
                meuAlert(`${error.response.data.message}`, "warning");
                console.log(error.message);
                console.log(error.response.data.message);
                //console.log("Erro "+error.response.data.statusCode);
            })
    }
    // Atualiza orgao (sem docs)
    async function atualizaOrgao() {

        { handleOpen() }

        var input = document.querySelector('input[type="file"]')

        var data = new FormData()
        data.append('nome', String(document.getElementById("nome").value))
        data.append('sobre', texto)
        data.append('endereco', String(document.getElementById("endereco").value))
        data.append('cep', String(document.getElementById("cep").value))
        data.append('email', String(document.getElementById("email").value))
        data.append('celular', String(document.getElementById("celular").value))
        data.append('fone', String(document.getElementById("fone").value))
        data.append('logo', input.files[0])
        data.append('sigla', String(document.getElementById("sigla").value))
        data.append('expediente', String(document.getElementById("expediente").value))
        data.append('tipo_orgao_id', String(document.getElementById("tipo-orgao").value))
        //NÂO PRECISA
        //data.append('responsavel', String(document.getElementById("responsavel").value))
        //data.append('contratante_id', localStorage.getItem('contratante_id'))      

        //console.log(data);

        const url = `${base_url}/orgao/${id}`;
        await axios.patch(url, data, {
            "headers": {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                //console.log(response);
                //console.log(response.data.id);

                document.getElementById("notifica").innerHTML = "Alterado com sucesso!";
                const mostrar = setInterval(function () { { handleClose() }; clearInterval(mostrar); }, 2000);

                meuAlert("Alterado com sucesso!", "success");
                const redireciona = setInterval(function () {
                    //setRefreshKey(oldKey => oldKey +1);
                    window.location.reload();
                    clearInterval(redireciona);
                }, 2000);

            })
            .catch(error => {
                { handleClose() }
                meuAlert(`${error.response.data.message}`, "warning");
                console.log(error.message);
                console.log(error.response.data.message);
                //console.log("Erro "+error.response.data.statusCode);
            })

    }
    // Excluir orgao
    async function ExcluirOrgao() {

        if (window.confirm("Tem certeza que deseja excluir esse órgão?")) {

            { handleOpen() }

            const url = `${base_url}/orgao/${id}`;
            await axios.delete(url, {
                "headers": {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            })
                .then(response => {
                    //console.log(response);
                    //console.log(response.data.id);    

                    document.getElementById("notifica").innerHTML = "Excluído com sucesso!";
                    const mostrar = setInterval(function () { { handleClose() }; clearInterval(mostrar); }, 2000);

                    meuAlert("Excluído com sucesso!", "success");
                    const redireciona = setInterval(function () {
                        clearInterval(redireciona);
                        window.location.pathname = `orgao/editar/novo`;
                    }, 2000);

                })
                .catch(error => {
                    { handleClose() }
                    meuAlert(`${error.message}`, "error");
                    console.log(error.message);
                    //console.log("Erro "+error.response.data.statusCode);
                })
        }
    }

    function orgaoID(valor) {
        for (let i = 0; i < document.getElementById("tipo-orgao").children.length; i++) {
            if (document.getElementById("tipo-orgao").children[i].value == valor) {
                document.getElementById("tipo-orgao").children[i].selected = true;
            }
        }
    }
    function responsavel(valor) {
        for (let i = 0; i < document.getElementById("responsavel").children.length; i++) {
            if (document.getElementById("responsavel").children[i].value == valor) {
                document.getElementById("responsavel").children[i].selected = true;
            }
        }
    }

    return (
        <>
            <Sidebar page={{ name: 'Órgãos', route: '/orgao' }}>
                <Box sx={{ width: '100%' }}>
                    <Collapse in={alertAberto}>
                        <Alert
                            severity={tipoAlerta}
                            variant="filled"
                            action={
                                <IconButton
                                    aria-label="close"
                                    size="small"
                                    color="inherit"
                                    onClick={() => {
                                        setAlertAberto(false);
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                        >
                            {alertMensagem}
                        </Alert>
                    </Collapse>
                </Box>

                <div className="tituloPagina"><p id="titu" >Editar Órgão</p></div>

                <div className="botaoExcluirEspec" >
                    {id !== "novo" && (
                        <div className="botaoExcluirEspec">
                            <button onClick={ExcluirOrgao}>Excluir Orgão</button>
                        </div>
                    )}

                </div>


                <div className="semLinha menuPadrao">
                    <li>
                        <button id="0" >Cadastro Geral</button>
                    </li>
                </div>

                <div className="formulario" >
                    <div id="cadastroGaleria">
                        <div className="formularioGeral">
                            <div className="alinhaDireita">

                                <div className="formularioGeralTitulo"><p>Nome do órgão</p></div>
                                <input type="text" name="nome" id="nome" placeholder="Insira o nome do órgão" autoComplete="off"  ></input>
                                <div className="formularioGeralTitulo"><p>Email do órgão</p></div>
                                <input type="text" name="email" id="email" placeholder="Insira o email do órgão" autoComplete="off"  ></input>
                                <div className="formularioGeralTitulo"><p>Celular do órgão</p></div>
                                <input type="text" name="celular" id="celular" placeholder="Insira o celular do órgão" autoComplete="off"  ></input>
                                <div className="formularioGeralTitulo"><p>Fone do órgão</p></div>
                                <input type="text" name="fone" id="fone" placeholder="Insira o telefone do órgão" autoComplete="off"  ></input>

                                <div className="espaco2LadosB_Orgao"></div>

                                <div className="formularioGeralTitulo"><p>Expediente do órgão</p></div>
                                <input type="text" name="expediente" id="expediente" placeholder="Insira o expediente do órgão" autoComplete="off"  ></input>

                                <div className="formularioGeralTitulo"><p>Sigla do órgão</p></div>
                                <input type="text" name="sigla" id="sigla" placeholder="Insira a sigla do órgão" autoComplete="off"  ></input>

                                <div className="formularioGeralTitulo"><p>CEP do órgão</p></div>
                                <input type="text" name="cep" id="cep" placeholder="Insira o CEP do órgão" autoComplete="off"  ></input>

                            </div>

                            <div className="alinhaEsquerda">
                                <img id="logo" name="logo" src={carregando} ></img>

                                <div className="formularioGeralTitulo"><p>Logo</p></div>
                                <input type="file" name="InputCapa" id="InputCapa" autoComplete="off"  ></input>

                                <div className="formularioGeralTitulo"><p>Tipo de Órgão</p></div>
                                <select name="tipo-orgao" id="tipo-orgao">
                                </select>

                                <div className="formularioGeralTitulo"><p>Responsável</p></div>
                                <select name="responsavel" id="responsavel">
                                </select>

                            </div>

                            <div className="bloqueiaDiv formularioGeralTitulo"><p>Endereço do órgão</p></div>
                            <input type="text" name="endereco" id="endereco" placeholder="Insira o endereço do órgão" autoComplete="off"  ></input>

                        </div>

                        <div className="formularioConteudo">
                            <span>Descrição do órgão</span>
                            <TextEditor value={texto} onChange={setTexto} />
                            <button onClick={selectFuncao}>Salvar alterações</button>
                        </div>
                    </div>
                </div>
            </Sidebar>
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                ><p id="notifica" >vghfgh</p>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </>
    );
}

export default OrgaoEditar;