import { useCallback, useEffect, useState } from "react";
import { base_url } from "../environment";
import axios from "axios";

const api = axios.create({
  baseURL: base_url,
  headers: {
    'Content-Type': 'application/json',
  },
});

export function useAPI(options) {
  const token = localStorage.getItem('LOCAL_STORAGE_KEY__ACCESS_TOKEN');
  const method = options?.method?.toLowerCase() || 'get';
  const url = options?.url;
  const initial = options?.initial || null;
  const fetch = options?.fetch || false;

  const [response, setResponse] = useState(initial);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const request = useCallback(async (body) => {
    setLoading(true);
    try {
      const config = { method, url, headers: { 'Authorization': `Bearer ${token}` } }
      if (method === 'get') { config.params = body }
      else { config.data = body }

      const result = await api.request(config)
      setResponse(result.data);
    } catch (error) {
      setError(error);
      if (error.response && error.response.data.statusCode === 401) {
        window.location.pathname = 'login';
      }
    } finally {
      setLoading(false);
    }
  }, [method, url, token]);

  useEffect(() => {
    if (fetch) request();
  }, [request, fetch]);

  return { request, response, error, loading };
}
