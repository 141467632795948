import { useEffect, useRef, useState } from "react";
import '../../../components/Geral/geral.css';
import './EntidadeEditar.css'
import carregando from '../../../components/Geral/loading.gif';
import { verificaLogin, fazerLogout } from '../../../context/LoginContext';
import { Editor } from '@tinymce/tinymce-react';
import {base_url, midia_url, foto_default} from '../../../environment'
import axios from 'axios';
import Sidebar from '../../../components/Menu/Sidebar';
import { useParams } from "react-router-dom";

import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
//import Button from '@mui/material/Button';

var unavez = true, umavez = true, pinaVez = true;

function EntidadeEditar(){
    setInterval(function () {verificaLogin();}, 1000);

    const [refreshKey, setRefreshKey] = useState(0);
    
    function atualiza() {
        setRefreshKey(oldKey => oldKey +1); 
    }

    const [alertAberto, setAlertAberto] = React.useState(false);
    const [alertMensagem, setAlertMensagem] = React.useState('');
    const [tipoAlerta, setTipoAlerta] = React.useState('');

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    function meuAlert(texto, tipo) {
        setTipoAlerta(tipo);
        setAlertMensagem(texto);
        window.location = `#root`;
        setAlertAberto(true);
    }

    function loading() {return <div id='loading'><img src={carregando} alt="Carregando"/></div>; }    
    setInterval(function () {verificaLogin();}, 1000);
    
    var [textoInicial, setTextoInicial] = useState('');    

    // Clicar no primeiro botao e colocar texto inicial
    useEffect(() => {
        
        document.getElementById(0).click();
        setTextoInicial("<p>Escreva seu texto aqui. Aumente o tamanho da área de texto no canto inferior direito.</p>");
    
    },[]);

    const {id} = useParams();
    var token = localStorage.getItem('LOCAL_STORAGE_KEY__ACCESS_TOKEN');

    // pegar dados pessoa responsável
    useEffect(() => {
    axios.get(`${base_url}/pessoa`, {
        "headers": {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
    }})
    .then(response => { 

        // eslint-disable-next-line
        if((response.data.statusCode != undefined) && (response.data.statusCode != 200)){
            //window.location.pathname = `mensagem/${response.data.message}`;
            meuAlert(`${response.data.message}`, "error");
        }

        // eslint-disable-next-line 
        if(response.data.length == 0)
        {
            meuAlert("Não há pessoas.", "error");           
        }
        else
        {
            //console.log(response.data);

            var pessoa = document.getElementById("responsavel");                
            for(let i=0; i<response.data.length; i++){
                const option = document.createElement("option");
                option.value = response.data[i].id;
                option.innerHTML = response.data[i].nome;                    
                pessoa.appendChild(option);
            }

        }            
    })    
    .catch(error => { 
        console.log(error.message);
        if(error.response)
        {
            // eslint-disable-next-line
            if(error.response.data.statusCode == 401)
            {
                window.location.pathname = `login`;
            }
            else{
                meuAlert(`${error.message}`, "error");
            }  
        }
        else{
            meuAlert(`${error.message}`, "error");
        }            
    });

    },[]); 
    

    // pegar dados se não for novo
    useEffect(() => {
        
        if(id != "novo")
        {
            axios.get(`${base_url}/entidade/${id}`, 
            { headers: {
                'Authorization': `Bearer ${token}`,
            }})
            .then(async response => {             
                //console.log("Resposta ok");
                //console.log(response);
                //console.log(response.data);

                //document.getElementById("responsavel").value = response.data[0].responsavel;
                await responsavel(response.data[0].responsavel);

                document.getElementById("nome").value = response.data[0].nome;
                document.getElementById("endereco").value = response.data[0].endereco;
                document.getElementById("cep").value = response.data[0].cep;
                document.getElementById("email").value = response.data[0].email;
                document.getElementById("fone").value = response.data[0].fone;
                document.getElementById("celular").value = response.data[0].celular;
                //document.getElementById("logo").value = response.data[0].logo;
                //document.getElementById("brasao").value = response.data[0].brasao;
                document.getElementById("sigla").value = response.data[0].sigla;
                document.getElementById("expediente").value =response.data[0].expediente;
                document.getElementById("facebook").value = response.data[0].facebook;
                document.getElementById("instagram").value = response.data[0].instagram;
                document.getElementById("youtube").value = response.data[0].youtube;
                document.getElementById("twitter").value = response.data[0].twitter;
                          
                if(umavez){
                    document.getElementById("arquivoLogo").innerHTML = null;   
                
                    //arrayInicial.push(String('<div><p>'+response.data.Anexo[i].titulo+'</p><a target="_blank" href='+midia_url+response.data.Anexo[i].anexo+'>Baixar</a><a target="_blank" href="#" >Excluir</a></div>')); 
                        
                    var divGeral = document.getElementById("arquivoLogo");
                    
                    const divContainter = document.createElement("div");
    
                    const p = document.createElement("p");
                    p.innerHTML = response.data[0].logo.substring(32);
            
                    const a2 = document.createElement("a");
                    a2.target = "_blank";
                    a2.href = String(midia_url+response.data[0].logo);
                    a2.innerHTML = "Ver";
                    
                    divContainter.appendChild(p);              
                    divContainter.appendChild(a2); 
                        
                    divGeral.appendChild(divContainter);
                       
                    umavez = false;
                } 

                if(unavez){
                    document.getElementById("arquivoBrasao").innerHTML = null;   
                
                    //arrayInicial.push(String('<div><p>'+response.data.Anexo[i].titulo+'</p><a target="_blank" href='+midia_url+response.data.Anexo[i].anexo+'>Baixar</a><a target="_blank" href="#" >Excluir</a></div>')); 
                        
                    var divGeral = document.getElementById("arquivoBrasao");
                    
                    const divContainter = document.createElement("div");
    
                    const p = document.createElement("p");
                    p.innerHTML = response.data[0].brasao.substring(32);
            
                    const a2 = document.createElement("a");
                    a2.target = "_blank";
                    a2.href = String(midia_url+response.data[0].brasao);
                    a2.innerHTML = "Ver";
                    
                    divContainter.appendChild(p);              
                    divContainter.appendChild(a2); 
                        
                    divGeral.appendChild(divContainter);
                       
                    unavez = false;
                } 

                if(pinaVez){
                    document.getElementById("arquivoCarta").innerHTML = null;   
                
                    //arrayInicial.push(String('<div><p>'+response.data.Anexo[i].titulo+'</p><a target="_blank" href='+midia_url+response.data.Anexo[i].anexo+'>Baixar</a><a target="_blank" href="#" >Excluir</a></div>')); 
                        
                    var divGeral = document.getElementById("arquivoCarta");
                    
                    const divContainter = document.createElement("div");
    
                    const p = document.createElement("p");
                    p.innerHTML = response.data[0].carta_servicos.substring(32);
            
                    const a2 = document.createElement("a");
                    a2.target = "_blank";
                    a2.href = String(midia_url+response.data[0].carta_servicos);
                    a2.innerHTML = "Ver";
                    
                    divContainter.appendChild(p);              
                    divContainter.appendChild(a2); 
                        
                    divGeral.appendChild(divContainter);
                       
                    pinaVez = false;
                } 

            })
            .catch(error => { 
                console.log(error.message);
                if(error.response)
                {
                    if((error.response.data.statusCode == 404) || (error.response.data.statusCode == 400))
                    {
                        if(unavez)
                        {
                            meuAlert("Pessoa não existente.", "warning ");
                            //window.location.pathname = `obra/editar/novo`;
                            unavez = false;
                        }
                        
                    }
                    else{
                        meuAlert(`${error.message}`, "error");
                    }
                    if(error.response.data.statusCode == 401)
                    {
                        window.location.pathname = `login`;
                    }
                }   
                else{
                    meuAlert(`${error.message}`, "error");
                }            
            });
        }
        else{
            document.getElementById("cadastroDocRelatorio").innerHTML = null;
            document.getElementById("cadastroDocRelatorio2").innerHTML = null;
            document.getElementById("cadastroCarta").innerHTML = null;
        }
        document.getElementById("notifica").innerHTML = "Processo em andamento, aguarde...";
               
    },[refreshKey]); 


    function verificaVazios() {

        if(document.getElementById("nome").value == ""){meuAlert("Nome não preenchido.", "warning");}
        else if(document.getElementById("endereco").value == ""){meuAlert("Endereco não preenchido.", "warning");}
        else if(document.getElementById("cep").value == ""){meuAlert("CEP não preenchido.", "warning");}
        else if(document.getElementById("email").value == ""){meuAlert("Email não preenchido.", "warning");}
        else if(document.getElementById("fone").value == ""){meuAlert("Fone não preenchido.", "warning");}
        else if(document.getElementById("celular").value == ""){meuAlert("Celular não preenchido.", "warning");}
        //else if(document.getElementById("logo").value == ""){meuAlert("Logo não preenchido.", "warning");}
        //else if(document.getElementById("brasao").value == ""){meuAlert("Brasão não preenchido.", "warning");}
        else if(document.getElementById("sigla").value == ""){meuAlert("Sigla não preenchida.", "warning");}
        else if(document.getElementById("expediente").value == ""){meuAlert("Expediente não preenchido.", "warning");}
        else if(document.getElementById("responsavel").value == ""){meuAlert("Responsável não preenchido.", "warning");}
        else if(document.getElementById("facebook").value == ""){meuAlert("Facebook não preenchido.", "warning");}
        else if(document.getElementById("instagram").value == ""){meuAlert("Instagram não preenchido.", "warning");}
        else if(document.getElementById("youtube").value == ""){meuAlert("Youtube não preenchido.", "warning");}
        else if(document.getElementById("twitter").value == ""){meuAlert("Twitter não preenchido.", "warning");}
        else if(id == "novo"){
            if(document.getElementById("logo").value == "")
            {meuAlert("Logo não inserido.", "warning");}
            else if(document.getElementById("brasao").value == "")
            {meuAlert("Brasão não inserido.", "warning");}
            else if(document.getElementById("carta").value == "")
            {meuAlert("Carta de serviços não inserida.", "warning");}
            else{return true;}
        }
        else
        {
            return true;
        }    
    }
    
    function selectFuncao(){
        if(id == "novo")
        {
            if(verificaVazios())
            {
                cadastraEntidade();
            };            
        }
        else
        {
            if(verificaVazios())
            {
                atualizaEntidade();
            };    
        }
    }
    // Cadastra ponto-turistico (sem docs)
    async function cadastraEntidade() {

        {handleOpen()} 

        var brasao = document.getElementById("brasao");
        var logo = document.getElementById("logo");
        var carta = document.getElementById("carta");

        var data = new FormData()
        data.append('nome',  String(document.getElementById("nome").value))
        data.append('endereco',  String(document.getElementById("endereco").value))
        data.append('cep',  String(document.getElementById("cep").value))
        data.append('email',  String(document.getElementById("email").value))
        data.append('fone',  String(document.getElementById("fone").value))
        data.append('celular',  String(document.getElementById("celular").value))
        data.append('brasao', brasao.files[0])  
        data.append('logo', logo.files[0])  
        data.append('carta_servicos', carta.files[0])  
        data.append('sigla',  String(document.getElementById("sigla").value))
        data.append('expediente',  String(document.getElementById("expediente").value))
        data.append('responsavel',  String(document.getElementById("responsavel").value))
        data.append('facebook',  String(document.getElementById("facebook").value))
        data.append('instagram',  String(document.getElementById("instagram").value))
        data.append('youtube',  String(document.getElementById("youtube").value))
        data.append('twitter',  String(document.getElementById("twitter").value))
        //data.append('contratante_id', localStorage.getItem('contratante_id'))     
        // NÂO PRECISA
        //data.append('contratante_id', localStorage.getItem('contratante_id'))      

        //console.log(data);
        
        const url = `${base_url}/entidade`;
         await axios.post(url, data, {
        "headers": {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 
            //console.log(response);
            //console.log(response.data.id);

            document.getElementById("notifica").innerHTML = "Cadastrado com sucesso!";
            const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

            meuAlert("Cadastrado com sucesso!", "success");
            const redireciona = setInterval(function () {
                clearInterval(redireciona);
                window.location.pathname = `entidade/editar/${response.data.id}`;
            }, 3000);     
           
        })
        .catch(error => {
            {handleClose()}
            meuAlert(`${error.response.data.message}`, "warning");
            console.log(error.message);
            console.log(error.response.data.message);
            //console.log("Erro "+error.response.data.statusCode);
        })     
    }
    // Atualiza ponto-turistico (sem docs)
    async function atualizaEntidade() {
          
        {handleOpen()} 

        var brasao = document.getElementById("brasao");
        var logo = document.getElementById("logo");
        var carta = document.getElementById("carta");
        
        var data = new FormData()
        data.append('nome',  String(document.getElementById("nome").value))
        data.append('endereco',  String(document.getElementById("endereco").value))
        data.append('cep',  String(document.getElementById("cep").value))
        data.append('email',  String(document.getElementById("email").value))
        data.append('fone',  String(document.getElementById("fone").value))
        data.append('celular',  String(document.getElementById("celular").value))
        data.append('brasao', brasao.files[0])  
        data.append('logo', logo.files[0])  
        data.append('carta_servicos', carta.files[0])  
        data.append('sigla',  String(document.getElementById("sigla").value))
        data.append('expediente',  String(document.getElementById("expediente").value))
        data.append('responsavel',  String(document.getElementById("responsavel").value))
        data.append('facebook',  String(document.getElementById("facebook").value))
        data.append('instagram',  String(document.getElementById("instagram").value))
        data.append('youtube',  String(document.getElementById("youtube").value))
        data.append('twitter',  String(document.getElementById("twitter").value))
        
         //NÂO PRECISA
        //data.append('cargo', String(document.getElementById("cargo").value))
        //data.append('contratante_id', localStorage.getItem('contratante_id'))      

        console.log(data);
        
        const url = `${base_url}/entidade/${id}`;
        await axios.patch(url, data, {
        "headers": {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 
            //console.log(response);
            //console.log(response.data.id);

            document.getElementById("notifica").innerHTML = "Alterado com sucesso!";
            const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

            meuAlert("Alterado com sucesso!", "success");
            const redireciona = setInterval(function () {
                //setRefreshKey(oldKey => oldKey +1);
                window.location.reload();
                clearInterval(redireciona);
            }, 2000);     

        })
        .catch(error => { 
            {handleClose()}
            meuAlert(`${error.response.data.message}`, "warning");
            console.log(error.message);
            console.log(error.response.data.message);
            //console.log("Erro "+error.response.data.statusCode);
        })     
        
    }    
    // Excluir ponto-turistico
    async function ExcluirDoc() {  

        if (window.confirm("Tem certeza que deseja excluir esta entidade?")) {        
            
            {handleOpen()} 
         
            const url = `${base_url}/entidade/${id}`;
            await axios.delete(url, {
            "headers": {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            }})
            .then(response => { 
                //console.log(response);
                //console.log(response.data.id);    
               
                document.getElementById("notifica").innerHTML = "Excluído com sucesso!";
                const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

                meuAlert("Excluído com sucesso!", "success");
                const redireciona = setInterval(function () {
                    clearInterval(redireciona);
                    window.location.pathname = `entidade/editar/novo`;
                }, 2000);     

            })
            .catch(error => { 
                {handleClose()}
                meuAlert(`${error.message}`, "error");
                console.log(error.message);
                //console.log("Erro "+error.response.data.statusCode);
            })  
        }           
    }

    function responsavel(valor){
        for(let i=0; i<document.getElementById("responsavel").children.length; i++){
            if(document.getElementById("responsavel").children[i].value == valor){
                document.getElementById("responsavel").children[i].selected = true;
            }        
        }
    }

    return(
        <>   
            <Sidebar page={{ name: 'Entidades', route: '/entidade' }}> 
                <Box sx={{ width: '100%'}}>
                    <Collapse in={alertAberto}>
                        <Alert
                        severity={tipoAlerta}
                        variant="filled"
                        action={
                            <IconButton
                            aria-label="close"
                            size="small"
                            color="inherit"
                            onClick={() => {
                                setAlertAberto(false);
                            }}
                            >
                            <CloseIcon/>
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        {alertMensagem}
                        </Alert>
                    </Collapse>
                </Box>
                
                <div className="tituloPagina"><p id="titu" >Editar entidade</p></div>   

                <div className="botaoExcluirEspec" >
                    {id !== "novo" && (
                        <div className="botaoExcluirEspec">
                            <button onClick={ExcluirDoc}>Excluir Entidade</button>
                        </div>
                    )}

                </div>

                <div className="semLinha menuPadrao">
                    <li>
                        <button id="0" >Cadastro Geral</button>       
                    </li>  
                </div>

                <div className="formulario" >
                    <div id="cadastroGaleria">
                        <div className="formRedondo formularioGeral ">                                                       
                            
                            <div className="alinhaDireita">

                                <div className="formularioGeralTitulo"><p>Email</p></div>                            
                                <input type="text" name="email" id="email" placeholder="Insira o Email do entidade" autoComplete="off"  ></input>

                                <div className="formularioGeralTitulo"><p>Celular</p></div>                            
                                <input type="text" name="celular" id="celular" placeholder="Insira o Celular entidade" autoComplete="off"  ></input>
                               
                                <div className="formularioGeralTitulo"><p>Expediente</p></div>                            
                                <input type="text" name="expediente" id="expediente" placeholder="Insira o expediente da entidade" autoComplete="off"  ></input>

                                <div className="formularioGeralTitulo"><p>Facebook</p></div>                            
                                <input type="text" name="facebook" id="facebook" placeholder="Insira o facebook da entidade" autoComplete="off"  ></input>

                                <div className="formularioGeralTitulo"><p>Youtube</p></div>                            
                                <input type="text" name="youtube" id="youtube" placeholder="Insira o youtube da entidade" autoComplete="off"  ></input>
                                                                                                  
                                <div className="formularioGeralTitulo"><p>CEP</p></div>                            
                                <input type="text" name="cep" id="cep" placeholder="Insira o CEP da entidade" autoComplete="off"  ></input>
                               
                            </div>    
                            
                            <div className="alinhaEsquerda">
                                
                                <div className="formularioGeralTitulo"><p>Nome</p></div>                            
                                <input type="text" name="nome" id="nome" placeholder="Insira o nome da entidade" autoComplete="off"  ></input>
                                
                                <div className="formularioGeralTitulo"><p>Fone</p></div>                            
                                <input type="text" name="fone" id="fone" placeholder="Insira o Fone da entidade" autoComplete="off"  ></input>

                                <div className="formularioGeralTitulo"><p>Sigla</p></div>                            
                                <input type="text" name="sigla" id="sigla" placeholder="Insira a sigla da entidade" autoComplete="off"  ></input>
                                
                                <div className="formularioGeralTitulo"><p>Instagram</p></div>                            
                                <input type="text" name="instagram" id="instagram" placeholder="Insira o instagram da entidade" autoComplete="off"  ></input>

                                <div className="formularioGeralTitulo"><p>Twitter</p></div>                            
                                <input type="text" name="twitter" id="twitter" placeholder="Insira o twitter da entidade" autoComplete="off"  ></input>
                                                                               
                                <div className="formularioGeralTitulo"><p>Responsável</p></div>
                                <select name="responsavel" id="responsavel">        
                                </select>
                                  
                            </div>       

                            <div className="entidadeEndereco">
                                <div className="formularioGeralTitulo"><p>Endereço</p></div>                            
                                <input type="text" name="endereco" id="endereco" placeholder="Insira o Endereço do entidade" autoComplete="off"  ></input>
                            </div>        

                            <div className="alinhaDireita">
                                <div className="formularioGeralTitulo"><p>Brasão (Inserir arquivo)</p></div>                            
                                <input type="file" name="brasao" id="brasao" autoComplete="off"  ></input>

                                
                                <div id="cadastroDocRelatorio2">
                                    <div className="removeBordaa cadastroDocInterno">
                                        <p>Brasão cadastrado: Para substituir, insira novo arquivo acima</p>
                                        <div id="arquivoBrasao" className="anexos">
                                            {loading()}
                                        </div>       
                                    </div>
                                </div>   
                                
                            </div>     
                            
                            <div className="alinhaEsquerda">
                                <div className="formularioGeralTitulo"><p>Logo (Inserir arquivo)</p></div>                            
                                <input type="file" name="logo" id="logo" autoComplete="off"  ></input>

                                
                                <div id="cadastroDocRelatorio">
                                    <div className="removeBordaa cadastroDocInterno entidade">
                                        <p>Logo cadastrado: Para substituir, insira novo arquivo acima</p>
                                        <div id="arquivoLogo" className="anexos">
                                            {loading()}
                                        </div>       
                                    </div>
                                </div>  
                            </div>  
                             
                            <div className="alinhaEsquerda">
                                <div className="formularioGeralTitulo"><p>Carta de Serviços (Inserir arquivo)</p></div>                            
                                <input type="file" name="carta" id="carta" autoComplete="off"  ></input>

                                
                                <div id="cadastroCarta">
                                    <div className="removeBordaa cadastroDocInterno entidade">
                                        <p>Carta cadastrada: Para substituir, insira novo arquivo acima</p>
                                        <div id="arquivoCarta" className="anexos">
                                            {loading()}
                                        </div>       
                                    </div>
                                </div>  
                            </div>  

                                                         
                            <button onClick={selectFuncao}>Salvar alterações</button>

                        </div>

                    </div>                    
                </div>
            </Sidebar>
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                ><p id="notifica" >vghfgh</p>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </>   
    );
}

export default EntidadeEditar;