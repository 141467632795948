import { useEffect, useRef, useState } from "react";
import '../../../components/Geral/geral.css';
import './BoletimEditar.css'
import carregando from '../../../components/Geral/loading.gif';
import { verificaLogin, fazerLogout } from '../../../context/LoginContext';
import { Editor } from '@tinymce/tinymce-react';
import {base_url, midia_url, foto_default} from '../../../environment'
import axios from 'axios';
import Sidebar from '../../../components/Menu/Sidebar';
import { useParams } from "react-router-dom";

import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
//import Button from '@mui/material/Button';

var unavez = true, umavez = true;

function BoletimEditar(){
    setInterval(function () {verificaLogin();}, 1000);

    const [refreshKey, setRefreshKey] = useState(0);
    
    function atualiza() {
        setRefreshKey(oldKey => oldKey +1); 
    }

    const [alertAberto, setAlertAberto] = React.useState(false);
    const [alertMensagem, setAlertMensagem] = React.useState('');
    const [tipoAlerta, setTipoAlerta] = React.useState('');

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    function meuAlert(texto, tipo) {
        setTipoAlerta(tipo);
        setAlertMensagem(texto);
        window.location = `#root`;
        setAlertAberto(true);
    }

    function loading() {return <div id='loading'><img src={carregando} alt="Carregando"/></div>; }    
    setInterval(function () {verificaLogin();}, 1000);
    
    var [textoInicial, setTextoInicial] = useState('');    

    // Clicar no primeiro botao e colocar texto inicial
    useEffect(() => {
        
        document.getElementById(0).click();
        setTextoInicial("<p>Escreva seu texto aqui. Aumente o tamanho da área de texto no canto inferior direito.</p>");
    
    },[]);

    const {id} = useParams();
    var token = localStorage.getItem('LOCAL_STORAGE_KEY__ACCESS_TOKEN');

    // pegar dados se não for novo
    useEffect(() => {
        
        if(id != "novo")
        {
            axios.get(`${base_url}/boletim/${id}`, 
            { headers: {
                'Authorization': `Bearer ${token}`,
            }})
            .then(async response => {             
                //console.log("Resposta ok");
                //console.log(response);
                //console.log(response.data);

                document.getElementById("data").value = new Date(response.data.data).toISOString().split('T')[0];    
                document.getElementById("numero").value = response.data.numero;
                document.getElementById("ativos").value = response.data.ativos;
                document.getElementById("confirmados").value = response.data.confirmados;
                document.getElementById("monitorados").value = response.data.monitorados;
                document.getElementById("suspeitos").value = response.data.suspeitos;
                document.getElementById("recuperados").value = response.data.recuperados;
                document.getElementById("descartados").value = response.data.descartados;
                document.getElementById("hospitalizados").value = response.data.hospitalizados;
                document.getElementById("obitos").value =response.data.obitos;
                document.getElementById("isolamento").value = response.data.isolamento;
                                
                //var arrayInicial = [];        

            })
            .catch(error => { 
                console.log(error.message);
                if(error.response)
                {
                    if((error.response.data.statusCode == 404) || (error.response.data.statusCode == 400))
                    {
                        if(unavez)
                        {
                            meuAlert("Boletim não existente.", "warning");
                            //window.location.pathname = `obra/editar/novo`;
                            unavez = false;
                        }
                        
                    }
                    else{
                        meuAlert(`${error.message}`, "error");
                    }
                    if(error.response.data.statusCode == 401)
                    {
                        window.location.pathname = `login`;
                    }
                }   
                else{
                    meuAlert(`${error.message}`, "error");
                }            
            });
        }
        else{
            //nao faz nada
        }
        document.getElementById("notifica").innerHTML = "Processo em andamento, aguarde...";
               
    },[refreshKey]); 


    function verificaVazios() {

        if(document.getElementById("data").value == ""){meuAlert("Data não preenchida.", "warning");}
        else if(document.getElementById("numero").value == ""){meuAlert("Número não preenchido.", "warning");}
        else if(document.getElementById("ativos").value == ""){meuAlert("Nº de Ativos não preenchido.", "warning");}
        else if(document.getElementById("confirmados").value == ""){meuAlert("Nº de confirmados não preenchido.", "warning");}
        else if(document.getElementById("monitorados").value == ""){meuAlert("Nº de Monitorados não preenchido.", "warning");}
        else if(document.getElementById("suspeitos").value == ""){meuAlert("Nº de Suspeitos não preenchidoo.", "warning");}
        else if(document.getElementById("recuperados").value == ""){meuAlert("Nº de Recuperados não preenchido.", "warning");}
        else if(document.getElementById("descartados").value == ""){meuAlert("Nº de Descartados não preenchido.", "warning");}
        else if(document.getElementById("hospitalizados").value == ""){meuAlert("Nº de Hospilatizados não preenchido.", "warning");}
        else if(document.getElementById("obitos").value == ""){meuAlert("Nº de Óbitos não preenchido.", "warning");}
        else if(document.getElementById("isolamento").value == ""){meuAlert("Nº de isolamentos não preenchido.", "warning");}
        else
        {
            return true;
        }    
    }
    
    function selectFuncao(){
        if(id == "novo")
        {
            if(verificaVazios())
            {
                cadastraBoletim();
            };            
        }
        else
        {
            if(verificaVazios())
            {
                atualizaBoletim();
            };    
        }
    }
    // Cadastra ponto-turistico (sem docs)
    async function cadastraBoletim() {

        {handleOpen()} 

        var input = document.querySelector('input[type="file"]')

        var data = new FormData()

        data.append('data', new Date(document.getElementById("data").value).toISOString())        
        data.append('numero', document.getElementById("numero").value)
        data.append('ativos', document.getElementById("ativos").value)
        data.append('confirmados', document.getElementById("confirmados").value)
        data.append('monitorados', document.getElementById("monitorados").value)
        data.append('suspeitos', document.getElementById("suspeitos").value)
        data.append('recuperados', document.getElementById("recuperados").value)
        data.append('descartados', document.getElementById("descartados").value)
        data.append('hospitalizados', document.getElementById("hospitalizados").value)
        data.append('obitos', document.getElementById("obitos").value)
        data.append('isolamento', document.getElementById("isolamento").value)


        const params = JSON.stringify({
            "data": new Date(document.getElementById("data").value).toISOString(),
            "numero": document.getElementById("numero").value,
            "ativos": document.getElementById("ativos").value,
            "confirmados": document.getElementById("confirmados").value,
            "monitorados": document.getElementById("monitorados").value,
            "suspeitos": document.getElementById("suspeitos").value,
            "recuperados": document.getElementById("recuperados").value,
            "descartados": document.getElementById("descartados").value,
            "hospitalizados": document.getElementById("hospitalizados").value,
            "obitos": document.getElementById("obitos").value,
            "isolamento": document.getElementById("isolamento").value
        });

        // NÂO PRECISA
        //data.append('contratante_id', localStorage.getItem('contratante_id'))      

        //console.log(data);
        
        const url = `${base_url}/boletim`;
         await axios.post(url, params, {
        "headers": {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 
            //console.log(response);
            //console.log(response.data.id);

            document.getElementById("notifica").innerHTML = "Cadastrado com sucesso!";
            const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

            meuAlert("Cadastrado com sucesso!", "success");
            const redireciona = setInterval(function () {
                clearInterval(redireciona);
                window.location.pathname = `boletim/editar/${response.data.id}`;
            }, 3000);     
           
        })
        .catch(error => {
            {handleClose()}
            meuAlert(`${error.response.data.message}`, "warning");
            console.log(error.message);
            console.log(error.response.data.message);
            //console.log("Erro "+error.response.data.statusCode);
        })     
    }
    // Atualiza ponto-turistico (sem docs)
    async function atualizaBoletim() {
          
        {handleOpen()} 

        var input = document.querySelector('input[type="file"]')

        var data = new FormData()
        data.append('data', new Date(document.getElementById("data").value).toISOString())        
        data.append('numero', document.getElementById("numero").value)
        data.append('ativos', document.getElementById("ativos").value)
        data.append('confirmados', document.getElementById("confirmados").value)
        data.append('monitorados', document.getElementById("monitorados").value)
        data.append('suspeitos', document.getElementById("suspeitos").value)
        data.append('recuperados', document.getElementById("recuperados").value)
        data.append('descartados', document.getElementById("descartados").value)
        data.append('hospitalizados', document.getElementById("hospitalizados").value)
        data.append('obitos', document.getElementById("obitos").value)
        data.append('isolamento', document.getElementById("isolamento").value)

         //NÂO PRECISA
        //data.append('cargo', String(document.getElementById("cargo").value))
        //data.append('contratante_id', localStorage.getItem('contratante_id'))      

        //console.log(data);
        
        const url = `${base_url}/boletim/${id}`;
        await axios.patch(url, data, {
        "headers": {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 
            //console.log(response);
            //console.log(response.data.id);

            document.getElementById("notifica").innerHTML = "Alterado com sucesso!";
            const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

            meuAlert("Alterado com sucesso!", "success");
            const redireciona = setInterval(function () {
                //setRefreshKey(oldKey => oldKey +1);
                window.location.reload();
                clearInterval(redireciona);
            }, 2000);     

        })
        .catch(error => { 
            {handleClose()}
            meuAlert(`${error.response.data.message}`, "warning");
            console.log(error.message);
            console.log(error.response.data.message);
            //console.log("Erro "+error.response.data.statusCode);
        })     
        
    }    
    // Excluir ponto-turistico
    async function ExcluirDoc() {  

        if (window.confirm("Tem certeza que deseja excluir este boletim?")) {        
            
            {handleOpen()} 
         
            const url = `${base_url}/boletim/${id}`;
            await axios.delete(url, {
            "headers": {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            }})
            .then(response => { 
                //console.log(response);
                //console.log(response.data.id);    
               
                document.getElementById("notifica").innerHTML = "Excluído com sucesso!";
                const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

                meuAlert("Excluído com sucesso!", "success");
                const redireciona = setInterval(function () {
                    clearInterval(redireciona);
                    window.location.pathname = `boletim/editar/novo`;
                }, 2000);     

            })
            .catch(error => { 
                {handleClose()}
                meuAlert(`${error.message}`, "error");
                console.log(error.message);
                //console.log("Erro "+error.response.data.statusCode);
            })  
        }           
    }

    return(
        <>   
            <Sidebar page={{ name: 'Boletins', route: '../../boletim' }}>
                <Box sx={{ width: '100%'}}>
                    <Collapse in={alertAberto}>
                        <Alert
                        severity={tipoAlerta}
                        variant="filled"
                        action={
                            <IconButton
                            aria-label="close"
                            size="small"
                            color="inherit"
                            onClick={() => {
                                setAlertAberto(false);
                            }}
                            >
                            <CloseIcon/>
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        {alertMensagem}
                        </Alert>
                    </Collapse>
                </Box>
                
                <div className="tituloPagina"><p id="titu" >Editar Boletim</p></div>   

                <div className="botaoExcluirEspec" >
                    {id !== "novo" && (
                        <div className="botaoExcluirEspec">
                            <button onClick={ExcluirDoc}>Excluir Boletim</button>
                        </div>
                    )}

                </div>


                <div className="semLinha menuPadrao">
                    <li>
                        <button id="0" >Cadastro Geral</button>       
                    </li>  
                </div>

                <div className="formulario" >
                    <div id="cadastroGaleria">
                        <div className="formRedondo formularioGeral ">                                                       
                            
                            <div className="alinhaDireita">
                                
                                <div className="formularioGeralTitulo"><p>Número</p></div>                            
                                <input type="number" name="numero" id="numero" placeholder="Insira o número do boletim" autoComplete="off"  ></input>

                                <div className="formularioGeralTitulo"><p>Confirmados</p></div>                            
                                <input type="number" name="confirmados" id="confirmados" placeholder="Insira o nº de confirmados" autoComplete="off"  ></input>

                                <div className="formularioGeralTitulo"><p>Suspeitos</p></div>                            
                                <input type="number" name="suspeitos" id="suspeitos" placeholder="Insira o nº de suspeitos" autoComplete="off"  ></input>
                                
                                <div className="formularioGeralTitulo"><p>Descartados</p></div>                            
                                <input type="number" name="descartados" id="descartados" placeholder="Insira o nº de descartados" autoComplete="off"  ></input>

                                <div className="formularioGeralTitulo"><p>Óbitos</p></div>                            
                                <input type="number" name="obitos" id="obitos" placeholder="Insira o nº de obitos" autoComplete="off"  ></input>
                                              
                            </div>

                            <div className="alinhaEsquerda">
                                                                                                
                                <div className="formularioGeralTitulo"><p>Data</p></div>                            
                                <input type="date" name="data" id="data" placeholder="Insira a data do boletim" autoComplete="off"  ></input>

                                <div className="formularioGeralTitulo"><p>Ativos</p></div>                            
                                <input type="number" name="ativos" id="ativos" placeholder="Insira o número de ativos" autoComplete="off"  ></input>

                                <div className="formularioGeralTitulo"><p>Monitorados</p></div>                            
                                <input type="number" name="monitorados" id="monitorados" placeholder="Insira o número de monitorados" autoComplete="off"  ></input>

                                <div className="formularioGeralTitulo"><p>Recuperados</p></div>                            
                                <input type="number" name="recuperados" id="recuperados" placeholder="Insira o número de recuperados" autoComplete="off"  ></input>

                                <div className="formularioGeralTitulo"><p>Hospitalizados</p></div>                            
                                <input type="number" name="hospitalizados" id="hospitalizados" placeholder="Insira o número de hospitalizados" autoComplete="off"  ></input>

                                <div className="formularioGeralTitulo"><p>Isolamento</p></div>                            
                                <input type="number" name="isolamento" id="isolamento" placeholder="Insira o número de isolamento" autoComplete="off"  ></input>
                                                
                            </div>                                
                                                         
                            <button onClick={selectFuncao}>Salvar alterações</button>

                        </div>

                    </div>                    
                </div>
            </Sidebar>
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                ><p id="notifica" >vghfgh</p>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </>   
    );
}

export default BoletimEditar;