import React, { useEffect, useState } from "react";
import "../../../components/Geral/geral.css";
import "../../../components/Geral/tabela.css";
import "./Icone.css";
import carregando from "../../../components/Geral/loading.gif";
import { base_url, midia_url } from "../../../environment";
import Sidebar from "../../../components/Menu/Sidebar";
import axios from "axios";
import { verificaLogin } from "../../../context/LoginContext";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { BiEdit } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { GoCircleSlash } from "react-icons/go";

import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

function Icone() {
  useEffect(() => {
    const interval = setInterval(() => {
      verificaLogin();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const [tables, setTables] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);

  const [alertAberto, setAlertAberto] = useState(false);
  const [alertMensagem, setAlertMensagem] = useState("");
  const [tipoAlerta, setTipoAlerta] = useState("");

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  function meuAlert(texto, tipo) {
    setTipoAlerta(tipo);
    setAlertMensagem(texto);
    window.location = `#root`;
    setAlertAberto(true);
  }

  function loading() {
    return (
      <div id="loading">
        <img src={carregando} alt="Carregando" />
      </div>
    );
  }

  // Criar novo ícone
  async function criarIcone() {
    try {
      handleOpen();
      const token = localStorage.getItem("LOCAL_STORAGE_KEY__ACCESS_TOKEN");
      await axios.post(
        `${base_url}/icone`,
        {
          // Aqui você passa os dados necessários para criar o ícone
          // Por exemplo: { nome: "Nome do Ícone", imagem: "Link da Imagem" }
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setRefreshKey((oldKey) => oldKey + 1);
      handleClose();
      meuAlert("Ícone criado com sucesso!", "success");
    } catch (error) {
      handleClose();
      console.error("Erro ao criar ícone:", error);
      meuAlert("Erro ao criar ícone. Por favor, tente novamente.", "error");
    }
  }

  // Excluir ícone
  async function excluirIcone(id) {
    if (window.confirm("Tem certeza que deseja excluir esse ícone?")) {
      try {
        handleOpen();
        const token = localStorage.getItem("LOCAL_STORAGE_KEY__ACCESS_TOKEN");
        await axios.delete(`${base_url}/icone/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setRefreshKey((oldKey) => oldKey + 1);
        handleClose();
        meuAlert("Ícone excluído com sucesso!", "success");
      } catch (error) {
        handleClose();
        console.error("Erro ao excluir ícone:", error);
        meuAlert("Erro ao excluir ícone. Por favor, tente novamente.", "error");
      }
    }
  }

  function mostraStatus(status) {
    if (status) {
      return <a className="true"><BsFillCheckCircleFill /></a>;
    } else {
      return <a className="false"><GoCircleSlash /></a>;
    }
  }

  return (
    <>
      <Sidebar>
        <Box sx={{ width: "100%" }}>
          <Collapse in={alertAberto}>
            <Alert
              severity={tipoAlerta}
              variant="filled"
              action={
                <IconButton
                  aria-label="close"
                  size="small"
                  color="inherit"
                  onClick={() => {
                    setAlertAberto(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {alertMensagem}
            </Alert>
          </Collapse>
        </Box>

        <div className="tituloPagina">
          <p id="titu">Ícones</p>
        </div>
        <div className="optionNovo">
          <button onClick={criarIcone}>
            Novo ícone
          </button>
        </div>

        <div className="tabelaPadrao">
          <TableContainer className="modal-content">
            <Table sx={{ minWidth: 650 }} arial-label="caption table" className="table">
              <TableHead>
                <TableRow>
                  <TableCell>Nome do Ícone</TableCell>
                  <TableCell>Ícone</TableCell>
                  <TableCell>Editar</TableCell>
                  <TableCell>Excluir</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tables.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.titulo || "--"}</TableCell>
                    <TableCell>
                      <img src={item.imagem || "--"} alt="Ícone" />
                    </TableCell>
                    <TableCell>
                      <a target="_self" href={`icone/editar/${item.id}`}>
                        <BiEdit />
                      </a>
                    </TableCell>
                    <TableCell>
                      <a href="#" onClick={() => excluirIcone(item.id)}>
                        <MdDeleteForever />
                      </a>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <div className="loading">
          <div id="icones">{loading()}</div>
        </div>
      </Sidebar>
      <div>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </>
  );
}

export default Icone;
