import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import SidebarData from './SidebarData';
import SubMenu from './SubMenu';
import { IconContext } from 'react-icons/lib';
import '../Geral/geral.css';
import useProfile from '../../hooks/useProfile';
import Select from '../Select/Select';
import { useAPI } from '../../hooks/useAPI';

const Nav = styled.div`
  background: var(--mainColor);
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom:30px;
`;

const NavIcon = styled(Link)`
  margin-left: 2rem;
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Navgation = styled.div`
  color: var(--textColor2);
  margin: 0 50px;
`;

const SidebarNav = styled.nav`
  background: var(--mainColor);
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
  transition: 350ms;
  z-index: 10;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const Sidebar = ({ children, page }) => {
  const user = useProfile();
  const isMasterUser = user?.Roles?.includes('MASTER');

  const filterUserRoles = item => !item.role
    || user?.Roles?.some(role => item.role.includes(role))
    || isMasterUser;
  
  const sidebarData = SidebarData.filter(filterUserRoles).map(item => {
    item.subNav = item.subNav?.filter(filterUserRoles)
    return item;
  });
  
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);

  const { response: contratantes } = useAPI({
    method: 'get',
    url: '/contratante/all',
    initial: [],
    fetch: true,
  });
  
  const { request: handle } = useAPI({
    method: 'patch',
    url: '/usuario/perfil',
  });

  const setContratante = (event) => {
    handle({ contratante_id: event.target.value || null });
    window.location.reload();
  }

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <Nav>
          <NavIcon to='#'>
            <FaIcons.FaBars onClick={showSidebar} />
          </NavIcon>
          
          <Navgation>
            <a target="_self" href="/">Inicial</a> { page && ' / ' }
            <a target="_self" href={ page?.route }>{ page?.name }</a>
          </Navgation>

          {
            isMasterUser &&
            <Select onChange={setContratante} value={user?.contratante_id}>
                <option value="">-- Selecione um Contratante --</option>
                { contratantes.map(({ id, nome }) => <option value={id} key={id}>{ nome }</option>) }
            </Select>
          }
        </Nav>

        <SidebarNav sidebar={sidebar}>
          <SidebarWrap>
            <NavIcon to='#'>
              <AiIcons.AiOutlineClose onClick={showSidebar} />
            </NavIcon>
            { sidebarData.map((item, index) => <SubMenu item={item} key={index} />) }
          </SidebarWrap>
        </SidebarNav>
      </IconContext.Provider>

      <main>
        { children }
      </main>
    </>
  );
};

export default Sidebar;
