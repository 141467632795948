import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';

const SidebarData = [
  {
    title: 'Usuários',
    path: 'usuario',
    icon: <AiIcons.AiFillHome />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },  
  {
    title: 'Geral',
    path: '',
    icon: <FaIcons.FaCartPlus />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Comissão',
        path: 'comissao',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav',
        role: ['ADMIN_SITE', 'USUARIO_SITE'],
      },
      {
        title: 'Sessão Plenária',
        path: 'plenaria',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav',
        role: ['ADMIN_SITE', 'USUARIO_SITE'],
      },       
      {
        title: 'Manifestação',
        path: 'manifestacao',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav',
        role: ['ADMIN_OUVIDORIA_ESIC', 'USUARIO_OUVIDORIA_ESIC'],
      }
    ]
  },
  {
    title: 'Transparência',
    path: '',
    icon: <FaIcons.FaCartPlus />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    role: ['ADMIN_PORTAL', 'USUARIO_PORTAL'],

    subNav: [
      {
        title: 'Ver e Lançar Documentos',
        path: 'documentos',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      }, 
      {
        title: 'Licitações',
        path: 'licitacao',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
      {
        title: 'Contratos',
        path: 'contratos',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
      {
        title: 'Relatórios LRF',
        path: 'relatoriolrf',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
    ]
  },
  {
    title: 'Cadastros',
    path: '',
    icon: <IoIcons.IoMdPeople />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    role: ['ADMIN_SITE', 'USUARIO_SITE'],

    subNav: [
      {
        title: 'Obras',
        path: 'obra',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
      {
        title: 'Agenda de Eventos',
        path: '',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
      {
        title: 'Pesquisas Públicas',
        path: '',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
      {
        title: 'Contatos - Gerais, Esic e Ouvidoria',
        path: 'contato',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
      {
        title: 'Concursos e Seleções',
        path: 'concurso',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
      {
        title: 'Perguntas Frequentes',
        path: 'pergunta',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },      
      {
        title: 'Pontos Turísticos',
        path: 'turismo',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
    ]
  },
  {
    title: 'Multimídia',
    path: '',
    icon: <FaIcons.FaEnvelopeOpenText />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    role: ['ADMIN_SITE', 'USUARIO_SITE'],

    subNav: [
      {
        title: 'Notícias',
        path: 'noticia',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
      {
        title: 'Galeria de Imagens',
        path: 'galeria',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
      {
        title: 'Banners e Popups',
        path: 'banner',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
      {
        title: 'Vídeos',
        path: 'video',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },      
      {
        title: 'Ícones',
        path: 'icone',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav',
        role: ['MASTER'],
      }
    ]
  },
  {
    title: 'Gestão de Pessoas',
    path: '',
    icon: <FaIcons.FaCartPlus />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    role: ['ADMIN_SITE', 'USUARIO_SITE'],

    subNav: [      
      {
        title: 'Pessoas',
        path: 'pessoa',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
      {
        title: 'Fornecedores',
        path: 'fornecedores',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },      
      {
        title: 'Mesa Diretora - Participantes',
        path: '',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },      
      {
        title: 'Mesa Diretora - Períodos Admin.',
        path: '',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      }
    ],
  },
  {
    title: 'Carta de Serviços',
    path: '',
    icon: <FaIcons.FaCartPlus />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    role: ['ADMIN_CARTA_SERVICOS', 'USUARIO_CARTA_SERVICOS'],
  },
  {
    title: 'Saúde (Covid)',
    path: '',
    icon: <FaIcons.FaCartPlus />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    role: ['ADMIN_COVID', 'USUARIO_COVID'],

    subNav: [
      {
        title: 'Vacinas',
        path: 'vacina',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
      {
        title: 'Tipo de Vacina',
        path: 'tipo-vacina',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
      {
        title: 'Grupo Vacinado',
        path: 'grupo-vacinado',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
      {
        title: 'Dose',
        path: 'dose',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
      {
        title: 'Dose Aplicada',
        path: 'dose-aplicada',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
      {
        title: 'Boletim',
        path: 'boletim',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      }
    ]
  },
  {
    title: 'Configurações',
    path: '',
    icon: <FaIcons.FaCartPlus />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Entidade',
        path: 'entidade',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav',
        role: ['ADMIN_SITE', 'USUARIO_SITE'],
      },
      {
        title: 'Contratante',
        path: 'contratante',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav',
        role: ['MASTER'],
      },
      {
        title: 'Município',
        path: 'municipio',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav',
        role: ['ADMIN_SITE', 'USUARIO_SITE'],
      },
      {
        title: 'Logs do Sistema',
        path: '',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav',
        role: ['MASTER'],
      },
      {
        title: 'Ferramentas',
        path: '',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav',
        role: ['MASTER'],
      },
      {
        title: 'Parâmetros',
        path: '',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav',
        role: ['MASTER'],
      },
      {
        title: 'Ajustes Gerais',
        path: '',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav',
        role: ['MASTER'],
      },
      {
        title: 'Órgãos',
        path: 'orgao',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav',
        role: ['ADMIN_SITE', 'USUARIO_SITE'],
      },      
    ]
  },
  {
    title: 'Links Gerais',
    path: 'link',
    icon: <FaIcons.FaCartPlus />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    role: ['ADMIN_SITE', 'USUARIO_SITE'],
  },
  {
    title: 'Categorias',
    path: '',
    icon: <FaIcons.FaCartPlus />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    role: ['MASTER'],

    subNav: [
      {
        title: 'Tipos de Documentos',
        path: 'tipo-documentos',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
      {
        title: 'Modalidade de Licitação',
        path: 'modalidade-licitacao',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
      {
        title: 'Grupo de Documentos',
        path: 'grupo-documentos',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
      {
        title: 'Tipos de Contrato',
        path: 'tipo-contrato',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
      {
        title: 'Tipos de Relatório',
        path: 'tipo-relatorio',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
      {
        title: 'Tipos de Órgãos',
        path: 'tipo-orgao',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
      {
        title: 'Categorias Pontos Turísticos',
        path: 'categoria-turismo',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
    ]
  },
];

const formatSidebarData = item => {
  if (!!item.role && !item.role.includes('MASTER')) {
    item.role.push('SUPER_ADMIN')
  }
  return item
}
  
export default SidebarData.map(formatSidebarData).map(item => {
  item.subNav = item.subNav?.map(formatSidebarData)
  
  if (!item.role) {
    item.role = item.subNav?.flatMap(({ role }) => role) || undefined
  }

  return item;
 });
