import { useEffect, useRef, useState } from "react";
import '../../../components/Geral/geral.css';
import './Relatorio-lrfEditar.css'
import carregando from '../../../components/Geral/loading.gif';
import { verificaLogin, fazerLogout } from '../../../context/LoginContext';
import { Editor } from '@tinymce/tinymce-react';
import { base_url, midia_url, foto_default } from '../../../environment'
import axios from 'axios';
import Sidebar from '../../../components/Menu/Sidebar';
import { useParams } from "react-router-dom";
import FileUploadMultiple from './uploadRelatorio-lrf.tsx'

import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
//import Button from '@mui/material/Button';
var optionClicado = "background-color: var(--mainColor); color: var(--textColor2);"

var unavez = true, umavez = true;

function RelatorioLrfEditar() {
    setInterval(function () { verificaLogin(); }, 1000);

    const [refreshKey, setRefreshKey] = useState(0);

    function atualiza() {
        setRefreshKey(oldKey => oldKey + 1);
    }


    const [alertAberto, setAlertAberto] = React.useState(false);
    const [alertMensagem, setAlertMensagem] = React.useState('');
    const [tipoAlerta, setTipoAlerta] = React.useState('');

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    function meuAlert(texto, tipo) {
        setTipoAlerta(tipo);
        setAlertMensagem(texto);
        window.location = `#root`;
        setAlertAberto(true);
    }

    function loading() { return <div id='loading'><img src={carregando} alt="Carregando" /></div>; }
    setInterval(function () { verificaLogin(); }, 1000);

    var [textoInicial, setTextoInicial] = useState('');

    // Clicar no primeiro botao e colocar texto inicial
    useEffect(() => {

        document.getElementById(0).click();
        setTextoInicial("<p>Escreva seu texto aqui. Aumente o tamanho da área de texto no canto inferior direito.</p>");

    }, []);

    const { id } = useParams();
    var token = localStorage.getItem('LOCAL_STORAGE_KEY__ACCESS_TOKEN');

    // pegar dados tipo_documento
    useEffect(() => {
        axios.get(`${base_url}/tipo-relatorio-lrf`, {
            "headers": {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {

                // eslint-disable-next-line
                if ((response.data.statusCode != undefined) && (response.data.statusCode != 200)) {
                    //window.location.pathname = `mensagem/${response.data.message}`;
                    meuAlert(`${response.data.message}`, "error");
                }

                // eslint-disable-next-line 
                if (response.data.length == 0) {
                    meuAlert("Não há tipos de relaório lrf.", "error");
                }
                else {
                    //console.log(response.data);

                    var pessoa = document.getElementById("tipo_relatorio");
                    for (let i = 0; i < response.data.length; i++) {
                        const option = document.createElement("option");
                        option.value = response.data[i].id;
                        option.innerHTML = response.data[i].nome;
                        pessoa.appendChild(option);
                    }

                }
            })
            .catch(error => {
                console.log(error.message);
                if (error.response) {
                    // eslint-disable-next-line
                    if (error.response.data.statusCode == 401) {
                        window.location.pathname = `login`;
                    }
                    else {
                        meuAlert(`${error.message}`, "error");
                    }
                }
                else {
                    meuAlert(`${error.message}`, "error");
                }
            });

    }, []);

    // pegar dados se não for novo
    useEffect(() => {

        if (id != "novo") {
            axios.get(`${base_url}/relatorio-lrf/${id}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                })
                .then(async response => {
                    //console.log("Resposta ok");
                    //console.log(response);
                    //console.log(response.data);

                    document.getElementById("titulo").value = response.data.titulo;
                    document.getElementById("referencia").value = response.data.referencia;
                    document.getElementById("data").value = new Date(response.data.data).toISOString().split('T')[0];

                    if (response.data.status == true) {
                        document.getElementById("radioativo").checked = true;
                    }
                    else {
                        document.getElementById("radioinativo").checked = true;
                    }

                    await TipoRelatorio(response.data.tipo_relatorio_id);

                    if (umavez) {
                        document.getElementById("anexoUnico").innerHTML = null;

                        //arrayInicial.push(String('<div><p>'+response.data.Anexo[i].titulo+'</p><a target="_blank" href='+midia_url+response.data.Anexo[i].anexo+'>Baixar</a><a target="_blank" href="#" >Excluir</a></div>')); 

                        var divGeral = document.getElementById("anexoUnico");

                        const divContainter = document.createElement("div");

                        const p = document.createElement("p");
                        p.innerHTML = response.data.arquivo.substring(32);

                        const a2 = document.createElement("a");
                        a2.target = "_blank";
                        a2.href = String(midia_url + response.data.arquivo);
                        a2.innerHTML = "Baixar";

                        divContainter.appendChild(p);
                        divContainter.appendChild(a2);

                        divGeral.appendChild(divContainter);




                        document.getElementById("concursoAnexos").innerHTML = null;
                        for (let i = 0; i < response.data.Anexo.length; i++) {

                            //arrayInicial.push(String('<div><p>'+response.data.Anexo[i].titulo+'</p><a target="_blank" href='+midia_url+response.data.Anexo[i].anexo+'>Baixar</a><a target="_blank" href="#" >Excluir</a></div>')); 

                            var divGeral = document.getElementById("concursoAnexos");

                            const divContainter = document.createElement("div");

                            const p = document.createElement("p");
                            p.innerHTML = response.data.Anexo[i].titulo;

                            const a = document.createElement("a");
                            a.innerHTML = "Excluir";
                            a.id = response.data.Anexo[i].id;
                            a.addEventListener("click", (e) => ExcluirAnexo(e.target.id));

                            const a2 = document.createElement("a");
                            a2.target = "_blank";
                            a2.href = String(midia_url + response.data.Anexo[i].anexo);
                            a2.innerHTML = "Baixar";

                            divContainter.appendChild(p);
                            divContainter.appendChild(a);
                            divContainter.appendChild(a2);

                            divGeral.appendChild(divContainter);

                        };
                        umavez = false;
                    }

                })
                .catch(error => {
                    console.log(error.message);
                    if (error.response) {
                        if ((error.response.data.statusCode == 404) || (error.response.data.statusCode == 400)) {
                            if (unavez) {
                                meuAlert("Pessoa não existente.", "warning");
                                //window.location.pathname = `relatoriolrf/editar/novo`;
                                unavez = false;
                            }

                        }
                        else {
                            meuAlert(`${error.message}`, "error");
                        }
                        if (error.response.data.statusCode == 401) {
                            window.location.pathname = `login`;
                        }
                    }
                    else {
                        meuAlert(`${error.message}`, "error");
                    }
                });
        }
        else {
            document.getElementById("cadastroDocRelatorio").innerHTML = null;
        }
        document.getElementById("notifica").innerHTML = "Processo em andamento, aguarde...";

    }, [refreshKey]);

    function menuSec(menuId) {
        for (let i = 0; i <= 1; i++) {
            document.getElementById(i).style = null;
        }
        document.getElementById(menuId).style = optionClicado;
        //console.log("Selecionou o botão " + id);

        // eslint-disable-next-line
        if (menuId != 0) {
            document.getElementById("cadastroRelatorioLrf").setAttribute('hidden', '');
        }
        else {
            document.getElementById("cadastroRelatorioLrf").removeAttribute('hidden');
            document.getElementById("cadastroDocRelatorioLrf").setAttribute('hidden', '');
        }

        // eslint-disable-next-line
        if (menuId != 1) {
            document.getElementById("cadastroDocRelatorioLrf").setAttribute('hidden', '');
        }
        else {
            if (id == "novo") {
                meuAlert("Cadastre e salve o concurso antes de inserir anexos.", "info");
                //alert("Cadastre e salve o concurso antes de inserir anexos.")
                document.getElementById(0).click();
            }
            else {
                document.getElementById("cadastroDocRelatorioLrf").removeAttribute('hidden');
                document.getElementById("cadastroRelatorioLrf").setAttribute('hidden', '');
            }
        }

    }

    function verificaVazios() {

        if (document.getElementById("titulo").value == "") { meuAlert("Título não preenchido.", "warning"); }
        else if (document.getElementById("referencia").value == "") { meuAlert("Referência não preenchida.", "warning"); }
        else if (document.getElementById("data").value == "") { meuAlert("Data não preenchida.", "warning"); }
        else if (id == "novo") {
            if (document.getElementById("arquivo").value == "") { meuAlert("Arquivo não inserido.", "warning"); }
            else { return true; }
        }
        else {
            return true;
        }
    }

    function selectFuncao() {
        if (id == "novo") {
            if (verificaVazios()) {
                cadastraRelatorioLrf();
            };
        }
        else {
            if (verificaVazios()) {
                atualizaRelatorioLrf();
            };
        }
    }
    // Cadastra ponto-turistico (sem docs)
    async function cadastraRelatorioLrf() {

        { handleOpen() }

        var input = document.querySelector('input[type="file"]')

        var data = new FormData()
        data.append('titulo', String(document.getElementById("titulo").value))
        data.append('referencia', String(document.getElementById("referencia").value))
        data.append('data', new Date(document.getElementById("data").value).toISOString())
        data.append('arquivo', input.files[0])
        data.append('status', document.querySelector('input[name="statusDoc"]:checked').value)
        data.append('tipo_relatorio_id', document.getElementById("tipo_relatorio").value)
        data.append('contratante_id', localStorage.getItem('contratante_id'))
        // NÂO PRECISA
        //data.append('contratante_id', localStorage.getItem('contratante_id'))      

        //console.log(data);

        const url = `${base_url}/relatorio-lrf`;
        await axios.post(url, data, {
            "headers": {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                //console.log(response);
                //console.log(response.data.id);

                document.getElementById("notifica").innerHTML = "Cadastrado com sucesso!";
                const mostrar = setInterval(function () { { handleClose() }; clearInterval(mostrar); }, 2000);

                meuAlert("Cadastrado com sucesso!", "success");
                const redireciona = setInterval(function () {
                    clearInterval(redireciona);
                    window.location.pathname = `relatoriolrf/editar/${response.data.id}`;
                }, 3000);

            })
            .catch(error => {
                { handleClose() }
                meuAlert(`${error.response.data.message}`, "warning");
                console.log(error.message);
                console.log(error.response.data.message);
                //console.log("Erro "+error.response.data.statusCode);
            })
    }
    // Atualiza ponto-turistico (sem docs)
    async function atualizaRelatorioLrf() {

        { handleOpen() }

        var input = document.querySelector('input[type="file"]')

        var data = new FormData()
        data.append('titulo', String(document.getElementById("titulo").value))
        data.append('referencia', String(document.getElementById("referencia").value))
        data.append('data', new Date(document.getElementById("data").value).toISOString())
        data.append('arquivo', input.files[0])
        data.append('status', document.querySelector('input[name="statusDoc"]:checked').value)
        data.append('tipo_relatorio_id', document.getElementById("tipo_relatorio").value)
        //NÂO PRECISA
        //data.append('cargo', String(document.getElementById("cargo").value))
        //data.append('contratante_id', localStorage.getItem('contratante_id'))      

        //console.log(data);

        const url = `${base_url}/relatorio-lrf/${id}`;
        await axios.patch(url, data, {
            "headers": {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                //console.log(response);
                //console.log(response.data.id);

                document.getElementById("notifica").innerHTML = "Alterado com sucesso!";
                const mostrar = setInterval(function () { { handleClose() }; clearInterval(mostrar); }, 2000);

                meuAlert("Alterado com sucesso!", "success");
                const redireciona = setInterval(function () {
                    //setRefreshKey(oldKey => oldKey +1);
                    window.location.reload();
                    clearInterval(redireciona);
                }, 2000);

            })
            .catch(error => {
                { handleClose() }
                meuAlert(`${error.response.data.message}`, "warning");
                console.log(error.message);
                console.log(error.response.data.message);
                //console.log("Erro "+error.response.data.statusCode);
            })

    }
    // Excluir ponto-turistico
    async function ExcluirRelatorioLrf() {

        if (window.confirm("Tem certeza que deseja excluir este relatório?")) {

            { handleOpen() }

            const url = `${base_url}/relatorio-lrf/${id}`;
            await axios.delete(url, {
                "headers": {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            })
                .then(response => {
                    //console.log(response);
                    //console.log(response.data.id);    

                    document.getElementById("notifica").innerHTML = "Excluído com sucesso!";
                    const mostrar = setInterval(function () { { handleClose() }; clearInterval(mostrar); }, 2000);

                    meuAlert("Excluído com sucesso!", "success");
                    const redireciona = setInterval(function () {
                        clearInterval(redireciona);
                        window.location.pathname = `relatoriolrf/editar/novo`;
                    }, 2000);

                })
                .catch(error => {
                    { handleClose() }
                    meuAlert(`${error.message}`, "error");
                    console.log(error.message);
                    //console.log("Erro "+error.response.data.statusCode);
                })
        }
    }
    // Excluir anexo
    async function ExcluirAnexo(idAnexo) {

        if (window.confirm("Tem certeza que deseja excluir esse anexo?")) {

            document.getElementById("notifica").innerHTML = "Processo em andamento, aguarde...";

            { handleOpen() }

            const url = `${base_url}/relatorio-lrf/anexo/${idAnexo}`;
            await axios.delete(url, {
                "headers": {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            })
                .then(response => {
                    //console.log(response);
                    //console.log(response.data.id);
                    //window.location.pathname = `concurso/editar/${id}`;

                    document.getElementById("notifica").innerHTML = "Anexo excluído com sucesso!";
                    const mostrar = setInterval(function () { { handleClose() }; clearInterval(mostrar); }, 2000);

                    meuAlert("Anexo excluído com sucesso!", "success");
                    const redireciona = setInterval(function () {
                        //setRefreshKey(oldKey => oldKey +1); 
                        clearInterval(redireciona);
                        window.location.reload();
                    }, 2000);

                })
                .catch(error => {
                    { handleClose() }
                    meuAlert(`${error.message}`, "error");
                    console.log(error.message);
                    //console.log("Erro "+error.response.data.statusCode);
                })
        }
    }

    function TipoRelatorio(valor) {
        for (let i = 0; i < document.getElementById("tipo_relatorio").children.length; i++) {
            if (document.getElementById("tipo_relatorio").children[i].value == valor) {
                document.getElementById("tipo_relatorio").children[i].selected = true;
            }
        }
    }

    return (
        <>
            <Sidebar page={{ name: 'Relatórios LRF', route: '/relatoriolrf' }}>
                <Box sx={{ width: '100%' }}>
                    <Collapse in={alertAberto}>
                        <Alert
                            severity={tipoAlerta}
                            variant="filled"
                            action={
                                <IconButton
                                    aria-label="close"
                                    size="small"
                                    color="inherit"
                                    onClick={() => {
                                        setAlertAberto(false);
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                        >
                            {alertMensagem}
                        </Alert>
                    </Collapse>
                </Box>

                <div className="tituloPagina"><p id="titu" >Editar relatório</p></div>

                {id !== "novo" && (
                    <div className="botaoExcluirEspec">
                        <button onClick={ExcluirRelatorioLrf}>Excluir Relatório</button>
                    </div>
                )}


                <div className="semLinha menuPadrao">
                    <li>
                        <button id="0" onClick={(e) => menuSec(e.target.id)}>Cadastro Geral</button>
                        <button id="1" onClick={(e) => menuSec(e.target.id)}>Anexos</button>
                    </li>
                </div>

                <div className="formulario" >
                    <div id="cadastroRelatorioLrf">
                        <div className="formRedondo formularioGeral ">

                            <div className="alinhaDireita">

                                <div className="novoRadio statusRadio">
                                    <div className="formularioGeralTitulo"><p>Status do documento</p></div>
                                    <input type="radio" id="radioativo" name="statusDoc" value="true" checked></input>
                                    <label for="radioativo">Ativo</label>

                                    <input type="radio" id="radioinativo" name="statusDoc" value="false"></input>
                                    <label for="radioinativo">Inativo</label>
                                </div>

                                <div className="espaco2LadosB_relatoriolrf"></div>

                                <div className="formularioGeralTitulo"><p>Tipo de relatório</p></div>
                                <select name="tipo_relatorio" id="tipo_relatorio">
                                </select>

                                <div className="formularioGeralTitulo"><p>Inserir arquivo</p></div>
                                <input type="file" name="arquivo" id="arquivo" autoComplete="off"  ></input>

                            </div>

                            <div className="alinhaEsquerda">

                                <div className="formularioGeralTitulo"><p>Título</p></div>
                                <input type="text" name="titulo" id="titulo" placeholder="Insira o título do relatório" autoComplete="off"  ></input>

                                <div className="formularioGeralTitulo"><p>Referência</p></div>
                                <input type="text" name="referencia" id="referencia" placeholder="Insira a referência do relatório" autoComplete="off"  ></input>


                                <div className="formularioGeralTitulo"><p>Data</p></div>
                                <input type="date" name="data" id="data" autoComplete="off"  ></input>

                            </div>


                            <div id="cadastroDocRelatorio">
                                <div className="removeBorda cadastroDocInterno">
                                    <p>Arquivo cadastrado: Para substituir, insira novo arquivo acima</p>
                                    <div id="anexoUnico" className="anexos">
                                        {loading()}
                                    </div>
                                </div>
                            </div>

                            <button onClick={selectFuncao}>Salvar alterações</button>

                        </div>

                    </div>
                </div>

                <div className="cadastroDoc">
                    <div id="cadastroDocRelatorioLrf">
                        <div className="cadastroDocInterno">
                            <p>Documentos do Relatório</p>
                            <div id="concursoAnexos" className="anexos">
                                {loading()}
                            </div>
                            <span>Clique no botão ou solte os arquivos abaixo para anexar.</span>
                            <FileUploadMultiple />
                        </div>
                    </div>
                </div>
            </Sidebar>
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                ><p id="notifica" >vghfgh</p>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </>
    );
}

export default RelatorioLrfEditar;