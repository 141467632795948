import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './components/Geral/geral.css';
import { PopupProvider } from './context/PopupContext';

import Usuario from './pages/Usuarios/usuario/Usuario';
import UsuarioLogado from './pages/Usuarios/usuarioLogado/UsuarioLogado';
import UsuarioEditar from './pages/Usuarios/usuarioEditar/UsuarioEditar';
import Login from './pages/login/Login';
import Page404 from './pages/404/404';
import Mensagem from './pages/mensagem/Mensagem';

import Concurso from './pages/Cadastros/concurso/Concurso';
import ConcursoEditar from './pages/Cadastros/concursoEditar/ConcursoEditar';
import Contato from './pages/Cadastros/contato/Contato';
import ContatoEditar from './pages/Cadastros/contatoEditar/ContatoEditar';
import EsicEditar from './pages/Cadastros/esicEditar/EsicEditar';
import OuvidoriaEditar from './pages/Cadastros/ouvidoriaEditar/OuvidoriaEditar';
import Pergunta from './pages/Cadastros/pergunta/Pergunta';
import PerguntaEditar from './pages/Cadastros/perguntaEditar/PerguntaEditar';
import Galeria from './pages/Multimidia/galeria/Galeria';
import GaleriaEditar from './pages/Multimidia/galeriaEditar/GaleriaEditar';
import MunicipioEditar from './pages/Cadastros/municipioEditar/MunicipioEditar';
import Noticia from './pages/Multimidia/noticia/Noticia';
import NoticiaEditar from './pages/Multimidia/noticiaEditar/NoticiaEditar';
import Orgao from './pages/Configuracoes/orgao/Orgao';
import OrgaoEditar from './pages/Configuracoes/orgaoEditar/OrgaoEditar';
import Pessoa from './pages/GestaoPessoas/pessoa/Pessoa';
import PessoaEditar from './pages/GestaoPessoas/pessoaEditar/PessoaEditar';
import Turismo from './pages/Cadastros/turismo/Turismo';
import TurismoEditar from './pages/Cadastros/turismoEditar/TurismoEditar';
import Cargo from './pages/GestaoPessoas/cargo/Cargo';
import CargoEditar from './pages/GestaoPessoas/cargoEditar/CargoEditar';
import TipoOrgao from './pages/Configuracoes/tipoOrgao/TipoOrgao';
import TipoOrgaoEditar from './pages/Configuracoes/tipoOrgaoEditar/TipoOrgaoEditar';
import Video from './pages/Multimidia/video/Video';
import VideoEditar from './pages/Multimidia/videoEditar/VideoEditar';
import Banner from './pages/Multimidia/banner/Banner';
import BannerEditar from './pages/Multimidia/bannerEditar/BannerEditar';
import Link from './pages/Link/link/Link';
import LinkEditar from './pages/Link/linkEditar/LinkEditar';
import CategoriaTurismo from './pages/Cadastros/categoriaTurismo/CategoriaTurismo';
import CategoriaTurismoEditar from './pages/Cadastros/categoriaTurismoEditar/CategoriaTurismoEditar';
import Obra from './pages/Cadastros/obra/Obra';
import ObraEditar from './pages/Cadastros/obraEditar/ObraEditar';
import Comissao from './pages/Configuracoes/comissao/Comissao';
import ComissaoEditar from './pages/Configuracoes/comissaoEditar/ComissaoEditar';
import Plenaria from './pages/Configuracoes/plenaria/Plenaria';
import PlenariaEditar from './pages/Configuracoes/plenariaEditar/PlenariaEditar';
import Manifestacao from './pages/Cidadao/manifestacao/Manifestacao';
import ManifestacaoEditar from './pages/Cidadao/manifestacaoEditar/ManifestacaoEditar';
import Documentos from './pages/Documentos/documentos/Docs';
import DocumentosEditar from './pages/Documentos/documentosEditar/DocsEditar';
import TipoDocumentos from './pages/Documentos/tipoDoc/TipoDoc';
import TipoDocumentosEditar from './pages/Documentos/tipoDocEditar/TipoDocEditar';
import RelatorioLrf from './pages/Documentos/relatorio-lrf/Relatorio-lrf';
import RelatorioLrfEditar from './pages/Documentos/relatorio-lrfEditar/Relatorio-lrfEditar';
import TipoRelatorio from './pages/Documentos/tipoRelatorio/TipoRelatorio';
import TipoRelatorioEditar from './pages/Documentos/tipoRelatorioEditar/TipoRelatorioEditar';
import Licitacao from './pages/Documentos/licitacao/Licitacao';
import LicitacaoEditar from './pages/Documentos/licitacaoEditar/LicitacaoEditar';
import ModalidadeLicitacao from './pages/Documentos/modalidade-licitacao/Modalidade-licitacao';
import ModalidadeLicitacaoEditar from './pages/Documentos/modalidade-licitacaoEditar/Modalidade-licitacaoEditar';
import Contratos from './pages/Documentos/contratos/Contratos';
import ContratosEditar from './pages/Documentos/contratosEditar/ContratosEditar';
import TipoContrato from './pages/Documentos/tipoContrato/TipoContrato';
import TipoContratoEditar from './pages/Documentos/tipoContratoEditar/TipoContratoEditar';
import Fornecedores from './pages/Documentos/fornecedores/Fornecedores';
import FornecedoresEditar from './pages/Documentos/fornecedoresEditar/FornecedoresEditar';
import GrupoDocumentos from './pages/Documentos/grupoDoc/GrupoDoc';
import GrupoDocumentosEditar from './pages/Documentos/grupoDocEditar/GrupoDocEditar';
import Vacina from './pages/Covid/vacina/Vacina';
import VacinaEditar from './pages/Covid/vacinaEditar/VacinaEditar';
import TipoVacina from './pages/Covid/tipoVacina/TipoVacina';
import TipoVacinaEditar from './pages/Covid/tipoVacinaEditar/TipoVacinaEditar';
import GrupoVacinado from './pages/Covid/grupoVacinado/GrupoVacinado';
import GrupoVacinadoEditar from './pages/Covid/grupoVacinadoEditar/GrupoVacinadoEditar';
import Dose from './pages/Covid/dose/Dose';
import DoseEditar from './pages/Covid/doseEditar/DoseEditar';
import DoseAplicada from './pages/Covid/doseAplicada/DoseAplicada';
import DoseAplicadaEditar from './pages/Covid/doseAplicadaEditar/DoseAplicadaEditar';
import Boletim from './pages/Covid/boletim/Boletim';
import BoletimEditar from './pages/Covid/boletimEditar/BoletimEditar';
import Contratante from './pages/Administrativo/contratante/Contratante';
import ContratanteEditar from './pages/Administrativo/contratanteEditar/ContratanteEditar';
import Icone from './pages/Multimidia/icone/Icone';
import IconeEditar from './pages/Multimidia/iconeEditar/IconeEditar';
import Entidade from './pages/Administrativo/entidade/Entidade';
import EntidadeEditar from './pages/Administrativo/entidadeEditar/EntidadeEditar';
import TipoProposicao from './pages/Configuracoes/tipoProposicao/TipoProposicao';
import TipoProposicaoEditar from './pages/Configuracoes/tipoProposicaoEditar/TipoProposicaoEditar';
import TipoAutoriaProposicao from './pages/Configuracoes/tipoAutoriaProposicao/TipoAutoriaProposicao';
import TipoAutoriaProposicaoEditar from './pages/Configuracoes/tipoAutoriaProposicaoEditar/TipoAutoriaProposicaoEditar';
import StatusProposicao from './pages/Configuracoes/statusProposicao/StatusProposicao';
import StatusProposicaoEditar from './pages/Configuracoes/statusProposicaoEditar/StatusProposicaoEditar';
import Proposicao from './pages/Configuracoes/proposicao/Proposicao';
import ProposicaoEditar from './pages/Configuracoes/proposicaoEditar/ProposicaoEditar';
import PeriodoADM from './pages/GestaoPessoas/periodoadm/Periodoadm';
import PeriodoADMEditar from './pages/GestaoPessoas/periodoadmEditar/PeriodoadmEditar';
import Diretoria from './pages/GestaoPessoas/diretoria/Diretoria';
import DiretoriaEditar from './pages/GestaoPessoas/diretoriaEditar/DiretoriaEditar';

ReactDOM.render(
  <React.StrictMode>
    <PopupProvider/>
    <Router>
      <Routes>
        <Route path='/' element={<UsuarioLogado/>}/>
        <Route path='/usuario' element={<Usuario/>}/>
        <Route path='/usuario/editar/:id' element={<UsuarioEditar/>}/> 
        <Route path='/login' element={<Login/>}/> 
        <Route path='/login/:mensagem' element={<Login/>}/>         
        <Route path='*' element={<Page404/>}/>               
        <Route path='mensagem/:mensagem' element={<Mensagem/>}/>
        <Route path='/concurso' element={<Concurso/>}/> 
        <Route path='/concurso/editar/:id' element={<ConcursoEditar/>}/> 
        <Route path='/contato' element={<Contato/>}/> 
        <Route path='/contato/editar/:id' element={<ContatoEditar/>}/> 
        <Route path='/esic/editar/:id' element={<EsicEditar/>}/> 
        <Route path='/ouvidoria/editar/:id' element={<OuvidoriaEditar/>}/> 
        <Route path='/pergunta' element={<Pergunta/>}/> 
        <Route path='/pergunta/editar/:id' element={<PerguntaEditar/>}/> 
        <Route path='/galeria' element={<Galeria/>}/> 
        <Route path='/galeria/editar/:id' element={<GaleriaEditar/>}/> 
        <Route path='/municipio' element={<MunicipioEditar/>}/> 
        <Route path='/noticia' element={<Noticia/>}/> 
        <Route path='/noticia/editar/:id' element={<NoticiaEditar/>}/> 
        <Route path='/orgao' element={<Orgao/>}/> 
        <Route path='/orgao/editar/:id' element={<OrgaoEditar/>}/> 
        <Route path='/pessoa' element={<Pessoa/>}/> 
        <Route path='/pessoa/editar/:id' element={<PessoaEditar/>}/> 
        <Route path='/turismo' element={<Turismo/>}/> 
        <Route path='/turismo/editar/:id' element={<TurismoEditar/>}/> 
        <Route path='/cargo' element={<Cargo/>}/> 
        <Route path='/cargo/editar/:id' element={<CargoEditar/>}/> 
        <Route path='/tipo-orgao' element={<TipoOrgao/>}/> 
        <Route path='/tipo-orgao/editar/:id' element={<TipoOrgaoEditar/>}/> 
        <Route path='/video' element={<Video/>}/> 
        <Route path='/video/editar/:id' element={<VideoEditar/>}/> 
        <Route path='/banner' element={<Banner/>}/> 
        <Route path='/banner/editar/:id' element={<BannerEditar/>}/> 
        <Route path='/link' element={<Link/>}/> 
        <Route path='/link/editar/:id' element={<LinkEditar/>}/> 
        <Route path='/categoria-turismo' element={<CategoriaTurismo/>}/> 
        <Route path='/categoria-turismo/editar/:id' element={<CategoriaTurismoEditar/>}/> 
        <Route path='/obra' element={<Obra/>}/> 
        <Route path='/obra/editar/:id' element={<ObraEditar/>}/> 
        <Route path='/comissao' element={<Comissao/>}/> 
        <Route path='/comissao/editar/:id' element={<ComissaoEditar/>}/> 
        <Route path='/plenaria' element={<Plenaria/>}/> 
        <Route path='/plenaria/editar/:id' element={<PlenariaEditar/>}/> 
        <Route path='/manifestacao' element={<Manifestacao/>}/> 
        <Route path='/manifestacao/editar/:id' element={<ManifestacaoEditar/>}/> 
        <Route path='/documentos' element={<Documentos/>}/> 
        <Route path='/documentos/editar/:id' element={<DocumentosEditar/>}/> 
        <Route path='/tipo-documentos' element={<TipoDocumentos/>}/> 
        <Route path='/tipo-documentos/editar/:id' element={<TipoDocumentosEditar/>}/> 
        <Route path='/relatoriolrf' element={<RelatorioLrf/>}/> 
        <Route path='/relatoriolrf/editar/:id' element={<RelatorioLrfEditar/>}/> 
        <Route path='/tipo-relatorio' element={<TipoRelatorio/>}/> 
        <Route path='/tipo-relatorio/editar/:id' element={<TipoRelatorioEditar/>}/> 
        <Route path='/licitacao' element={<Licitacao/>}/> 
        <Route path='/licitacao/editar/:id' element={<LicitacaoEditar/>}/> 
        <Route path='/modalidade-licitacao' element={<ModalidadeLicitacao/>}/> 
        <Route path='/modalidade-licitacao/editar/:id' element={<ModalidadeLicitacaoEditar/>}/> 
        <Route path='/contratos' element={<Contratos/>}/> 
        <Route path='/contratos/editar/:id' element={<ContratosEditar/>}/> 
        <Route path='/tipo-contrato' element={<TipoContrato/>}/> 
        <Route path='/tipo-contrato/editar/:id' element={<TipoContratoEditar/>}/> 
        <Route path='/fornecedores' element={<Fornecedores/>}/> 
        <Route path='/fornecedores/editar/:id' element={<FornecedoresEditar/>}/> 
        <Route path='/grupo-documentos' element={<GrupoDocumentos/>}/> 
        <Route path='/grupo-documentos/editar/:id' element={<GrupoDocumentosEditar/>}/> 
        <Route path='/vacina' element={<Vacina/>}/> 
        <Route path='/vacina/editar/:id' element={<VacinaEditar/>}/> 
        <Route path='/tipo-vacina' element={<TipoVacina/>}/> 
        <Route path='/tipo-vacina/editar/:id' element={<TipoVacinaEditar/>}/> 
        <Route path='/grupo-vacinado' element={<GrupoVacinado/>}/> 
        <Route path='/grupo-vacinado/editar/:id' element={<GrupoVacinadoEditar/>}/> 
        <Route path='/dose' element={<Dose/>}/> 
        <Route path='/dose/editar/:id' element={<DoseEditar/>}/> 
        <Route path='/dose-aplicada' element={<DoseAplicada/>}/> 
        <Route path='/dose-aplicada/editar/:id' element={<DoseAplicadaEditar/>}/> 
        <Route path='/boletim' element={<Boletim/>}/> 
        <Route path='/boletim/editar/:id' element={<BoletimEditar/>}/> 
        <Route path='/contratante' element={<Contratante/>}/> 
        <Route path='/contratante/editar/:id' element={<ContratanteEditar/>}/> 
        <Route path='/icone' element={<Icone/>}/> 
        <Route path='/icone/editar/:id' element={<IconeEditar/>}/> 
        <Route path='/entidade' element={<Entidade/>}/> 
        <Route path='/entidade/editar/:id' element={<EntidadeEditar/>}/> 
        <Route path='/tipo-proposicao' element={<TipoProposicao/>}/> 
        <Route path='/tipo-proposicao/editar/:id' element={<TipoProposicaoEditar/>}/> 
        <Route path='/tipo-autoria-proposicao' element={<TipoAutoriaProposicao/>}/> 
        <Route path='/tipo-autoria-proposicao/editar/:id' element={<TipoAutoriaProposicaoEditar/>}/> 
        <Route path='/status-proposicao' element={<StatusProposicao/>}/> 
        <Route path='/status-proposicao/editar/:id' element={<StatusProposicaoEditar/>}/> 
        <Route path='/proposicao' element={<Proposicao/>}/> 
        <Route path='/proposicao/editar/:id' element={<ProposicaoEditar/>}/> 
        <Route path='/periodoadm' element={<PeriodoADM/>}/> 
        <Route path='/periodoadm/editar/:id' element={<PeriodoADMEditar/>}/> 
        <Route path='/diretoria' element={<Diretoria/>}/> 
        <Route path='/diretoria/editar/:id' element={<DiretoriaEditar/>}/> 

      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);