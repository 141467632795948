import { useEffect, useRef, useState } from "react";
import '../../../components/Geral/geral.css';
import './ContratosEditar.css'
import carregando from '../../../components/Geral/loading.gif';
import { verificaLogin, fazerLogout } from '../../../context/LoginContext';
import { Editor } from '@tinymce/tinymce-react';
import {base_url, midia_url, foto_default} from '../../../environment'
import axios from 'axios';
import Sidebar from '../../../components/Menu/Sidebar';
import FileUploadMultiple from './uploadContrato.tsx'
import { useParams } from "react-router-dom";

import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import TipoContrato from "../tipoContrato/TipoContrato";
//import Button from '@mui/material/Button';

var unavez = true, umavez = true, pinaVez=true;
var optionClicado = "background-color: var(--mainColor); color: var(--textColor2);"

function ContratosEditar(){
    setInterval(function () {verificaLogin();}, 1000);

    const [refreshKey, setRefreshKey] = useState(0);
    
    function atualiza() {
        setRefreshKey(oldKey => oldKey +1); 
    }

    const [alertAberto, setAlertAberto] = React.useState(false);
    const [alertMensagem, setAlertMensagem] = React.useState('');
    const [tipoAlerta, setTipoAlerta] = React.useState('');

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    function meuAlert(texto, tipo) {
        setTipoAlerta(tipo);
        setAlertMensagem(texto);
        window.location = `#root`;
        setAlertAberto(true);
    }

    function loading() {return <div id='loading'><img src={carregando} alt="Carregando"/></div>; }    
    setInterval(function () {verificaLogin();}, 1000);
    
    var [textoInicial, setTextoInicial] = useState('');    

    // Clicar no primeiro botao e colocar texto inicial
    useEffect(() => {
        
        document.getElementById(0).click();
        setTextoInicial("<p>Escreva seu texto aqui. Aumente o tamanho da área de texto no canto inferior direito.</p>");
    
    },[]);

    const {id} = useParams();
    var token = localStorage.getItem('LOCAL_STORAGE_KEY__ACCESS_TOKEN');

    // pegar dados fornecedor
    useEffect(() => {
    axios.get(`${base_url}/fornecedor`, {
        "headers": {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 

            // eslint-disable-next-line
            if((response.data.statusCode != undefined) && (response.data.statusCode != 200)){
                //window.location.pathname = `mensagem/${response.data.message}`;
                meuAlert(`${response.data.message}`, "error");
            }

            // eslint-disable-next-line 
            if(response.data.length == 0)
            {
                meuAlert("Não há fornecedores.", "error");     
            }
            else
            {
                //console.log(response.data);

                if(pinaVez){
                
                    var forn = document.getElementById("fornecedores");                
                    for(let i=0; i<response.data.length; i++){
                        const div = document.createElement("div");
                        div.className = "formularioGeralTitulo";
                        
                        const p = document.createElement("p");
                        p.innerHTML = "Fornecedor "+(i+1);

                        const sele = document.createElement("select");
                        sele.name = "fornecedor";
                        sele.id = "fornecedor"+i;
                        
                        div.appendChild(p)   
                        forn.appendChild(div);
                        forn.appendChild(sele);

                        var pessoa = document.getElementById("fornecedor"+i);   
                        
                        //opção vazia
                        const option = document.createElement("option");
                        option.value = "";
                        option.innerHTML = "--";                    
                        pessoa.appendChild(option);

                        for(let i=0; i<response.data.length; i++){
                            const option = document.createElement("option");
                            option.value = response.data[i].id;
                            option.innerHTML = response.data[i].nome;                    
                            pessoa.appendChild(option);
                        }
                    }

                    pinaVez=false;
                }
            }            
        })    
        .catch(error => { 
            console.log(error.message);
            if(error.response)
            {
                // eslint-disable-next-line
                if(error.response.data.statusCode == 401)
                {
                    window.location.pathname = `login`;
                }
                else{
                    meuAlert(`${error.message}`, "error");
                }  
            }
            else{
                meuAlert(`${error.message}`, "error");
            }            
        });

    },[]); 

    // pegar dados tipo-contrato
    useEffect(() => {
    axios.get(`${base_url}/tipo-contrato`, {
        "headers": {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 

            // eslint-disable-next-line
            if((response.data.statusCode != undefined) && (response.data.statusCode != 200)){
                //window.location.pathname = `mensagem/${response.data.message}`;
                meuAlert(`${response.data.message}`, "error");
            }

            // eslint-disable-next-line 
            if(response.data.length == 0)
            {
                meuAlert("Não há dados tipo de contrato.", "error");    
            }
            else
            {
                //console.log(response.data);

                var pessoa = document.getElementById("tipo_contrato");                
                for(let i=0; i<response.data.length; i++){
                    const option = document.createElement("option");
                    option.value = response.data[i].id;
                    option.innerHTML = response.data[i].nome;                    
                    pessoa.appendChild(option);
                }

            }            
        })    
        .catch(error => { 
            console.log(error.message);
            if(error.response)
            {
                // eslint-disable-next-line
                if(error.response.data.statusCode == 401)
                {
                    window.location.pathname = `login`;
                }
                else{
                    meuAlert(`${error.message}`, "error");
                }  
            }
            else{
                meuAlert(`${error.message}`, "error");
            }            
        });

    },[]); 

    // pegar dados licitacao
    useEffect(() => {
    axios.get(`${base_url}/licitacao`, {
        "headers": {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 

            //console.log(response.data)
            //console.log(response.data[0])

            // eslint-disable-next-line
            if((response.data.statusCode != undefined) && (response.data.statusCode != 200)){
                //window.location.pathname = `mensagem/${response.data.message}`;
                meuAlert(`${response.data.message}`, "error");
            }

            // eslint-disable-next-line 
            if(response.data.length == 0)
            {
                meuAlert("Não há dados de licitação.", "error");          
            }
            else
            {
                //console.log(response.data);

                var pessoa = document.getElementById("licitacao");                
                for(let i=0; i<response.data.length; i++){
                    const option = document.createElement("option");
                    option.value = response.data[i].id;
                    option.innerHTML = response.data[i].numero;                    
                    pessoa.appendChild(option);
                }

            }            
        })    
        .catch(error => { 
            console.log(error.message);
            if(error.response)
            {
                // eslint-disable-next-line
                if(error.response.data.statusCode == 401)
                {
                    window.location.pathname = `login`;
                }
                else{
                    meuAlert(`${error.message}`, "error");
                }  
            }
            else{
                meuAlert(`${error.message}`, "error");
            }            
        });

    },[]); 
        
    // pegar dados se não for novo
    useEffect(() => {
        
        if(id != "novo")
        {
            axios.get(`${base_url}/contrato/${id}`, 
            { headers: {
                'Authorization': `Bearer ${token}`,
            }})
            .then(async response => {             
                //console.log("Resposta ok");
                //console.log(response);
                //console.log(response.data);

                document.getElementById("numero").value = response.data.numero;        
                document.getElementById("valor").value = response.data.valor; 
                document.getElementById("data_inicio").value = new Date(response.data.data_inicio).toISOString().split('T')[0];    
                document.getElementById("data_fim").value = new Date(response.data.data_fim).toISOString().split('T')[0];    
                document.getElementById("objeto").value = response.data.objeto;
                document.getElementById("identidade_fiscal").value = response.data.identidade_fiscal;        
                document.getElementById("fiscal_responsavel").value = response.data.fiscal_responsavel; 

                if(response.data.status == true){document.getElementById("radioativo").checked = true;}
                else {document.getElementById("radioinativo").checked = true;}

                for(let i=0; i<response.data.Fornecedor.length; i++){
                    await Fornecedores(response.data.Fornecedor[i].id, i);
                }
                
                await TipoContrato(response.data.tipo_contrato_id);
                
                await Licitacao(response.data.licitacao_id);

                //arquivo
                if(umavez){
                    document.getElementById("anexoUnico").innerHTML = null;   
                
                    //arrayInicial.push(String('<div><p>'+response.data.Anexo[i].titulo+'</p><a target="_blank" href='+midia_url+response.data.Anexo[i].anexo+'>Baixar</a><a target="_blank" href="#" >Excluir</a></div>')); 
                        
                    var divGeral = document.getElementById("anexoUnico");
                    
                    const divContainter = document.createElement("div");
    
                    const p = document.createElement("p");
                    p.innerHTML = response.data.arquivo.substring(32);
            
                    const a2 = document.createElement("a");
                    a2.target = "_blank";
                    a2.href = String(midia_url+response.data.arquivo);
                    a2.innerHTML = "Baixar";
                    
                    divContainter.appendChild(p);              
                    divContainter.appendChild(a2); 
                        
                    divGeral.appendChild(divContainter);
                       
                    umavez = false;
                }

                //anexos
                if(unavez){
                    document.getElementById("contratosAnexos").innerHTML = null;   
                    for(let i=0; i<response.data.Anexo.length; i++){
                
                        //arrayInicial.push(String('<div><p>'+response.data.Anexo[i].titulo+'</p><a target="_blank" href='+midia_url+response.data.Anexo[i].anexo+'>Baixar</a><a target="_blank" href="#" >Excluir</a></div>')); 
                            
                        var divGeral = document.getElementById("contratosAnexos");
                        
                        const divContainter = document.createElement("div");
        
                        const p = document.createElement("p");
                        p.innerHTML = response.data.Anexo[i].titulo;
        
                        const a = document.createElement("a");
                        a.innerHTML = "Excluir";
                        a.id = response.data.Anexo[i].id;
                        a.addEventListener("click", (e) => ExcluirAnexo(e.target.id));
        
                        const a2 = document.createElement("a");
                        a2.target = "_blank";
                        a2.href = String(midia_url+response.data.Anexo[i].anexo);
                        a2.innerHTML = "Baixar";
                        
                        divContainter.appendChild(p); 
                        divContainter.appendChild(a);                 
                        divContainter.appendChild(a2); 
                            
                        divGeral.appendChild(divContainter);
                        
                    };
                    unavez = false;
                } 
                
                //var arrayInicial = [];        

            })
            .catch(error => { 
                console.log(error.message);
                if(error.response)
                {
                    if((error.response.data.statusCode == 404) || (error.response.data.statusCode == 400))
                    {
                        if(unavez)
                        {
                            meuAlert("Contrato não existente.", "warning");
                            //window.location.pathname = `contratos/editar/novo`;
                            unavez = false;
                        }
                        
                    }
                    else{
                        meuAlert(`${error.message}`, "error");
                    }
                    if(error.response.data.statusCode == 401)
                    {
                        window.location.pathname = `login`;
                    }
                }   
                else{
                    meuAlert(`${error.message}`, "error");
                }            
            });
        }
        else{
            document.getElementById("cadastroDocRelatorio").innerHTML = null;      
        }
        document.getElementById("notifica").innerHTML = "Processo em andamento, aguarde...";
               
    },[refreshKey]); 

    function menuSec(menuId) {
        for(let i=0; i<=2; i++){
            document.getElementById(i).style = null;
        }
        document.getElementById(menuId).style = optionClicado;       
        //console.log("Selecionou o botão " + id);

        // eslint-disable-next-line
        if(menuId != 0)
        {
            document.getElementById("cadastroContrato").setAttribute('hidden', '');
        }
        else
        {
            document.getElementById("cadastroDocContrato").setAttribute('hidden', '');           
            document.getElementById("cadastroFornecedores").setAttribute('hidden', '');  
            document.getElementById("cadastroContrato").removeAttribute('hidden');
            
        }

        // eslint-disable-next-line
        if(menuId != 1){
            document.getElementById("cadastroDocContrato").setAttribute('hidden', '');
        }
        else{
            if(id == "novo")
            {
                meuAlert("Cadastre e salve o contrato antes de inserir anexos.", "info");
                //alert("Cadastre e salve o contrato antes de inserir anexos.")
                document.getElementById(0).click();   
            }
            else
            {
               document.getElementById("cadastroDocContrato").removeAttribute('hidden');
                document.getElementById("cadastroContrato").setAttribute('hidden', ''); 
                document.getElementById("cadastroFornecedores").setAttribute('hidden', ''); 
            }            
        }

        // eslint-disable-next-line
        if(menuId != 2){
            document.getElementById("cadastroFornecedores").setAttribute('hidden', '');
        }
        else{
            document.getElementById("cadastroFornecedores").removeAttribute('hidden');
            document.getElementById("cadastroContrato").setAttribute('hidden', ''); 
            document.getElementById("cadastroDocContrato").setAttribute('hidden', '');            
        }

    }  

    function verificaVazios() {

        if(document.getElementById("numero").value == ""){meuAlert("Número do contrato não preenchido.", "warning");}
        else if(document.getElementById("valor").value == ""){meuAlert("Valor do contrato não preenchido.", "warning");}
        else if(document.getElementById("data_inicio").value == ""){meuAlert("Data Início do contrato não preenchida.", "warning");}
        else if(document.getElementById("data_fim").value == ""){meuAlert("Data Fim do contrato não preenchida.", "warning");}
        else if(document.getElementById("objeto").value == ""){meuAlert("Objeto do contrato não preenchido.", "warning");}
        else if(id == "novo"){
            if(document.getElementById("arquivo").value == "")
            {meuAlert("Arquivo não inserido.", "warning");}
            else{return true;}
        }
        else
        {
            return true;
        }    
    }
    
    function selectFuncao(){
        if(id == "novo")
        {
            if(verificaVazios())
            {
                cadastraContrato();
            };            
        }
        else
        {
            if(verificaVazios())
            {
                atualizaContrato();
            };    
        }
    }
    // Cadastra ponto-turistico (sem docs)
    async function cadastraContrato() {

        {handleOpen()} 

        var input = document.querySelector('input[type="file"]')

        var element = document.getElementById("fornecedores");
        var numberOfChildren = element.getElementsByTagName('select').length;
        var arrayForneced =[];

        for(let i=0; i<numberOfChildren; i++){

            var verificaForn = document.getElementById("fornecedor"+i).value;
            if(verificaForn != ""){

                arrayForneced.push(verificaForn);
            }
            
        }
        //"modulos":["CIDADAO","CARTA_SERVICOS","RELATORIO"]
        //console.log('"modulos":['+arrayForneced.toString()+"]")
        console.log(arrayForneced.toString())

        var data = new FormData()
        data.append('numero', document.getElementById("numero").value)    
        data.append('valor', document.getElementById("valor").value) 
        data.append('arquivo', input.files[0])  
        data.append('data_inicio', new Date(document.getElementById("data_inicio").value).toISOString())   
        data.append('data_fim', new Date(document.getElementById("data_fim").value).toISOString())   
        data.append('objeto', String(document.getElementById("objeto").value))   
        data.append('status', document.querySelector('input[name="statusContrato"]:checked').value)
        data.append('fornecedores', arrayForneced.toString())  
        data.append('tipo_contrato_id', String(document.getElementById("tipo_contrato").value))
        data.append('licitacao_id', String(document.getElementById("licitacao").value))    
        data.append('identidade_fiscal', document.getElementById("identidade_fiscal").value) 
        data.append('fiscal_responsavel', document.getElementById("fiscal_responsavel").value) 
       // data.append('contratante_id', localStorage.getItem('contratante_id'))     
        // NÂO PRECISA
        //data.append('contratante_id', localStorage.getItem('contratante_id'))      

        //console.log(data);
        
        const url = `${base_url}/contrato`;
         await axios.post(url, data, {
        "headers": {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 
            //console.log(response);
            //console.log(response.data.id);

            document.getElementById("notifica").innerHTML = "Cadastrado com sucesso!";
            const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

            meuAlert("Cadastrado com sucesso!", "success");
            const redireciona = setInterval(function () {
                clearInterval(redireciona);
                window.location.pathname = `contratos/editar/${response.data.id}`;
            }, 3000);     
           
        })
        .catch(error => {
            {handleClose()}
            meuAlert(`${error.response.data.message}`, "warning");
            console.log(error.message);
            console.log(error.response.data.message);
            //console.log("Erro "+error.response.data.statusCode);
        })     
    }
    // Atualiza ponto-turistico (sem docs)
    async function atualizaContrato() {
          
        {handleOpen()} 

        var input = document.querySelector('input[type="file"]')

        var element = document.getElementById("fornecedores");
        var numberOfChildren = element.getElementsByTagName('select').length;
        var arrayForneced =[];

        for(let i=0; i<numberOfChildren; i++){

            var verificaForn = document.getElementById("fornecedor"+i).value;
            if(verificaForn != ""){

                arrayForneced.push(verificaForn);
            }
            
        }
        //"modulos":["CIDADAO","CARTA_SERVICOS","RELATORIO"]
        //console.log('"modulos":['+arrayForneced.toString()+"]")
        console.log(arrayForneced.toString())

        var data = new FormData()
        data.append('numero', document.getElementById("numero").value)    
        data.append('valor', document.getElementById("valor").value) 
        data.append('arquivo', input.files[0])  
        data.append('data_inicio', new Date(document.getElementById("data_inicio").value).toISOString())   
        data.append('data_fim', new Date(document.getElementById("data_fim").value).toISOString())   
        data.append('objeto', String(document.getElementById("objeto").value))   
        data.append('status', document.querySelector('input[name="statusContrato"]:checked').value)
        data.append('fornecedores', arrayForneced.toString())  
        data.append('tipo_contrato_id', String(document.getElementById("tipo_contrato").value))
        data.append('licitacao_id', String(document.getElementById("licitacao").value))    
        data.append('identidade_fiscal', document.getElementById("identidade_fiscal").value) 
        data.append('fiscal_responsavel', document.getElementById("fiscal_responsavel").value) 
        //NÂO PRECISA
        //data.append('cargo', String(document.getElementById("cargo").value))
        //data.append('contratante_id', localStorage.getItem('contratante_id'))      

        //console.log(String(data));
        
        const url = `${base_url}/contrato/${id}`;
        await axios.patch(url, data, {
        "headers": {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 
            //console.log(response);
            //console.log(response.data.id);

            document.getElementById("notifica").innerHTML = "Alterado com sucesso!";
            const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

            meuAlert("Alterado com sucesso!", "success");
            const redireciona = setInterval(function () {
                //setRefreshKey(oldKey => oldKey +1);
                window.location.reload();
                clearInterval(redireciona);
            }, 2000);     

        })
        .catch(error => { 
            {handleClose()}
            meuAlert(`${error.response.data.message}`, "warning");
            console.log(error.message);
            console.log(error.response.data.message);
            //console.log("Erro "+error.response.data.statusCode);
        })     
        
    }    
    // Excluir ponto-turistico
    async function ExcluirDoc() {  

        if (window.confirm("Tem certeza que deseja excluir este contrato?")) {        
            
            {handleOpen()} 
         
            const url = `${base_url}/contrato/${id}`;
            await axios.delete(url, {
            "headers": {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            }})
            .then(response => { 
                //console.log(response);
                //console.log(response.data.id);    
               
                document.getElementById("notifica").innerHTML = "Excluído com sucesso!";
                const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

                meuAlert("Excluído com sucesso!", "success");
                const redireciona = setInterval(function () {
                    clearInterval(redireciona);
                    window.location.pathname = `contratos/editar/novo`;
                }, 2000);     

            })
            .catch(error => { 
                {handleClose()}
                meuAlert(`${error.message}`, "error");
                console.log(error.message);
                //console.log("Erro "+error.response.data.statusCode);
            })  
        }           
    }

    function Fornecedores(valor, local){
        for(let i=0; i<document.getElementById("fornecedor"+local).children.length; i++){
            if(document.getElementById("fornecedor"+local).children[i].value == valor){
                document.getElementById("fornecedor"+local).children[i].selected = true;
            }        
        }
    }

    function TipoContrato(valor){
        for(let i=0; i<document.getElementById("tipo_contrato").children.length; i++){
            if(document.getElementById("tipo_contrato").children[i].value == valor){
                document.getElementById("tipo_contrato").children[i].selected = true;
            }        
        }
    }

    function Licitacao(valor){
        for(let i=0; i<document.getElementById("licitacao").children.length; i++){
            if(document.getElementById("licitacao").children[i].value == valor){
                document.getElementById("licitacao").children[i].selected = true;
            }        
        }
    }

    // Excluir anexo
    async function ExcluirAnexo(idAnexo) {  

        if (window.confirm("Tem certeza que deseja excluir esse anexo?")) {     
            
            document.getElementById("notifica").innerHTML = "Processo em andamento, aguarde...";
            
            {handleOpen()}    

            const url = `${base_url}/contrato/anexo/${idAnexo}`;
            await axios.delete(url, {
            "headers": {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            }})
            .then(response => { 
                //console.log(response);
                //console.log(response.data.id);
                //window.location.pathname = `contrato/editar/${id}`;

                document.getElementById("notifica").innerHTML = "Anexo excluído com sucesso!";
                const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

                meuAlert("Anexo excluído com sucesso!", "success");
                const redireciona = setInterval(function () {
                    //setRefreshKey(oldKey => oldKey +1); 
                    clearInterval(redireciona);
                    window.location.reload();
                }, 2000);     

            })
            .catch(error => { 
                {handleClose()}
                meuAlert(`${error.message}`, "error");
                console.log(error.message);
                //console.log("Erro "+error.response.data.statusCode);
            })  
        }           
    }

    return(
        <>   
            <Sidebar page={{ name: 'Contratos', route: '/contratos' }}>
                <Box sx={{ width: '100%'}}>
                    <Collapse in={alertAberto}>
                        <Alert
                        severity={tipoAlerta}
                        variant="filled"
                        action={
                            <IconButton
                            aria-label="close"
                            size="small"
                            color="inherit"
                            onClick={() => {
                                setAlertAberto(false);
                            }}
                            >
                            <CloseIcon/>
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        {alertMensagem}
                        </Alert>
                    </Collapse>
                </Box>
                
                <div className="tituloPagina"><p id="titu" >Editar Contrato</p></div>   

                <div className="botaoExcluirEspec" >
                    {id !== "novo" && (
                        <div className="botaoExcluirEspec">
                            <button onClick={ExcluirDoc}>Excluir Contrato</button>
                        </div>
                    )}

                </div>


                <div className="semLinha menuPadrao">
                    <li>
                        <button id="0" onClick={(e) => menuSec(e.target.id)}>Cadastro Geral</button>                        
                        <button id="2" onClick={(e) => menuSec(e.target.id)}>Fornecedores</button>
                        <button id="1" onClick={(e) => menuSec(e.target.id)}>Anexos</button>
                    </li>  
                </div>

                <div className="formulario" >
                    <div id="cadastroContrato">
                        <div className="formRedondo formularioGeral ">                                                       
                            
                            <div className="alinhaDireita">
                                
                                <div className="novoRadio statusRadio"> 
                                    <div className="formularioGeralTitulo"><p>Status do Contrato</p></div> 
                                    <input type="radio" id="radioativo" name="statusContrato" value="true"checked></input>
                                    <label for="ativo">Ativo</label>

                                    <input type="radio" id="radioinativo" name="statusContrato" value="false"></input>
                                    <label for="inativo">Inativo</label>
                                </div>

                                <div className="espaco2LadosB_Docs"></div>
                                                                
                                <div className="formularioGeralTitulo"><p>Objeto</p></div>                            
                                <input type="text" name="objeto" id="objeto" placeholder="Insira o Objeto" autoComplete="off"  ></input>

                                <div className="formularioGeralTitulo"><p>Data Fim do Contrato</p></div>
                                <input type="date" name="data_fim" id="data_fim" autoComplete="off"  ></input>
                                                                
                                <div className="formularioGeralTitulo"><p>Tipo de contrato</p></div>   
                                <select name="tipo_contrato" id="tipo_contrato">
                                </select>
                                    
                                <div className="formularioGeralTitulo"><p>CPF / CNPJ do (a) Fiscal</p></div>                            
                                <input type="text" name="identidade_fiscal" id="identidade_fiscal" placeholder="Insira o CPF / CNPJ do (a) Fiscal" autoComplete="off"  ></input>

                            </div>    

                            <div className="alinhaEsquerda">

                                <div className="formularioGeralTitulo"><p>Número</p></div>                            
                                <input type="number" name="numero" id="numero" placeholder="Insira o número do contrato" autoComplete="off"  ></input>

                                <div className="formularioGeralTitulo"><p>Valor</p></div>                            
                                <input type="number" name="valor" id="valor" placeholder="Insira o valor do contrato" autoComplete="off"  ></input>

                                <div className="formularioGeralTitulo"><p>Data de Início</p></div>
                                <input type="date" name="data_inicio" id="data_inicio" autoComplete="off"  ></input>
                                                            
                                <div className="formularioGeralTitulo"><p>Licitação</p></div>   
                                <select name="licitacao" id="licitacao">
                                </select>

                                <div className="formularioGeralTitulo"><p>Nome do (a) Fiscal</p></div>                            
                                <input type="text" name="fiscal_responsavel" id="fiscal_responsavel" placeholder="Insira o nome do (a) Fiscal" autoComplete="off"  ></input>


                                <div className="formularioGeralTitulo"><p>Arquivo</p></div>
                                <input type="file" name="arquivo" id="arquivo" autoComplete="off"  ></input>
                                                                               
                            </div>

                            <div id="cadastroDocRelatorio">
                                <div className="removeBordu cadastroDocInterno">
                                    <p>Arquivo cadastrado: Para substituir, insira novo arquivo acima</p>
                                    <div id="anexoUnico" className="anexos">
                                        {loading()}
                                    </div>       
                                </div>
                            </div>   
                                                         
                            <button onClick={selectFuncao}>Salvar alterações</button>

                        </div>

                    </div>                    
                </div>
                
                <div className="cadastroDoc">
                    <div id="cadastroDocContrato">
                        <div className="cadastroDocInterno">
                            <p>Documentos do Contrato</p>
                            <div id="contratosAnexos" className="anexos">
                                {loading()}
                            </div>       
                            <span>Clique no botão ou solte os arquivos abaixo para anexar.</span>
                            <FileUploadMultiple/>
                        </div>
                    </div>                        
                </div>
                
                <div className="cadastroDoc">
                    <div id="cadastroFornecedores" className="cadastroFornecedores">
                    <div id="fornecedores" className="formRedondo formularioGeral ">   
                        </div>
                    </div>                        
                </div>

            </Sidebar>
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                ><p id="notifica" >vghfgh</p>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </>   
    );
}

export default ContratosEditar;