import { useEffect, useState } from "react";
import '../../../components/Geral/geral.css';
import './ObraEditar.css'
import carregando from '../../../components/Geral/loading.gif';
import { verificaLogin } from '../../../context/LoginContext';
import { TextEditor } from "../../../components/TextEditor/TextEditor";
import { base_url, midia_url, foto_default } from '../../../environment'
import axios from 'axios';
import Sidebar from '../../../components/Menu/Sidebar';
import FileUploadMultiple from './uploadObra.tsx'
import UploadEtapa from './uploadEtapa.tsx'
import { useParams } from "react-router-dom";

import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

var optionClicado = "background-color: var(--mainColor); color: var(--textColor2);"
var unavez = true, pinaVez = true, dessaVez = true;

function ObraEditar() {
    setInterval(function () { verificaLogin(); }, 1000);

    const [alertAberto, setAlertAberto] = React.useState(false);
    const [alertMensagem, setAlertMensagem] = React.useState('');
    const [tipoAlerta, setTipoAlerta] = React.useState('');
    const [idEtapa, setidEtapa] = React.useState('');
    const [textoEtapa, settextoEtapa] = React.useState('');
    const [numEtapa, setnumEtapa] = React.useState('');

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };
  
    function meuAlert(texto, tipo) {
        setTipoAlerta(tipo);
        setAlertMensagem(texto);
        window.location = `#root`;
        setAlertAberto(true);
    }

    function loading() { return <div id='loading'><img src={carregando} alt="Carregando" /></div>; }

    setInterval(function () { verificaLogin(); }, 1000);

    var [texto, setTexto] = useState('<p>Escreva seu texto aqui. Aumente o tamanho da área de texto no canto inferior direito.</p>');

    // Clicar no primeiro botao e colocar texto inicial
    useEffect(() => {
        document.getElementById(0).click();

        
    }, []);

    const { id } = useParams();
    var token = localStorage.getItem('LOCAL_STORAGE_KEY__ACCESS_TOKEN');

    // pegar dados da galeria
    useEffect(() => {
        axios.get(`${base_url}/galeria`, {
            "headers": {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {

                // eslint-disable-next-line
                if ((response.data.statusCode != undefined) && (response.data.statusCode != 200)) {
                    //window.location.pathname = `mensagem/${response.data.message}`;
                    meuAlert(`${response.data.message}`, "error");
                }

                // eslint-disable-next-line 
                if (response.data.length == 0) {
                    meuAlert("Não há galerias.", "error");
                }
                else {
                    //console.log(response.data);

                    var pessoa = document.getElementById("galeria");
                    for (let i = 0; i < response.data.length; i++) {
                        const option = document.createElement("option");
                        option.value = response.data[i].id;
                        option.innerHTML = response.data[i].titulo;
                        pessoa.appendChild(option);
                    }

                }
            })
            .catch(error => {
                console.log(error.message);
                if (error.response) {
                    // eslint-disable-next-line
                    if (error.response.data.statusCode == 401) {
                        window.location.pathname = `login`;
                    }
                    else {
                        meuAlert(`${error.message}`, "error");
                    }
                }
                else {
                    meuAlert(`${error.message}`, "error");
                }
            });

    }, [token]);

    // pegar dados se não for novo
    useEffect(() => {

        if (id !== "novo") {
            axios.get(`${base_url}/obra/${id}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                })
                .then(async response => {
                    document.getElementById("titulo").value = response.data.titulo;
                    document.getElementById("localidade").value = response.data.localidade;
                    document.getElementById("regiao").value = response.data.regiao;
                    document.getElementById("status").value = response.data.status;
                    document.getElementById("data_inicio").value = new Date(response.data.data_inicio).toISOString().split('T')[0];
                    document.getElementById("data_fim").value = new Date(response.data.data_fim).toISOString().split('T')[0];
                    document.getElementById("prazo_meses").value = response.data.prazo_meses;
                    document.getElementById("valor_total").value = response.data.valor_total;
                    document.getElementById("valor_executado").value = response.data.valor_executado;
                    document.getElementById("fiscal_responsavel").value = (response.data.fiscal_responsavel)
                    document.getElementById("profissional_responsavel").value = (response.data.profissional_responsavel)
                    document.getElementById("empresa_responsavel").value = (response.data.empresa_responsavel)

                    if (response.data.descricao) { setTexto(response.data.descricao) };
                    if (response.data.capa) { document.getElementById("capa").src = midia_url + response.data.capa; }
                    else { document.getElementById("capa").src = foto_default }

                    document.getElementById("progresso").value = (response.data.progresso)
                    //calcula a porcentagem do progresso da obra
                    //document.getElementById("progresso").value = (document.getElementById("valor_executado").value / document.getElementById("valor_total").value) * 100;
                    document.getElementById("valor_executado").addEventListener("change", (e) => {document.getElementById("progresso").value = (document.getElementById("valor_executado").value / document.getElementById("valor_total").value) * 100;});
                    document.getElementById("valor_total").addEventListener("change", (e) => {document.getElementById("progresso").value = (document.getElementById("valor_executado").value / document.getElementById("valor_total").value) * 100;});
        
                    //anexos
                    if(pinaVez){
                        document.getElementById("cadastroAnexObra").innerHTML = null;   
                        for(let i=0; i<response.data.Anexo.length; i++){
                    
                            //arrayInicial.push(String('<div><p>'+response.data.Anexo[i].titulo+'</p><a target="_blank" href='+midia_url+response.data.Anexo[i].anexo+'>Baixar</a><a target="_blank" href="#" >Excluir</a></div>')); 
                                
                            var divGeral = document.getElementById("cadastroAnexObra");
                            
                            const divContainter = document.createElement("div");
            
                            const p = document.createElement("p");
                            p.innerHTML = response.data.Anexo[i].titulo;
            
                            const a = document.createElement("a");
                            a.innerHTML = "Excluir";
                            a.id = response.data.Anexo[i].id;
                            a.addEventListener("click", (e) => ExcluirAnexo(e.target.id));
            
                            const a2 = document.createElement("a");
                            a2.target = "_blank";
                            a2.href = String(midia_url+response.data.Anexo[i].anexo);
                            a2.innerHTML = "Baixar";
                            
                            divContainter.appendChild(p); 
                            divContainter.appendChild(a);                 
                            divContainter.appendChild(a2); 
                                
                            divGeral.appendChild(divContainter);
                            
                        };
                        pinaVez = false;
                    }

                    //etapas
                    if(dessaVez)
                    {
                        if(response.data.Etapa[0] != undefined){              
                            
                            //document.getElementById("etapas").innerHTML = null;
                            for(let y=0; y<response.data.Etapa.length; y++){                               

                                var divGeral = document.getElementById("etapas");

                                const div = document.createElement("div");
                                div.className = "cadastroDocInterno";                
                                const p = document.createElement("p");
                                p.innerHTML = "Etapa "+(y+1);
                                const p2 = document.createElement("p");
                                p2.innerHTML = "Arquivos";
                                const div2 = document.createElement("div");
                                div2.id = "cadastroAnexoEtapa"+(y+1);
                                div2.className = "anexos";
                                const p3 = document.createElement("p");
                                p3.innerHTML = "Descrição";
                                const div3 = document.createElement("div");
                                div3.className = "anexos obraEtapa";
                                const text = document.createElement("textarea");
                                text.rows = "5";
                                text.cols = "1";
                                text.name = "resposta"+(y+1);
                                text.id = "reposta"+(y+1);
                                text.placeholder = "Escreva sua resposta aqui";
                                text.innerHTML = response.data.Etapa[y].descricao;
                                                

                                const divBT = document.createElement("div");

                                //const bt = document.createElement("button");
                                //bt.innerHTML = "Editar";
                                //bt.id = response.data.Etapa[y].id;
                                //bt.className = "cadastroDoc button Etapa";
                                //bt.addEventListener("click", (e) => selecionaEtapa(e.target.id , response.data.Etapa[y].descricao, ("Etapa "+(y+1))));
                                
                                const bt2 = document.createElement("button");
                                bt2.innerHTML = "Excluir";
                                bt2.id = response.data.Etapa[y].id;
                                bt2.className = "cadastroDoc button Etapa";
                                bt2.addEventListener("click", (e) => ExcluirEtapa(e.target.id));
                                
                                
                                div.appendChild(p);
                                div.appendChild(p2);
                                div.appendChild(div2);
                                div.appendChild(p3);
                                div3.appendChild(text);
                                div.appendChild(div3);

                                //divBT.appendChild(bt);                                
                                divBT.appendChild(bt2);
                                div.appendChild(divBT);
                                
                                await divGeral.appendChild(div);
                                                        

                                //anexos da etapa
                                //console.log('Parou aqui');
                                //console.log(response.data.Etapa[y].Imagem.length);
                                for(let i=0; i<response.data.Etapa[y].Imagem.length; i++){
                            
                                   
                                    //arrayInicial.push(String('<div><p>'+response.data.Anexo[i].titulo+'</p><a target="_blank" href='+midia_url+response.data.Anexo[i].anexo+'>Baixar</a><a target="_blank" href="#" >Excluir</a></div>')); 
                                        
                                    var divAnexo = document.getElementById("cadastroAnexoEtapa"+(y+1));
                                    
                                    const divSub = document.createElement("div");
                    
                                    const pipa = document.createElement("p");
                                    pipa.innerHTML = response.data.Etapa[y].Imagem[i].imagem;
                    
                                    const a = document.createElement("a");
                                    a.innerHTML = "Excluir";
                                    a.id = response.data.Etapa[y].Imagem[i].id;
                                    a.addEventListener("click", (e) => ExcluirImgEtapa(e.target.id));
                    
                                    const a2 = document.createElement("a");
                                    a2.target = "_blank";
                                    a2.href = String(midia_url+response.data.Etapa[y].Imagem[i].imagem);
                                    a2.innerHTML = "Baixar";
                                    
                                    divSub.appendChild(pipa); 
                                    divSub.appendChild(a);                 
                                    divSub.appendChild(a2); 
                                        
                                    divAnexo.appendChild(divSub);
                                    
                                };

                                //tentar adicionar componente
                                setnumEtapa("Nova Etapa");
                                
                            } 
                        }
                        else
                        {
                            document.getElementById("etapas").remove();
                        }
                        dessaVez = false;
                    }
                   

                    await galeriaObra(response.data.galeria_id);     

                })
                .catch(error => {
                    console.log(error.message);
                    if (error.response) {
                        if ((error.response.data.statusCode === 404) || (error.response.data.statusCode === 400)) {
                            if (unavez) {
                                meuAlert("Pessoa não existente.", "warning");
                                unavez = false;
                            }

                        }
                        else {
                            meuAlert(`${error.message}`, "error");
                        }
                        if (error.response.data.statusCode === 401) {
                            window.location.pathname = `login`;
                        }
                    }
                    else {
                        meuAlert(`${error.message}`, "error");
                    }
                });
        }
        else {
            document.getElementById("capa").src = foto_default;
        }
        document.getElementById("notifica").innerHTML = "Processo em andamento, aguarde...";

    }, [id, token]);

    function menuSec(menuId) {
        for(let i=0; i<=2; i++){
            document.getElementById(i).style = null;
        }
        document.getElementById(menuId).style = optionClicado;       
        //console.log("Selecionou o botão " + id);

        // eslint-disable-next-line
        if(menuId != 0)
        {
            document.getElementById("cadastroObra").setAttribute('hidden', '');
        }
        else
        {
            document.getElementById("cadastroObra").removeAttribute('hidden');
            document.getElementById("cadastroAnexoObra").setAttribute('hidden', '');    
            document.getElementById("cadastroEtapaObra").setAttribute('hidden', '');         
        }

        // eslint-disable-next-line
        if(menuId != 1){
            document.getElementById("cadastroAnexoObra").setAttribute('hidden', '');
        }
        else{
            if(id == "novo")
            {
                meuAlert("Cadastre e salve a obra antes de inserir anexos.", "info");
                //alert("Cadastre e salve o contrato antes de inserir anexos.")
                document.getElementById(0).click();   
            }
            else
            {
               document.getElementById("cadastroAnexoObra").removeAttribute('hidden');
                document.getElementById("cadastroObra").setAttribute('hidden', ''); 
                document.getElementById("cadastroEtapaObra").setAttribute('hidden', ''); 
            }            
        }


        // eslint-disable-next-line
        if(menuId != 2){
            document.getElementById("cadastroEtapaObra").setAttribute('hidden', '');
        }
        else{
            if(id == "novo")
            {
                meuAlert("Cadastre e salve a obra antes de inserir etapas.", "info");
                //alert("Cadastre e salve o contrato antes de inserir anexos.")
                document.getElementById(0).click();   
            }
            else
            {
               document.getElementById("cadastroEtapaObra").removeAttribute('hidden');
                document.getElementById("cadastroObra").setAttribute('hidden', ''); 
                document.getElementById("cadastroAnexoObra").setAttribute('hidden', ''); 
            }            
        }

    }  


    function verificaVazios() {

        if (document.getElementById("titulo").value === "") { meuAlert("Título da obra não preenchida.", "warning"); }
        else if (document.getElementById("localidade").value === "") { meuAlert("Localidade da obra não preenchida", "warning"); }
        else if (document.getElementById("data_inicio").value === "") { meuAlert("Data Início da obra não preenchida.", "warning"); }
        else if (document.getElementById("data_fim").value === "") { meuAlert("Data Fim da obra não preenchida.", "warning"); }
        else if (document.getElementById("prazo_meses").value === "") { meuAlert("Prazo da obra não preenchida.", "warning"); }
        else if (document.getElementById("valor_total").value === "") { meuAlert("Valor total da obra não preenchida.", "warning"); }
        else if (document.getElementById("valor_executado").value === "") { meuAlert("Valor Executado da obra não preenchida.", "warning"); }
        else if (document.getElementById("fiscal_responsavel").value === "") { meuAlert("Fiscal Responsável da obra não preenchida.", "warning"); }
        else if (document.getElementById("profissional_responsavel").value === "") { meuAlert("Profissional responsável da obra não preenchida.", "warning"); }
        else if (document.getElementById("empresa_responsavel").value === "") { meuAlert("Empresa responsável da obra não preenchida.", "warning"); }
        else if (id === "novo") {
            if (document.getElementById("InputCapa").value === "") { meuAlert("Capa do obra não preenchida.", "warning"); }
            else { return true; }
        }
        else {
            return true;
        }
    }

    function selectFuncao() {
        if (id === "novo") {
            if (verificaVazios()) {
                cadastraObra();
            };
        }
        else {
            if (verificaVazios()) {
                atualizaObra();
            };
        }
    }

    // Cadastra pobra
    async function cadastraObra() {

        handleOpen()

        var input = document.querySelector('input[type="file"]')

        var data = new FormData()
        data.append('titulo', String(document.getElementById("titulo").value))
        data.append('capa', input.files[0])
        data.append('descricao', texto)
        data.append('localidade', String(document.getElementById("localidade").value))
        data.append('regiao', document.getElementById("regiao").value)
        data.append('status', document.getElementById("status").value)
        data.append('data_inicio', new Date(document.getElementById("data_inicio").value).toISOString())
        data.append('data_fim', new Date(document.getElementById("data_fim").value).toISOString())
        data.append('progresso', document.getElementById("progresso").value)
        data.append('prazo_meses', document.getElementById("prazo_meses").value)
        data.append('valor_total', document.getElementById("valor_total").value)
        data.append('valor_executado', document.getElementById("valor_executado").value)
        data.append('fiscal_responsavel', String(document.getElementById("fiscal_responsavel").value))
        data.append('profissional_responsavel', String(document.getElementById("profissional_responsavel").value))
        data.append('empresa_responsavel', String(document.getElementById("empresa_responsavel").value))
        data.append('galeria_id', document.getElementById("galeria").value)
        //data.append('contratante_id', localStorage.getItem('contratante_id'))
        // NÂO PRECISA
        //data.append('contratante_id', localStorage.getItem('contratante_id'))      

        //console.log(data);

        const url = `${base_url}/obra`;
        await axios.post(url, data, {
            "headers": {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                //console.log(response);
                //console.log(response.data.id);

                document.getElementById("notifica").innerHTML = "Cadastrado com sucesso!";
                const mostrar = setInterval(function () { handleClose(); clearInterval(mostrar); }, 2000);

                meuAlert("Cadastrado com sucesso!", "success");
                const redireciona = setInterval(function () {
                    clearInterval(redireciona);
                    window.location.pathname = `obra/editar/${response.data.id}`;
                }, 3000);

            })
            .catch(error => {
                handleClose()
                meuAlert(`${error.response.data.message}`, "warning");
                console.log(error.message);
                console.log(error.response.data.message);
                //console.log("Erro "+error.response.data.statusCode);
            })
    }
    // Atualiza obra
    async function atualizaObra() {

        handleOpen()

        var input = document.querySelector('input[type="file"]')

        var data = new FormData()
        data.append('titulo', String(document.getElementById("titulo").value))
        data.append('capa', input.files[0])
        data.append('descricao', texto)
        data.append('localidade', String(document.getElementById("localidade").value))
        data.append('regiao', document.getElementById("regiao").value)
        data.append('status', document.getElementById("status").value)
        data.append('data_inicio', new Date(document.getElementById("data_inicio").value).toISOString())
        data.append('data_fim', new Date(document.getElementById("data_fim").value).toISOString())
        data.append('progresso', document.getElementById("progresso").value)
        data.append('prazo_meses', document.getElementById("prazo_meses").value)
        data.append('valor_total', document.getElementById("valor_total").value)
        data.append('valor_executado', document.getElementById("valor_executado").value)
        data.append('fiscal_responsavel', String(document.getElementById("fiscal_responsavel").value))
        data.append('profissional_responsavel', String(document.getElementById("profissional_responsavel").value))
        data.append('empresa_responsavel', String(document.getElementById("empresa_responsavel").value))
        data.append('galeria_id', document.getElementById("galeria").value)
        //NÂO PRECISA
        //data.append('cargo', String(document.getElementById("cargo").value))
        //data.append('contratante_id', localStorage.getItem('contratante_id'))      

        //console.log(data);

        const url = `${base_url}/obra/${id}`;
        await axios.patch(url, data, {
            "headers": {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                //console.log(response);
                //console.log(response.data.id);

                document.getElementById("notifica").innerHTML = "Alterado com sucesso!";
                const mostrar = setInterval(function () { handleClose(); clearInterval(mostrar); }, 2000);

                meuAlert("Alterado com sucesso!", "success");
                const redireciona = setInterval(function () {
                    //setRefreshKey(oldKey => oldKey +1);
                    window.location.reload();
                    clearInterval(redireciona);
                }, 2000);

            })
            .catch(error => {
                handleClose()
                meuAlert(`${error.response.data.message}`, "warning");
                console.log(error.message);
                console.log(error.response.data.message);
                //console.log("Erro "+error.response.data.statusCode);
            })

    }
    // Excluir obra
    async function ExcluirObra() {

        if (window.confirm("Tem certeza que deseja excluir esta obra?")) {

            handleOpen()

            const url = `${base_url}/obra/${id}`;
            await axios.delete(url, {
                "headers": {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            })
                .then(response => {
                    //console.log(response);
                    //console.log(response.data.id);    

                    document.getElementById("notifica").innerHTML = "Excluído com sucesso!";
                    const mostrar = setInterval(function () { handleClose(); clearInterval(mostrar); }, 2000);

                    meuAlert("Excluído com sucesso!", "success");
                    const redireciona = setInterval(function () {
                        clearInterval(redireciona);
                        window.location.pathname = `obra/editar/novo`;
                    }, 2000);

                })
                .catch(error => {
                    handleClose()
                    meuAlert(`${error.message}`, "error");
                    console.log(error.message);
                    //console.log("Erro "+error.response.data.statusCode);
                })
        }
    }

    // Excluir etapa
    async function ExcluirEtapa(idEtapa) {

        if (window.confirm("Tem certeza que deseja excluir esta etapa?")) {

            handleOpen()

            const url = `${base_url}/obra/etapa/${idEtapa}`;
            await axios.delete(url, {
                "headers": {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            })
                .then(response => {
                    //console.log(response);
                    //console.log(response.data.id);    

                    document.getElementById("notifica").innerHTML = "Excluído com sucesso!";
                    const mostrar = setInterval(function () { handleClose(); clearInterval(mostrar); }, 2000);

                    meuAlert("Excluído com sucesso!", "success");
                    const redireciona = setInterval(function () {
                        clearInterval(redireciona);
                        window.location.reload();
                    }, 2000);

                })
                .catch(error => {
                    handleClose()
                    meuAlert(`${error.message}`, "error");
                    console.log(error.message);
                    //console.log("Erro "+error.response.data.statusCode);
                })
        }
    }
    

    // Excluir anexo
    async function ExcluirAnexo(idAnexo) {  

        if (window.confirm("Tem certeza que deseja excluir esse anexo?")) {     
            
            document.getElementById("notifica").innerHTML = "Processo em andamento, aguarde...";
            
            {handleOpen()}    

            const url = `${base_url}/obra/anexo/${idAnexo}`;
            await axios.delete(url, {
            "headers": {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            }})
            .then(response => { 
                //console.log(response);
                //console.log(response.data.id);
                //window.location.pathname = `contrato/editar/${id}`;

                document.getElementById("notifica").innerHTML = "Anexo excluído com sucesso!";
                const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

                meuAlert("Anexo excluído com sucesso!", "success");
                const redireciona = setInterval(function () {
                    //setRefreshKey(oldKey => oldKey +1); 
                    clearInterval(redireciona);
                    window.location.reload();
                }, 2000);     

            })
            .catch(error => { 
                {handleClose()}
                meuAlert(`${error.message}`, "error");
                console.log(error.message);
                //console.log("Erro "+error.response.data.statusCode);
            })  
        }           
    }

    // Excluir imagem etapa
    async function ExcluirImgEtapa(idAnexo) {  

        if (window.confirm("Tem certeza que deseja excluir essa imagem da etapa?")) {     
            
            document.getElementById("notifica").innerHTML = "Processo em andamento, aguarde...";
            
            {handleOpen()}    

            const url = `${base_url}/obra/etapa/imagem/${idAnexo}`;
            await axios.delete(url, {
            "headers": {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            }})
            .then(response => { 
                //console.log(response);
                //console.log(response.data.id);
                //window.location.pathname = `contrato/editar/${id}`;

                document.getElementById("notifica").innerHTML = "Imagem da etapa excluída com sucesso!";
                const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

                meuAlert("Imagem da etapa excluída com sucesso!", "success");
                const redireciona = setInterval(function () {
                    //setRefreshKey(oldKey => oldKey +1); 
                    clearInterval(redireciona);
                    window.location.reload();
                }, 2000);     

            })
            .catch(error => { 
                {handleClose()}
                meuAlert(`${error.message}`, "error");
                console.log(error.message);
                //console.log("Erro "+error.response.data.statusCode);
            })  
        }           
    }

    function galeriaObra(valor) {
        for (let i = 0; i < document.getElementById("galeria").children.length; i++) {
            if (document.getElementById("galeria").children[i].value === valor) {
                document.getElementById("galeria").children[i].selected = true;
            }
        }
    }

    function selecionaEtapa(valor, valor2, etapa) {
        setidEtapa(valor);
        settextoEtapa(valor2);
        setnumEtapa(etapa);
        window.location = `#cadastroDocInterno`;
    }
    

    return (
        <>
            <Sidebar page={{ name: 'Obras', route: '/obra' }}>
                <Box sx={{ width: '100%' }}>
                    <Collapse in={alertAberto}>
                        <Alert
                            severity={tipoAlerta}
                            variant="filled"
                            action={
                                <IconButton
                                    aria-label="close"
                                    size="small"
                                    color="inherit"
                                    onClick={() => {
                                        setAlertAberto(false);
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                        >
                            {alertMensagem}
                        </Alert>
                    </Collapse>
                </Box>

                <div className="tituloPagina"><p id="titu" >Editar Obra</p></div>

                <div className="botaoExcluirEspec" >
                    {id !== "novo" && (
                        <div className="botaoExcluirEspec">
                            <button onClick={ExcluirObra}>Excluir Obra</button>
                        </div>
                    )}

                </div>

                <div className="semLinha menuPadrao">
                    <li>
                        <button id="0" onClick={(e) => menuSec(e.target.id)}>Cadastro Geral</button>                        
                        <button id="1" onClick={(e) => menuSec(e.target.id)}>Anexos</button>
                        <button id="2" onClick={(e) => menuSec(e.target.id)}>Etapas</button>
                    </li>  
                </div>

                <div className="formulario" >
                    <div id="cadastroObra">
                        <div className="formularioGeral">
                            <div className="alinhaDireita">

                                <div className="formularioGeralTitulo"><p>Título da obra</p></div>
                                <input type="text" name="titulo" id="titulo" placeholder="Insira o titulo da obra" autoComplete="off"  ></input>

                                <div className="formularioGeralTitulo"><p>Status da obra</p></div>
                                <select name="status" id="status">
                                    <option value="RASCUNHO">Rascunho</option>
                                    <option value="LANCADA">Lançada</option>
                                    <option value="EM_EXECUCAO">Em execução</option>
                                    <option value="EXECUTADA">Executada</option>
                                </select>

                                <div className="formularioGeralTitulo"><p>Região da obra</p></div>
                                <select name="regiao" id="regiao">
                                    <option value="URBANA">Urbana</option>
                                    <option value="RURAL">Rural</option>
                                </select>

                                <div className="formularioGeralTitulo"><p>Prazo do obra (em meses)</p></div>
                                <input type="text" name="prazo_meses" id="prazo_meses" placeholder="Insira o prazo da obra" autoComplete="off"  ></input>

                                <div className="espaco2LadosB_Obra"></div>

                                <div className="formularioGeralTitulo"><p>Valor total do obra</p></div>
                                <input type="text" name="valor_total" id="valor_total" placeholder="Insira o valor total da obra" autoComplete="off"  ></input>
                                <div className="formularioGeralTitulo"><p>Valor executado do obra</p></div>
                                <input type="text" name="valor_executado" id="valor_executado" placeholder="Insira o valor executado da obra" autoComplete="off"  ></input>
                                <div className="formularioGeralTitulo"><p>Fiscal do obra</p></div>
                                <input type="text" name="fiscal_responsavel" id="fiscal_responsavel" placeholder="Insira o fiscal da obra" autoComplete="off"  ></input>
                                <div className="formularioGeralTitulo"><p>Profissional responsável do obra</p></div>
                                <input type="text" name="profissional_responsavel" id="profissional_responsavel" placeholder="Insira o profissional responsável da obra" autoComplete="off"  ></input>

                                <div className="formularioGeralTitulo"><p>Porcentagem do progresso (%) </p></div>
                                <input type="number" name="progresso" id="progresso" placeholder="Insira o valor de progresso da obra" autoComplete="off" value="0" ></input>
                                
                                
                            </div>

                            <div className="alinhaEsquerda">
                                <img id="capa" name="capa" src={carregando} alt=""></img>

                                <div className="formularioGeralTitulo"><p>Capa do obra</p></div>
                                <input type="file" name="InputCapa" id="InputCapa" autoComplete="off"  ></input>

                                <div className="formularioGeralTitulo"><p>Galeria da obra</p></div>
                                <select name="galeria" id="galeria">
                                </select>

                                <div className="formularioGeralTitulo"><p>Data de início</p></div>
                                <input type="date" name="data_inicio" id="data_inicio" autoComplete="off"  ></input>
                                <div className="formularioGeralTitulo"><p>Data do fim</p></div>
                                <input type="date" name="data_fim" id="data_fim" autoComplete="off"  readyonly></input>

                                <div className="formularioGeralTitulo"><p>Empresa responsável do obra</p></div>
                                <input type="text" name="empresa_responsavel" id="empresa_responsavel" placeholder="Insira a empresa responsável da obra" autoComplete="off"  ></input>

                                <div className="formularioGeralTitulo"><p>Localidade da obra</p></div>
                                <input type="text" name="localidade" id="localidade" placeholder="Insira a localidade da obra" autoComplete="off"  ></input>

                            </div>

                            <div className="bloqueiaDiv formularioGeralTitulo"></div>

                        </div>

                        <div className="formularioConteudo">
                            <span>Descrição do obra</span>
                            <TextEditor value={texto} onChange={setTexto} />
                            <button onClick={selectFuncao}>Salvar alterações</button>
                        </div>
                    </div>
                </div>

                <div className="cadastroDoc">
                    <div id="cadastroAnexoObra">
                        <div className="cadastroDocInterno">
                            <p>Anexos da Obra</p>
                            <div id="cadastroAnexObra" className="anexos">
                                {loading()}
                            </div>       
                            <span>Clique no botão ou solte os arquivos abaixo para anexar.</span>
                            <FileUploadMultiple/>
                        </div>
                    </div>                        
                </div>

                
                <div className="cadastroDoc">
                    <div id="cadastroEtapaObra">
                        
                        <div id="etapas" className="etapas">
                        </div>
                        

                        <div className="cadastroDocInterno" id="cadastroDocInterno">
                            <p>{numEtapa}</p> 
                            <span>Descrição</span>
                            <UploadEtapa idEtapa={idEtapa} textoEtapa={textoEtapa} numEtapa ={numEtapa}/>
                        </div>
                    </div>                        
                </div>

                
            </Sidebar>
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                ><p id="notifica" >vghfgh</p>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </>
    );
}

export default ObraEditar;