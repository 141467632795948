import React from "react";
import './Mensagem.css'
import {Link} from "react-router-dom";
import {BiErrorCircle} from "react-icons/bi"
import { useParams } from "react-router-dom";
import Sidebar from '../../components/Menu/Sidebar';

function Mensagem(){

    const {mensagem} = useParams();
  
    return(
        <>   
            <Sidebar />
            <div className="pagina404"> 
                <button className="erru" >< BiErrorCircle /></button>
                <h1>{mensagem+"."}</h1>
                <Link to={'/'}> <p>Ir para página inicial </p></Link>
            </div>    
        </>   
    );
}

export default Mensagem;