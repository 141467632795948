import React, { useEffect, useState } from "react";
import '../../../components/Geral/geral.css';
import '../../../components/Geral/tabela.css';
import './CategoriaTurismo.css'
import carregando from '../../../components/Geral/loading.gif';
import { base_url } from '../../../environment'
import Sidebar from '../../../components/Menu/Sidebar';
import axios from 'axios';
import { verificaLogin } from '../../../context/LoginContext';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { BiEdit } from "react-icons/bi"
import { MdDeleteForever } from "react-icons/md"

import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

function CategoriaTurismo() {
    setInterval(function () { verificaLogin(); }, 1000);

    const [tables, setTables] = useState([]);
    const [refreshKey, setRefreshKey] = useState(0);

    const [alertAberto, setAlertAberto] = React.useState(false);
    const [alertMensagem, setAlertMensagem] = React.useState('');
    const [tipoAlerta, setTipoAlerta] = React.useState('');

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    function meuAlert(texto, tipo) {
        setTipoAlerta(tipo);
        setAlertMensagem(texto);
        window.location = `#root`;
        setAlertAberto(true);
    }

    var token = localStorage.getItem('LOCAL_STORAGE_KEY__ACCESS_TOKEN');

    function loading() {
        return <div id='loading'><img src={carregando} alt="Carregando" /></div>;
    }

    // pegar dados do categoria-turismo
    useEffect(() => {

        axios.get(`${base_url}/categoria-ponto-turistico`, {
            "headers": {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                if (response.data.length === 0) {
                    document.getElementById("categoria-turismo").innerHTML = '<button><p>Não há categorias de turismo.</p></button>';

                } else {
                    console.log(response.data);
                    setTables(response.data);
                    document.getElementById("categoria-turismo").innerHTML = null;
                }
            })
            .catch(error => {
                console.log(error.message);
                if (error.response) {
                    if (error.response.data.statusCode === 401) {
                        window.location.pathname = `login`;
                    } else {
                        meuAlert(`${error.message}`, "error");
                    }
                } else {
                    meuAlert(`${error.message}`, "error");
                }
            });

        document.getElementById("mensagem").innerHTML = "Processo em andamento, aguarde...";
    }, [refreshKey]);

    async function ExcluirCatPtTur(id) {
        const confirmacao = window.confirm("Tem certeza que deseja excluir este tipo de órgão?");
        if (confirmacao) {
            handleOpen();
    
            try {
                await axios.delete(`${base_url}/categoria-ponto-turistico/${id}`, {
                    "headers": {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    }
                });
    
                const updatedTables = tables.filter(item => item.id !== id);
                setTables(updatedTables); // Atualiza o estado imediatamente após a exclusão
    
                document.getElementById("mensagem").innerHTML = "Operação finalizada!";
                const mostrar = setInterval(function () { handleClose(); clearInterval(mostrar); }, 2000);
    
                meuAlert("Excluído com sucesso!", "success");
    
                if (updatedTables.length === 0) {
                    document.getElementById("categoria-turismo").innerHTML = '<button><p>Não há categorias de turismo.</p></button>';
                }
            } catch (error) {
                handleClose();
                meuAlert(`${error.message}`, "error");
                console.log(error.message);
            }
        }
    }
    

    function marcaTodos() {
        var itens = 0;

        if (document.getElementById("checkGeral").checked) {
            while (document.getElementById(itens)) {
                document.getElementById(itens).checked = true;
                itens++;
            }
        } else {
            while (document.getElementById(itens)) {
                document.getElementById(itens).checked = false;
                itens++;
            }
        }
    }

    async function opcoesMarcados(acao) {
        handleOpen();
    
        const itensMarcados = [];
        tables.forEach((item, index) => {
            if (document.getElementById(index.toString()).checked) {
                itensMarcados.push(item.id);
            }
        });
    
        if (itensMarcados.length === 0) {
            meuAlert("Nenhum item selecionado para exclusão", "error");
            handleClose();
            return;
        }
    
        try {
            await Promise.all(itensMarcados.map(async (itemId) => {
                await axios.delete(`${base_url}/categoria-ponto-turistico/${itemId}`, {
                    "headers": {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    }
                });
            }));
    
            const updatedTables = tables.filter(item => !itensMarcados.includes(item.id));
            setTables(updatedTables);
    
            document.getElementById("mensagem").innerHTML = "Operação finalizada!";
            const mostrar = setInterval(function () { handleClose(); clearInterval(mostrar); }, 2000);
    
            meuAlert("Excluído com sucesso!", "success");
    
            if (updatedTables.length === 0) {
                document.getElementById("categoria-turismo").innerHTML = '<button><p>Não há categorias de turismo.</p></button>';
            }
        } catch (error) {
            handleClose();
            meuAlert(`${error.message}`, "error");
            console.log(error.message);
        }
    }

    return (
        <>
            <Sidebar>
                <Box sx={{ width: '100%' }}>
                    <Collapse in={alertAberto}>
                        <Alert
                            severity={tipoAlerta}
                            variant="filled"
                            action={
                                <IconButton
                                    aria-label="close"
                                    size="small"
                                    color="inherit"
                                    onClick={() => {
                                        setAlertAberto(false);
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                        >
                            {alertMensagem}
                        </Alert>
                    </Collapse>
                </Box>

                <div className="tituloPagina">
                    <p id="titu" >Categoria de Ponto Turístico</p>
                </div>
                <div className="optionNovo" >
                    <button onClick={(e) => window.location.pathname = `categoria-turismo/editar/novo`}>Nova Categoria de Ponto Turístico</button>

                </div>

                <div className="optionsBotoes" >
                    <button onClick={(e) => opcoesMarcados("excluir")}>Excluir</button>
                </div>

                <div className="tabelaPadrao">
                    <TableContainer className="modal-content">
                        <Table sx={{ minWidth: 650 }} arial-label="caption table" className="table">
                            <TableHead>
                                <TableRow>
                                    <TableCell><input type="checkbox" id="checkGeral" onClick={(e) => marcaTodos()}></input></TableCell>
                                    <TableCell>Nome da Categoria</TableCell>
                                    <TableCell>Descrição</TableCell>
                                    <TableCell>Editar</TableCell>
                                    <TableCell>Excluir</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tables.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell><input type="checkbox" id={index}></input></TableCell>
                                        <TableCell>{item.nome || '--'}</TableCell>
                                        <TableCell>{item.descricao || '--'}</TableCell>
                                        <TableCell><a target="_self" href={"categoria-turismo/editar/" + item.id}><BiEdit /></a></TableCell>
                                        <TableCell><a href="#" onClick={(e) => ExcluirCatPtTur(item.id)}><MdDeleteForever /></a></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>

                <div className="loading" >
                    <div id="categoria-turismo">{loading()}</div>
                </div>
            </Sidebar>
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                ><p id="mensagem" ></p>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </>
    );
}

export default CategoriaTurismo;
