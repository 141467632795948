import { useEffect, useState } from "react";
import '../../../components/Geral/geral.css';
import './MunicipioEditar.css'
import carregando from '../../../components/Geral/loading.gif';
import { verificaLogin } from '../../../context/LoginContext';
import { TextEditor } from "../../../components/TextEditor/TextEditor.js";
import {base_url, midia_url} from '../../../environment'
import axios from 'axios';
import Sidebar from '../../../components/Menu/Sidebar';
import FileUploadMultiple from './uploadMunicipio.tsx'

import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

var optionClicado = "background-color: var(--mainColor); color: var(--textColor2);"
var unavez = true, umavez = true;

function MunicipioEditar(){
    setInterval(function () {verificaLogin();}, 1000);

    const [refreshKey, setRefreshKey] = useState(0);
    
    function atualiza() {
        setRefreshKey(oldKey => oldKey +1); 
    }

    const [alertAberto, setAlertAberto] = React.useState(false);
    const [alertMensagem, setAlertMensagem] = React.useState('');
    const [tipoAlerta, setTipoAlerta] = React.useState('');

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    function meuAlert(texto, tipo) {
        setTipoAlerta(tipo);
        setAlertMensagem(texto);
        window.location = `#root`;
        setAlertAberto(true);
    }

    function loading() {return <div id='loading'><img src={carregando} alt="Carregando"/></div>; }    
    setInterval(function () {verificaLogin();}, 1000);
    
    var [texto1, settexto1] = useState('<p>Escreva seu texto aqui. Aumente o tamanho da área de texto no canto inferior direito.</p>');    
    var [texto2, settexto2] = useState('<p>Escreva seu texto aqui. Aumente o tamanho da área de texto no canto inferior direito.</p>');    
    var [texto3, settexto3] = useState('<p>Escreva seu texto aqui. Aumente o tamanho da área de texto no canto inferior direito.</p>');    

    // Clicar no primeiro botao e colocar texto inicial
    useEffect(() => {  
        document.getElementById(0).click();
    },[]);
    
    var token = localStorage.getItem('LOCAL_STORAGE_KEY__ACCESS_TOKEN');

    // pegar dados das galerias
    useEffect(() => {  

        axios.get(`${base_url}/galeria`, {
            "headers": {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 

            // eslint-disable-next-line
            if((response.data.statusCode != undefined) && (response.data.statusCode != 200)){
                //window.location.pathname = `mensagem/${response.data.message}`;
                meuAlert(`${response.data.message}`, "error");
            }

            // eslint-disable-next-line 
            if(response.data.length == 0)
            {
                meuAlert("Não há galerias.", "error");  
            }
            else
            {
                //console.log(response.data);

                var galeria_id = document.getElementById("galeria_id");
                
                for(let i=0; i<response.data.length; i++){
                    const option = document.createElement("option");
                    option.value = response.data[i].id;
                    option.innerHTML = response.data[i].titulo;                    
                    galeria_id.appendChild(option);
                }

                //console.log(document.getElementById("galeria_id").value);
            }            
        })    
        .catch(error => { 
            console.log(error.message);
            if(error.response)
            {
                // eslint-disable-next-line
                if(error.response.data.statusCode == 401)
                {
                    window.location.pathname = `login`;
                }
                else{
                    meuAlert(`${error.message}`, "error");
                }  
            }
            else{
                meuAlert(`${error.message}`, "error");
            }            
        });

    },[]);        
  
    // pegar dados do municipio
    useEffect(() => {               
        
            axios.get(`${base_url}/municipio`, 
            { headers: {
                'Authorization': `Bearer ${token}`,
            }})
            .then(async response => {             
                //console.log("Resposta ok");
                //console.log(response);
                //console.log(response.data);
                await galeriaSelecionada(response.data[0].galeria_id);                
                
                document.getElementById("nome").value = response.data[0].nome;
                document.getElementById("populacao").value = response.data[0].populacao;
                document.getElementById("gentilico").value = response.data[0].gentilico;
                document.getElementById("area").value = response.data[0].area;
                document.getElementById("data_aniversario").value = response.data[0].data_aniversario
                    ? new Date(response.data[0].data_aniversario).toISOString().split('T')[0] 
                    : null;
                document.getElementById("link_maps").value = response.data[0].link_maps;
                document.getElementById("estado_uf").value = response.data[0].estado_uf;
                
                settexto1(response.data[0].sobre);
                settexto2(response.data[0].historia);
                settexto3(response.data[0].hino);
                
                if(umavez){
                    document.getElementById("galeriaSimbolos").innerHTML = null;   
                    for(var i=0; i<response.data[0].Simbolo?.length || 0; i++){
                
                        //arrayInicial.push(String('<div><p>'+response.data[0].Imagem[i].titulo+'</p><a target="_blank" href='+midia_url+response.data[0].Imagem[i].anexo+'>Baixar</a><a target="_blank" href="#" >Excluir</a></div>')); 
                            
                        var divGeral = document.getElementById("galeriaSimbolos");
                        
                        const divContainter = document.createElement("div");
        
                        const p = document.createElement("p");
                        p.innerHTML = response.data[0].Simbolo[i].id.substring(0,10);

                        const img = document.createElement("img");
                        img.src = String(midia_url+response.data[0].Simbolo[i].imagem);                        

                        const a = document.createElement("a");
                        a.innerHTML = "Excluir";
                        a.id = response.data[0].Simbolo[i].id;
                        a.addEventListener("click", (e) => ExcluirSimbolo(e.target.id));

                        //console.log(response.data[0].Simbolo[i].id);
        
                        const a2 = document.createElement("a");
                        a2.target = "_blank";
                        a2.href = String(midia_url+response.data[0].Simbolo[i].imagem);
                        a2.innerHTML = "Baixar";
                        
                        divContainter.appendChild(img); 
                        divContainter.appendChild(p); 
                        divContainter.appendChild(a);                 
                        divContainter.appendChild(a2); 
                            
                        divGeral.appendChild(divContainter);
                        
                    };
                    umavez = false;
                } 

            })
            .catch(error => { 
                console.log(error.message);
                if(error.response)
                {
                    if((error.response.data[0].statusCode == 404) || (error.response.data[0].statusCode == 400))
                    {
                        if(unavez)
                        {
                            meuAlert("Informação não existente.", "warning");
                            //window.location.pathname = `home`;
                            unavez = false;
                        }
                        
                    }
                    else{
                        meuAlert(`${error.message}`, "error");
                    }
                    if(error.response.data[0].statusCode == 401)
                    {
                        window.location.pathname = `login`;
                    }
                }   
                else{
                    meuAlert(`${error.message}`, "error");
                }            
            });
        
            document.getElementById("notifica").innerHTML = "Processo em andamento, aguarde...";
        
               
    },[refreshKey]); 

    function verificaVazios() {
        if(document.getElementById("nome").value == ""){meuAlert("Nome não preenchido.", "warning");}
        else if(document.getElementById("populacao").value == ""){meuAlert("População não preenchida.", "warning");}
        else if(document.getElementById("gentilico").value == ""){meuAlert("Gentílico não preenchido.", "warning");}
        else if(document.getElementById("area").value == ""){meuAlert("Área não preenchida.", "warning");}
        else if(document.getElementById("data_aniversario").value == ""){meuAlert("Data de aniversário não preenchida.", "warning");}
        else if(document.getElementById("link_maps").value == ""){meuAlert("Link mapa não preenchido.", "warning");}
        else if(document.getElementById("estado_uf").value == ""){meuAlert("Estado não preenchido.", "warning");}
        else
        {
            return true;
        }   
    }
    
    function selectFuncao(){
        
        if(verificaVazios())
        {
            atualizaMunicipio();
        };   
    }

    // Atualiza contato (sem docs)
    async function atualizaMunicipio() {
          
        {handleOpen()} 

        //filtrando o link do iframe e enviando somente a url
        var link_mapsEditado = document.getElementById("link_maps").value.split(" ")[1].substr(4).replace('"', '').replace('"', '');
        //console.log(link_mapsEditado);

        const params = JSON.stringify({

            "nome": String(document.getElementById("nome").value),
            "sobre": texto1,
            "historia": texto2,
            "hino": texto3,
            "populacao": document.getElementById("populacao").value,
            "gentilico": String(document.getElementById("gentilico").value),
            "area": document.getElementById("area").value,
            "data_aniversario": new Date(document.getElementById("data_aniversario").value).toISOString(),
            "link_maps": String(link_mapsEditado),
            "estado_uf": String(document.getElementById("estado_uf").value),
            "galeria_id": String(document.getElementById("galeria_id").value)
        });

        //console.log(params);
        
        const url = `${base_url}/municipio`;
        await axios.patch(url, params, {
        "headers": {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 
            //console.log(response);
            //console.log(response.data.id);

            document.getElementById("notifica").innerHTML = "Alterado com sucesso!";
            const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

            meuAlert("Alterado com sucesso!", "success");
            const redireciona = setInterval(function () {atualiza(); clearInterval(redireciona);}, 2000);     

        })
        .catch(error => { 
            {handleClose()}
            meuAlert(`${error.response.data.message}`, "warning");
            console.log(error.message);
            console.log(error.response.data.message);
            //console.log("Erro "+error.response.data.statusCode);
        })     
        
    }
    
    // Excluir anexo
    async function ExcluirSimbolo(idSimbolo) {  

        if (window.confirm("Tem certeza que deseja excluir esta imagem?")) {     
            
            document.getElementById("notifica").innerHTML = "Processo em andamento, aguarde...";
         
            {handleOpen()}    

            const url = `${base_url}/municipio/simbolo/${idSimbolo}`;
            await axios.delete(url, {
            "headers": {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            }})
            .then(response => { 
                //console.log(response);
                //console.log(response.data.id);
                //window.location.pathname = `galeria/editar/${id}`;

                document.getElementById("notifica").innerHTML = "Imagem excluída com sucesso!";
                const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

                meuAlert("Imagem excluída com sucesso!", "success");
                const redireciona = setInterval(function () {
                    //setRefreshKey(oldKey => oldKey +1); 
                    clearInterval(redireciona);
                    window.location.reload();
                }, 2000);     

            })
            .catch(error => { 
                {handleClose()}
                meuAlert(`${error.message}`, "error");
                console.log(error.message);
                //console.log("Erro "+error.response.data.statusCode);
            })  
        }           
    } 

    function menuSec(menuId) {
        for(let i=0; i<=1; i++){
            document.getElementById(i).style = null;
        }
        document.getElementById(menuId).style = optionClicado;       
        //console.log("Selecionou o botão " + id);

        // eslint-disable-next-line
        if(menuId != 0)
        {
            document.getElementById("cadastroMunicipio").setAttribute('hidden', '');
        }
        else
        {
            document.getElementById("cadastroMunicipio").removeAttribute('hidden');
            document.getElementById("cadastroImgMunicipio").setAttribute('hidden', '');            
        }

        // eslint-disable-next-line
        if(menuId != 1){
            document.getElementById("cadastroImgMunicipio").setAttribute('hidden', '');
        }
        else{
            
            document.getElementById("cadastroImgMunicipio").removeAttribute('hidden');
            document.getElementById("cadastroMunicipio").setAttribute('hidden', '');          
        }

    }  

    function galeriaSelecionada(valor){

        for(let i=0; i<document.getElementById("galeria_id").children.length; i++){
            if(document.getElementById("galeria_id").children[i].value == valor){
                document.getElementById("galeria_id").children[i].selected = true;
            }        
        }
    }

    return(
        <>   
            <Sidebar>
                <Box sx={{ width: '100%'}}>
                    <Collapse in={alertAberto}>
                        <Alert
                        severity={tipoAlerta}
                        variant="filled"
                        action={
                            <IconButton
                            aria-label="close"
                            size="small"
                            color="inherit"
                            onClick={() => {
                                setAlertAberto(false);
                            }}
                            >
                            <CloseIcon/>
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        {alertMensagem}
                        </Alert>
                    </Collapse>
                </Box>
                
                <div className="tituloPagina"><p id="titu" >Editar Município</p></div>   

                <div className="semLinha menuPadrao">
                    <li>
                        <button id="0" onClick={(e) => menuSec(e.target.id)}>Cadastro Geral</button>        
                        <button id="1" onClick={(e) => menuSec(e.target.id)}>Símbolos</button>
                    </li>  
                </div>

                <div className="formulario" >
                    <div id="cadastroMunicipio">
                        <div className="municipio formularioGeral">
                            <div className="formularioGeralTitulo"><p>Nome</p><p>População</p></div>                            
                            <input type="text" name="nome" id="nome" placeholder="Insira o nome do município" autoComplete="off"  ></input>
                            <input type="text" name="populacao" id="populacao" placeholder="Insira a população do município" autoComplete="off"  ></input>
                                
                            <div className="formularioGeralTitulo"><p>Gentílico</p><p>Área</p></div>
                            <input type="text" name="gentilico" id="gentilico" placeholder="Insira o gentílico do município" autoComplete="off"  ></input>
                            <input type="text" name="area" id="area" placeholder="Insira a população do município" autoComplete="off"  ></input>
                            
                            <div className="formularioGeralTitulo"><p>Data Aniversário</p><p>Link Mapa</p></div>
                            <input type="date" name="data_aniversario" id="data_aniversario" autoComplete="off"  ></input>
                            <input type="text" name="link_maps" id="link_maps" placeholder="Insira o link de localização do município" autoComplete="off"  ></input>

                            <div className="formularioGeralTitulo"><p>Estado</p><p>Galeria</p></div>
                            <input type="text" name="estado_uf" id="estado_uf" placeholder="Insira o estado do município" autoComplete="off"  ></input>

                            <select name="galeria_id" id="galeria_id">        
                            </select>
                            
                        </div>

                        <div className="formularioConteudo">
                            <span>Sobre</span>
                            <TextEditor value={texto1} onChange={settexto1} />
                        </div>

                        <div className="formMunicipio formularioConteudo">
                            <span>História</span>
                            <TextEditor value={texto2} onChange={settexto2} />
                        </div>

                        <div className="formMunicipio formularioConteudo">
                            <span>Hino</span>
                            <TextEditor value={texto3} onChange={settexto3} />
                            <button onClick={selectFuncao}>Salvar alterações</button>
                        </div>

                    </div>                    
                </div>

                <div className="cadastroDoc">
                    <div id="cadastroImgMunicipio">
                        <div className="cadastroDocInterno">
                            <p>Símbolos</p>
                            <div id="galeriaSimbolos" className="anexos">
                                {loading()}
                            </div>    
                            <span>Clique ou solte as imagens abaixo para adicionar</span>
                            <FileUploadMultiple  />
                        </div>
                    </div>                        
                </div>
            </Sidebar>
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                ><p id="notifica" ></p>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </>   
    );
}

export default MunicipioEditar;