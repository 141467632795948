import { useEffect, useRef, useState } from "react";
import '../../../components/Geral/geral.css';
import './VacinaEditar.css'
import carregando from '../../../components/Geral/loading.gif';
import { verificaLogin, fazerLogout } from '../../../context/LoginContext';
import { Editor } from '@tinymce/tinymce-react';
import {base_url, midia_url, foto_default} from '../../../environment'
import axios from 'axios';
import Sidebar from '../../../components/Menu/Sidebar';
import { useParams } from "react-router-dom";

import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
//import Button from '@mui/material/Button';

var unavez = true, umavez = true;

function VacinaEditar(){
    setInterval(function () {verificaLogin();}, 1000);

    const [refreshKey, setRefreshKey] = useState(0);
    
    function atualiza() {
        setRefreshKey(oldKey => oldKey +1); 
    }

    const [alertAberto, setAlertAberto] = React.useState(false);
    const [alertMensagem, setAlertMensagem] = React.useState('');
    const [tipoAlerta, setTipoAlerta] = React.useState('');

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    function meuAlert(texto, tipo) {
        setTipoAlerta(tipo);
        setAlertMensagem(texto);
        window.location = `#root`;
        setAlertAberto(true);
    }

    function loading() {return <div id='loading'><img src={carregando} alt="Carregando"/></div>; }    
    setInterval(function () {verificaLogin();}, 1000);
    
    var [textoInicial, setTextoInicial] = useState('');    

    // Clicar no primeiro botao e colocar texto inicial
    useEffect(() => {
        
        document.getElementById(0).click();
        setTextoInicial("<p>Escreva seu texto aqui. Aumente o tamanho da área de texto no canto inferior direito.</p>");
    
    },[]);

    const {id} = useParams();
    var token = localStorage.getItem('LOCAL_STORAGE_KEY__ACCESS_TOKEN');

    // pegar dados tipo_vacina
    useEffect(() => {
    axios.get(`${base_url}/tipo-vacina`, {
        "headers": {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 

            // eslint-disable-next-line
            if((response.data.statusCode != undefined) && (response.data.statusCode != 200)){
                //window.location.pathname = `mensagem/${response.data.message}`;
                meuAlert(`${response.data.message}`, "error");
            }

            // eslint-disable-next-line 
            if(response.data.length == 0)
            {
                meuAlert("Não há tipos de vacina.", "error");           
            }
            else
            {
                //console.log(response.data);

                var pessoa = document.getElementById("tipo_vacina");                
                for(let i=0; i<response.data.length; i++){
                    const option = document.createElement("option");
                    option.value = response.data[i].id;
                    option.innerHTML = response.data[i].nome;                    
                    pessoa.appendChild(option);
                }

            }            
        })    
        .catch(error => { 
            console.log(error.message);
            if(error.response)
            {
                // eslint-disable-next-line
                if(error.response.data.statusCode == 401)
                {
                    window.location.pathname = `login`;
                }
                else{
                    meuAlert(`${error.message}`, "error");
                }  
            }
            else{
                meuAlert(`${error.message}`, "error");
            }            
        });

    },[]); 

    // pegar dados se não for novo
    useEffect(() => {
        
        if(id != "novo")
        {
            axios.get(`${base_url}/vacina/${id}`, 
            { headers: {
                'Authorization': `Bearer ${token}`,
            }})
            .then(async response => {             
                //console.log("Resposta ok");
                //console.log(response);
                //console.log(response.data);

                document.getElementById("nome").value = response.data.nome;
                document.getElementById("fornecedor").value = response.data.fornecedor;
                document.getElementById("quantidade").value = response.data.quantidade;
                document.getElementById("lote").value = response.data.lote;
                document.getElementById("valor_unitario").value = response.data.valor_unitario;
                document.getElementById("valor_total").value = response.data.valor_total;
                document.getElementById("data_recebimento").value = new Date(response.data.data_recebimento).toISOString().split('T')[0];  
                document.getElementById("data_validade").value = new Date(response.data.data_validade).toISOString().split('T')[0]; 
                document.getElementById("nota_fiscal").value = response.data.nota_fiscal;

                await TipoVacina(response.data.tipo_vacina_id);

                if(umavez){
                    document.getElementById("nota_fiscal").innerHTML = null;   
                
                    //arrayInicial.push(String('<div><p>'+response.data.Anexo[i].titulo+'</p><a target="_blank" href='+midia_url+response.data.Anexo[i].anexo+'>Baixar</a><a target="_blank" href="#" >Excluir</a></div>')); 
                        
                    var divGeral = document.getElementById("nota_fiscal");
                    
                    const divContainter = document.createElement("div");
    
                    const p = document.createElement("p");
                    p.innerHTML = response.data.nota_fiscal.substring(32);
            
                    const a2 = document.createElement("a");
                    a2.target = "_blank";
                    a2.href = String(midia_url+response.data.nota_fiscal);
                    a2.innerHTML = "Baixar";
                    
                    divContainter.appendChild(p);              
                    divContainter.appendChild(a2); 
                        
                    divGeral.appendChild(divContainter);
                       
                    umavez = false;
                } 
                                
            })
            .catch(error => { 
                console.log(error.message);
                if(error.response)
                {
                    if((error.response.data.statusCode == 404) || (error.response.data.statusCode == 400))
                    {
                        if(unavez)
                        {
                            meuAlert("Pessoa não existente.", "warning");
                            //window.location.pathname = `obra/editar/novo`;
                            unavez = false;
                        }
                        
                    }
                    else{
                        meuAlert(`${error.message}`, "error");
                    }
                    if(error.response.data.statusCode == 401)
                    {
                        window.location.pathname = `login`;
                    }
                }   
                else{
                    meuAlert(`${error.message}`, "error");
                }            
            });
        }
        else{
            document.getElementById("cadastroDocRelatorio").innerHTML = null;      
        }
        document.getElementById("notifica").innerHTML = "Processo em andamento, aguarde...";
               
    },[refreshKey]); 


    function verificaVazios() {

        if(document.getElementById("nome").value == ""){meuAlert("Nome não preenchido.", "warning");}
        else if(document.getElementById("fornecedor").value == ""){meuAlert("Fornecedor não preenchido.", "warning");}
        else if(document.getElementById("quantidade").value == ""){meuAlert("Quantidade não preenchida.", "warning");}
        else if(document.getElementById("lote").value == ""){meuAlert("Lote não preenchido.", "warning");}
        else if(document.getElementById("valor_unitario").value == ""){meuAlert("Valor unitário não preenchido.", "warning");}
        else if(document.getElementById("valor_total").value == ""){meuAlert("Valor Total não preenchido.", "warning");}
        else if(document.getElementById("data_recebimento").value == ""){meuAlert("Data recebimento não preenchida.", "warning");}
        else if(document.getElementById("data_validade").value == ""){meuAlert("Data Validade não preenchida.", "warning");}
        else if(id == "novo"){
            if(document.getElementById("arquivo").value == "")
            {meuAlert("Arquivo não inserido.", "warning");}
            else{return true;}
        }
        else
        {
            return true;
        }    
    }
    
    function selectFuncao(){
        if(id == "novo")
        {
            if(verificaVazios())
            {
                cadastraVacina();
            };            
        }
        else
        {
            if(verificaVazios())
            {
                atualizaVacina();
            };    
        }
    }
    // Cadastra ponto-turistico (sem docs)
    async function cadastraVacina() {

        {handleOpen()} 

        var input = document.querySelector('input[type="file"]')

        var data = new FormData()
        data.append('nome',  String(document.getElementById("nome").value))
        data.append('fornecedor', String(document.getElementById("fornecedor").value))     
        data.append('quantidade', document.getElementById("quantidade").value)
        data.append('lote', String(document.getElementById("lote").value))
        data.append('valor_unitario', document.getElementById("valor_unitario").value)
        data.append('valor_total', document.getElementById("valor_total").value)
        data.append('data_recebimento', new Date(document.getElementById("data_recebimento").value).toISOString())   
        data.append('data_validade', new Date(document.getElementById("data_validade").value).toISOString())   
        data.append('nota_fiscal', input.files[0])  
        data.append('contratante_id', localStorage.getItem('contratante_id'))    
        data.append('tipo_vacina_id', String(document.getElementById("tipo_vacina").value)) 
        
        // NÂO PRECISA
        //data.append('contratante_id', localStorage.getItem('contratante_id'))      

        //console.log(data);
        
        const url = `${base_url}/vacina`;
         await axios.post(url, data, {
        "headers": {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 
            //console.log(response);
            //console.log(response.data.id);

            document.getElementById("notifica").innerHTML = "Cadastrado com sucesso!";
            const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

            meuAlert("Cadastrado com sucesso!", "success");
            const redireciona = setInterval(function () {
                clearInterval(redireciona);
                window.location.pathname = `vacina/editar/${response.data.id}`;
            }, 3000);     
           
        })
        .catch(error => {
            {handleClose()}
            meuAlert(`${error.response.data.message}`, "warning");
            console.log(error.message);
            console.log(error.response.data.message);
            //console.log("Erro "+error.response.data.statusCode);
        })     
    }
    // Atualiza ponto-turistico (sem docs)
    async function atualizaVacina() {
          
        {handleOpen()} 

        var input = document.querySelector('input[type="file"]')

        var data = new FormData()
        data.append('nome',  String(document.getElementById("nome").value))
        data.append('fornecedor', String(document.getElementById("fornecedor").value))     
        data.append('quantidade', document.getElementById("quantidade").value)
        data.append('lote', String(document.getElementById("lote").value))
        data.append('valor_unitario', document.getElementById("valor_unitario").value)
        data.append('valor_total', document.getElementById("valor_total").value)
        data.append('data_recebimento', new Date(document.getElementById("data_recebimento").value).toISOString())   
        data.append('data_validade', new Date(document.getElementById("data_validade").value).toISOString())   
        data.append('nota_fiscal', input.files[0])  
        data.append('tipo_vacina_id', String(document.getElementById("tipo_vacina").value)) 
        
         //NÂO PRECISA
        //data.append('cargo', String(document.getElementById("cargo").value))
        //data.append('contratante_id', localStorage.getItem('contratante_id'))      

        //console.log(data);
        
        const url = `${base_url}/vacina/${id}`;
        await axios.patch(url, data, {
        "headers": {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 
            //console.log(response);
            //console.log(response.data.id);

            document.getElementById("notifica").innerHTML = "Alterado com sucesso!";
            const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

            meuAlert("Alterado com sucesso!", "success");
            const redireciona = setInterval(function () {
                //setRefreshKey(oldKey => oldKey +1);
                window.location.reload();
                clearInterval(redireciona);
            }, 2000);     

        })
        .catch(error => { 
            {handleClose()}
            meuAlert(`${error.response.data.message}`, "warning");
            console.log(error.message);
            console.log(error.response.data.message);
            //console.log("Erro "+error.response.data.statusCode);
        })     
        
    }    
    // Excluir ponto-turistico
    async function ExcluirDoc() {  

        if (window.confirm("Tem certeza que deseja excluir esta vacina?")) {        
            
            {handleOpen()} 
         
            const url = `${base_url}/vacina/${id}`;
            await axios.delete(url, {
            "headers": {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            }})
            .then(response => { 
                //console.log(response);
                //console.log(response.data.id);    
               
                document.getElementById("notifica").innerHTML = "Excluído com sucesso!";
                const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

                meuAlert("Excluído com sucesso!", "success");
                const redireciona = setInterval(function () {
                    clearInterval(redireciona);
                    window.location.pathname = `vacina/editar/novo`;
                }, 2000);     

            })
            .catch(error => { 
                {handleClose()}
                meuAlert(`${error.message}`, "error");
                console.log(error.message);
                //console.log("Erro "+error.response.data.statusCode);
            })  
        }           
    }

    function TipoVacina(valor){
        for(let i=0; i<document.getElementById("tipo_vacina").children.length; i++){
            if(document.getElementById("tipo_vacina").children[i].value == valor){
                document.getElementById("tipo_vacina").children[i].selected = true;
            }        
        }
    }

    return(
        <>   
            <Sidebar page={{ name: 'Vacinas', route: '/vacina' }}>
                <Box sx={{ width: '100%'}}>
                    <Collapse in={alertAberto}>
                        <Alert
                        severity={tipoAlerta}
                        variant="filled"
                        action={
                            <IconButton
                            aria-label="close"
                            size="small"
                            color="inherit"
                            onClick={() => {
                                setAlertAberto(false);
                            }}
                            >
                            <CloseIcon/>
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        {alertMensagem}
                        </Alert>
                    </Collapse>
                </Box>
                
                <div className="tituloPagina"><p id="titu" >Editar Vacinas</p></div>   

                <div className="botaoExcluirEspec" >
                    {id !== "novo" && (
                        <div className="botaoExcluirEspec">
                            <button onClick={ExcluirDoc}>Excluir Vacina</button>
                        </div>
                    )}

                </div>


                <div className="semLinha menuPadrao">
                    <li>
                        <button id="0" >Cadastro Geral</button>       
                    </li>  
                </div>

                <div className="formulario" >
                    <div id="cadastroGaleria">
                        <div className="formRedondo formularioGeral ">                                                       
                            
                            <div className="alinhaDireita">
                                
                                <div className="formularioGeralTitulo"><p>Fornecedor</p></div>                            
                                <input type="text" name="fornecedor" id="fornecedor" placeholder="Insira o fornecedor" autoComplete="off"  ></input>

                                <div className="formularioGeralTitulo"><p>Lote</p></div>                            
                                <input type="text" name="lote" id="lote" placeholder="Insira o lote" autoComplete="off"  ></input>

                                <div className="formularioGeralTitulo"><p>Valor Total</p></div>                            
                                <input type="number" name="valor_total" id="valor_total" placeholder="Insira o Valor Total" autoComplete="off"  ></input>

                                <div className="formularioGeralTitulo"><p>Data Validade</p></div>                            
                                <input type="date" name="data_validade" id="data_validade" placeholder="Insira a Data de Validade" autoComplete="off"  ></input>
              
                                <div className="formularioGeralTitulo"><p>Tipo de Vacina</p></div>   
                                <select name="tipo_vacina" id="tipo_vacina">
                                </select>

                            </div>

                            <div className="alinhaEsquerda">
                                                                                                
                                <div className="formularioGeralTitulo"><p>Nome</p></div>                            
                                <input type="text" name="nome" id="nome" placeholder="Insira o nome da vacina" autoComplete="off"  ></input>

                                <div className="formularioGeralTitulo"><p>Quantidade</p></div>                            
                                <input type="number" name="quantidade" id="quantidade" placeholder="Insira a quantidade da vacina" autoComplete="off"  ></input>

                                <div className="formularioGeralTitulo"><p>Valor Unitário</p></div>                            
                                <input type="number" name="valor_unitario" id="valor_unitario" placeholder="Insira o valor unitário" autoComplete="off"  ></input>

                                <div className="formularioGeralTitulo"><p>Data recebimento</p></div>                            
                                <input type="date" name="data_recebimento" id="data_recebimento" placeholder="Insira a data de recebimento" autoComplete="off"  ></input>
                               
                                <div className="formularioGeralTitulo"><p>Nota Fiscal (Inserir arquivo)</p></div>                            
                                <input type="file" name="arquivo" id="arquivo" autoComplete="off"  ></input>

                            </div>           

                            <div id="cadastroDocRelatorio">
                                <div className="removeBordaa cadastroDocInterno">
                                    <p>Arquivo cadastrado: Para substituir, insira novo arquivo acima</p>
                                    <div id="nota_fiscal" className="anexos">
                                        {loading()}
                                    </div>       
                                </div>
                            </div>                      
                                                         
                            <button onClick={selectFuncao}>Salvar alterações</button>

                        </div>

                    </div>                    
                </div>
            </Sidebar>
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                ><p id="notifica" >vghfgh</p>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </>   
    );
}

export default VacinaEditar;