import { useEffect, useRef, useState } from "react";
import '../../../components/Geral/geral.css';
import './BannerEditar.css'
import carregando from '../../../components/Geral/loading.gif';
import { verificaLogin, fazerLogout } from '../../../context/LoginContext';
import { Editor } from '@tinymce/tinymce-react';
import {base_url, midia_url, foto_default} from '../../../environment'
import axios from 'axios';
import Sidebar from '../../../components/Menu/Sidebar';
import { useParams } from "react-router-dom";

import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
//import Button from '@mui/material/Button';

var unavez = true, umavez = true;

function BannerEditar(){
    setInterval(function () {verificaLogin();}, 1000);

    const [refreshKey, setRefreshKey] = useState(0);
    
    function atualiza() {
        setRefreshKey(oldKey => oldKey +1); 
    }

    const [alertAberto, setAlertAberto] = React.useState(false);
    const [alertMensagem, setAlertMensagem] = React.useState('');
    const [tipoAlerta, setTipoAlerta] = React.useState('');

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    function meuAlert(texto, tipo) {
        setTipoAlerta(tipo);
        setAlertMensagem(texto);
        window.location = `#root`;
        setAlertAberto(true);
    }

    function loading() {return <div id='loading'><img src={carregando} alt="Carregando"/></div>; }    
    setInterval(function () {verificaLogin();}, 1000);
    
    //var [textoInicial, setTextoInicial] = useState('');    

    // Clicar no primeiro botao e colocar texto inicial
    useEffect(() => {
        
        document.getElementById(0).click();
        //setTextoInicial("Escreva sua descrição.");
        document.getElementById("descricao").value = "Escreva sua descrição.";
    
    },[]);

    const {id} = useParams();
    var token = localStorage.getItem('LOCAL_STORAGE_KEY__ACCESS_TOKEN');
 
    // pegar dados se não for novo
    useEffect(() => {
        
        if(id != "novo")
        {
            axios.get(`${base_url}/banner/${id}`, 
            { headers: {
                'Authorization': `Bearer ${token}`,
            }})
            .then(async response => {             
                //console.log("Resposta ok");
                //console.log(response);
                //console.log(response.data);

                if(response.data.imagem){document.getElementById("imagem").src = midia_url+response.data.imagem;}
                else{document.getElementById("imagem").src = foto_default}   

                document.getElementById("data_inicio").value = new Date(response.data.data_inicio).toISOString().split('T')[0];
                document.getElementById("data_fim").value = new Date(response.data.data_fim).toISOString().split('T')[0];

                if(response.data.status == true){document.getElementById("bannerativo").checked = true;}
                else {document.getElementById("bannerinativo").checked = true;}        

                document.getElementById("ordem_posicao").value = response.data.ordem_posicao;    

                await categoriaBan(response.data.categoria);
                
                if(response.data.descricao){document.getElementById("descricao").value = response.data.descricao};
                
                //var arrayInicial = [];        

            })
            .catch(error => { 
                console.log(error.message);
                if(error.response)
                {
                    if((error.response.data.statusCode == 404) || (error.response.data.statusCode == 400))
                    {
                        if(unavez)
                        {
                            meuAlert("Pessoa não existente.", "warning");
                            //window.location.pathname = `banner/editar/novo`;
                            unavez = false;
                        }
                        
                    }
                    else{
                        meuAlert(`${error.message}`, "error");
                    }
                    if(error.response.data.statusCode == 401)
                    {
                        window.location.pathname = `login`;
                    }
                }   
                else{
                    meuAlert(`${error.message}`, "error");
                }            
            });
        }
        else{
            document.getElementById("imagem").src = foto_default;
        }
        document.getElementById("notifica").innerHTML = "Processo em andamento, aguarde...";
               
    },[refreshKey]); 


    function verificaVazios() {

        if(document.getElementById("data_inicio").value == ""){meuAlert("Data Início do banner não preenchida.", "warning");}
        else if(document.getElementById("data_fim").value == ""){meuAlert("Data Fim do banner não preenchida.", "warning");}
        else if(document.getElementById("ordem_posicao").value == ""){meuAlert("Ordem de posição do banner não preenchida.", "warning");}
        else if(document.getElementById("categoria").value == ""){meuAlert("Categoria do banner não preenchida.", "warning");}
        else if(document.getElementById("descricao").value == ""){meuAlert("Descrição do banner não preenchida.", "warning");}
        else if(id == "novo"){
            if(document.getElementById("imagem").value == "")
            {meuAlert("Imagem do Banner não preenchida.", "warning");}
            else{return true;}
        }
        else
        {
            return true;
        }    
    }
    
    // Função salvar começa aqui
    const editorRef = useRef(null);
    const pegaConteudo = () => {
        if (editorRef.current) {
        console.log(editorRef.current.getContent());
    }}

    function selectFuncao(){
        if(id == "novo")
        {
            if(verificaVazios())
            {
                cadastraBanner();
            };            
        }
        else
        {
            if(verificaVazios())
            {
                atualizaPonto();
            };    
        }
    }

    // Cadastra banner (sem docs)
    async function cadastraBanner() {

        {handleOpen()} 

        var input = document.querySelector('input[type="file"]')

        var data = new FormData()
        data.append('imagem', input.files[0])       
        data.append('data_inicio', new Date(document.getElementById("data_inicio").value).toISOString())
        data.append('data_fim', new Date(document.getElementById("data_fim").value).toISOString())
        data.append('status', document.querySelector('input[name="statusBanner"]:checked').value)
        data.append('ordem_posicao', String(document.getElementById("ordem_posicao").value))
        data.append('categoria', String(document.getElementById("categoria").value))
        data.append('descricao', String(document.getElementById("descricao").value))
        // NÂO PRECISA
        //data.append('contratante_id', localStorage.getItem('contratante_id'))      

        //console.log(data);
        
        const url = `${base_url}/banner`;
         await axios.post(url, data, {
        "headers": {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 
            //console.log(response);
            //console.log(response.data.id);

            document.getElementById("notifica").innerHTML = "Cadastrado com sucesso!";
            const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

            meuAlert("Cadastrado com sucesso!", "success");
            const redireciona = setInterval(function () {
                clearInterval(redireciona);
                window.location.pathname = `banner/editar/${response.data.id}`;
            }, 3000);     
           
        })
        .catch(error => {
            {handleClose()}
            meuAlert(`${error.response.data.message}`, "warning");
            console.log(error.message);
            console.log(error.response.data.message);
            //console.log("Erro "+error.response.data.statusCode);
        })     
    }
    // Atualiza banner (sem docs)
    async function atualizaPonto() {
          
        {handleOpen()} 

        var input = document.querySelector('input[type="file"]')

        var data = new FormData()
        data.append('imagem', input.files[0])       
        data.append('data_inicio', new Date(document.getElementById("data_inicio").value).toISOString())
        data.append('data_fim', new Date(document.getElementById("data_fim").value).toISOString())
        data.append('status', document.querySelector('input[name="statusBanner"]:checked').value)
        data.append('ordem_posicao', String(document.getElementById("ordem_posicao").value))
        data.append('categoria', String(document.getElementById("categoria").value))
        data.append('descricao', String(document.getElementById("descricao").value))
        //NÂO PRECISA
        //data.append('contratante_id', localStorage.getItem('contratante_id'))      

        //console.log(data);
        
        const url = `${base_url}/banner/${id}`;
        await axios.patch(url, data, {
        "headers": {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 
            //console.log(response);
            //console.log(response.data.id);

            document.getElementById("notifica").innerHTML = "Alterado com sucesso!";
            const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

            meuAlert("Alterado com sucesso!", "success");
            const redireciona = setInterval(function () {
                //setRefreshKey(oldKey => oldKey +1);
                window.location.reload();
                clearInterval(redireciona);
            }, 2000);     

        })
        .catch(error => { 
            {handleClose()}
            meuAlert(`${error.response.data.message}`, "warning");
            console.log(error.message);
            console.log(error.response.data.message);
            //console.log("Erro "+error.response.data.statusCode);
        })     
        
    }    
    // Excluir banner
    async function ExcluirPonto() {  

        if (window.confirm("Tem certeza que deseja excluir este banner?")) {        
            
            {handleOpen()} 
         
            const url = `${base_url}/banner/${id}`;
            await axios.delete(url, {
            "headers": {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            }})
            .then(response => { 
                //console.log(response);
                //console.log(response.data.id);    
               
                document.getElementById("notifica").innerHTML = "Excluído com sucesso!";
                const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

                meuAlert("Excluído com sucesso!", "success");
                const redireciona = setInterval(function () {
                    clearInterval(redireciona);
                    window.location.pathname = `banner/editar/novo`;
                }, 2000);     

            })
            .catch(error => { 
                {handleClose()}
                meuAlert(`${error.message}`, "error");
                console.log(error.message);
                //console.log("Erro "+error.response.data.statusCode);
            })  
        }           
    }

    function categoriaBan(valor){
        for(let i=0; i<document.getElementById("categoria").children.length; i++){
            if(document.getElementById("categoria").children[i].value == valor){
                document.getElementById("categoria").children[i].selected = true;
            }        
        }
    }

    return(
        <>   
            <Sidebar page={{ name: 'Banners e Popups', route: '/banner' }}>
                <Box sx={{ width: '100%'}}>
                    <Collapse in={alertAberto}>
                        <Alert
                        severity={tipoAlerta}
                        variant="filled"
                        action={
                            <IconButton
                            aria-label="close"
                            size="small"
                            color="inherit"
                            onClick={() => {
                                setAlertAberto(false);
                            }}
                            >
                            <CloseIcon/>
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        {alertMensagem}
                        </Alert>
                    </Collapse>
                </Box>
                
                <div className="tituloPagina"><p id="titu" >Editar Banner / Popup</p></div>   

                <div className="botaoExcluirEspec">
                    {id !== "novo" && (
                        <button onClick={ExcluirPonto}>Excluir Banner </button>
                    )}
                </div>

                <div className="semLinha menuPadrao">
                    <li>
                        <button id="0" >Cadastro Geral</button>       
                    </li>  
                </div>

                <div className="formulario" >
                    <div id="cadastroGaleria">
                        <div className="formRedondo formularioGeral">                                                       
                            <div className="alinhaDireita">

                                <div className="formularioGeralTitulo"><p>Data Início</p></div>
                                <input type="date" name="data_inicio" id="data_inicio" autoComplete="off"  ></input>

                                <div className="formularioGeralTitulo"><p>Data Fim</p></div>
                                <input type="date" name="data_fim" id="data_fim" autoComplete="off"  ></input>

                                <div className="novoRadio statusRadio"> 
                                    <div className="formularioGeralTitulo"><p>Status</p></div> 
                                    <input type="radio" id="bannerativo" name="statusBanner" value="true"checked></input>
                                    <label for="ativo">Ativo</label>

                                    <input type="radio" id="bannerinativo" name="statusBanner" value="false"></input>
                                    <label for="inativo">Inativo</label>
                                </div>

                                <div className="espaco2LadosB_Banner"></div>
                                
                                <div className="formularioGeralTitulo"><p>Ordem posição</p></div>   
                                <input type="text" name="ordem_posicao" id="ordem_posicao" placeholder="Insira o nº da posição na ordem" autoComplete="off"  ></input>
                               
                                <div className="formularioGeralTitulo"><p>Categoria</p></div>
                                <select name="categoria" id="categoria">
                                    <option value="HEADER">Header</option>

                                    {/*
                                        <option value="FOOTER">Footer</option>
                                        <option value="HOME">Home</option>
                                       
                                    */} 

                                    <option value="POPUP_INFERIOR">Popup Inferior</option>
                                    <option value="POPUP_CENTRAL">Popup Central</option>
                                    <option value="POPUP_LATERAL">Popup Lateral</option>
                                </select>
                                    
                            </div>

                            <div className="alinhaEsquerda">
                                <img id="imagem" name="imagem" src={carregando} ></img>
                                
                                <div className="formularioGeralTitulo"><p>Capa do banner</p></div>
                                <input type="file" name="InputCapa" id="InputCapa" autoComplete="off"  ></input>
                                   
                            </div>    

                            <div className="bloqueiaDiv formularioGeralTitulo"><p>Descrição do banner</p></div>   
                            <input type="text" name="descricao" id="descricao" placeholder="Insira a descrição do banner" autoComplete="off"  ></input>

                            <button onClick={selectFuncao}>Salvar alterações</button>
                                          
                        </div>                        
                    </div>                    
                </div>
            </Sidebar>
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                ><p id="notifica" >vghfgh</p>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </>   
    );
}

export default BannerEditar;