import styled from "styled-components";

export default styled.select`
  font-size: 20px;
  height: 50px;
  width: fit-content;
  min-width: 120px;
  padding-left: 1%;
  padding-right: 1%;
  color: var(--textColor);
  border: 1px solid;
  border-color: var(--mainColor2);
  border-radius: 15px 15px 15px 15px;
  background-color: var(--textColor2);
`