import { useEffect, useState } from "react";
import '../../../components/Geral/geral.css';
import './GaleriaEditar.css'
import carregando from '../../../components/Geral/loading.gif';
import { verificaLogin } from '../../../context/LoginContext';
import { TextEditor } from "../../../components/TextEditor/TextEditor.js";
import {base_url, midia_url, foto_default} from '../../../environment'
import axios from 'axios';
import Sidebar from '../../../components/Menu/Sidebar';
import FileUploadMultiple from './uploadGaleria.tsx'
import { useParams } from "react-router-dom";

import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

var unavez = true, umavez = true;
var optionClicado = "background-color: var(--mainColor); color: var(--textColor2);"


function GaleriaEditar(){
    setInterval(function () {verificaLogin();}, 1000);

    const [refreshKey, setRefreshKey] = useState(0);
    
    function atualiza() {
        setRefreshKey(oldKey => oldKey +1); 
    }

    const [alertAberto, setAlertAberto] = React.useState(false);
    const [alertMensagem, setAlertMensagem] = React.useState('');
    const [tipoAlerta, setTipoAlerta] = React.useState('');

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    function meuAlert(texto, tipo) {
        setTipoAlerta(tipo);
        setAlertMensagem(texto);
        window.location = `#root`;
        setAlertAberto(true);
    }

    function loading() {return <div id='loading'><img src={carregando} alt="Carregando"/></div>; }    
    setInterval(function () {verificaLogin();}, 1000);
    
    var [texto, setTexto] = useState('<p>Escreva seu texto aqui. Aumente o tamanho da área de texto no canto inferior direito.</p>');    

    // Clicar no primeiro botao e colocar texto inicial
    useEffect(() => {
        document.getElementById(0).click();    
    },[]);

    const {id} = useParams();
    var token = localStorage.getItem('LOCAL_STORAGE_KEY__ACCESS_TOKEN');

    // pegar dados se não for novo
    useEffect(() => {
        
        if(id != "novo")
        {
            axios.get(`${base_url}/galeria/${id}`, 
            { headers: {
                'Authorization': `Bearer ${token}`,
            }})
            .then(response => {             
                //console.log("Resposta ok");
                //console.log(response);
                //console.log(response.data);
                
                document.getElementById("titulo").value = response.data.titulo;
                document.getElementById("data").value = new Date(response.data.data).toISOString().split('T')[0];
                document.getElementById("capa").src = midia_url+response.data.capa;
                setTexto(response.data.conteudo);

                if(response.data.status == true){
                    document.getElementById("ativo").checked = true;
                }
                else
                {
                    document.getElementById("inativo").checked = true;
                }
                
                //var arrayInicial = [];        

                if(umavez){
                    document.getElementById("galeriaImagens").innerHTML = null;   
                    for(let i=0; i<response.data.Imagem.length; i++){
                
                        //arrayInicial.push(String('<div><p>'+response.data.Imagem[i].titulo+'</p><a target="_blank" href='+midia_url+response.data.Imagem[i].anexo+'>Baixar</a><a target="_blank" href="#" >Excluir</a></div>')); 
                            
                        var divGeral = document.getElementById("galeriaImagens");
                        
                        const divContainter = document.createElement("div");
        
                        const p = document.createElement("p");
                        p.innerHTML = response.data.Imagem[i].id.substring(0,10);

                        const img = document.createElement("img");
                        img.src = String(midia_url+response.data.Imagem[i].imagem);                        

                        const a = document.createElement("a");
                        a.innerHTML = "Excluir";
                        a.id = response.data.Imagem[i].id;
                        a.addEventListener("click", (e) => ExcluirImagem(e.target.id));
        
                        const a2 = document.createElement("a");
                        a2.target = "_blank";
                        a2.href = String(midia_url+response.data.Imagem[i].imagem);
                        a2.innerHTML = "Baixar";
                        
                        divContainter.appendChild(img); 
                        divContainter.appendChild(p); 
                        divContainter.appendChild(a);                 
                        divContainter.appendChild(a2); 
                            
                        divGeral.appendChild(divContainter);
                        
                    };
                    umavez = false;
                } 
            })
            .catch(error => { 
                console.log(error.message);
                if(error.response)
                {
                    if((error.response.data.statusCode == 404) || (error.response.data.statusCode == 400))
                    {
                        if(unavez)
                        {
                            meuAlert("Concurso não existente.", "warning");
                            //window.location.pathname = `galeria/editar/novo`;
                            unavez = false;
                        }
                        
                    }
                    else{
                        meuAlert(`${error.message}`, "error");
                    }
                    if(error.response.data.statusCode == 401)
                    {
                        window.location.pathname = `login`;
                    }
                }   
                else{
                    meuAlert(`${error.message}`, "error");
                }            
            });
        }
        else{
            document.getElementById("capa").src = foto_default;
        }
        document.getElementById("notifica").innerHTML = "Processo em andamento, aguarde...";
               
    },[refreshKey]); 

    function menuSec(menuId) {
        for(let i=0; i<=1; i++){
            document.getElementById(i).style = null;
        }
        document.getElementById(menuId).style = optionClicado;       
        //console.log("Selecionou o botão " + id);

        // eslint-disable-next-line
        if(menuId != 0)
        {
            document.getElementById("cadastroGaleria").setAttribute('hidden', '');
        }
        else
        {
            document.getElementById("cadastroGaleria").removeAttribute('hidden');
            document.getElementById("cadastroDocConcurso").setAttribute('hidden', '');            
        }

        // eslint-disable-next-line
        if(menuId != 1){
            document.getElementById("cadastroDocConcurso").setAttribute('hidden', '');
        }
        else{
            if(id == "novo")
            {
                meuAlert("Cadastre e salve a galeria antes de inserir imagens.", "info");
                //alert("Cadastre e salve a galeria antes de inserir anexos.")
                document.getElementById(0).click();   
            }
            else
            {
               document.getElementById("cadastroDocConcurso").removeAttribute('hidden');
                document.getElementById("cadastroGaleria").setAttribute('hidden', ''); 
            }            
        }

    }  

    function verificaVazios() {

        if(document.getElementById("titulo").value == ""){meuAlert("Título não preenchido.", "warning");}
        else if(document.getElementById("data").value == ""){meuAlert("Data da galeria não preenchida.", "warning");}
        else if(id == "novo"){
            if(document.getElementById("InputCapa").value == "")
            {meuAlert("Imagem da capa não preenchida.", "warning");}
            else{return true;}
        }
        else
        {
            return true;
        }    
    }

    function selectFuncao(){
        if(id == "novo")
        {
            if(verificaVazios())
            {
                cadastraGaleria();
            };            
        }
        else
        {
            if(verificaVazios())
            {
                atualizaGaleria();
            };    
        }
    }

    // Cadastra galeria (sem docs)
    async function cadastraGaleria() {

        {handleOpen()} 

        var input = document.querySelector('input[type="file"]')

        var data = new FormData()
        data.append('titulo', document.getElementById("titulo").value)
        data.append('capa', input.files[0])        
        data.append('conteudo', texto)
        data.append('data', new Date(document.getElementById("data").value).toISOString())
        data.append('status', document.querySelector('input[name="statusRadio"]:checked').value)
        //data.append('contratante_id', localStorage.getItem('contratante_id'))      

        console.log(data);
        
        const url = `${base_url}/galeria`;
         await axios.post(url, data, {
        "headers": {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 
            //console.log(response);
            //console.log(response.data.id);

            document.getElementById("notifica").innerHTML = "Cadastrado com sucesso!";
            const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

            meuAlert("Cadastrado com sucesso!", "success");
            const redireciona = setInterval(function () {
                clearInterval(redireciona);
                window.location.pathname = `galeria/editar/${response.data.id}`;
            }, 3000);     
           
        })
        .catch(error => {
            {handleClose()}
            meuAlert(`${error.response.data.message}`, "warning");
            console.log(error.message);
            console.log(error.response.data.message);
            //console.log("Erro "+error.response.data.statusCode);
        })     
    }
    // Atualiza galeria (sem docs)
    async function atualizaGaleria() {
          
        {handleOpen()} 

        var input = document.querySelector('input[type="file"]')

        var data = new FormData()
        data.append('titulo', document.getElementById("titulo").value)
        data.append('capa', input.files[0])        
        data.append('conteudo', texto)
        data.append('data', new Date(document.getElementById("data").value).toISOString())
        data.append('status', document.querySelector('input[name="statusRadio"]:checked').value)

        //console.log(data);
        
        const url = `${base_url}/galeria/${id}`;
        await axios.patch(url, data, {
        "headers": {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 
            //console.log(response);
            //console.log(response.data.id);

            document.getElementById("notifica").innerHTML = "Alterado com sucesso!";
            const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

            meuAlert("Alterado com sucesso!", "success");
            const redireciona = setInterval(function () {
                //setRefreshKey(oldKey => oldKey +1);
                window.location.reload();
                clearInterval(redireciona);
            }, 2000);     

        })
        .catch(error => { 
            {handleClose()}
            meuAlert(`${error.response.data.message}`, "warning");
            console.log(error.message);
            console.log(error.response.data.message);
            //console.log("Erro "+error.response.data.statusCode);
        })     
        
    }
    
    // Excluir galeria
    async function ExcluirGaleria() {  

        if (window.confirm("Tem certeza que deseja excluir esse galeria?")) {        
            
            {handleOpen()} 
         
            const url = `${base_url}/galeria/${id}`;
            await axios.delete(url, {
            "headers": {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            }})
            .then(response => { 
                //console.log(response);
                //console.log(response.data.id);    
               
                document.getElementById("notifica").innerHTML = "Excluído com sucesso!";
                const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

                meuAlert("Excluído com sucesso!", "success");
                const redireciona = setInterval(function () {
                    clearInterval(redireciona);
                    window.location.pathname = `galeria/editar/novo`;
                }, 2000);     

            })
            .catch(error => { 
                {handleClose()}
                meuAlert(`${error.message}`, "error");
                console.log(error.message);
                //console.log("Erro "+error.response.data.statusCode);
            })  
        }           
    }
    // Excluir anexo
    async function ExcluirImagem(idImagem) {  

        if (window.confirm("Tem certeza que deseja excluir esta imagem?")) {     
            
            document.getElementById("notifica").innerHTML = "Processo em andamento, aguarde...";
         
            {handleOpen()}    

            const url = `${base_url}/galeria/imagem/${idImagem}`;
            await axios.delete(url, {
            "headers": {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            }})
            .then(response => { 
                //console.log(response);
                //console.log(response.data.id);
                //window.location.pathname = `galeria/editar/${id}`;

                document.getElementById("notifica").innerHTML = "Imagem excluída com sucesso!";
                const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

                meuAlert("Imagem excluída com sucesso!", "success");
                const redireciona = setInterval(function () {
                    //setRefreshKey(oldKey => oldKey +1); 
                    clearInterval(redireciona);
                    window.location.reload();
                }, 2000);     

            })
            .catch(error => { 
                {handleClose()}
                meuAlert(`${error.message}`, "error");
                console.log(error.message);
                //console.log("Erro "+error.response.data.statusCode);
            })  
        }           
    }  
    return(
        <>   
            <Sidebar page={{ name: 'Galerias', route: '/galeria' }}>
                <Box sx={{ width: '100%'}}>
                    <Collapse in={alertAberto}>
                        <Alert
                        severity={tipoAlerta}
                        variant="filled"
                        action={
                            <IconButton
                            aria-label="close"
                            size="small"
                            color="inherit"
                            onClick={() => {
                                setAlertAberto(false);
                            }}
                            >
                            <CloseIcon/>
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        {alertMensagem}
                        </Alert>
                    </Collapse>
                </Box>
                
                <div className="tituloPagina"><p id="titu" >Editar Galeria</p></div>

                <div className="botaoExcluirEspec">
                    {id !== "novo" && (
                        <button onClick={ExcluirGaleria}>Excluir Galeria</button>
                    )}
                </div>
                <div className="semLinha menuPadrao">
                    <li>
                        <button id="0" onClick={(e) => menuSec(e.target.id)}>Cadastro Geral</button>                        
                        <button id="1" onClick={(e) => menuSec(e.target.id)}>Imagens</button>
                    </li>  
                </div>

                <div className="formulario" >
                    <div id="cadastroGaleria">
                        <div className="espaco1Lado formularioGeral">                                                       
                            <div className="alinhaDireita">

                                <div className="formularioGeralTitulo"><p>Título da galeria</p></div>                            
                                <input type="text" name="titulo" id="titulo" placeholder="Insira o título da galeria" autoComplete="off"  ></input>
                                <div className="novoRadio statusRadio"> 
                                    <div className="formularioGeralTitulo"><p>Status da galeria</p></div>                            
                                
                                    <input type="radio" id="ativo" name="statusRadio" value="true"checked></input>
                                    <label for="ativo">Ativo</label>

                                    <input type="radio" id="inativo" name="statusRadio" value="false"></input>
                                    <label for="inativo">Inativo</label>
                                </div>

                                <div className="formularioGeralTitulo"><p>Data</p></div>
                                <input type="date" name="data" id="data" autoComplete="off"  ></input>

                                <div className="formularioGeralTitulo"><p>Capa</p></div>
                                <input type="file" name="InputCapa" id="InputCapa" autoComplete="off"  ></input>
                            </div>

                            <div className="alinhaEsquerda">
                                <img id="capa" name="capa" src={carregando} ></img>
                            </div>
                            
                            <div className="bloqueiaDiv formularioGeralTitulo"></div>   
                            
                        </div>

                        <div className="formularioConteudo">
                            <span>Descrição da galeria</span>
                            <TextEditor value={texto} onChange={setTexto} />
                            <button onClick={selectFuncao}>Salvar alterações</button>
                        </div>

                    </div>                    
                </div>

                <div className="cadastroDoc">
                    <div id="cadastroDocConcurso">
                        <div className="cadastroDocInterno">
                            <p>Imagens salvas na galeria</p>
                            <div id="galeriaImagens" className="anexos">
                                {loading()}
                            </div>    
                            <span>Clique ou solte as imagens abaixo para adicionar</span>
                            <FileUploadMultiple  />
                        </div>
                    </div>                        
                </div>
            </Sidebar>
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                ><p id="notifica" >vghfgh</p>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </>   
    );
}

export default GaleriaEditar;