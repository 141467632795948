import { useEffect, useState} from "react";
import '../../../components/Geral/geral.css';
import '../../../components/Geral/tabela.css';
import './UsuarioLogado.css'
import carregando from '../../../components/Geral/loading.gif';
import {base_url, midia_url} from '../../../environment'
import Sidebar from '../../../components/Menu/Sidebar';
import axios from 'axios';
import { verificaLogin, fazerLogout} from '../../../context/LoginContext';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {BiEdit} from "react-icons/bi"
import {MdDeleteForever} from "react-icons/md"
import {BsFillCheckCircleFill} from "react-icons/bs"
import {GoCircleSlash} from "react-icons/go"

import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
//import Button from '@mui/material/Button';

function Usuarios(){
    setInterval(function () {verificaLogin();}, 1000);

    const [refreshKey, setRefreshKey] = useState(0);

    const [alertAberto, setAlertAberto] = React.useState(false);
    const [alertMensagem, setAlertMensagem] = React.useState('');
    const [tipoAlerta, setTipoAlerta] = React.useState('');

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    function meuAlert(texto, tipo) {
        setTipoAlerta(tipo);
        setAlertMensagem(texto);
        window.location = `#root`;
        setAlertAberto(true);
    }
    
    var token = localStorage.getItem('LOCAL_STORAGE_KEY__ACCESS_TOKEN');

    var id = localStorage.getItem('USUARIO_LOGADO');
 
    const [nome, setnome] = useState();
    const [email, setemail] = useState();
    const [status, setstatus] = useState();

    function loading() {
        return <div id='loading'><img src={carregando} alt="Carregando"/></div>;
    }

    // pegar dados da usuario
    useEffect(() => {
      
        //console.log(dados);
        //console.log(dados.email);
        
        axios.get(`${base_url}/usuario/${id}`, {
            "headers": {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 

            // eslint-disable-next-line
            if((response.data.statusCode != undefined) && (response.data.statusCode != 200)){
                //window.location.pathname = `mensagem/${response.data.message}`;
                meuAlert(`${response.data.message}`, "error");
            }

            
            //setTables(response.data); 
            setnome(response.data.nome);
            setemail(response.data.email);
            setstatus(response.data.status);
            //console.log(response.data);
                        
        })    
        .catch(error => { 
            console.log(error.message);
            if(error.response)
            {
                // eslint-disable-next-line
                if(error.response.data.statusCode == 401)
                {
                    window.location.pathname = `login`;
                }
                else{
                    meuAlert(`${error.message}`, "error");
                }  
            }
            else{
                meuAlert(`${error.message}`, "error");
            }            
        });
                
        document.getElementById("mensagem").innerHTML = "Processo em andamento, aguarde...";
        // eslint-disable-next-line
    },[]); 

    // Excluir usuario
    async function ExcluirUsuario(id) {  

        if (window.confirm("Tem certeza que deseja excluir esse usuario?")) {            
            // eslint-disable-next-line
            {handleOpen()} 
        
            const url = `${base_url}/usuario/${id}`;
            await axios.delete(url, {
            "headers": {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            }})
            .then(response => { 
                //console.log(response);
                //console.log(response.data.id);

                document.getElementById("mensagem").innerHTML = "Operação finalizada!";
                // eslint-disable-next-line
                const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

                meuAlert("Excluído com sucesso!", "success");
                const redireciona = setInterval(function () {setRefreshKey(oldKey => oldKey +1); clearInterval(redireciona);}, 2000);     

            })
            .catch(error => { 
                // eslint-disable-next-line
                {handleClose()}
                meuAlert(`${error.message}`, "error");
                console.log(error.message);
                //console.log("Erro "+error.response.data.statusCode);
            })  
        }           
    }

    function mostraStatus(status) {  
        if(status)
        {return <a className="true">< BsFillCheckCircleFill /></a>;}
        else
        { return <a className="false">< GoCircleSlash /></a>;}
    }


    return(
        <>   
           <Sidebar>
                    <Box sx={{ width: '100%'}}>
                        <Collapse in={alertAberto}>
                            <Alert
                            severity={tipoAlerta}
                            variant="filled"
                            action={
                                <IconButton
                                aria-label="close"
                                size="small"
                                color="inherit"
                                onClick={() => {
                                    setAlertAberto(false);
                                }}
                                >
                                <CloseIcon/>
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                            >
                            {alertMensagem}
                            </Alert>
                        </Collapse>
                    </Box>
                    
                    <div className="tituloPagina"> 
                        <p id="titu" >Página inicial</p>
                    </div>   

                    <div className="logoff"> 
                        <p id="titu" onClick={fazerLogout} >Logout</p>
                    </div>   

                    <div className="tabelaPadrao">
                        <TableContainer className="modal-content">
                            <Table sx={{minWidth: 650}} arial-label="caption table" className="table">
                                <TableHead>
                                    <TableRow>                                    
                                        <TableCell>Usuário logado</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Editar</TableCell>
                                        <TableCell>Excluir</TableCell>
                                    </TableRow>
                                </TableHead>                            
                                <TableBody>
                                        <TableRow>
                                            <TableCell>{nome || '--'}</TableCell>                                    
                                            <TableCell>{email || '--'}</TableCell>
                                            <TableCell>{mostraStatus(status) || '--'}</TableCell>
                                            <TableCell><a target="_self" href={"usuario/editar/"+id}>< BiEdit /></a></TableCell>
                                            <TableCell><a target="_self" href="#" onClick={(e) => ExcluirUsuario(id)}>< MdDeleteForever /></a></TableCell>
                                        </TableRow>         
                                </TableBody>
                            </Table>                    
                        </TableContainer>
                    </div>   

                    <div className="bloqueiaDiv"><p>&nbsp;</p></div>
                    
            </Sidebar>    
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                ><p id="mensagem" ></p>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </>   
    );
}

export default Usuarios;