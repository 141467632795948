import { useEffect, useRef, useState } from "react";
import '../../../components/Geral/geral.css';
import './CargoEditar.css'
import carregando from '../../../components/Geral/loading.gif';
import { verificaLogin, fazerLogout } from '../../../context/LoginContext';
import {base_url, midia_url} from '../../../environment'
import axios from 'axios';
import Sidebar from '../../../components/Menu/Sidebar';
import { useParams } from "react-router-dom";

import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
//import Button from '@mui/material/Button';

var unavez = true, umavez = true;

function CargoEditar(){
    setInterval(function () {verificaLogin();}, 1000);

    const [refreshKey, setRefreshKey] = useState(0);
    
    function atualiza() {
        setRefreshKey(oldKey => oldKey +1); 
    }

    const [alertAberto, setAlertAberto] = React.useState(false);
    const [alertMensagem, setAlertMensagem] = React.useState('');
    const [tipoAlerta, setTipoAlerta] = React.useState('');

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    function meuAlert(texto, tipo) {
        setTipoAlerta(tipo);
        setAlertMensagem(texto);
        window.location = `#root`;
        setAlertAberto(true);
    }

    function loading() {return <div id='loading'><img src={carregando} alt="Carregando"/></div>; }    
    setInterval(function () {verificaLogin();}, 1000);
    
    // Clicar no primeiro botao e colocar texto inicial
    useEffect(() => {
        
        document.getElementById(0).click();
        
    },[]);

    const {id} = useParams();
    var token = localStorage.getItem('LOCAL_STORAGE_KEY__ACCESS_TOKEN');
        
    // pegar dados se não for novo
    useEffect(() => {

        
        var pessoa = document.getElementById("contratante"); 

        const option = document.createElement("option");
        option.value = "PREFEITURA";
        option.innerHTML = "Prefeitura";

        const option2 = document.createElement("option");
        option2.value = "CAMARA";
        option2.innerHTML = "Câmara";

        pessoa.appendChild(option);
        pessoa.appendChild(option2);
        
        if(id != "novo")
        {
            axios.get(`${base_url}/cargo/${id}`, 
            { headers: {
                'Authorization': `Bearer ${token}`,
            }})
            .then(async response => {             
                //console.log("Resposta ok");
                //console.log(response);
                //console.log(response.data);
                
                document.getElementById("cargo").value = response.data[0].titulo;
                //document.getElementById("contratante").value = response.data[0].tipo_contratante;

                //var arrayInicial = [];      

                await contratante(response.data[0].tipo_contratante);
            })
            .catch(error => { 
                console.log(error.message);
                if(error.response)
                {
                    if((error.response.data.statusCode == 404) || (error.response.data.statusCode == 400))
                    {
                        if(unavez)
                        {
                            meuAlert("Cargo não existente.", "warning");
                            //window.location.pathname = `concurso/editar/novo`;
                            unavez = false;
                        }
                        
                    }
                    else{
                        meuAlert(`${error.message}`, "error");
                    }
                    if(error.response.data.statusCode == 401)
                    {
                        window.location.pathname = `login`;
                    }
                }   
                else{
                    meuAlert(`${error.message}`, "error");
                }            
            });
        }
        document.getElementById("notifica").innerHTML = "Processo em andamento, aguarde...";
               
    },[refreshKey]); 

    function verificaVazios() {
        if(document.getElementById("cargo").value == ""){meuAlert("Cargo não preenchido.", "warning");}
        else
        {
            return true;
        }   
    }
    
    function selectFuncao(){
        if(id == "novo")
        {
            if(verificaVazios())
            {
                cadastraCargo();
            };            
        }
        else
        {
            if(verificaVazios())
            {
                atualizaCargo();
            };    
        }
    }

    // Cadastra Cargo (sem docs)
    async function cadastraCargo() {

        {handleOpen()} 

        const params = JSON.stringify({            
            "titulo": String(document.getElementById("cargo").value),
            "tipo_contratante": String(document.getElementById("contratante").value)
        });

        //console.log(params);
        
        const url = `${base_url}/cargo`;
         await axios.post(url, params, {
        "headers": {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 
            //console.log(response);
            //console.log(response.data.id);

            document.getElementById("notifica").innerHTML = "Cadastrado com sucesso!";
            const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

            meuAlert("Cadastrado com sucesso!", "success");
            const redireciona = setInterval(function () {
                clearInterval(redireciona);
                window.location.pathname = `cargo/editar/${response.data.id}`;
            }, 3000);     
           
        })
        .catch(error => {
            {handleClose()}
            meuAlert(`${error.response.data.message}`, "warning");
            console.log(error.message);
            console.log(error.response.data.message);
            //console.log("Erro "+error.response.data.statusCode);
        })     
    }

    // Atualiza Cargo (sem docs)
    async function atualizaCargo() {
          
        {handleOpen()} 

        const params = JSON.stringify({
            "titulo": String(document.getElementById("cargo").value),
            "tipo_contratante": String(document.getElementById("contratante").value)
        });

        //console.log(params);
        
        const url = `${base_url}/cargo/${id}`;
        await axios.patch(url, params, {
        "headers": {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 
            //console.log(response);
            //console.log(response.data.id);

            document.getElementById("notifica").innerHTML = "Alterado com sucesso!";
            const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

            meuAlert("Alterado com sucesso!", "success");
            const redireciona = setInterval(function () {atualiza(); clearInterval(redireciona);}, 2000);     

        })
        .catch(error => { 
            {handleClose()}
            meuAlert(`${error.response.data.message}`, "warning");
            console.log(error.message);
            console.log(error.response.data.message);
            //console.log("Erro "+error.response.data.statusCode);
        })     
        
    }
    
    // Excluir Cargo
    async function ExcluirCargo() {  

        if (window.confirm("Tem certeza que deseja excluir esse cargo?")) {        
            
            {handleOpen()} 
         
            const url = `${base_url}/cargo/${id}`;
            await axios.delete(url, {
            "headers": {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            }})
            .then(response => { 
                //console.log(response);
                //console.log(response.data.id);    
               
                document.getElementById("notifica").innerHTML = "Excluído com sucesso!";
                const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

                meuAlert("Excluído com sucesso!", "success");
                const redireciona = setInterval(function () {
                    clearInterval(redireciona);
                    window.location.pathname = `cargo/editar/novo`;
                }, 2000);     

            })
            .catch(error => { 
                {handleClose()}
                meuAlert(`${error.message}`, "error");
                console.log(error.message);
                //console.log("Erro "+error.response.data.statusCode);
            })  
        }           
    }

    function contratante(valor){
        for(let i=0; i<document.getElementById("contratante").children.length; i++){
            if(document.getElementById("contratante").children[i].value == valor){
                document.getElementById("contratante").children[i].selected = true;
            }        
        }
    }

    return(
        <>   
            <Sidebar page={{ name: 'Cargos', route: '/cargo' }}>
                <Box sx={{ width: '100%'}}>
                    <Collapse in={alertAberto}>
                        <Alert
                        severity={tipoAlerta}
                        variant="filled"
                        action={
                            <IconButton
                            aria-label="close"
                            size="small"
                            color="inherit"
                            onClick={() => {
                                setAlertAberto(false);
                            }}
                            >
                            <CloseIcon/>
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        {alertMensagem}
                        </Alert>
                    </Collapse>
                </Box>
                
                <div className="tituloPagina"><p id="titu" >Editar Cargo</p></div>   

                <div className="botaoExcluirEspec">
                    {id !== "novo" && (
                        <button onClick={ExcluirCargo}>Excluir Cargo</button>
                    )}
                </div>

                <div className="semLinha menuPadrao">
                    <li>
                        <button id="0" >Cadastro Geral</button>
                    </li>  
                </div>

                <div className="formulario" >
                    <div id="cadastroCargo">
                        <div className="formRedondo cargoEditar formularioGeral">
                            
                            <div className="formularioGeralTitulo"><p>Título do Cargo</p><p>Contratante</p></div>
                            <input type="text" name="cargo" id="cargo" autoComplete="off"  ></input>
                            <select name="contratante" id="contratante"></select>

                            <button onClick={(e) => selectFuncao()} >Salvar alterações</button>
                        </div>

                    </div>                    
                </div>
            </Sidebar>
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                ><p id="notifica" >vghfgh</p>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </>   
    );
}

export default CargoEditar;