import { useEffect, useState} from "react";
import '../../../components/Geral/geral.css';
import './Contato.css'
import '../../../components/Geral/tabela.css';
import carregando from '../../../components/Geral/loading.gif';
import {base_url} from '../../../environment'
import Sidebar from '../../../components/Menu/Sidebar';
import axios from 'axios';
import { verificaLogin} from '../../../context/LoginContext';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {BiEdit} from "react-icons/bi"
import {MdDeleteForever} from "react-icons/md"
import {BsFillCheckCircleFill} from "react-icons/bs"
import {GoCircleSlash} from "react-icons/go"

import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
//import Button from '@mui/material/Button';

var optionClicado = "background-color: var(--mainColor); color: var(--textColor2);"

function Contato(){
    setInterval(function () {verificaLogin();}, 1000);

    const [tables, setTables] = useState();
    const [Esictables, setEsicTables] = useState();
    const [Ouvidoriatables, setOuvidoriaTables] = useState();
    const [refreshKey, setRefreshKey] = useState(0);

    const [alertAberto, setAlertAberto] = React.useState(false);
    const [alertMensagem, setAlertMensagem] = React.useState('');
    const [tipoAlerta, setTipoAlerta] = React.useState('');


    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    function meuAlert(texto, tipo) {
        setTipoAlerta(tipo);
        setAlertMensagem(texto);
        window.location = `#root`;
        setAlertAberto(true);
    }
    
    var token = localStorage.getItem('LOCAL_STORAGE_KEY__ACCESS_TOKEN');

    function loading() {
        return <div id='loading'><img src={carregando} alt="Carregando"/></div>;
    }
    
    // pegar dados
    useEffect(() => {
        
        // pegar dados ouvidoria
        axios.get(`${base_url}/ouvidoria`, {
            "headers": {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }})
        .then(async response => { 

            // eslint-disable-next-line
            if((response.data.statusCode != undefined) && (response.data.statusCode != 200)){
                //window.location.pathname = `mensagem/${response.data.message}`;
                meuAlert(`${response.data.message}`, "error");
            }

            // eslint-disable-next-line 
            if(response.data.length == 0)
            {
                meuAlert("Não há dados.", "error");         
                document.getElementById("temporario").innerHTML = null;     
            }
            else
            {
                //console.log(response.data);
                setOuvidoriaTables(response.data);
                document.getElementById("temporario").innerHTML = null;
            }            
        })    
        .catch(error => { 
            console.log(error.message);
            if(error.response)
            {
                // eslint-disable-next-line
                if(error.response.data.statusCode == 401)
                {
                    window.location.pathname = `login`;
                }
                else{
                    meuAlert(`${error.message}`, "error");
                }  
            }
            else{
                meuAlert(`${error.message}`, "error");
            }            
        });

        // pegar dados esic
        axios.get(`${base_url}/esic`, {
            "headers": {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }})
        .then(async response => { 

            // eslint-disable-next-line
            if((response.data.statusCode != undefined) && (response.data.statusCode != 200)){
                //window.location.pathname = `mensagem/${response.data.message}`;
                meuAlert(`${response.data.message}`, "error");
            }

            // eslint-disable-next-line 
            if(response.data.length == 0)
            {
                meuAlert("Não há dados.", "error");         
                document.getElementById("temporario").innerHTML = null;     
            }
            else
            {
                //console.log(response.data);
                setEsicTables(response.data);
                document.getElementById("temporario").innerHTML = null;
            }            
        })    
        .catch(error => { 
            console.log(error.message);
            if(error.response)
            {
                // eslint-disable-next-line
                if(error.response.data.statusCode == 401)
                {
                    window.location.pathname = `login`;
                }
                else{
                    meuAlert(`${error.message}`, "error");
                }  
            }
            else{
                meuAlert(`${error.message}`, "error");
            }            
        });
                
        // pegar dados contato-util        
        axios.get(`${base_url}/contato-util`, {
            "headers": {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 

            // eslint-disable-next-line
            if((response.data.statusCode != undefined) && (response.data.statusCode != 200)){
                //window.location.pathname = `mensagem/${response.data.message}`;
                meuAlert(`${response.data.message}`, "error");
            }

            // eslint-disable-next-line 
            if(response.data.length == 0)
            {
                meuAlert("Não há dados.", "error");         
                document.getElementById("temporario").innerHTML = null;            
            }
            else
            {
                //console.log(response.data);
                setTables(response.data);
                document.getElementById("temporario").innerHTML = null;   
                
            }            
        })    
        .catch(error => { 
            console.log(error.message);
            if(error.response)
            {
                // eslint-disable-next-line
                if(error.response.data.statusCode == 401)
                {
                    window.location.pathname = `login`;
                }
                else{
                    meuAlert(`${error.message}`, "error");
                }  
            }
            else{
                meuAlert(`${error.message}`, "error");
            }            
        });
                
        document.getElementById("mensagem").innerHTML = "Processo em andamento, aguarde...";

        document.getElementById("A").click();   

        // eslint-disable-next-line
    },[refreshKey]); 

    // Excluir concurso
    async function ExcluirContato(id) {  

        if (window.confirm("Tem certeza que deseja excluir esse contato?")) {            
            // eslint-disable-next-line
            {handleOpen()} 
        
            const url = `${base_url}/contato-util/${id}`;
            await axios.delete(url, {
            "headers": {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            }})
            .then(response => { 
                //console.log(response);
                //console.log(response.data.id);

                document.getElementById("mensagem").innerHTML = "Operação finalizada!";
                // eslint-disable-next-line
                const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

                meuAlert("Excluído com sucesso!", "success");
                const redireciona = setInterval(function () {setRefreshKey(oldKey => oldKey +1); clearInterval(redireciona);}, 2000);     

            })
            .catch(error => { 
                // eslint-disable-next-line
                {handleClose()}
                meuAlert(`${error.message}`, "error");
                console.log(error.message);
                //console.log("Erro "+error.response.data.statusCode);
            })  
        }           
    }

    // Atualiza dados (sem docs)
    async function atualizaContatos(qual) {

        if(verificaVazios(qual))
        {
            //console.log(qual);
              
            if(qual == "faleconosco")
            {
                {handleOpen()} 

                const params = JSON.stringify({
                    "email": document.getElementById("emailFC").value,
                    "link_maps": document.getElementById("mapaFC").value,
                    "endereco": document.getElementById("enderecoFC").value,
                    "expediente": document.getElementById("expedienteFC").value,

                    "nome": "Prefeitura de Palmas",
                    "cnpj": "24851511000185",
                    "tipo": "PREFEITURA",
                    "dominio": "http://localhost:3000",
                    "cor_primaria": "#004348"
                });
    
                //console.log(params);
                
                const url = `${base_url}/contratante`;
                await axios.patch(url, params, {
                "headers": {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                }})
                .then(response => { 
                    //console.log(response);
                    //console.log(response.data.id);
    
                    document.getElementById("mensagem").innerHTML = "Alterado com sucesso!";
                    const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     
    
                    meuAlert("Alterado com sucesso!", "success");
                    //const redireciona = setInterval(function () {setRefreshKey(oldKey => oldKey +1); clearInterval(redireciona);}, 2000);     
    
                })
                .catch(error => { 
                    {handleClose()}
                    meuAlert(`${error.response.data.message[0]}`, "warning");
                    console.log(error.message);
                    console.log(error.response.data.message);
                    //console.log("Erro "+error.response.data.statusCode);
                })  
            }
            else if(qual == "ouvidoria")
            {

                //console.log("entrou");
                {handleOpen()} 

                const params = JSON.stringify({
                    "email": document.getElementById("emailOU").value,
                    "celular": String(document.getElementById("celularOU").value),
                    "fone": document.getElementById("foneOU").value,
                    "responsavel_id": String(document.getElementById("respOU").value)
                });
    
                //console.log(params);
                
                const url = `${base_url}/ouvidoria`;
                await axios.patch(url, params, {
                "headers": {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                }})
                .then(response => { 
                    //console.log(response);
                    //console.log(response.data.id);
    
                    document.getElementById("mensagem").innerHTML = "Alterado com sucesso!";
                    const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     
    
                    meuAlert("Alterado com sucesso!", "success");
                    //const redireciona = setInterval(function () {setRefreshKey(oldKey => oldKey +1); clearInterval(redireciona);}, 2000);     
    
                })
                .catch(error => { 
                    {handleClose()}
                    meuAlert(`${error.response.data.message[0]}`, "warning");
                    console.log(error.message);
                    console.log(error.response.data.message);
                    //console.log("Erro "+error.response.data.statusCode);
                })  
            } 
            else if(qual == "esic")
            {
                {handleOpen()} 

                const params = JSON.stringify({
                    "expediente": document.getElementById("expedienteES").value,
                    "email": document.getElementById("emailES").value,
                    "celular": String(document.getElementById("celularES").value),
                    "fone": String(document.getElementById("foneES").value),
                    "responsavel_id": document.getElementById("respES").value,
                });
    
                //console.log(params);
                
                const url = `${base_url}/esic`;
                await axios.patch(url, params, {
                "headers": {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                }})
                .then(response => { 
                    //console.log(response);
                    //console.log(response.data.id);
    
                    document.getElementById("mensagem").innerHTML = "Alterado com sucesso!";
                    const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     
    
                    meuAlert("Alterado com sucesso!", "success");
                    //const redireciona = setInterval(function () {setRefreshKey(oldKey => oldKey +1); clearInterval(redireciona);}, 2000);     
    
                })
                .catch(error => { 
                    {handleClose()}
                    meuAlert(`${error.response.data.message[0]}`, "warning");
                    console.log(error.message);
                    console.log(error.response.data.message);
                    //console.log("Erro "+error.response.data.statusCode);
                })  
            }
        }
    }

    function verificaVazios(verificar) {

        if(verificar == "faleconosco")
        {
            if(document.getElementById("emailFC").value == ""){meuAlert("Email não preenchido.", "warning");}
            else if(document.getElementById("mapaFC").value == ""){meuAlert("Link do mapa não preenchido.", "warning");}
            else if(document.getElementById("enderecoFC").value == ""){meuAlert("Endereço não preenchido.", "warning");}
            else if(document.getElementById("expedienteFC").value == ""){meuAlert("Expediente não preenchido.", "warning");}
            else
            {
                return true;
            } 
        }      
    }

    function marcaTodos() {        

        var itens = 0;

        if(document.getElementById("checkGeral").checked)
        {
            while(document.getElementById(itens))
            {
                document.getElementById(itens).checked = true;
                itens++;
            }
        }
        else
        {
            while(document.getElementById(itens))
            {
                document.getElementById(itens).checked = false;
                itens++;
            }
        }
    }
    
    async function opcoesMarcados(acao) {        

        //var acao = document.getElementById("acao").value;
        //alert(acao);
     
        var itens = 0;
        var passadas =0;
        while(document.getElementById(itens)){itens++;}

        // eslint-disable-next-line
        {handleOpen()}

        for(var valor=0; valor<itens; valor++)
        {
            // eslint-disable-next-line
            if((acao == "excluir") && document.getElementById(valor).checked)
            {
                //ExcluirConcursoSemConfirmar(tables[valor].id);
                //alert(tables[itens].id);

                const url = `${base_url}/contato-util/${tables[valor].id}`;
                await axios.delete(url, {
                "headers": {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                }})
                .then(response => { 
                    //console.log(response);
                    //console.log(response.data.id);
                    //alert("Excluído com sucesso!")
                    //console.log("Resposta ok");
                    //window.location.pathname = `/concurso`;
                })
                .catch(error => {
                    // eslint-disable-next-line
                    {handleClose()}
                    meuAlert(`${error.message}`, "error");
                    console.log(error.message);
                    //console.log("Erro "+error.response.data.statusCode);
                    passadas = -100;
                })  
            }               
            // eslint-disable-next-line
            if((acao == "inativar") && document.getElementById(valor).checked)
            {
                const params = JSON.stringify({
                    "status": false,
                });

                console.log(params);
                
                const url = `${base_url}/contato-util/${tables[valor].id}`;
                await axios.patch(url, params, {
                "headers": {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                }})
                .then(response => { 
                    //console.log(response);
                    //console.log(response.data.id);
                    //alert("Inativado com sucesso!")
                    //console.log("Resposta ok");
                    //window.location.pathname = `concurso/editar/${response.data.id}`;
                })
                .catch(error => { 
                    // eslint-disable-next-line
                    {handleClose()}
                    meuAlert(`${error.message}`, "error");
                    console.log(error.message);
                    //console.log("Erro "+error.response.data.statusCode);
                    passadas = -100;
                })  
            } 
            // eslint-disable-next-line 
            if((acao == "ativar") && document.getElementById(valor).checked)
            {
                const params = JSON.stringify({
                    "status": true,
                });

                console.log(params);
                
                const url = `${base_url}/contato-util/${tables[valor].id}`;
                await axios.patch(url, params, {
                "headers": {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                }})
                .then(response => { 
                    //console.log(response);
                    //console.log(response.data.id);
                    //alert("Ativado com sucesso!")
                    //console.log("Resposta ok");
                    //window.location.pathname = `concurso/editar/${response.data.id}`;
                })
                .catch(error => { 
                    // eslint-disable-next-line
                    {handleClose()}
                    meuAlert(`${error.message}`, "error");
                    console.log(error.message);
                    //console.log("Erro "+error.response.data.statusCode);
                    passadas = -100;
                })  
            }  
            passadas++;    
            // eslint-disable-next-line
            if(itens == passadas)
            {
                document.getElementById("mensagem").innerHTML = "Operação finalizada!";                
                // eslint-disable-next-line
                const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);                

                meuAlert("Operação finalizada!", "success");
                const redireciona = setInterval(function () {setRefreshKey(oldKey => oldKey +1); clearInterval(redireciona);}, 2000);     
            
                document.getElementById("checkGeral").checked = false;
                marcaTodos();
            }
        }

    }

    function mostraStatus(status) {  
        if(status)
        {return <a className="true">< BsFillCheckCircleFill /></a>;}
        else
        { return <a className="false">< GoCircleSlash /></a>;}
    }

    function menuContato(menuId) {

        document.getElementById("A").style = null;
        document.getElementById("C").style = null;
        document.getElementById("D").style = null;

        document.getElementById(menuId).style = optionClicado;       
        //console.log("Selecionou o botão " + id);

        // eslint-disable-next-line
        if(menuId != "A")
        {
            document.getElementById("contatosuteis").setAttribute('hidden', '');
            document.getElementById("botaoExcluir").setAttribute('hidden', '');
            document.getElementById("botaoAtivar").setAttribute('hidden', '');
            document.getElementById("botaoInativar").setAttribute('hidden', '');
            document.getElementById("botaoNovo").setAttribute('hidden', '');            
        }
        else{
            document.getElementById("contatosuteis").removeAttribute('hidden');
            document.getElementById("botaoExcluir").removeAttribute('hidden');
            document.getElementById("botaoAtivar").removeAttribute('hidden');
            document.getElementById("botaoInativar").removeAttribute('hidden');
            document.getElementById("botaoNovo").removeAttribute('hidden');
        }

        // eslint-disable-next-line
        if(menuId != "C"){
            document.getElementById("esic").setAttribute('hidden', '');
        }
        else{
            document.getElementById("esic").removeAttribute('hidden');       
        }

        // eslint-disable-next-line
        if(menuId != "D"){
            document.getElementById("ouvidoria").setAttribute('hidden', '');
        }
        else{
            document.getElementById("ouvidoria").removeAttribute('hidden');       
        }

    } 
    

    return(
        <>   
           <Sidebar>
                <Box sx={{ width: '100%'}}>
                        <Collapse in={alertAberto}>
                            <Alert
                            severity={tipoAlerta}
                            variant="filled"
                            action={
                                <IconButton
                                aria-label="close"
                                size="small"
                                color="inherit"
                                onClick={() => {
                                    setAlertAberto(false);
                                }}
                                >
                                <CloseIcon/>
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                            >
                            {alertMensagem}
                            </Alert>
                        </Collapse>
                    </Box>
                    
                    <div className="tituloPagina"> 
                        <p id="titu" >Contatos</p>
                    </div>   
                    
                    <div className="linha menuPadrao">
                        <li> 
                            <button id="A" onClick={(e) => menuContato(e.target.id)}>Contatos Úteis</button>
                            <button id="C" onClick={(e) => menuContato(e.target.id)}>E-SIC</button>
                            <button id="D" onClick={(e) => menuContato(e.target.id)}>Ouvidoria</button>
                        </li>  
                    </div>

                    
                    <div className="optionNovo" >
                        <button id="botaoNovo" onClick={(e) => window.location.pathname = `contato/editar/novo`}>Novo Contato</button>
                    </div>  

                    <div className="optionsBotoes" >
                        <button id="botaoAtivar" onClick={(e) => opcoesMarcados("ativar")}>Ativar</button> 
                        <button id="botaoInativar" onClick={(e) => opcoesMarcados("inativar")}>Inativar</button>      
                        <button id="botaoExcluir" onClick={(e) => opcoesMarcados("excluir")}>Excluir</button>
                    </div>
                   
                    <div className="tabelaPadrao" id="contatosuteis">
                        <TableContainer className="modal-content">
                            <Table sx={{minWidth: 650}} arial-label="caption table" className="table">
                                <TableHead>
                                    <TableRow>             
                                        <TableCell><input type="checkbox" id="checkGeral" onClick={(e) => marcaTodos()}></input></TableCell>
                                        <TableCell>Nome</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Celular</TableCell>
                                        <TableCell>Fone</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Endereço</TableCell>
                                        <TableCell>Editar</TableCell>
                                        <TableCell>Excluir</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tables && tables.map((item,index) => (
                                        <TableRow>
                                            <TableCell><input type="checkbox" id={index} ></input></TableCell>
                                            <TableCell>{item.nome || '--'}</TableCell>
                                            <TableCell>{mostraStatus(item.status) || '--'}</TableCell>                                            
                                            <TableCell>{item.celular || '--'}</TableCell>
                                            <TableCell>{item.fone || '--'}</TableCell>
                                            <TableCell>{item.email || '--'}</TableCell>
                                            <TableCell>{item.endereco.slice(0, 10)+"..." || '--'}</TableCell>                                        
                                        <TableCell><a target="_self" href={"contato/editar/"+item.id}>< BiEdit /></a></TableCell>
                                        <TableCell><a target="_self" href="#" onClick={(e) => ExcluirContato(item.id)}>< MdDeleteForever /></a></TableCell>
                                        </TableRow>
                                    ))}  
                                </TableBody>
                            </Table>                    
                        </TableContainer>
                    </div>   

                    <div className="tabelaPadrao" id="esic">
                        <TableContainer className="modal-content">
                            <Table sx={{minWidth: 650}} arial-label="caption table" className="table">
                                <TableHead>
                                    <TableRow>             
                                        <TableCell>ID</TableCell>
                                        <TableCell>Editar</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Esictables && Esictables.map((item,index) => (
                                        <TableRow>
                                        <TableCell>{item.id || '--'}</TableCell>                                 
                                        <TableCell><a target="_self" href={"esic/editar/"+item.id}>< BiEdit /></a></TableCell>
                                        </TableRow>
                                    ))}  
                                </TableBody>
                            </Table>                    
                        </TableContainer>
                    </div>   

                    <div className="tabelaPadrao" id="ouvidoria">
                        <TableContainer className="modal-content">
                            <Table sx={{minWidth: 650}} arial-label="caption table" className="table">
                                <TableHead>
                                    <TableRow>             
                                        <TableCell>ID</TableCell>
                                        <TableCell>Editar</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Ouvidoriatables && Ouvidoriatables.map((item,index) => (
                                        <TableRow>
                                        <TableCell>{item.id || '--'}</TableCell>                                 
                                        <TableCell><a target="_self" href={"ouvidoria/editar/"+item.id}>< BiEdit /></a></TableCell>
                                        </TableRow>
                                    ))}  
                                </TableBody>
                            </Table>                    
                        </TableContainer>
                    </div>   
                    
                    <div className="loading">
                        <div id="temporario">{loading()}</div>
                    </div>
            </Sidebar>    
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                ><p id="mensagem" ></p>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </>   
    );
}

export default Contato;