import { ChangeEvent, useState } from 'react';
import {base_url} from '../../../environment'
import axios from 'axios';
import { useParams } from "react-router-dom";
import './uploadObra.css'

import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
//import Button from '@mui/material/Button';

const FileUploadMultiple  = () => {

  const [alertAberto, setAlertAberto] = React.useState(false);
  const [alertMensagem, setAlertMensagem] = React.useState('');
  const [tipoAlerta, setTipoAlerta] = React.useState('');

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
      setOpen(false);
  };
  const handleOpen = () => {
      setOpen(true);
  };

  function meuAlert(texto, tipo) {
      setTipoAlerta(tipo);
      setAlertMensagem(texto);
      ///window.location = `#root`;
      setAlertAberto(true);
  }

  const {id} = useParams();
  const [fileList, setFileList] = useState<FileList | null>(null);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFileList(e.target.files);
  };

  const handleUploadClick = async () => {
    if (!fileList) {
      return;
    }

    // 👇 Create new FormData object and append files
    const data = new FormData();
    //files.forEach((file, i) => {
    files.forEach((file) => {
      //data.append(`file-${i}`, file, file.name);
      data.append(`anexo`, file, file.name);
    });

    // 👇 Uploading the files using the fetch API to the server

    const url = `${base_url}/obra/${id}/anexo`;
    var token = localStorage.getItem('LOCAL_STORAGE_KEY__ACCESS_TOKEN');

    document.getElementById("mensagem").innerHTML = "Processo em andamento, aguarde...";
    // eslint-disable-next-line
    {handleOpen()}   
    
    await axios.post(url, data, 
        { headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 
            //console.log(response);
            //console.log("Resposta ok");
            //window.location.pathname = `obra/editar/${response.data.id}`;

            document.getElementById("mensagem").innerHTML = "Arquivo inserido com sucesso!";
            // eslint-disable-next-line
            const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

            meuAlert("Arquivo inserido com sucesso!", "success");
            const redireciona = setInterval(function () {
              //setRefreshKey(oldKey => oldKey +1); 
              clearInterval(redireciona);
              window.location.reload();
            }, 2000);     

        })
        .catch(error => { 
          // eslint-disable-next-line
          {handleClose()}            
          console.log(error.message);
          //console.log("Erro "+error.response.data.statusCode);
          if(error.response)
          {
            // eslint-disable-next-line
            if(error.response.data.statusCode == 415)
            {
              //alert("Formato de arquivo incorrreto! São aceitos apenas documentos.")
              meuAlert("Formato de arquivo incorrreto! São aceitos apenas documentos.", "warning");
            }
            else
            {
              meuAlert(`${error.message}`, "error");
            }
          }
          else{
            meuAlert(`${error.message}`, "error");
          }            
        })

  };

  // 👇 files is not an array, but it's iterable, spread to get an array of files
  const files = fileList ? [...fileList] : [];
  //console.log(files);

  return (
    <>  
      <div className='alertUpload'>
        <Box sx={{ width: '100%'}}>
          <Collapse in={alertAberto}>
              <Alert
              severity={tipoAlerta}
              variant="filled"
              action={
                  <IconButton
                  aria-label="close"
                  size="small"
                  color="inherit"
                  onClick={() => {
                      setAlertAberto(false);
                  }}
                  >
                  <CloseIcon/>
                  </IconButton>
              }
              sx={{ mb: 2 }}
              >
              {alertMensagem}
              </Alert>
          </Collapse>
        </Box>
      </div>      

      <div>
        <input type="file" onChange={handleFileChange} multiple />

        <ul>
          {files.map((file, i) => (
            <li key={i}>
              {(file.name.split(".").slice(0,1)).join().substring(0,15)+"..."} - Tipo: {file.type}
            </li>
          ))}
        </ul>

        <button onClick={handleUploadClick}>Salvar alterações</button>
      </div>

      <div className='uploadWait'>
          <Backdrop
              sx={{ color: '#FFF', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={open}
          ><p id="mensagem" ></p>
              <CircularProgress color="inherit" />
          </Backdrop>
      </div>
    </> 
  ); 
}

export default FileUploadMultiple;