import { useEffect, useState } from "react";
import { base_url } from "../environment";
import axios from "axios";

export default function useProfile() {
  const token = localStorage.getItem('LOCAL_STORAGE_KEY__ACCESS_TOKEN');
  const url = `${base_url}/usuario/perfil`;
  const [user, setUser] = useState(null)
  
  useEffect(() => {
    const handle = async () => {
      try {
        const response = await axios.get(url, {
          "headers": {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          }
        });
      
        setUser(response.data)
      } catch (error) {
        window.location.pathname = `login`;
      }  
    }
    handle()
  }, [token, url])

  return user  
}