import { useEffect, useRef, useState } from "react";
import '../../../components/Geral/geral.css';
import './ManifestacaoEditar.css'
import carregando from '../../../components/Geral/loading.gif';
import { verificaLogin, fazerLogout } from '../../../context/LoginContext';
import {base_url, midia_url, foto_default} from '../../../environment'
import axios from 'axios';
import Sidebar from '../../../components/Menu/Sidebar';
import { useParams } from "react-router-dom";
import { Editor } from '@tinymce/tinymce-react';
import FileUploadMultiple from './uploadManifestacao.tsx'

import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
//import Button from '@mui/material/Button';

var unavez = true, umavez = true;
var optionClicado = "background-color: var(--mainColor); color: var(--textColor2);"

function PlenariaEditar(){
    setInterval(function () {verificaLogin();}, 1000);

    const [refreshKey, setRefreshKey] = useState(0);
    
    function atualiza() {
        setRefreshKey(oldKey => oldKey +1); 
    }

    const [alertAberto, setAlertAberto] = React.useState(false);
    const [alertMensagem, setAlertMensagem] = React.useState('');
    const [tipoAlerta, setTipoAlerta] = React.useState('');

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    function meuAlert(texto, tipo) {
        setTipoAlerta(tipo);
        setAlertMensagem(texto);
        window.location = `#root`;
        setAlertAberto(true);
    }

    function loading() {return <div id='loading'><img src={carregando} alt="Carregando"/></div>; }    
    setInterval(function () {verificaLogin();}, 1000);
    
    var [textoInicial, setTextoInicial] = useState('');    

    // Clicar no primeiro botao e colocar texto inicial
    useEffect(() => {
        
        document.getElementById(0).click();
        setTextoInicial("<p>Escreva seu texto aqui. Aumente o tamanho da área de texto no canto inferior direito.</p>");
    
    },[]);

    const {id} = useParams();
    var token = localStorage.getItem('LOCAL_STORAGE_KEY__ACCESS_TOKEN');
        
    // pegar dados se não for novo
    useEffect(() => {
              
        if(id != "novo")
        {
            axios.get(`${base_url}/manifestacao/${id}`, 
            { headers: {
                'Authorization': `Bearer ${token}`,
            }})
            .then(async response => {             
                //console.log("Resposta ok");
                //console.log(response);
                //console.log(response.data);
            
                document.getElementById("titulo").value = response.data.titulo;
                document.getElementById("endereco_manifestante").value = response.data.endereco_manifestante;
                document.getElementById("protocolo").value = response.data.protocolo;
                document.getElementById("data").value = new Date(response.data.created_at).toISOString().split('T')[0];    

                if(response.data.anonimato == true){
                    document.getElementById("anonimatoAtivo").checked = true;
                }
                else
                {
                    document.getElementById("anonimatoInativo").checked = true;
                }

                if(response.data.sigilo == true){
                    document.getElementById("sigiloAtivo").checked = true;
                }
                else
                {
                    document.getElementById("sigiloInativo").checked = true;
                }

                document.getElementById("nome_manifestante").value = response.data.nome_manifestante;
                document.getElementById("identidade_manifestante").value = response.data.identidade_manifestante;
                document.getElementById("email_manifestante").value = response.data.email_manifestante;
                document.getElementById("telefone_manifestante").value = response.data.telefone_manifestante;

                if(response.data.Mensagem.conteudo){setTextoInicial(response.data.Mensagem.conteudo)};                
                
                await tipoManifes(response.data.tipo);
                await statusManifes(response.data.status);

                //pegar mensagens
                if(umavez){
                    document.getElementById("manifestacaoMensagens").innerHTML = null;   
                    for(let i=0; i<response.data.Mensagem.length; i++){
                                          
                        var divGeral = document.getElementById("manifestacaoMensagens");
                        
                        const divContainter = document.createElement("div");
        
                        const usuario = document.createElement("p");

                        if(response.data.Mensagem[i].Usuario != null) 
                        {
                            usuario.innerHTML = 'Usuário: ' + response.data.Mensagem[i].Usuario.nome;
                        }
                        else
                        {
                            usuario.innerHTML = 'Usuário: ' + response.data.nome_manifestante;
                        }    
                        
                        const data = document.createElement("p");
                        data.innerHTML = 'Data: ' +  ((new Date(response.data.Mensagem[i].created_at).toISOString().split('T')[0]).split("-").reverse().join("/") || 'Data indefinida.');
                        
                        const conteudo = document.createElement("p");

                        if(response.data.Mensagem[i].conteudo != null) 
                        {
                            conteudo.innerHTML = 'Conteúdo da mensagem: ' + response.data.Mensagem[i].conteudo;
                        }
                        else
                        {
                            conteudo.innerHTML = 'Conteúdo da mensagem: Sem mensagens.';
                        }  
                                           
                        const anexos = document.createElement("p");
                        anexos.innerHTML = 'Anexos:';
                        
                        divContainter.appendChild(usuario);         
                        divContainter.appendChild(data);      
                        divContainter.appendChild(conteudo); 
                        divContainter.appendChild(anexos); 

                        for(let y=0; y<response.data.Mensagem[i].Anexo.length; y++){

                            const a = document.createElement("a");
                            //ou button?
                            a.innerHTML = response.data.Mensagem[i].Anexo[y].titulo;
                            a.target = "_blank";
                            a.href = midia_url+response.data.Mensagem[i].Anexo[y].anexo;
                            
                            divContainter.appendChild(a); 
                        }
                            
                        divGeral.appendChild(divContainter);
                        
                    };
                    umavez = false;
                } 
            })
            .catch(error => { 
                console.log(error.message);
                if(error.response)
                {
                    if((error.response.data.statusCode == 404) || (error.response.data.statusCode == 400))
                    {
                        if(unavez)
                        {
                            meuAlert("Comissão não existente.", "warning");
                            //window.location.pathname = `concurso/editar/novo`;
                            unavez = false;
                        }
                        
                    }
                    else{
                        meuAlert(`${error.message}`, "error");
                    }
                    if(error.response.data.statusCode == 401)
                    {
                        window.location.pathname = `login`;
                    }
                }   
                else{
                    meuAlert(`${error.message}`, "error");
                }            
            });
        }
        document.getElementById("notifica").innerHTML = "Processo em andamento, aguarde...";
               
    },[refreshKey]); 

    function verificaVazios() {        
        if(document.getElementById("protocolo").value == ""){meuAlert("Protocolo não preenchido.", "warning");}
        else if(document.getElementById("tipo").value == ""){meuAlert("Tipo de Manifestação não preenchida.", "warning");}
        else if(document.getElementById("titulo").value == ""){meuAlert("Título de Manifestação não preenchida.", "warning");}
        else if(document.getElementById("status").value == ""){meuAlert("Status da Manifestação não preenchida.", "warning");}
        else if(document.getElementById("data").value == ""){meuAlert("Data da Manifestação não preenchida.", "warning");}
        else if(document.getElementById("nome_manifestante").value == ""){meuAlert("Nome do manifestante não preenchido.", "warning");}
        else if(document.getElementById("identidade_manifestante").value == ""){meuAlert("Identidade do manifestante não preenchida.", "warning");}
        else if(document.getElementById("email_manifestante").value == ""){meuAlert("Email do manifestante não preenchido.", "warning");}
        else if(document.getElementById("telefone_manifestante").value == ""){meuAlert("Telefone do manifestante não preenchido.", "warning");}
        else if(document.getElementById("endereco_manifestante").value == ""){meuAlert("Endereço do manifestante não preenchido.", "warning");}
        else{return true;}   
    }

    function menuSec(menuId) {
        for(let i=0; i<=1; i++){
            document.getElementById(i).style = null;
        }
        document.getElementById(menuId).style = optionClicado;       
        //console.log("Selecionou o botão " + id);

        // eslint-disable-next-line
        if(menuId != 0)
        {
            document.getElementById("cadastroManifestacao").setAttribute('hidden', '');
        }
        else
        {
            document.getElementById("cadastroManifestacao").removeAttribute('hidden');
            document.getElementById("cadastroMensagem").setAttribute('hidden', '');            
        }

        // eslint-disable-next-line
        if(menuId != 1){
            document.getElementById("cadastroMensagem").setAttribute('hidden', '');
        }
        else{
            if(id == "novo")
            {
                meuAlert("Cadastre e salve o concurso antes de inserir anexos.", "info");
                //alert("Cadastre e salve o concurso antes de inserir anexos.")
                document.getElementById(0).click();   
            }
            else
            {
               document.getElementById("cadastroMensagem").removeAttribute('hidden');
                document.getElementById("cadastroManifestacao").setAttribute('hidden', ''); 
            }            
        }

    }  

    // Função salvar começa aqui
    const editorRef = useRef(null);
    const pegaConteudo = () => {
        if (editorRef.current) {
        console.log(editorRef.current.getContent());
    }}
    
    function selectFuncao(){
        
            if(verificaVazios())
            {
                atualizaManifestacao();
            }; 
    }

    // Atualiza manifestacao
    async function atualizaManifestacao() {
          
        {handleOpen()} 

        const params = JSON.stringify({
            "titulo": document.getElementById("titulo").value,
            "anonimato" : document.querySelector('input[name="statusAnonimato"]:checked').value, 
            "sigilo" : document.querySelector('input[name="statusSigilo"]:checked').value, 
            "tipo": document.getElementById("tipo").value,
            "nome_manifestante": document.getElementById("nome_manifestante").value,
            "identidade_manifestante": document.getElementById("identidade_manifestante").value,
            "email_manifestante": document.getElementById("email_manifestante").value,
            "telefone_manifestante": document.getElementById("telefone_manifestante").value,
            "endereco_manifestante": document.getElementById("endereco_manifestante").value,
            "status": document.getElementById("status").value
        });

        //console.log(params);
        
        const url = `${base_url}/manifestacao/${id}`;
        await axios.patch(url, params, {
        "headers": {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 
            //console.log(response);
            //console.log(response.data.id);

            document.getElementById("notifica").innerHTML = "Alterado com sucesso!";
            const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

            meuAlert("Alterado com sucesso!", "success");
            const redireciona = setInterval(function () {atualiza(); clearInterval(redireciona);}, 2000);     

        })
        .catch(error => { 
            {handleClose()}
            meuAlert(`${error.response.data.message}`, "warning");
            console.log(error.message);
            console.log(error.response.data.message);
            //console.log("Erro "+error.response.data.statusCode);
        })     
        
    }
    
    // Excluir manifestacao
    async function ExcluirManifestacao() {  

        if (window.confirm("Tem certeza que deseja excluir essa manifestação?")) {        
            
            {handleOpen()} 
         
            const url = `${base_url}/manifestacao/${id}`;
            await axios.delete(url, {
            "headers": {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            }})
            .then(response => { 
                //console.log(response);
                //console.log(response.data.id);    
               
                document.getElementById("notifica").innerHTML = "Excluído com sucesso!";
                const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

                meuAlert("Excluído com sucesso!", "success");
                const redireciona = setInterval(function () {
                    clearInterval(redireciona);
                    window.location.pathname = `plenaria/editar/novo`;
                }, 2000);     

            })
            .catch(error => { 
                {handleClose()}
                meuAlert(`${error.message}`, "error");
                console.log(error.message);
                //console.log("Erro "+error.response.data.statusCode);
            })  
        }           
    }

    // indeferir manifestacao
    async function IndeferirManifestacao() {  

            
            {handleOpen()} 

            const params = JSON.stringify({
                "id": id
            });
         
            const url = `${base_url}/manifestacao/indeferir/${id}`;
            await axios.patch(url, params, {
            "headers": {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            }})
            .then(response => { 
                //console.log(response);
                //console.log(response.data.id);    
               
                document.getElementById("notifica").innerHTML = "Indeferido com sucesso!";
                const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

                meuAlert("Indeferido com sucesso!", "success");
                const redireciona = setInterval(function () {atualiza(); clearInterval(redireciona);}, 2000);     
   

            })
            .catch(error => { 
                {handleClose()}
                meuAlert(`${error.message}`, "error");
                console.log(error.message);
                //console.log("Erro "+error.response.data.statusCode);
            })  
                   
    }

    function tipoManifes(valor){
        for(let i=0; i<document.getElementById("tipo").children.length; i++){
            if(document.getElementById("tipo").children[i].value == valor){
                document.getElementById("tipo").children[i].selected = true;
            }        
        }
    }

    function statusManifes(valor){
        for(let i=0; i<document.getElementById("status").children.length; i++){
            if(document.getElementById("status").children[i].value == valor){
                document.getElementById("status").children[i].selected = true;
            }        
        }
    }

    return(
        <>   
            <Sidebar page={{ name: 'Manifestações', route: '/manifestacao' }}>
                <Box sx={{ width: '100%'}}>
                    <Collapse in={alertAberto}>
                        <Alert
                        severity={tipoAlerta}
                        variant="filled"
                        action={
                            <IconButton
                            aria-label="close"
                            size="small"
                            color="inherit"
                            onClick={() => {
                                setAlertAberto(false);
                            }}
                            >
                            <CloseIcon/>
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        {alertMensagem}
                        </Alert>
                    </Collapse>
                </Box>
                
                <div className="tituloPagina"><p id="titu" >Editar Manifestação</p></div>   

                <div className="botaoExcluirEspec" >
                    {id !== "novo" && (
                        <div className="botaoExcluirEspec">
                            <button onClick={ExcluirManifestacao}>Excluir Manifestação</button>
                        </div>
                    )}

                </div>


                <div className="botaoExcluirEspec" >
                    <button onClick={IndeferirManifestacao}>Indeferir esta Manifestação</button>
                </div>

                <div className="semLinha menuPadrao">
                    <li>
                        <button id="0" onClick={(e) => menuSec(e.target.id)}>Informações</button>                        
                        <button id="1" onClick={(e) => menuSec(e.target.id)}>Mensagens</button>
                    </li>  
                </div>

                <div className="formulario" >
                    <div id="cadastroManifestacao">
                        <div className="manifestacaoEditar formRedondo formularioGeral">
                            
                            <div className="formularioGeralTitulo"><p>Título da manifestação</p><p>Tipo de manifestação</p></div>
                            <input type="text" name="titulo" id="titulo" autoComplete="off" ></input>
                            <select name="tipo" id="tipo">
                                <option value="CONSULTA">Consulta</option>
                                <option value="DENUNCIA">Denúncia</option>
                                <option value="DUVIDA">Dúvida</option>
                                <option value="ELOGIO">Elogio</option>
                                <option value="RECLAMACAO">Reclamação</option>
                                <option value="SOLICITACAO">Solicitação</option>
                                <option value="SUGESTAO">Sugestão</option>
                            </select>

                            <div className="formularioGeralTitulo"><p>Status da manifestação</p><p>Protocolo da manifestação</p></div>
                            <select name="status" id="status">
                                <option value="RESPONDIDO">Respondido</option>
                                <option value="EM_TRAMITACAO">Em tramitação</option>
                                <option value="FORA_DO_PRAZO">Fora do prazo</option>
                                <option value="RESPONDIDO_FORA_DO_PRAZO">Respondido fora do prazo</option>
                                <option value="INDEFERIDO">Indeferido</option>
                            </select>
                            <input type="text" name="protocolo" id="protocolo" autoComplete="off"  readOnly></input>
                                        
                            <div className="alinhaDireita">                               

                                <div className="novoRadio statusRadio">                             
                                    <div className="formularioGeralTitulo"><p>Sigilo</p></div> 
                                    <input type="radio" id="sigiloAtivo" name="statusSigilo" value="true"checked></input>
                                    <label for="ativo">Sim</label>

                                    <input type="radio" id="sigiloInativo" name="statusSigilo" value="false"></input>
                                    <label for="inativo">Não</label>
                                </div>  

                                <div className="formularioGeralTitulo"><p>Identidade manifestante</p></div>
                                <input type="text" name="identidade_manifestante" id="identidade_manifestante" autoComplete="off"  ></input>
                                
                                <div className="formularioGeralTitulo"><p>Telefone manifestante</p></div>
                                <input type="number" name="telefone_manifestante" id="telefone_manifestante" autoComplete="off"  ></input>

                                <div className="formularioGeralTitulo"><p>Manifestação criada em</p></div>
                                <input type="date" name="data" id="data" autoComplete="off"  readOnly></input>
                                                                
                            </div>
                                                            
                            <div className="alinhaEsquerda">   

                                <div className="novoRadio statusRadio">                             
                                    <div className="formularioGeralTitulo"><p>Anonimato?</p></div> 
                                    <input type="radio" id="anonimatoAtivo" name="statusAnonimato" value="true"checked></input>
                                    <label for="ativo">Sim</label>

                                    <input type="radio" id="anonimatoInativo" name="statusAnonimato" value="false"></input>
                                    <label for="inativo">Não</label>
                                </div>  
                                
                                <div className="formularioGeralTitulo"><p>Nome manifestante</p></div>
                                <input type="text" name="nome_manifestante" id="nome_manifestante" autoComplete="off"  ></input>

                                <div className="formularioGeralTitulo"><p>Email manifestante</p></div>
                                <input type="text" name="email_manifestante" id="email_manifestante" autoComplete="off"  ></input>

                                <div className="formularioGeralTitulo"><p>Endereço manifestante</p></div>
                                <input type="text" name="endereco_manifestante" id="endereco_manifestante" autoComplete="off"  ></input>

                            </div>                            
                            
                            <button onClick={selectFuncao}>Salvar alterações</button>

                        </div>                        
                        
                    </div>                    
                </div>

                <div className="cadastroDoc">
                    <div id="cadastroMensagem">
                        <div className="cadastroDocInternoManifes formRedondo formularioGeral">
                            <p>Mensagens da manifestação</p>
                            <div id="manifestacaoMensagens" className="anexos anexosManifestacao">
                                {loading()}
                            </div>   

                            <span>Digite aqui sua resposta:</span>
                            <FileUploadMultiple/>
                        </div>
                    </div>                        
                </div>
            </Sidebar>
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                ><p id="notifica" >vghfgh</p>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </>   
    );
}

export default PlenariaEditar;