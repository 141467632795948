import { useEffect, useState} from "react";
import '../../../components/Geral/geral.css';
import './ContratanteEditar.css'
import '../../../components/Geral/tabela.css';
import carregando from '../../../components/Geral/loading.gif';
import {base_url} from '../../../environment'
import Sidebar from '../../../components/Menu/Sidebar';
import { useParams } from "react-router-dom";
import axios from 'axios';
import { verificaLogin} from '../../../context/LoginContext';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {BiEdit} from "react-icons/bi"
import {MdDeleteForever} from "react-icons/md"
import {BsFillCheckCircleFill} from "react-icons/bs"
import {GoCircleSlash} from "react-icons/go"

import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
//import Button from '@mui/material/Button';

var optionClicado = "background-color: var(--mainColor); color: var(--textColor2);"
const modulos = [];

function Contato(){
    setInterval(function () {verificaLogin();}, 1000);

    const [tables, setTables] = useState();
    const [refreshKey, setRefreshKey] = useState(0);

    const [alertAberto, setAlertAberto] = React.useState(false);
    const [alertMensagem, setAlertMensagem] = React.useState('');
    const [tipoAlerta, setTipoAlerta] = React.useState('');


    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    function meuAlert(texto, tipo) {
        setTipoAlerta(tipo);
        setAlertMensagem(texto);
        window.location = `#root`;
        setAlertAberto(true);
    }
    
    const {id} = useParams();
    var token = localStorage.getItem('LOCAL_STORAGE_KEY__ACCESS_TOKEN');

    function loading() {
        return <div id='loading'><img src={carregando} alt="Carregando"/></div>;
    }

    // pegar dados do modulo
    useEffect(() => {
        axios.get(`${base_url}/modulos`, {
        "headers": {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 

            // eslint-disable-next-line
            if((response.data.statusCode != undefined) && (response.data.statusCode != 200)){
                //window.location.pathname = `mensagem/${response.data.message}`;
                meuAlert(`${response.data.message}`, "error");
            }

            // eslint-disable-next-line 
            if(response.data.length == 0)
            {
                meuAlert("Não há dados.", "error");         
                document.getElementById("temporario").innerHTML = null;      
            }
            else
            {
                //console.log(response.data);
                for(let i=0; i<response.data.length; i++){
                    modulos.push(response.data[i]);
                }       
                //console.log(modulos);
            }            
        })    
        .catch(error => { 
            console.log(error.message);
            if(error.response)
            {
                // eslint-disable-next-line
                if(error.response.data.statusCode == 401)
                {
                    window.location.pathname = `login`;
                }
                else{
                    meuAlert(`${error.message}`, "error");
                }  
            }
            else{
                meuAlert(`${error.message}`, "error");
            }            
        });

    },[]); 
    
    // pegar dados
    useEffect(() => {

        if(id != "novo")
        {
        
            // pegar dados contratante
            axios.get(`${base_url}/contratante/${id}`, {
                "headers": {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }})
            .then(async response => { 

                // eslint-disable-next-line
                if((response.data.statusCode != undefined) && (response.data.statusCode != 200)){
                    //window.location.pathname = `mensagem/${response.data.message}`;
                    meuAlert(`${response.data.message}`, "error");
                }

                // eslint-disable-next-line 
                if(response.data.length == 0)
                {
                    //console.log("entrou aqui");
                    meuAlert("Não há dados.", "error");         
                    document.getElementById("temporario").innerHTML = null;      
                }
                else
                {
                    //console.log(response.data);

                    //dados
                    document.getElementById("nome").value = (response.data.nome  || "");
                    document.getElementById("cnpj").value = (response.data.cnpj  || "");
                    document.getElementById("endereco").value = (response.data.endereco  || "");
                    document.getElementById("email").value = (response.data.email  || "");
                    document.getElementById("celular").value = (response.data.celular  || "");
                    document.getElementById("fone").value = (response.data.fone  || "");
                    document.getElementById("cep").value = (response.data.cep  || "");
                    document.getElementById("expediente").value = (response.data.expediente  || "");
                    document.getElementById("link_maps").value = (response.data.link_maps  || "");
                    
                    //modulos
                    for(let i=0; i<response.data.ModuloContratado.length; i++){

                        if(response.data.ModuloContratado.includes(modulos[i])){document.getElementById(modulos[i]).checked = true;}
                    }

                    document.getElementById("dominio").value = (response.data.Config.dominio  || "");
                    document.getElementById("cor_primaria").value = (response.data.Config.cor_primaria  || "");
                    document.getElementById("cor_secundaria").value = (response.data.Config.cor_secundaria  || "");
                    document.getElementById("prazo_resposta").value = (response.data.Config.prazo_resposta  || "");

                    if(response.data.Config.grafico_manifestacao == true){
                        document.getElementById("radioativo").checked = true;
                    }
                    else
                    {
                        document.getElementById("radioinativo").checked = true;
                    }

                    await TipoContratante(response.data.Config.tipo);
                }            
            })    
            .catch(error => { 
                console.log(error.message);
                if(error.response)
                {
                    // eslint-disable-next-line
                    if(error.response.data.statusCode == 401)
                    {
                        window.location.pathname = `login`;
                    }
                    else{
                        meuAlert(`${error.message}`, "error");
                    }  
                }
                else{
                    meuAlert(`${error.message}`, "error");
                }            
            });

        }

        
        document.getElementById("temporario").innerHTML = null;   

        document.getElementById("mensagem").innerHTML = "Processo em andamento, aguarde...";

        document.getElementById("B").click();   

        // eslint-disable-next-line
    },[refreshKey]); 

    // Atualiza dados (sem docs)
    async function atualizaContratante(qual) {

        //se for novo vai cadastrar
        if(id == "novo"){
            if(verificaVazios("dadosGerais") && verificaVazios("configuracao") && verificaVazios("modulos"))
            cadastraContratante();
        }
        else if(verificaVazios(qual))
        {
            //console.log(qual);
              
            if(qual == "dadosGerais")
            {
                {handleOpen()} 

                //filtrando o link do iframe e enviando somente a url
                var link_mapsEditado = document.getElementById("link_maps").value.split(" ")[1].substr(4).replace('"', '').replace('"', '');
                //console.log(link_mapsEditado);

                const params = JSON.stringify({
                    "nome": document.getElementById("nome").value,
                    "cnpj": document.getElementById("cnpj").value,                    
                    "endereco": document.getElementById("endereco").value,
                    "cep": document.getElementById("cep").value,
                    "email": document.getElementById("email").value,
                    "celular": document.getElementById("celular").value,
                    "fone": document.getElementById("fone").value,
                    "expediente": document.getElementById("expediente").value,
                    "link_maps": String(link_mapsEditado)
                                        
                    //"dominio": "http://localhost:3000",
                    //"cor_primaria": "#004348"
                });
    
                //console.log(params);
                
                const url = `${base_url}/contratante/${id}`;
                await axios.patch(url, params, {
                "headers": {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                }})
                .then(response => { 
                    //console.log(response);
                    //console.log(response.data.id);
    
                    document.getElementById("mensagem").innerHTML = "Alterado com sucesso!";
                    const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     
    
                    meuAlert("Alterado com sucesso!", "success");
                    //const redireciona = setInterval(function () {setRefreshKey(oldKey => oldKey +1); clearInterval(redireciona);}, 2000);     
    
                })
                .catch(error => { 
                    {handleClose()}
                    meuAlert(`${error.response.data.message}`, "warning");
                    console.log(error.message);
                    console.log(error.response.data.message);
                    //console.log("Erro "+error.response.data.statusCode);
                })  
            }
            else if(qual == "configuracao")
            {

                //console.log("entrou");
                {handleOpen()} 

                const params = JSON.stringify({
                    "dominio": String(document.getElementById("dominio").value),
                    "tipo": String(document.getElementById("tipo").value),
                    "cor_primaria": String(document.getElementById("cor_primaria").value),
                    "cor_secundaria": String(document.getElementById("cor_secundaria").value),
                    "prazo_resposta": document.getElementById("prazo_resposta").value,
                    "grafico_manifestacao": document.querySelector('input[name="grafico_manifestacao"]:checked').value
                });
    
                //console.log(params);
                
                const url = `${base_url}/contratante/${id}`;
                await axios.patch(url, params, {
                "headers": {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                }})
                .then(response => { 
                    //console.log(response);
                    //console.log(response.data.id);
    
                    document.getElementById("mensagem").innerHTML = "Alterado com sucesso!";
                    const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     
    
                    meuAlert("Alterado com sucesso!", "success");
                    //const redireciona = setInterval(function () {setRefreshKey(oldKey => oldKey +1); clearInterval(redireciona);}, 2000);     
    
                })
                .catch(error => { 
                    {handleClose()}
                    meuAlert(`${error.response.data.message}`, "warning");
                    console.log(error.message);
                    console.log(error.response.data.message);
                    //console.log("Erro "+error.response.data.statusCode);
                })  
            } 
            else if(qual == "modulos")
            {
                {handleOpen()} 

                const modulosSelec = [];
                //modulos
                for(let i=0; i<modulos.length; i++){
                    if(document.getElementById(modulos[i]).checked){modulosSelec.push(modulos[i]);}
                    ///console.log(modulosSelec);  
                }

               // console.log(modulosSelec);

                const params = JSON.stringify({
                    "modulos": modulosSelec,
                });
    
                //console.log(params);
                
                const url = `${base_url}/contratante/${id}`;
                await axios.patch(url, params, {
                "headers": {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                }})
                .then(response => { 
                    //console.log(response);
                    //console.log(response.data.id);
    
                    document.getElementById("mensagem").innerHTML = "Alterado com sucesso!";
                    const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     
    
                    meuAlert("Alterado com sucesso!", "success");
                    //const redireciona = setInterval(function () {setRefreshKey(oldKey => oldKey +1); clearInterval(redireciona);}, 2000);     
    
                })
                .catch(error => { 
                    {handleClose()}
                    meuAlert(`${error.response.data.message}`, "warning");
                    console.log(error.message);
                    console.log(error.response.data.message);
                    //console.log("Erro "+error.response.data.statusCode);
                })  
            }
        }
    }

    async function cadastraContratante() {
        
        {handleOpen()} 

        const modulosSelec = [];
        //modulos
        for(let i=0; i<modulos.length; i++){
            if(document.getElementById(modulos[i]).checked){modulosSelec.push(modulos[i]);}
            ///console.log(modulosSelec);  
        }

        //filtrando o link do iframe e enviando somente a url
        var link_mapsEditado = document.getElementById("link_maps").value.split(" ")[1].substr(4).replace('"', '').replace('"', '');
        //console.log(link_mapsEditado);

        const params = JSON.stringify({
            "nome": String(document.getElementById("nome").value),
            "cnpj": String(document.getElementById("cnpj").value),                    
            "endereco": String(document.getElementById("endereco").value),
            "cep": String(document.getElementById("cep").value),
            "email": String(document.getElementById("email").value),
            "celular": String(document.getElementById("celular").value),
            "fone": String(document.getElementById("fone").value),
            "expediente": String(document.getElementById("expediente").value),
            "link_maps": String(link_mapsEditado),
            "dominio": String(document.getElementById("dominio").value),
            "tipo": String(document.getElementById("tipo").value),
            "cor_primaria": String(document.getElementById("cor_primaria").value),
            "cor_secundaria": String(document.getElementById("cor_secundaria").value),
            "prazo_resposta": document.getElementById("prazo_resposta").value,
            "grafico_manifestacao": document.querySelector('input[name="grafico_manifestacao"]:checked').value,

            "modulos": modulosSelec
                                
            //"dominio": "http://localhost:3000",
            //"cor_primaria": "#004348"
        });

        //console.log(params);
        
        const url = `${base_url}/contratante`;
        await axios.post(url, params, {
        "headers": {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 
            //console.log(response);
            //console.log(response.data.id);

            document.getElementById("notifica").innerHTML = "Cadastrado com sucesso!";
            const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

            meuAlert("Cadastrado com sucesso!", "success");
            const redireciona = setInterval(function () {
                clearInterval(redireciona);
                window.location.pathname = `contratante/editar/${response.id}`;
            }, 3000);    

        })
        .catch(error => { 
            {handleClose()}
            meuAlert(`${error.response.data.message}`, "warning");
            console.log(error.message);
            console.log(error.response.data.message);
            //console.log("Erro "+error.response.data.statusCode);
        })             
        
    }

    function verificaVazios(verificar) {

        if(verificar == "dadosGerais")
        {
            if(document.getElementById("nome").value == ""){meuAlert("Nome não preenchido.", "warning");}
            else if(document.getElementById("cnpj").value == ""){meuAlert("CNPJ não preenchido.", "warning");}
            else if(document.getElementById("endereco").value == ""){meuAlert("Endereço não preenchido.", "warning");}
            else if(document.getElementById("cep").value == ""){meuAlert("CEP não preenchido.", "warning");}
            else if(document.getElementById("email").value == ""){meuAlert("Email não preenchido.", "warning");}
            else if(document.getElementById("celular").value == ""){meuAlert("Celular não preenchido.", "warning");}
            else if(document.getElementById("fone").value == ""){meuAlert("Fone não preenchido.", "warning");}
            else if(document.getElementById("expediente").value == ""){meuAlert("Expediente não preenchido.", "warning");}
            else if(document.getElementById("link_maps").value == ""){meuAlert("Link Maps não preenchido.", "warning");}
            else
            {
                return true;
            } 
        }       
        if(verificar == "modulos")
        {
            return true;
        }
        if(verificar == "configuracao")
        {
            if(document.getElementById("dominio").value == ""){meuAlert("Domínio não preenchido.", "warning");}
            else if(document.getElementById("cor_primaria").value == ""){meuAlert("Cor primária não preenchida.", "warning");}
            else if(document.getElementById("cor_secundaria").value == ""){meuAlert("Cor secundária não preenchida.", "warning");}
            else if(document.getElementById("prazo_resposta").value == ""){meuAlert("Prazo de resposta não preenchido.", "warning");}
            else
            {
                return true;
            } 
        }      
    }
    
    function menuContato(menuId) {

        document.getElementById("B").style = null;
        document.getElementById("C").style = null;
        document.getElementById("D").style = null;

        document.getElementById(menuId).style = optionClicado;       
        //console.log("Selecionou o botão " + id);

        // eslint-disable-next-line
        if(menuId != "B"){
            document.getElementById("dadosGerais").setAttribute('hidden', '');
        }
        else{
            document.getElementById("dadosGerais").removeAttribute('hidden');       
        }

        // eslint-disable-next-line
        if(menuId != "C"){
            document.getElementById("modulos").setAttribute('hidden', '');
        }
        else{
            document.getElementById("modulos").removeAttribute('hidden');       
        }

        // eslint-disable-next-line
        if(menuId != "D"){
            document.getElementById("configuracao").setAttribute('hidden', '');
        }
        else{
            document.getElementById("configuracao").removeAttribute('hidden');       
        }

    } 

    function TipoContratante(valor){
        for(let i=0; i<document.getElementById("tipo").children.length; i++){
            if(document.getElementById("tipo").children[i].value == valor){
                document.getElementById("tipo").children[i].selected = true;
            }        
        }
    }
    
    return(
        <>   
           <Sidebar page={{ name: 'Contratantes', route: '/contratante' }}>
                    <Box sx={{ width: '100%'}}>
                        <Collapse in={alertAberto}>
                            <Alert
                            severity={tipoAlerta}
                            variant="filled"
                            action={
                                <IconButton
                                aria-label="close"
                                size="small"
                                color="inherit"
                                onClick={() => {
                                    setAlertAberto(false);
                                }}
                                >
                                <CloseIcon/>
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                            >
                            {alertMensagem}
                            </Alert>
                        </Collapse>
                    </Box>
                    
                    <div className="tituloPagina"> 
                        <p id="titu" >Editar Contratante</p>
                    </div>   
                    
                    <div className="linha menuPadrao">
                        <li> 
                            <button id="B" onClick={(e) => menuContato(e.target.id)}>Dados Gerais</button>                        
                            <button id="C" onClick={(e) => menuContato(e.target.id)}>Módulos</button>
                            <button id="D" onClick={(e) => menuContato(e.target.id)}>Configuração</button>
                        </li>  
                    </div>
                   
                    <div className="formularioContato formulario" >
                        <div id="cadastroConcurso">
                            <div className="formContratante formularioGeral" id="dadosGerais">

                                <div className="formularioGeralTitulo"><p>Nome</p><p>CNPJ</p></div>                            
                                <input type="text" name="nome" id="nome" placeholder="Insira o Nome" autoComplete="off" ></input>
                                <input type="number" name="cnpj" id="cnpj" placeholder="Insira o CNPJ (Somente números)" autoComplete="off" ></input>
                                
                                <div className="formularioGeralTitulo"><p>Endereço</p></div>
                                <input type="text" name="endereco" id="endereco" placeholder="Insira o endereço do contato" autoComplete="off" ></input>
                               
                                <div className="formularioGeralTitulo"><p>CEP</p><p>Email</p></div>                            
                                <input type="text" name="cep" id="cep" placeholder="Insira o CEP" autoComplete="off" ></input>
                                <input type="text" name="email" id="email" placeholder="Insira o email" autoComplete="off" ></input>
                                
                                <div className="formularioGeralTitulo"><p>Celular</p><p>Fone</p></div>                            
                                <input type="text" name="celular" id="celular" placeholder="Insira o celular" autoComplete="off" ></input>
                                <input type="text" name="fone" id="fone" placeholder="Insira o fone" autoComplete="off" ></input>
                                
                                <div className="formularioGeralTitulo"><p>Expediente</p><p>Link Maps</p></div>                            
                                <input type="text" name="expediente" id="expediente" placeholder="Insira o expediente" autoComplete="off" ></input>
                                <input type="text" name="link_maps" id="link_maps" placeholder="Insira o link do Maps" autoComplete="off" ></input>
                                
                                <button onClick={(e) => atualizaContratante("dadosGerais")} >Salvar alterações</button>
                            </div>
                        </div>  
                    </div>  

                    <div className="formularioContato formulario" >
                        <div id="cadastroConcurso">
                            <div className="formContato formularioGeral" id="modulos">

                                <div className="novoRadio statusRadio"> 
                                    <div className="formularioGeralTitulo"><p>Módulos do sistema</p></div> 
                                    
                                        <input type="checkbox" id="BASE" name="BASE"></input>
                                        <label for="BASE">Base</label>
                                  
                                        <input type="checkbox" id="SITE" name="SITE" ></input>
                                        <label for="SITE">Site</label>

                                        <input type="checkbox" id="PORTAL" name="PORTAL" ></input>
                                        <label for="PORTAL">Portal</label>
                                 
                                        <input type="checkbox" id="DIARIO" name="DIARIO" ></input>
                                        <label for="DIARIO">Diário</label>
                                   
                                        <input type="checkbox" id="CIDADAO" name="CIDADAO" ></input>
                                        <label for="CIDADAO">Cidadão</label>
                                   
                                        <input type="checkbox" id="CARTA_SERVICOS" name="CARTA_SERVICOS" ></input>
                                        <label for="CARTA_SERVICOS">Carta de Serviços</label>
                                  
                                        <input type="checkbox" id="RELATORIO" name="RELATORIO" ></input>
                                        <label for="RELATORIO">Relatório</label>
                                
                                </div>
                                
                                <button onClick={(e) => atualizaContratante("modulos")} >Salvar alterações</button>
                            </div>
                        </div>  
                    </div>

                    <div className="formularioContato formulario" >
                        <div id="cadastroUsuario">
                            <div className="formContato3 formularioGeral" id="configuracao">                                                       
                                
                                <div className="alinhaDireita">

                                    <div className="novoRadio statusRadio"> 
                                        <div className="formularioGeralTitulo"><p>Gráfico Manifestação</p></div> 
                                        <input type="radio" id="radioativo" name="grafico_manifestacao" value="true"checked></input>
                                        <label for="radioativo">Ativo</label>

                                        <input type="radio" id="radioinativo" name="grafico_manifestacao" value="false"></input>
                                        <label for="radioinativo">Inativo</label>
                                    </div>

                                    <div className="espaco2LadosB_Usuário"></div>

                                    <div className="formularioGeralTitulo"><p>Domínio</p></div>
                                    <input type="text" name="dominio" id="dominio" autoComplete="off"  ></input>
                                                                                                                                         
                                    <div className="formularioGeralTitulo"><p>Cor Primária</p></div>
                                    <input type="text" name="cor_primaria" id="cor_primaria" autoComplete="off"  ></input>
                                       
                                     
                                </div>    

                                <div className="alinhaEsquerda">

                                    <div className="formularioGeralTitulo"><p>Prazo Resposta</p></div>
                                    <input type="number" name="prazo_resposta" id="prazo_resposta" autoComplete="off"  ></input>
                                     

                                    <div className="formularioGeralTitulo"><p>Tipo</p></div>                            
                                    <select name="tipo" id="tipo">
                                        <option value="PREFEITURA">Prefeitura</option>
                                        <option value="CAMARA">Câmara</option>
                                    </select>

                                    <div className="formularioGeralTitulo"><p>Cor Secundária</p></div>
                                    <input type="text" name="cor_secundaria" id="cor_secundaria" autoComplete="off"  ></input>
                                       
                                </div>
                               
                                <button onClick={(e) => atualizaContratante("configuracao")} >Salvar alterações</button>

                            </div>
                        </div>                    
                    </div>
                    
                    <div className="loading">
                        <div id="temporario">{loading()}</div>
                    </div>
                </Sidebar>    
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                ><p id="mensagem" ></p>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </>   
    );
}

export default Contato;