import { useEffect, useRef, useState } from "react";
import '../../../components/Geral/geral.css';
import './LinkEditar.css'
import carregando from '../../../components/Geral/loading.gif';
import { verificaLogin, fazerLogout } from '../../../context/LoginContext';
import { Editor } from '@tinymce/tinymce-react';
import {base_url, midia_url, foto_default} from '../../../environment'
import axios from 'axios';
import Sidebar from '../../../components/Menu/Sidebar';
import { useParams } from "react-router-dom";

import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
//import Button from '@mui/material/Button';

var unavez = true, umavez = true;
var arrayIcones=[], arrayIds=[];

function LinkEditar(){
    setInterval(function () {verificaLogin();}, 1000);

    const [refreshKey, setRefreshKey] = useState(0);
    
    function atualiza() {
        setRefreshKey(oldKey => oldKey +1); 
    }

    const [alertAberto, setAlertAberto] = React.useState(false);
    const [alertMensagem, setAlertMensagem] = React.useState('');
    const [tipoAlerta, setTipoAlerta] = React.useState('');

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    function meuAlert(texto, tipo) {
        setTipoAlerta(tipo);
        setAlertMensagem(texto);
        window.location = `#root`;
        setAlertAberto(true);
    }

    function loading() {return <div id='loading'><img src={carregando} alt="Carregando"/></div>; }    
    setInterval(function () {verificaLogin();}, 1000);
    
    //var [textoInicial, setTextoInicial] = useState('');    

    // Clicar no primeiro botao e colocar texto inicial
    //useEffect(() => {
        
        //document.getElementById(0).click();
        //setTextoInicial("Escreva sua descrição.");
        //document.getElementById("descricao").value = "Escreva sua descrição.";
    
    //},[]);

    const { id } = useParams();
    var token = localStorage.getItem('LOCAL_STORAGE_KEY__ACCESS_TOKEN');

    function trocaLogo(valor){
        var selecionado = arrayIds.indexOf(valor);
        document.getElementById('imagem').src = arrayIcones[selecionado];
    }

    // pegar dados do icone
    useEffect(() => {
    axios.get(`${base_url}/icone`, {
        "headers": {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 

            // eslint-disable-next-line
            if((response.data.statusCode != undefined) && (response.data.statusCode != 200)){
                //window.location.pathname = `mensagem/${response.data.message}`;
                meuAlert(`${response.data.message}`, "error");
            }

            // eslint-disable-next-line 
            if(response.data.length == 0)
            {
                meuAlert("Não há icones.", "error");         
            }
            else
            {
                //console.log(response.data);

                var pessoa = document.getElementById("icone");                
                for(let i=0; i<response.data.length; i++){
                    const option = document.createElement("option");
                    option.value = response.data[i].id;
                    //option.id = i;
                    option.innerHTML = response.data[i].titulo;         
                    pessoa.appendChild(option);

                    arrayIcones.push(response.data[i].imagem);        
                    arrayIds.push(response.data[i].id);
                }

                document.getElementById('imagem').src = response.data[0].imagem;

                document.getElementById('icone').addEventListener('change', function() {trocaLogo(this.value)}, false);
                
            }            
        })    
        .catch(error => { 
            console.log(error.message);
            if(error.response)
            {
                // eslint-disable-next-line
                if(error.response.data.statusCode == 401)
                {
                    window.location.pathname = `login`;
                }
                else{
                    meuAlert(`${error.message}`, "error");
                }  
            }
            else{
                meuAlert(`${error.message}`, "error");
            }            
        });

    },[]); 
    
 
    // pegar dados se não for novo
    useEffect(() => {
        
        if(id != "novo")
        {
            axios.get(`${base_url}/link/${id}`, 
            { headers: {
                'Authorization': `Bearer ${token}`,
            }})
            .then(async response => {             
                //console.log("Resposta ok");
                //console.log(response);
                //console.log(response.data);

                document.getElementById("titulo").value = response.data.titulo; 
                await tipoLink(response.data.tipo); 

                if(response.data.status == true){document.getElementById("linkativo").checked = true;}
                else {document.getElementById("linkinativo").checked = true;}        

                document.getElementById("ordem_posicao").value = response.data.ordem_posicao;    

                await categoriaLink(response.data.categoria);

                document.getElementById("url").value = response.data.url; 
                if(response.data.descricao){document.getElementById("descricao").value = response.data.descricao};
                
                await selectIcone(response.data.icone_id);

            })
            .catch(error => { 
                console.log(error.message);
                if(error.response)
                {
                    if((error.response.data.statusCode == 404) || (error.response.data.statusCode == 400))
                    {
                        if(unavez)
                        {
                            meuAlert("Pessoa não existente.", "warning");
                            //window.location.pathname = `link/editar/novo`;
                            unavez = false;
                        }
                        
                    }
                    else{
                        meuAlert(`${error.message}`, "error");
                    }
                    if(error.response.data.statusCode == 401)
                    {
                        window.location.pathname = `login`;
                    }
                }   
                else{
                    meuAlert(`${error.message}`, "error");
                }            
            });
        }
        else{
            //document.getElementById("imagem").src = foto_default;
        }
        document.getElementById("notifica").innerHTML = "Processo em andamento, aguarde...";
               
    },[refreshKey]); 


    function verificaVazios() {

        if(document.getElementById("titulo").value == ""){meuAlert("Título não preenchido.", "warning");}
        else if(document.getElementById("url").value == ""){meuAlert("URL não preenchida.", "warning");}
        else if(document.getElementById("tipo").value == ""){meuAlert("Tipo de link não preenchido.", "warning");}
        else if(document.getElementById("descricao").value == ""){meuAlert("Descrição não preenchida.", "warning");}
        else
        {
            return true;
        }    
    }
    
    // Função salvar começa aqui
    function selectFuncao(){
        if(id == "novo")
        {
            if(verificaVazios())
            {
                cadastraLink();
            };            
        }
        else
        {
            if(verificaVazios())
            {
                atualizaLink();
            };    
        }
    }

    // Cadastra Link (sem docs)
    async function cadastraLink() {

        {handleOpen()} 

        var input = document.querySelector('input[type="file"]')

        var data = new FormData()
        data.append('titulo', String(document.getElementById("titulo").value))
        data.append('url', String(document.getElementById("url").value))
        data.append('tipo', String(document.getElementById("tipo").value))
        data.append('categoria', String(document.getElementById("categoria").value))
        data.append('descricao', String(document.getElementById("descricao").value))
        data.append('status', document.querySelector('input[name="statusLink"]:checked').value)
        data.append('icone_id', String(document.getElementById("icone").value))

        // NÂO PRECISA
        //data.append('contratante_id', localStorage.getItem('contratante_id'))      

        console.log(data);
        
        const url = `${base_url}/link`;
         await axios.post(url, data, {
        "headers": {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 
            //console.log(response);
            //console.log(response.data.id);

            document.getElementById("notifica").innerHTML = "Cadastrado com sucesso!";
            const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

            meuAlert("Cadastrado com sucesso!", "success");
            const redireciona = setInterval(function () {
                clearInterval(redireciona);
                window.location.pathname = `link/editar/${response.data.id}`;
            }, 3000);     
           
        })
        .catch(error => {
            {handleClose()}
            meuAlert(`${error.response.data.message}`, "warning");
            console.log(error.message);
            console.log(error.response.data.message);
            //console.log("Erro "+error.response.data.statusCode);
        })     
    }
    // Atualiza Link (sem docs)
    async function atualizaLink() {
          
        {handleOpen()} 

        //var input = document.querySelector('input[type="file"]')

        var data = new FormData()
        data.append('titulo', String(document.getElementById("titulo").value))
        data.append('url', String(document.getElementById("url").value))
        data.append('tipo', String(document.getElementById("tipo").value))
        data.append('categoria', String(document.getElementById("categoria").value))
        data.append('descricao', String(document.getElementById("descricao").value))
        data.append('status', document.querySelector('input[name="statusLink"]:checked').value)
        data.append('icone_id', String(document.getElementById("icone").value))
        //NÂO PRECISA
        //data.append('contratante_id', localStorage.getItem('contratante_id'))      

        //console.log(data);
        
        const url = `${base_url}/link/${id}`;
        await axios.patch(url, data, {
        "headers": {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 
            //console.log(response);
            //console.log(response.data.id);

            document.getElementById("notifica").innerHTML = "Alterado com sucesso!";
            const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

            meuAlert("Alterado com sucesso!", "success");
            const redireciona = setInterval(function () {
                //setRefreshKey(oldKey => oldKey +1);
                window.location.reload();
                clearInterval(redireciona);
            }, 2000);     

        })
        .catch(error => { 
            {handleClose()}
            meuAlert(`${error.response.data.message}`, "warning");
            console.log(error.message);
            console.log(error.response.data.message);
            //console.log("Erro "+error.response.data.statusCode);
        })     
        
    }    
    // Excluir Link
    async function ExcluirLink() {  

        if (window.confirm("Tem certeza que deseja excluir este link?")) {        
            
            {handleOpen()} 
         
            const url = `${base_url}/link/${id}`;
            await axios.delete(url, {
            "headers": {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            }})
            .then(response => { 
                //console.log(response);
                //console.log(response.data.id);    
               
                document.getElementById("notifica").innerHTML = "Excluído com sucesso!";
                const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

                meuAlert("Excluído com sucesso!", "success");
                const redireciona = setInterval(function () {
                    clearInterval(redireciona);
                    window.location.pathname = `link/editar/novo`;
                }, 2000);     

            })
            .catch(error => { 
                {handleClose()}
                meuAlert(`${error.message}`, "error");
                console.log(error.message);
                //console.log("Erro "+error.response.data.statusCode);
            })  
        }           
    }

    function categoriaLink(valor){
        for(let i=0; i<document.getElementById("categoria").children.length; i++){
            if(document.getElementById("categoria").children[i].value == valor){
                document.getElementById("categoria").children[i].selected = true;
            }        
        }
    }

    function tipoLink(valor){
        for(let i=0; i<document.getElementById("tipo").children.length; i++){
            if(document.getElementById("tipo").children[i].value == valor){
                document.getElementById("tipo").children[i].selected = true;
            }        
        }
    }


    async function selectIcone(valor){
        for(let i=0; i<document.getElementById("icone").children.length; i++){
            if(document.getElementById("icone").children[i].value == valor){
                document.getElementById("icone").children[i].selected = true;
                await trocaLogo(valor);
            }        
        }
    }

    return(
        <>   
            <Sidebar page={{ name: 'Links', route: '/link' }}>
                <Box sx={{ width: '100%'}}>
                    <Collapse in={alertAberto}>
                        <Alert
                        severity={tipoAlerta}
                        variant="filled"
                        action={
                            <IconButton
                            aria-label="close"
                            size="small"
                            color="inherit"
                            onClick={() => {
                                setAlertAberto(false);
                            }}
                            >
                            <CloseIcon/>
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        {alertMensagem}
                        </Alert>
                    </Collapse>
                </Box>
                
                <div className="tituloPagina"><p id="titu" >Editar Link</p></div>   

                <div className="botaoExcluirEspec">
                    {id !== "novo" && (
                        <button onClick={ExcluirLink}>Excluir Link</button>
                    )}
                </div>

                <div className="semLinha menuPadrao">
                    <li>
                        <button id="0" >Cadastro Geral</button>       
                    </li>  
                </div>

                <div className="formulario" >
                    <div id="cadastroGaleria">
                        <div className="formRedondo formularioGeral linkEditar">                                                       
                            <div className="alinhaDireita">

                                <div className="formularioGeralTitulo"><p>Título do link</p></div>   
                                <input type="text" name="titulo" id="titulo" placeholder="Insira o título do link" autoComplete="off"  ></input>
                               
                                <div className="formularioGeralTitulo"><p>Tipo de link</p></div>   
                                <select name="tipo" id="tipo">
                                    <option value="INTERNO">Interno</option>
                                    <option value="EXTERNO">Externo</option>
                                    <option value="IFRAME">Iframe</option>
                                </select>

                                <div className="novoRadio statusRadio"> 
                                    <div className="formularioGeralTitulo"><p>Status</p></div> 
                                    <input type="radio" id="linkativo" name="statusLink" value="true"checked></input>
                                    <label for="ativo">Ativo</label>

                                    <input type="radio" id="linkinativo" name="statusLink" value="false"></input>
                                    <label for="inativo">Inativo</label>
                                </div>

                                <div className="espaco2LadosB_Link"></div>

                                <div className="formularioGeralTitulo"><p>Ordem posição</p></div>   
                                <input type="text" name="ordem_posicao" id="ordem_posicao" placeholder="Insira o nº da posição na ordem" autoComplete="off"  ></input>
                               
                                <div className="formularioGeralTitulo"><p>Categoria</p></div>
                                <select name="categoria" id="categoria">
                                    <option value="HEADER">Header</option>
                                    <option value="FOOTER">Footer</option>
                                    <option value="HOME_CIDADAO">Home Cidadão</option>
                                    <option value="HOME_SERVIDOR">Home Servidor</option>
                                    <option value="HOME_EMPRESA">Home Empresa</option>
                                    <option value="HOME_TURISTA">Home Turista</option>
                                    <option value="TRANSPARENCIA">Transparência</option>
                                    <option value="ACESO_RAPIDO">Acesso Rápido</option>
                                    <option value="COVID">Covid</option>
                                </select>
                                    
                            </div>

                            <div className="alinhaEsquerda">
                                <img id="imagem" name="imagem" src={carregando} ></img>
                                
                                <div className="formularioGeralTitulo"><p>Selecione o ícone (imagem acima)</p></div>
                                <select name="icone" id="icone">
                                </select>
                                   
                            </div>    

                            <div className="bloqueiaDiv formularioGeralTitulo"><p>URL do link</p></div>   
                            <input type="text" name="url" id="url" placeholder="Insira a URL do link" autoComplete="off"  ></input>

                            <div className="bloqueiaDiv formularioGeralTitulo"><p>Descrição do link</p></div>   
                            <input type="text" name="descricao" id="descricao" placeholder="Insira a descrição do link" autoComplete="off"  ></input>

                            <button onClick={selectFuncao}>Salvar alterações</button>
                                          
                        </div>                        
                    </div>                    
                </div>
            </Sidebar>
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                ><p id="notifica" >vghfgh</p>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </>   
    );
}

export default LinkEditar;