import { useEffect, useRef, useState } from "react";
import '../../../components/Geral/geral.css';
import './LicitacaoEditar.css'
import carregando from '../../../components/Geral/loading.gif';
import { verificaLogin, fazerLogout } from '../../../context/LoginContext';
import { Editor } from '@tinymce/tinymce-react';
import {base_url, midia_url, foto_default} from '../../../environment'
import axios from 'axios';
import Sidebar from '../../../components/Menu/Sidebar';
import FileUploadMultiple from './uploadLicitacao.tsx'
import { useParams } from "react-router-dom";

import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
//import Button from '@mui/material/Button';

var unavez = true, umavez = true;
var optionClicado = "background-color: var(--mainColor); color: var(--textColor2);"

function LicitacaoEditar(){
    setInterval(function () {verificaLogin();}, 1000);

    const [refreshKey, setRefreshKey] = useState(0);
    
    function atualiza() {
        setRefreshKey(oldKey => oldKey +1); 
    }

    const [alertAberto, setAlertAberto] = React.useState(false);
    const [alertMensagem, setAlertMensagem] = React.useState('');
    const [tipoAlerta, setTipoAlerta] = React.useState('');

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    function meuAlert(texto, tipo) {
        setTipoAlerta(tipo);
        setAlertMensagem(texto);
        window.location = `#root`;
        setAlertAberto(true);
    }

    function loading() {return <div id='loading'><img src={carregando} alt="Carregando"/></div>; }    
    setInterval(function () {verificaLogin();}, 1000);
    
    var [textoInicial, setTextoInicial] = useState('');    

    // Clicar no primeiro botao e colocar texto inicial
    useEffect(() => {
        
        document.getElementById(0).click();
        setTextoInicial("<p>Escreva seu texto aqui. Aumente o tamanho da área de texto no canto inferior direito.</p>");
    
    },[]);

    const {id} = useParams();
    var token = localStorage.getItem('LOCAL_STORAGE_KEY__ACCESS_TOKEN');
 
    // pegar dados modalidade-licitacao
    useEffect(() => {
    axios.get(`${base_url}/modalidade-licitacao`, {
        "headers": {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 

            // eslint-disable-next-line
            if((response.data.statusCode != undefined) && (response.data.statusCode != 200)){
                //window.location.pathname = `mensagem/${response.data.message}`;
                meuAlert(`${response.data.message}`, "error");
            }

            // eslint-disable-next-line 
            if(response.data.length == 0)
            {
                meuAlert("Não há modalidades de licitação.", "error");           
            }
            else
            {
                //console.log(response.data);

                var pessoa = document.getElementById("modalidade-licitacao");                
                for(let i=0; i<response.data.length; i++){
                    const option = document.createElement("option");
                    option.value = response.data[i].id;
                    option.innerHTML = response.data[i].nome;                    
                    pessoa.appendChild(option);
                }

            }            
        })    
        .catch(error => { 
            console.log(error.message);
            if(error.response)
            {
                // eslint-disable-next-line
                if(error.response.data.statusCode == 401)
                {
                    window.location.pathname = `login`;
                }
                else{
                    meuAlert(`${error.message}`, "error");
                }  
            }
            else{
                meuAlert(`${error.message}`, "error");
            }            
        });

    },[]); 

    // pegar dados orgao
    useEffect(() => {
    axios.get(`${base_url}/orgao`, {
        "headers": {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 

            // eslint-disable-next-line
            if((response.data.statusCode != undefined) && (response.data.statusCode != 200)){
                //window.location.pathname = `mensagem/${response.data.message}`;
                meuAlert(`${response.data.message}`, "error");
            }

            // eslint-disable-next-line 
            if(response.data.length == 0)
            {
                meuAlert("Não há órgãos.", "error");         
            }
            else
            {
                //console.log(response.data);

                var pessoa = document.getElementById("orgao");                
                for(let i=0; i<response.data.length; i++){
                    const option = document.createElement("option");
                    option.value = response.data[i].id;
                    option.innerHTML = response.data[i].nome;                    
                    pessoa.appendChild(option);
                }

            }            
        })    
        .catch(error => { 
            console.log(error.message);
            if(error.response)
            {
                // eslint-disable-next-line
                if(error.response.data.statusCode == 401)
                {
                    window.location.pathname = `login`;
                }
                else{
                    meuAlert(`${error.message}`, "error");
                }  
            }
            else{
                meuAlert(`${error.message}`, "error");
            }            
        });

    },[]); 
    
    // pegar dados vencedor (fornecedor)
    useEffect(() => {
        axios.get(`${base_url}/fornecedor`, {
            "headers": {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            }})
            .then(response => { 
    
                // eslint-disable-next-line
                if((response.data.statusCode != undefined) && (response.data.statusCode != 200)){
                    //window.location.pathname = `mensagem/${response.data.message}`;
                    meuAlert(`${response.data.message}`, "error");
                }
    
                // eslint-disable-next-line 
                if(response.data.length == 0)
                {
                    meuAlert("Não há fornecedores.", "error");         
                }
                else
                {
                    console.log(response.data);
    
                    var pessoa = document.getElementById("vencedor");                
                    for(let i=0; i<response.data.length; i++){
                        const option = document.createElement("option");
                        option.value = response.data[i].id;
                        option.innerHTML = response.data[i].nome;                    
                        pessoa.appendChild(option);
                    }
    
                }            
            })    
            .catch(error => { 
                console.log(error.message);
                if(error.response)
                {
                    // eslint-disable-next-line
                    if(error.response.data.statusCode == 401)
                    {
                        window.location.pathname = `login`;
                    }
                    else{
                        meuAlert(`${error.message}`, "error");
                    }  
                }
                else{
                    meuAlert(`${error.message}`, "error");
                }            
            });
    
    },[]); 

    // pegar dados se não for novo
    useEffect(() => {
        
        if(id != "novo")
        {
            axios.get(`${base_url}/licitacao/${id}`, 
            { headers: {
                'Authorization': `Bearer ${token}`,
            }})
            .then(async response => {             
                //console.log("Resposta ok");
                //console.log(response);
                //console.log(response.data);

                document.getElementById("numero").value = response.data.numero;        
                document.getElementById("nprocesso").value = response.data.num_processo; 
                document.getElementById("data").value = new Date(response.data.data).toISOString().split('T')[0];    
                document.getElementById("certameData").value = new Date(response.data.data_certame).toISOString().split('T')[0];    
                document.getElementById("objeto").value = response.data.objeto;
                document.getElementById("valorTotal").value = response.data.valor_total;    
                              
                await TipoStatus(response.data.status);  
                await TipoOrgao(response.data.orgao_id);                
                await TipoModalidade(response.data.modalidade_licitacao_id);  
                await Vencedor(response.data.vencedor_id);  
                
                //var arrayInicial = [];       
                
                //arquivo
                if(unavez){
                    document.getElementById("documentosAnexos").innerHTML = null;   
                    for(let i=0; i<response.data.Anexo.length; i++){
                
                        //arrayInicial.push(String('<div><p>'+response.data.Anexo[i].titulo+'</p><a target="_blank" href='+midia_url+response.data.Anexo[i].anexo+'>Baixar</a><a target="_blank" href="#" >Excluir</a></div>')); 
                            
                        var divGeral = document.getElementById("documentosAnexos");
                        
                        const divContainter = document.createElement("div");
        
                        const p = document.createElement("p");
                        p.innerHTML = response.data.Anexo[i].titulo;
        
                        const a = document.createElement("a");
                        a.innerHTML = "Excluir";
                        a.id = response.data.Anexo[i].id;
                        a.addEventListener("click", (e) => ExcluirAnexo(e.target.id));
        
                        const a2 = document.createElement("a");
                        a2.target = "_blank";
                        a2.href = String(midia_url+response.data.Anexo[i].anexo);
                        a2.innerHTML = "Baixar";
                        
                        divContainter.appendChild(p); 
                        divContainter.appendChild(a);                 
                        divContainter.appendChild(a2); 
                            
                        divGeral.appendChild(divContainter);
                        
                    };
                    unavez = false;
                } 


            })
            .catch(error => { 
                console.log(error.message);
                if(error.response)
                {
                    if((error.response.data.statusCode == 404) || (error.response.data.statusCode == 400))
                    {
                        if(unavez)
                        {
                            meuAlert("Pessoa não existente.", "warning");
                            //window.location.pathname = `obra/editar/novo`;
                            unavez = false;
                        }
                        
                    }
                    else{
                        meuAlert(`${error.message}`, "error");
                    }
                    if(error.response.data.statusCode == 401)
                    {
                        window.location.pathname = `login`;
                    }
                }   
                else{
                    meuAlert(`${error.message}`, "error");
                }            
            });
        }
        else{
            //nao faz nada
        }
        document.getElementById("notifica").innerHTML = "Processo em andamento, aguarde...";
               
    },[refreshKey]); 

    function menuSec(menuId) {
        for(let i=0; i<=1; i++){
            document.getElementById(i).style = null;
        }
        document.getElementById(menuId).style = optionClicado;       
        //console.log("Selecionou o botão " + id);

        // eslint-disable-next-line
        if(menuId != 0)
        {
            document.getElementById("cadastroDocumento").setAttribute('hidden', '');
        }
        else
        {
            document.getElementById("cadastroDocumento").removeAttribute('hidden');
            document.getElementById("cadastroDocDocumento").setAttribute('hidden', '');            
        }

        // eslint-disable-next-line
        if(menuId != 1){
            document.getElementById("cadastroDocDocumento").setAttribute('hidden', '');
        }
        else{
            if(id == "novo")
            {
                meuAlert("Cadastre e salve o documento antes de inserir anexos.", "info");
                //alert("Cadastre e salve o documento antes de inserir anexos.")
                document.getElementById(0).click();   
            }
            else
            {
               document.getElementById("cadastroDocDocumento").removeAttribute('hidden');
                document.getElementById("cadastroDocumento").setAttribute('hidden', ''); 
            }            
        }

    }  

    function verificaVazios() {

        if(document.getElementById("numero").value == ""){meuAlert("Número não preenchido.", "warning");}
        else if(document.getElementById("nprocesso").value == ""){meuAlert("Número do processo não preenchido.", "warning");}
        else if(document.getElementById("data").value == ""){meuAlert("Data não preenchida.", "warning");}
        else if(document.getElementById("certameData").value == ""){meuAlert("Data do certame não preenchida.", "warning");}
        else if(document.getElementById("objeto").value == ""){meuAlert("Objeto não preenchido.", "warning");}
        else if(document.getElementById("valorTotal").value == ""){meuAlert("Valor total não preenchido.", "warning");}
        else if(document.getElementById("vencedor").value == ""){meuAlert("Vencedor não preenchido.", "warning");}
        else
        {
            return true;
        }    
    }
    
    function selectFuncao(){
        if(id == "novo")
        {
            if(verificaVazios())
            {
                cadastraLicitacao();
            };            
        }
        else
        {
            if(verificaVazios())
            {
                atualizaLicitacao();
            };    
        }
    }
    // Cadastra ponto-turistico (sem docs)
    async function cadastraLicitacao() {

        {handleOpen()} 

        var input = document.querySelector('input[type="file"]')

        const params = JSON.stringify({
            'numero': document.getElementById("numero").value,
            'num_processo': document.getElementById("nprocesso").value,
            'data': new Date(document.getElementById("data").value).toISOString(),
            'data_certame': new Date(document.getElementById("certameData").value).toISOString(),
            'objeto': String(document.getElementById("objeto").value),
            'valor_total': document.getElementById("valorTotal").value,
            'status': String(document.getElementById("status").value),   
            'orgao_id': String(document.getElementById("orgao").value),
            'modalidade_licitacao_id': String(document.getElementById("modalidade-licitacao").value),
            'vencedor_id': String(document.getElementById("vencedor").value),
            //'contratante_id': localStorage.getItem('contratante_id'),
            // NÂO PRECISA
            //data.append('contratante_id', localStorage.getItem('contratante_id'))      
        });
        //console.log(data);
        
        const url = `${base_url}/licitacao`;
         await axios.post(url, params, {
        "headers": {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 
            //console.log(response);
            //console.log(response.data.id);

            document.getElementById("notifica").innerHTML = "Cadastrado com sucesso!";
            const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

            meuAlert("Cadastrado com sucesso!", "success");
            const redireciona = setInterval(function () {
                clearInterval(redireciona);
                window.location.pathname = `licitacao/editar/${response.data.id}`;
            }, 3000);     
           
        })
        .catch(error => {
            {handleClose()}
            meuAlert(`${error.response.data.message}`, "warning");
            console.log(error.message);
            console.log(error.response.data.message);
            //console.log("Erro "+error.response.data.statusCode);
        })     
    }
    // Atualiza ponto-turistico (sem docs)
    async function atualizaLicitacao() {
          
        {handleOpen()} 

        var input = document.querySelector('input[type="file"]')

       const params = JSON.stringify({
            'numero': document.getElementById("numero").value,
            'num_processo': document.getElementById("nprocesso").value,
            'data': new Date(document.getElementById("data").value).toISOString(),
            'data_certame': new Date(document.getElementById("certameData").value).toISOString(),
            'objeto': String(document.getElementById("objeto").value),
            'valor_total': document.getElementById("valorTotal").value,
            'status': String(document.getElementById("status").value),   
            'orgao_id': String(document.getElementById("orgao").value),
            'modalidade_licitacao_id': String(document.getElementById("modalidade-licitacao").value),  
            'vencedor_id': String(document.getElementById("vencedor").value),
        });

         //NÂO PRECISA
        //data.append('cargo', String(document.getElementById("cargo").value))
        //data.append('contratante_id', localStorage.getItem('contratante_id'))      

        //console.log(data);
        
        const url = `${base_url}/licitacao/${id}`;
        await axios.patch(url, params, {
        "headers": {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 
            //console.log(response);
            //console.log(response.data.id);

            document.getElementById("notifica").innerHTML = "Alterado com sucesso!";
            const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

            meuAlert("Alterado com sucesso!", "success");
            const redireciona = setInterval(function () {
                //setRefreshKey(oldKey => oldKey +1);
                window.location.reload();
                clearInterval(redireciona);
            }, 2000);     

        })
        .catch(error => { 
            {handleClose()}
            meuAlert(`${error.response.data.message}`, "warning");
            console.log(error.message);
            console.log(error.response.data.message);
            //console.log("Erro "+error.response.data.statusCode);
        })     
        
    }    
    // Excluir ponto-turistico
    async function ExcluirDoc() {  

        if (window.confirm("Tem certeza que deseja excluir esta licitação?")) {        
            
            {handleOpen()} 
         
            const url = `${base_url}/licitacao/${id}`;
            await axios.delete(url, {
            "headers": {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            }})
            .then(response => { 
                //console.log(response);
                //console.log(response.data.id);    
               
                document.getElementById("notifica").innerHTML = "Excluído com sucesso!";
                const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

                meuAlert("Excluído com sucesso!", "success");
                const redireciona = setInterval(function () {
                    clearInterval(redireciona);
                    window.location.pathname = `licitacao/editar/novo`;
                }, 2000);     

            })
            .catch(error => { 
                {handleClose()}
                meuAlert(`${error.message}`, "error");
                console.log(error.message);
                //console.log("Erro "+error.response.data.statusCode);
            })  
        }           
    }

    function TipoModalidade(valor){
        //console.log(document.getElementById("modalidade-licitacao").children.length);
        for(let i=0; i<document.getElementById("modalidade-licitacao").children.length; i++){
            if(document.getElementById("modalidade-licitacao").children[i].value == valor){
                document.getElementById("modalidade-licitacao").children[i].selected = true;
            }        
        }
    }

    function TipoOrgao(valor){
        for(let i=0; i<document.getElementById("orgao").children.length; i++){
            if(document.getElementById("orgao").children[i].value == valor){
                document.getElementById("orgao").children[i].selected = true;
            }        
        }
    }

    function TipoStatus(valor){
        for(let i=0; i<document.getElementById("status").children.length; i++){
            if(document.getElementById("status").children[i].value == valor){
                document.getElementById("status").children[i].selected = true;
            }        
        }
    }

    function Vencedor(valor){
        for(let i=0; i<document.getElementById("vencedor").children.length; i++){
            if(document.getElementById("vencedor").children[i].value == valor){
                document.getElementById("vencedor").children[i].selected = true;
            }        
        }
    }

    
    // Excluir anexo
    async function ExcluirAnexo(idAnexo) {  

        if (window.confirm("Tem certeza que deseja excluir esse anexo?")) {     
            
            document.getElementById("notifica").innerHTML = "Processo em andamento, aguarde...";
            
            {handleOpen()}    

            const url = `${base_url}/licitacao/anexo/${idAnexo}`;
            await axios.delete(url, {
            "headers": {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            }})
            .then(response => { 
                //console.log(response);
                //console.log(response.data.id);
                //window.location.pathname = `licitacao/editar/${id}`;

                document.getElementById("notifica").innerHTML = "Anexo excluído com sucesso!";
                const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

                meuAlert("Anexo excluído com sucesso!", "success");
                const redireciona = setInterval(function () {
                    //setRefreshKey(oldKey => oldKey +1); 
                    clearInterval(redireciona);
                    window.location.reload();
                }, 2000);     

            })
            .catch(error => { 
                {handleClose()}
                meuAlert(`${error.message}`, "error");
                console.log(error.message);
                //console.log("Erro "+error.response.data.statusCode);
            })  
        }           
    }

    return(
        <>   
            <Sidebar page={{ name: 'Licitações', route: '/licitacao' }}>
                <Box sx={{ width: '100%'}}>
                    <Collapse in={alertAberto}>
                        <Alert
                        severity={tipoAlerta}
                        variant="filled"
                        action={
                            <IconButton
                            aria-label="close"
                            size="small"
                            color="inherit"
                            onClick={() => {
                                setAlertAberto(false);
                            }}
                            >
                            <CloseIcon/>
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        {alertMensagem}
                        </Alert>
                    </Collapse>
                </Box>
                
                <div className="tituloPagina"><p id="titu" >Editar Licitação</p></div>   

                <div className="botaoExcluirEspec">
                    {id !== "novo" && (
                        <button onClick={ExcluirDoc}>Excluir Licitação</button>
                    )}
                </div>

                <div className="semLinha menuPadrao">
                    <li>
                        <button id="0" onClick={(e) => menuSec(e.target.id)}>Cadastro Geral</button>                        
                        <button id="1" onClick={(e) => menuSec(e.target.id)}>Anexos</button>   
                    </li>  
                </div>

                <div className="formulario" >
                    <div id="cadastroDocumento">
                        <div className="formRedondo formularioGeral ">                                                       
                            
                            <div className="alinhaDireita">
                                
                                <div className="formularioGeralTitulo"><p>Número do processo</p></div>                            
                                <input type="number" name="nprocesso" id="nprocesso" placeholder="Insira o número do processo" autoComplete="off"  ></input>

                                <div className="formularioGeralTitulo"><p>Data do Certame</p></div>
                                <input type="date" name="certameData" id="certameData" autoComplete="off"  ></input>
                                     
                                <div className="formularioGeralTitulo"><p>Valor Total</p></div>                            
                                <input type="number" name="valorTotal" id="valorTotal" placeholder="Insira o Valor Total" autoComplete="off"  ></input>
 
                                <div className="formularioGeralTitulo"><p>Órgão</p></div>   
                                <select name="orgao" id="orgao">
                                </select>

                                <div className="formularioGeralTitulo"><p>Vencedor</p></div>   
                                <select name="vencedor" id="vencedor">
                                </select>
                                              
                            </div>

                            <div className="alinhaEsquerda">
                                                                                                
                                <div className="formularioGeralTitulo"><p>Número</p></div>                            
                                <input type="number" name="numero" id="numero" placeholder="Insira o Número" autoComplete="off"  ></input>

                                <div className="formularioGeralTitulo"><p>Data</p></div>
                                <input type="date" name="data" id="data" autoComplete="off"  ></input>
                                   
                                <div className="formularioGeralTitulo"><p>Objeto</p></div>                            
                                <input type="text" name="objeto" id="objeto" placeholder="Insira o Objeto" autoComplete="off"  ></input>

                                <div className="formularioGeralTitulo"><p>Status</p></div>   
                                <select name="status" id="status">
                                    <option value="RASCUNHO">Rascunho</option>
                                    <option value="ADIADA">Adiada</option>
                                    <option value="AGUARDANDO_PARECER">Aguardando parecer</option>
                                    <option value="AGUARDANDO_PROPOSTA">Aguardando proposta</option>
                                    <option value="ANULADA">Anulada</option>
                                    <option value="DESERTA">Deserta</option>
                                    <option value="EM_ANDAMENTO">Em andamento</option>
                                    <option value="EM_PRAZO_RECURSAL">Em prazo recursal</option>
                                    <option value="ENCERRADA">Encerrada</option>
                                    <option value="FRACASSADA">Fracassada</option>
                                    <option value="HOMOLOGADA">Homologada</option>
                                    <option value="REVOGADA">Revogada</option>
                                    <option value="SUSPENSA">Suspensa</option>
                                </select>

                                <div className="formularioGeralTitulo"><p>Modalidade Licitação</p></div>   
                                <select name="modalidade-licitacao" id="modalidade-licitacao">
                                </select>
                                                
                            </div>                                
                                                         
                            <button onClick={selectFuncao}>Salvar alterações</button>

                        </div>

                    </div>                    
                </div>

                <div className="cadastroDoc">
                    <div id="cadastroDocDocumento">
                        <div className="cadastroDocInterno">
                            <p>Documentos Anexos</p>
                            <div id="documentosAnexos" className="anexos">
                                {loading()}
                            </div>       
                            <span>Clique no botão ou solte os arquivos abaixo para anexar.</span>
                            <FileUploadMultiple/>
                        </div>
                    </div>                        
                </div>

            </Sidebar>
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                ><p id="notifica" >vghfgh</p>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </>   
    );
}

export default LicitacaoEditar;