import { useEffect, useRef, useState } from "react";
import '../../../components/Geral/geral.css';
import './OuvidoriaEditar.css'
import carregando from '../../../components/Geral/loading.gif';
import { verificaLogin, fazerLogout } from '../../../context/LoginContext';
import {base_url, midia_url} from '../../../environment'
import axios from 'axios';
import Sidebar from '../../../components/Menu/Sidebar';
import { useParams } from "react-router-dom";

import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
//import Button from '@mui/material/Button';

var unavez = true, umavez = true;

function OuvidoriaEditar(){
    setInterval(function () {verificaLogin();}, 1000);

    const [refreshKey, setRefreshKey] = useState(0);
    
    function atualiza() {
        setRefreshKey(oldKey => oldKey +1); 
    }

    const [alertAberto, setAlertAberto] = React.useState(false);
    const [alertMensagem, setAlertMensagem] = React.useState('');
    const [tipoAlerta, setTipoAlerta] = React.useState('');

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    function meuAlert(texto, tipo) {
        setTipoAlerta(tipo);
        setAlertMensagem(texto);
        window.location = `#root`;
        setAlertAberto(true);
    }

    function loading() {return <div id='loading'><img src={carregando} alt="Carregando"/></div>; }    
    setInterval(function () {verificaLogin();}, 1000);
    
    // Clicar no primeiro botao e colocar texto inicial
    useEffect(() => {
        
        document.getElementById(0).click();
        
    },[]);

    const {id} = useParams();
    var token = localStorage.getItem('LOCAL_STORAGE_KEY__ACCESS_TOKEN');
        
    // pegar dados se não for novo
    useEffect(() => {

        // pegar dados das pessoas (para esic e ouvidoria)
        axios.get(`${base_url}/pessoa`, {
            "headers": {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 

            // eslint-disable-next-line
            if((response.data.statusCode != undefined) && (response.data.statusCode != 200)){
                //window.location.pathname = `mensagem/${response.data.message}`;
                meuAlert(`${response.data.message}`, "error");
            }

            // eslint-disable-next-line 
            if(response.data.length == 0)
            {
                meuAlert("Não há pessoas.", "error"); 
            }
            else
            {
                //console.log(response.data);

                var respOU = document.getElementById("respOU");
                
                for(let i=0; i<response.data.length; i++){
                    const option2 = document.createElement("option");
                    option2.value = response.data[i].id;
                    option2.innerHTML = response.data[i].nome;
                    respOU.appendChild(option2);
                }

                //console.log(document.getElementById("respES").value);
            }            
        })    
        .catch(error => { 
            console.log(error.message);
            if(error.response)
            {
                // eslint-disable-next-line
                if(error.response.data.statusCode == 401)
                {
                    window.location.pathname = `login`;
                }
                else{
                    meuAlert(`${error.message}`, "error");
                }  
            }
            else{
                meuAlert(`${error.message}`, "error");
            }            
        });
        
        
        if(id != "novo")
        {
            axios.get(`${base_url}/ouvidoria/${id}`, 
            { headers: {
                'Authorization': `Bearer ${token}`,
            }})
            .then(async response => {             
                               
                // eslint-disable-next-line
                if((response.data.statusCode != undefined) && (response.data.statusCode != 200)){
                    //window.location.pathname = `mensagem/${response.data.message}`;
                    meuAlert(`${response.data.message}`, "error");
                }

                // eslint-disable-next-line 
                if(response.data.length == 0)
                {
                    meuAlert("Não há dados.", "error");         
                }
                else
                {
                    //console.log(response.data);

                    document.getElementById("emailOU").value = (response.data.email  || "");
                    document.getElementById("celularOU").value = (response.data.celular  || "");
                    document.getElementById("foneOU").value = (response.data.fone  || "");
                    await responsavelOuvidoria(response.data.responsavel_id);
                    document.getElementById("expediente").value = (response.data.expediente  || "");
                    document.getElementById("endereco").value = (response.data.endereco  || "");
                }    
            })
            .catch(error => { 
                console.log(error.message);
                if(error.response)
                {
                    if((error.response.data.statusCode == 404) || (error.response.data.statusCode == 400))
                    {
                        if(unavez)
                        {
                            meuAlert("Dados não existentes.", "warning");
                            //window.location.pathname = `concurso/editar/novo`;
                            unavez = false;
                        }
                        
                    }
                    else{
                        meuAlert(`${error.message}`, "error");
                    }
                    if(error.response.data.statusCode == 401)
                    {
                        window.location.pathname = `login`;
                    }
                }   
                else{
                    meuAlert(`${error.message}`, "error");
                }            
            });
        }
        document.getElementById("notifica").innerHTML = "Processo em andamento, aguarde...";
               
    },[refreshKey]); 

    function verificaVazios() {
        if(document.getElementById("emailOU").value == ""){meuAlert("Email não preenchido.", "warning");}
        else if(document.getElementById("celularOU").value == ""){meuAlert("Celular não preenchido.", "warning");}
        else if(document.getElementById("foneOU").value == ""){meuAlert("Telefone não preenchido.", "warning");}
        else if(document.getElementById("respOU").value == ""){meuAlert("Responsável não preenchido.", "warning");}
        else if(document.getElementById("endereco").value == ""){meuAlert("Endereço não preenchido.", "warning");}
        else if(document.getElementById("expediente").value == ""){meuAlert("Expediente não preenchido.", "warning");}
        else
        {
            return true;
        }   
    }
    
    // Atualiza contato (sem docs)
    async function atualizaOuvidoria() {
          
        if(verificaVazios())
        { 
            {handleOpen()} 

            const params = JSON.stringify({

                "email": document.getElementById("emailOU").value,
                "celular": String(document.getElementById("celularOU").value),
                "fone": document.getElementById("foneOU").value,
                "responsavel_id": String(document.getElementById("respOU").value),
                "expediente": String(document.getElementById("expediente").value),
                "endereco": String(document.getElementById("endereco").value)
            });

            console.log(params);
            
            const url = `${base_url}/ouvidoria/${id}`;
            await axios.patch(url, params, {
            "headers": {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            }})
            .then(response => { 
                //console.log(response);
                //console.log(response.data.id);

                document.getElementById("notifica").innerHTML = "Alterado com sucesso!";
                const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

                meuAlert("Alterado com sucesso!", "success");
                const redireciona = setInterval(function () {atualiza(); clearInterval(redireciona);}, 2000);     

            })
            .catch(error => { 
                {handleClose()}
                meuAlert(`${error.response.data.message}`, "warning");
                console.log(error.message);
                console.log(error.response.data.message);
                //console.log("Erro "+error.response.data.statusCode);
            })     
        };
    }
    
    function responsavelOuvidoria(valor){

        for(let i=0; i<document.getElementById("respOU").children.length; i++){
            if(document.getElementById("respOU").children[i].value == valor){
                document.getElementById("respOU").children[i].selected = true;
            }        
        }
    }

    return(
        <>   
            <Sidebar page={{ name: 'Contatos', route: '/contato' }}>
                <Box sx={{ width: '100%'}}>
                    <Collapse in={alertAberto}>
                        <Alert
                        severity={tipoAlerta}
                        variant="filled"
                        action={
                            <IconButton
                            aria-label="close"
                            size="small"
                            color="inherit"
                            onClick={() => {
                                setAlertAberto(false);
                            }}
                            >
                            <CloseIcon/>
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        {alertMensagem}
                        </Alert>
                    </Collapse>
                </Box>
                
                <div className="tituloPagina"><p id="titu" >Editar Ouvidoria</p></div>   

                <div className="semLinha menuPadrao">
                    <li>
                        <button id="0" >Cadastro Geral</button>
                    </li>  
                </div>

                <div className="formulario" >
                    <div id="cadastroConcurso">
                        <div className="formRedondo formularioGeral esicEditar ouvidoriaAgora" id="ouvidoria">
                                <div className="formularioGeralTitulo"><p>Email</p><p>Celular (com DDD e somente números)</p></div>                            
                                <input type="text" name="emailOU" id="emailOU" placeholder="Insira o email de contato" autoComplete="off" ></input>
                                <input type="text" name="celularOU" id="celularOU" placeholder="Insira o celular de contato" autoComplete="off" ></input>
                                
                                <div className="formularioGeralTitulo"><p>Fone (com DDD e somente números)</p><p>Responsável</p></div>
                                <input type="text" name="foneOU" id="foneOU" placeholder="Insira o telefone para contato" autoComplete="off" ></input>
                                
                                <select name="respOU" id="respOU">        
                                </select>

                                <div className="formularioGeralTitulo"><p>Expediente</p><p>Endereço</p></div>
                                <input type="text" name="expediente" id="expediente" placeholder="Insira o expediente" autoComplete="off" ></input>
                                <input type="text" name="endereco" id="endereco" placeholder="Insira o endereço" autoComplete="off" ></input>
                                

                                <button onClick={(e) => atualizaOuvidoria()} >Salvar alterações</button>
                        </div>
                    </div> 
                </div>
            </Sidebar>
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                ><p id="notifica" >vghfgh</p>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </>   
    );
}

export default OuvidoriaEditar;