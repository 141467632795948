import { useEffect, useState } from "react";
import '../../../components/Geral/geral.css';
import './PlenariaEditar.css'
import carregando from '../../../components/Geral/loading.gif';
import { verificaLogin } from '../../../context/LoginContext';
import {base_url, midia_url, foto_default} from '../../../environment'
import axios from 'axios';
import Sidebar from '../../../components/Menu/Sidebar';
import { useParams } from "react-router-dom";
import { TextEditor } from "../../../components/TextEditor/TextEditor";

import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
var optionClicado = "background-color: var(--mainColor); color: var(--textColor2);"

var unavez = true, umavez = true, pinaVez = true, agoravez = true;

const VereadoresID = [];

function PlenariaEditar(){
    setInterval(function () {verificaLogin();}, 1000);

    const [refreshKey, setRefreshKey] = useState(0);
    
    function atualiza() {
        setRefreshKey(oldKey => oldKey +1); 
    }

    const [alertAberto, setAlertAberto] = React.useState(false);
    const [alertMensagem, setAlertMensagem] = React.useState('');
    const [tipoAlerta, setTipoAlerta] = React.useState('');
    const [numVereadores, setnumVereadores] = React.useState('');

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    function meuAlert(texto, tipo) {
        setTipoAlerta(tipo);
        setAlertMensagem(texto);
        window.location = `#root`;
        setAlertAberto(true);
    }

    function loading() {return <div id='loading'><img src={carregando} alt="Carregando"/></div>; }    
    setInterval(function () {verificaLogin();}, 1000);
    
    var [texto, setTexto] = useState('<p>Escreva seu texto aqui. Aumente o tamanho da área de texto no canto inferior direito.</p>');    

    // Clicar no primeiro botao e colocar texto inicial
    useEffect(() => {
        document.getElementById(0).click();    
    },[]);

    const {id} = useParams();
    var token = localStorage.getItem('LOCAL_STORAGE_KEY__ACCESS_TOKEN');
        
    // pegar dados se não for novo
    useEffect(() => {

        //cria vereadores
        axios.get(`${base_url}/pessoa?status=true&cargo_id=78caae7a-cd7c-43b6-8d71-0393dc660745&deleted=false`, 
        { headers: {
            'Authorization': `Bearer ${token}`,
        }})
        .then(async response => {    
                        
            if(agoravez){

                setnumVereadores(response.data.length);

                for(let i=0; i<response.data.length; i++){

                    var divVere = document.getElementById("vereadores");
            
                    const input = document.createElement("input");
                    input.type = "checkbox";
                    input.id = response.data[i].id;
                    
                    const label = document.createElement("label");
                    label.htmlFor = response.data[i].id;
                    label.innerHTML = response.data[i].nome;
                                            
                    divVere.appendChild(input);  
                    divVere.appendChild(label);  

                    VereadoresID.push(response.data[i].id);

                }
                    
                agoravez = false;
                document.getElementById("MASTER").addEventListener("click", (e) => marcarVereadores());

            }

        })
        .catch(error => { 
            console.log(error.message);
            if(error.response)
            {
                if((error.response.data.statusCode == 404) || (error.response.data.statusCode == 400))
                {
                    if(unavez)
                    {
                        meuAlert("Comissão não existente.", "warning");
                        //window.location.pathname = `concurso/editar/novo`;
                        unavez = false;
                    }
                    
                }
                else{
                    meuAlert(`${error.message}`, "error");
                }
                if(error.response.data.statusCode == 401)
                {
                    window.location.pathname = `login`;
                }
            }   
            else{
                meuAlert(`${error.message}`, "error");
            }            
        });
        
              
        //dados principais
        if(id != "novo")
        {
            axios.get(`${base_url}/sessao-plenaria/${id}`, 
            { headers: {
                'Authorization': `Bearer ${token}`,
            }})
            .then(async response => {             
                //console.log("Resposta ok");
                //console.log(response);
                //console.log(response.data);
                
                document.getElementById("titulo").value = response.data.titulo;
                document.getElementById("numero").value = response.data.numero;
                document.getElementById("data").value = new Date(response.data.data).toISOString().split('T')[0];    

                if(response.data.status == true){
                    document.getElementById("radioativo").checked = true;
                }
                else
                {
                    document.getElementById("radioinativo").checked = true;
                }

                await TipoSessao(response.data.tipo);   
                document.getElementById("link_youtube").value = "https://www.youtube.com/watch?v="+response.data.link_youtube;
                               
                //arquivo
                if(umavez){
                    document.getElementById("anexo1").innerHTML = null;   
                
                    //arrayInicial.push(String('<div><p>'+response.data.Anexo[i].titulo+'</p><a target="_blank" href='+midia_url+response.data.Anexo[i].anexo+'>Baixar</a><a target="_blank" href="#" >Excluir</a></div>')); 
                        
                    var divGeral = document.getElementById("anexo1");
                    
                    const divContainter = document.createElement("div");
    
                    const p = document.createElement("p");
                    p.innerHTML = response.data.pauta.substring(32);
            
                    const a2 = document.createElement("a");
                    a2.target = "_blank";
                    a2.href = String(midia_url +response.data.pauta);
                    a2.innerHTML = "Baixar";
                    
                    divContainter.appendChild(p);              
                    divContainter.appendChild(a2); 
                        
                    divGeral.appendChild(divContainter);
                       
                    umavez = false;
                }

                //arquivo
                if(pinaVez){
                    document.getElementById("anexo2").innerHTML = null;   
                
                    //arrayInicial.push(String('<div><p>'+response.data.Anexo[i].titulo+'</p><a target="_blank" href='+midia_url+response.data.Anexo[i].anexo+'>Baixar</a><a target="_blank" href="#" >Excluir</a></div>')); 
                        
                    var divGeral = document.getElementById("anexo2");
                    
                    const divContainter = document.createElement("div");
    
                    const p = document.createElement("p");
                    p.innerHTML = response.data.pauta.substring(32);
            
                    const a2 = document.createElement("a");
                    a2.target = "_blank";
                    a2.href = String(midia_url +response.data.ata);
                    a2.innerHTML = "Baixar";
                    
                    divContainter.appendChild(p);              
                    divContainter.appendChild(a2); 
                        
                    divGeral.appendChild(divContainter);
                       
                    pinaVez = false;
                }

                //marca vereadores
                await setTimeout(() => {                    
                    for(let i=0; i<response.data.Presencas.length; i++){
                        document.getElementById(response.data.Presencas[i].id).checked = true;
                    }   
               }, "1000");
                 //modulos
                //for(let i=0; i<response.data.Presencas.length; i++){
                //    if(response.data.Presencas.includes(VereadoresID[i])){document.getElementById(VereadoresID[i]).checked = true;}
                //}
                    
            })
            .catch(error => { 
                console.log(error.message);
                if(error.response)
                {
                    if((error.response.data.statusCode == 404) || (error.response.data.statusCode == 400))
                    {
                        if(unavez)
                        {
                            meuAlert("Comissão não existente.", "warning");
                            //window.location.pathname = `concurso/editar/novo`;
                            unavez = false;
                        }
                        
                    }
                    else{
                        meuAlert(`${error.message}`, "error");
                    }
                    if(error.response.data.statusCode == 401)
                    {
                        window.location.pathname = `login`;
                    }
                }   
                else{
                    meuAlert(`${error.message}`, "error");
                }            
            });
        }
        else{
            document.getElementById("cadastroDocRelatorio1").innerHTML = null; 
            document.getElementById("cadastroDocRelatorio2").innerHTML = null; 
        }
        document.getElementById("notifica").innerHTML = "Processo em andamento, aguarde...";
               
    },[refreshKey]); 


        
    function menuSec(menuId) {
        for(let i=0; i<=1; i++){
            document.getElementById(i).style = null;
        }
        document.getElementById(menuId).style = optionClicado;       
        //console.log("Selecionou o botão " + id);

        // eslint-disable-next-line
        if(menuId != 0)
        {
            document.getElementById("cadastroCargo").setAttribute('hidden', '');
        }
        else
        {
            document.getElementById("cadastroCargo").removeAttribute('hidden');
            document.getElementById("cadastroPresenca").setAttribute('hidden', '');            
        }

        // eslint-disable-next-line
        if(menuId != 1){
            document.getElementById("cadastroPresenca").setAttribute('hidden', '');
        }
        else{
            
               document.getElementById("cadastroPresenca").removeAttribute('hidden');
                document.getElementById("cadastroCargo").setAttribute('hidden', ''); 
                        
        }

    }  

    function verificaVazios() {        
        if(document.getElementById("data").value == ""){meuAlert("Data não preenchida.", "warning");}
        else if(document.getElementById("titulo").value == ""){meuAlert("Título não preenchido.", "warning");}
        else if(document.getElementById("numero").value == ""){meuAlert("Número não preenchido.", "warning");}
        else if(document.getElementById("link_youtube").value == ""){meuAlert("Link Youtube não preenchido.", "warning");}
        else if(id == "novo"){
            if(document.getElementById("anexo1") == "")
            {meuAlert("Pauta não inserida.", "warning");}
            else if(document.getElementById("anexo2") == "")
                {meuAlert("Ata não inserida.", "warning");}
                else{return true;}
        }
        else
        {
            return true;
        }   
    }
    
    function selectFuncao(){
        if(id == "novo")
        {
            if(verificaVazios())
            {
                cadastraPlenaria();
            };            
        }
        else
        {
            if(verificaVazios())
            {
                atualizaPlenaria();
            };    
        }
    }

    // Cadastra Plenaria
    async function cadastraPlenaria() {

        {handleOpen()} 

        const vereadoresFinal = [];
        for(let i=0; i<VereadoresID.length; i++){
            if(document.getElementById(VereadoresID[i]).checked){vereadoresFinal.push(VereadoresID[i]);}
            ///console.log(vereadoresFinal);  
        }

        console.log(vereadoresFinal);  
        console.log(vereadoresFinal.join(','));

        var input = document.getElementById("pauta");
        var arquivo2 = document.getElementById("ata");


        var data = new FormData()
        data.append('titulo', String(document.getElementById("titulo").value))     
        data.append('numero', document.getElementById("numero").value)   
        data.append('data', new Date(document.getElementById("data").value).toISOString())   
        data.append('tipo', String(document.getElementById("tipo").value))   
        data.append('status', document.querySelector('input[name="statusPlenaria"]:checked').value)
        data.append('link_youtube', String(document.getElementById("link_youtube").value))   
        data.append('pauta', input.files[0])  
        data.append('ata', arquivo2.files[0])  
        data.append('presencas', vereadoresFinal.join(','))   
       
        const url = `${base_url}/sessao-plenaria`;
         await axios.post(url, data, {
        "headers": {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 
            //console.log(response);
            //console.log(response.data.id);

            document.getElementById("notifica").innerHTML = "Cadastrado com sucesso!";
            const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

            meuAlert("Cadastrado com sucesso!", "success");
            const redireciona = setInterval(function () {
                clearInterval(redireciona);
                window.location.pathname = `plenaria/editar/${response.data.id}`;
            }, 3000);     
           
        })
        .catch(error => {
            {handleClose()}
            meuAlert(`${error.response.data.message}`, "warning");
            console.log(error.message);
            console.log(error.response.data.message);
            //console.log("Erro "+error.response.data.statusCode);
        })     
    }

    // Atualiza Plenaria
    async function atualizaPlenaria() {
          
        {handleOpen()} 
        
        
        const vereadoresFinal = [];
        for(let i=0; i<VereadoresID.length; i++){
            if(document.getElementById(VereadoresID[i]).checked){vereadoresFinal.push(VereadoresID[i]);}
            ///console.log(vereadoresFinal);  
        }

        console.log(vereadoresFinal);  
        console.log(vereadoresFinal.join(','));

        var input = document.getElementById("pauta");
        var arquivo2 = document.getElementById("ata");

        var data = new FormData()
        data.append('titulo', String(document.getElementById("titulo").value))     
        data.append('numero', document.getElementById("numero").value)   
        data.append('data', new Date(document.getElementById("data").value).toISOString())   
        data.append('tipo', String(document.getElementById("tipo").value))   
        data.append('status', document.querySelector('input[name="statusPlenaria"]:checked').value)
        data.append('link_youtube', String(document.getElementById("link_youtube").value))   
        data.append('pauta', input.files[0])  
        data.append('ata', arquivo2.files[0])          
        data.append('presencas', vereadoresFinal.join(','))   

        //console.log(data);
        
        const url = `${base_url}/sessao-plenaria/${id}`;
        await axios.patch(url, data, {
        "headers": {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 
            //console.log(response);
            //console.log(response.data.id);

            document.getElementById("notifica").innerHTML = "Alterado com sucesso!";
            const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

            meuAlert("Alterado com sucesso!", "success");
            const redireciona = setInterval(function () {atualiza(); clearInterval(redireciona);}, 2000);     

        })
        .catch(error => { 
            {handleClose()}
            meuAlert(`${error.response.data.message}`, "warning");
            console.log(error.message);
            console.log(error.response.data.message);
            //console.log("Erro "+error.response.data.statusCode);
        })     
        
    }
    
    // Excluir Plenaria
    async function ExcluirPlenaria() {  

        if (window.confirm("Tem certeza que deseja excluir essa sessão plenária?")) {        
            
            {handleOpen()} 
         
            const url = `${base_url}/sessao-plenaria/${id}`;
            await axios.delete(url, {
            "headers": {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            }})
            .then(response => { 
                //console.log(response);
                //console.log(response.data.id);    
               
                document.getElementById("notifica").innerHTML = "Excluído com sucesso!";
                const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

                meuAlert("Excluído com sucesso!", "success");
                const redireciona = setInterval(function () {
                    clearInterval(redireciona);
                    window.location.pathname = `plenaria/editar/novo`;
                }, 2000);     

            })
            .catch(error => { 
                {handleClose()}
                meuAlert(`${error.message}`, "error");
                console.log(error.message);
                //console.log("Erro "+error.response.data.statusCode);
            })  
        }           
    }

    function TipoSessao(valor){
        for(let i=0; i<document.getElementById("tipo").children.length; i++){
            if(document.getElementById("tipo").children[i].value == valor){
                document.getElementById("tipo").children[i].selected = true;
            }        
        }
    }

    function marcarVereadores() {        

        for(var valor=0; valor<VereadoresID.length; valor++)
        {
            if(document.getElementById(VereadoresID[valor]).checked)
            {
                document.getElementById(VereadoresID[valor]).checked = false;
            }
            else{
                document.getElementById(VereadoresID[valor]).checked = true;
            }
        }
    }

    return(
        <>   
            <Sidebar page={{ name: 'Plenárias', route: '/plenaria' }}>  
                <Box sx={{ width: '100%'}}>
                    <Collapse in={alertAberto}>
                        <Alert
                        severity={tipoAlerta}
                        variant="filled"
                        action={
                            <IconButton
                            aria-label="close"
                            size="small"
                            color="inherit"
                            onClick={() => {
                                setAlertAberto(false);
                            }}
                            >
                            <CloseIcon/>
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        {alertMensagem}
                        </Alert>
                    </Collapse>
                </Box>
                
                <div className="tituloPagina"><p id="titu" >Editar Plenária</p></div>  

                <div className="botaoExcluirEspec" >
                    {id !== "novo" && (
                        <div className="botaoExcluirEspec">
                            <button onClick={ExcluirPlenaria}>Excluir Sessão Plenária</button>
                        </div>
                    )}

                </div>

                 <div className="semLinha menuPadrao">
                    <li>
                        <button id="0" onClick={(e) => menuSec(e.target.id)}>Cadastro Geral</button>                        
                        <button id="1" onClick={(e) => menuSec(e.target.id)}>Presenças</button>   
                    </li>  
                </div>

                <div className="formulario" >
                    <div id="cadastroCargo">
                        <div className="plenariaEditar formularioGeral formRedondo">                           
                                          
                            <div className="alinhaDireita">

                                <div className="formularioGeralTitulo"><p>Número</p></div>                            
                                <input type="number" name="numero" id="numero" placeholder="Insira o número da plenária" autoComplete="off"  ></input>
                                
                                <div className="novoRadio statusRadio">                             
                                    <div className="formularioGeralTitulo"><p>Status</p></div> 
                                    <input type="radio" id="radioativo" name="statusPlenaria" value="true"checked></input>
                                    <label for="ativo">Ativo</label>

                                    <input type="radio" id="radioinativo" name="statusPlenaria" value="false"></input>
                                    <label for="inativo">Inativo</label>
                                </div>  

                                <div className="espaco2LadosB_Plenaria"></div>
                                
                                <div className="formularioGeralTitulo"><p>Link Youtube</p></div>
                                <input type="text" name="link_youtube" id="link_youtube" autoComplete="off"  ></input>
                                
                                <div className="formularioGeralTitulo"><p>Pauta</p></div>
                                <input type="file" name="pauta" id="pauta" autoComplete="off"  ></input>
                                 
                                <div id="cadastroDocRelatorio1">
                                    <div className="removeBordu cadastroDocInterno cadastroDocInternoPlenaria">
                                        <p>Arquivo cadastrado: Para substituir, insira novo arquivo acima</p>
                                        <div id="anexo1" className="anexos">
                                            {loading()}
                                        </div>       
                                    </div>
                                </div>   

                            </div>
                                                            
                            <div className="alinhaEsquerda">     

                                <div className="formularioGeralTitulo"><p>Título</p></div>                            
                                <input type="text" name="titulo" id="titulo" placeholder="Insira o título da plenária" autoComplete="off"  ></input>
                                                           
                                <div className="formularioGeralTitulo"><p>Data</p></div>
                                <input type="date" name="data" id="data" autoComplete="off"  ></input>
                                                                
                                <div className="formularioGeralTitulo"><p>Tipo de sessão</p></div>   
                                <select name="tipo" id="tipo">
                                    <option value="ORDINARIA">Ordinária</option>
                                    <option value="EXTRAORDINARIA">Extraordinária</option>
                                </select>

                                <div className="formularioGeralTitulo"><p>Ata</p></div>
                                <input type="file" name="ata" id="ata" autoComplete="off"  ></input>
                                 
                                <div id="cadastroDocRelatorio2">
                                    <div className="removeBordu cadastroDocInterno">
                                        <p>Arquivo cadastrado: Para substituir, insira novo arquivo acima</p>
                                        <div id="anexo2" className="anexos">
                                            {loading()}
                                        </div>       
                                    </div>
                                </div>   

                            </div>

                            <button onClick={selectFuncao}>Salvar alterações</button>

                        </div>      
                        
                    </div>                    
                </div>

                <div className="cadastroDoc">
                    <div id="cadastroPresenca">
                        <div className="cadastroDocInterno">
                            
                            <div className="novoRadio statusRadio" id="vereadores"> 
                                <div className="formularioGeralTitulo" >
                                    <label for="ativo">Marcar /Desmarcar todos</label><input type="checkbox" id="MASTER" name="MASTER"></input>
                                </div> 
                            </div>
                            
                        </div>
                    </div>                        
                </div>

            </Sidebar>
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                ><p id="notifica" >vghfgh</p>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </>   
    );
}

export default PlenariaEditar;