export function verificaLogin() {

    if (localStorage.getItem('LOCAL_STORAGE_KEY__ACCESS_TOKEN')) {
        return true;
    }    
    else{
        window.location.pathname = `/login`;
    }
}

export function fazerLogout() {
    localStorage.removeItem('USUARIO_LOGADO');
    localStorage.removeItem('LOCAL_STORAGE_KEY__ACCESS_TOKEN');
    //localStorage.removeItem('contratante_id');
};