import { Editor } from "@tinymce/tinymce-react";
import { tiny_mce_api_key } from "../../environment";

export function TextEditor({ value, onChange }) {
  return (
    <Editor
      apiKey={tiny_mce_api_key}
      value={value}
      onEditorChange={onChange}
      init={{
        branding: false,
        height: 400,
        menubar: true,
        plugins: " directionality  visualchars pagebreak nonbreaking    preview paste searchreplace autolink directionality visualblocks visualchars fullscreen image link media Advanced Template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
        toolbar: "undo redo | blocks | formatselect | removeformat bold italic underline strikethrough | forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent",
        content_style: 'body { font-family:Trebuchet ms; font-size:14px }',
        image_advtab: true
      }}
    />
  );
};