import { useEffect, useState} from "react";
import '../../../components/Geral/geral.css';
import '../../../components/Geral/tabela.css';
import './Boletim.css'
import carregando from '../../../components/Geral/loading.gif';
import {base_url, midia_url} from '../../../environment'
import Sidebar from '../../../components/Menu/Sidebar';
import axios from 'axios';
import { verificaLogin} from '../../../context/LoginContext';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {BiEdit} from "react-icons/bi"
import {MdDeleteForever} from "react-icons/md"
import {BsFillCheckCircleFill} from "react-icons/bs"
import {GoCircleSlash} from "react-icons/go"

import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
//import Button from '@mui/material/Button';

function Boletim(){
    setInterval(function () {verificaLogin();}, 1000);

    const [tables, setTables] = useState();
    const [orgao, setOrgao] = useState();
    const [refreshKey, setRefreshKey] = useState(0);

    const [alertAberto, setAlertAberto] = React.useState(false);
    const [alertMensagem, setAlertMensagem] = React.useState('');
    const [tipoAlerta, setTipoAlerta] = React.useState('');


    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    function meuAlert(texto, tipo) {
        setTipoAlerta(tipo);
        setAlertMensagem(texto);
        window.location = `#root`;
        setAlertAberto(true);
    }
    
    var token = localStorage.getItem('LOCAL_STORAGE_KEY__ACCESS_TOKEN');

    function loading() {
        return <div id='loading'><img src={carregando} alt="Carregando"/></div>;
    }
    
    // pegar dados da boletim
    useEffect(() => {
        
        axios.get(`${base_url}/boletim`, {
            "headers": {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }})
        .then(response => { 

            // eslint-disable-next-line
            if((response.data.statusCode != undefined) && (response.data.statusCode != 200)){
                //window.location.pathname = `mensagem/${response.data.message}`;
                meuAlert(`${response.data.message}`, "error");
            }

            // eslint-disable-next-line 
            if(response.data.length == 0)
            {
                document.getElementById("boletim").innerHTML = '<button><p>Não há boletins.</p></button>';
                    
            }
            else
            {
                //console.log(response.data);
                setTables(response.data);
                document.getElementById("boletim").innerHTML = null;   
            }            
        })    
        .catch(error => { 
            console.log(error.message);
            if(error.response)
            {
                // eslint-disable-next-line
                if(error.response.data.statusCode == 401)
                {
                    window.location.pathname = `login`;
                }
                else{
                    meuAlert(`${error.message}`, "error");
                }  
            }
            else{
                meuAlert(`${error.message}`, "error");
            }            
        });
                
        document.getElementById("mensagem").innerHTML = "Processo em andamento, aguarde...";
        // eslint-disable-next-line
    },[refreshKey]); 

    // Excluir boletim
    async function ExcluirBoletim(id) {  

        if (window.confirm("Tem certeza que deseja excluir esse boletim?")) {            
            // eslint-disable-next-line
            {handleOpen()} 
        
            const url = `${base_url}/boletim/${id}`;
            await axios.delete(url, {
            "headers": {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            }})
            .then(response => { 
                //console.log(response);
                //console.log(response.data.id);

                document.getElementById("mensagem").innerHTML = "Operação finalizada!";
                // eslint-disable-next-line
                const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

                meuAlert("Excluído com sucesso!", "success");
                const redireciona = setInterval(function () {setRefreshKey(oldKey => oldKey +1); clearInterval(redireciona);}, 2000);     

            })
            .catch(error => { 
                // eslint-disable-next-line
                {handleClose()}
                meuAlert(`${error.message}`, "error");
                console.log(error.message);
                //console.log("Erro "+error.response.data.statusCode);
            })  
        }           
    }

    function mostraStatus(status) {  
        if(status)
        {return <a className="true">< BsFillCheckCircleFill /></a>;}
        else
        { return <a className="false">< GoCircleSlash /></a>;}
    }

    function marcaTodos() {        

        var itens = 0;

        if(document.getElementById("checkGeral").checked)
        {
            while(document.getElementById(itens))
            {
                document.getElementById(itens).checked = true;
                itens++;
            }
        }
        else
        {
            while(document.getElementById(itens))
            {
                document.getElementById(itens).checked = false;
                itens++;
            }
        }
    }
    
    async function opcoesMarcados(acao) {        

        //var acao = document.getElementById("acao").value;
        //alert(acao);
     
        var itens = 0;
        var passadas =0;
        while(document.getElementById(itens)){itens++;}

        // eslint-disable-next-line
        {handleOpen()}

        for(var valor=0; valor<itens; valor++)
        {
            // eslint-disable-next-line
            if((acao == "excluir") && document.getElementById(valor).checked)
            {
                //alert(tables[itens].id);

                const url = `${base_url}/boletim/${tables[valor].id}`;
                await axios.delete(url, {
                "headers": {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                }})
                .then(response => { 
                    //console.log(response);
                    //console.log(response.data.id);
                })
                .catch(error => {
                    // eslint-disable-next-line
                    {handleClose()}
                    meuAlert(`${error.message}`, "error");
                    console.log(error.message);
                    //console.log("Erro "+error.response.data.statusCode);
                    passadas = -100;
                })  
            }               
            // eslint-disable-next-line
            if((acao == "inativar") && document.getElementById(valor).checked)
            {
                const params = JSON.stringify({
                    "status": false,
                });

                console.log(params);
                
                const url = `${base_url}/boletim/${tables[valor].id}`;
                await axios.patch(url, params, {
                "headers": {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                }})
                .then(response => { 
                    //console.log(response);
                    //console.log(response.data.id);
                })
                .catch(error => { 
                    // eslint-disable-next-line
                    {handleClose()}
                    meuAlert(`${error.message}`, "error");
                    console.log(error.message);
                    //console.log("Erro "+error.response.data.statusCode);
                    passadas = -100;
                })  
            } 
            // eslint-disable-next-line 
            if((acao == "ativar") && document.getElementById(valor).checked)
            {
                const params = JSON.stringify({
                    "status": true,
                });

                console.log(params);
                
                const url = `${base_url}/boletim/${tables[valor].id}`;
                await axios.patch(url, params, {
                "headers": {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                }})
                .then(response => { 
                    //console.log(response);
                    //console.log(response.data.id);
                })
                .catch(error => { 
                    // eslint-disable-next-line
                    {handleClose()}
                    meuAlert(`${error.message}`, "error");
                    console.log(error.message);
                    //console.log("Erro "+error.response.data.statusCode);
                    passadas = -100;
                })  
            }  
            passadas++;    
            // eslint-disable-next-line
            if(itens == passadas)
            {
                document.getElementById("mensagem").innerHTML = "Operação finalizada!";                
                // eslint-disable-next-line
                const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);                

                meuAlert("Operação finalizada!", "success");
                const redireciona = setInterval(function () {setRefreshKey(oldKey => oldKey +1); clearInterval(redireciona);}, 2000);     
            
                document.getElementById("checkGeral").checked = false;
                marcaTodos();
            }
        }

    }

    return(
        <>   
           <Sidebar>
                    <Box sx={{ width: '100%'}}>
                        <Collapse in={alertAberto}>
                            <Alert
                            severity={tipoAlerta}
                            variant="filled"
                            action={
                                <IconButton
                                aria-label="close"
                                size="small"
                                color="inherit"
                                onClick={() => {
                                    setAlertAberto(false);
                                }}
                                >
                                <CloseIcon/>
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                            >
                            {alertMensagem}
                            </Alert>
                        </Collapse>
                    </Box>
                    
                    <div className="tituloPagina"> 
                        <p id="titu" >Boletim</p>
                    </div>   
                    <div className="optionNovo" >
                        <button onClick={(e) =>  window.location.pathname = `boletim/editar/novo`}>Novo Boletim</button>

                    </div>  

                    <div className="optionsBotoes" >
                        <button onClick={(e) => opcoesMarcados("excluir")}>Excluir</button>                      
                    </div>

                    <div className="tabelaPadrao">
                        <TableContainer className="modal-content">
                            <Table sx={{minWidth: 650}} arial-label="caption table" className="table">
                            <TableHead>
                                <TableRow>                                    
                                    <TableCell><input type="checkbox" id="checkGeral" onClick={(e) => marcaTodos()}></input></TableCell>
                                    <TableCell>Número</TableCell>
                                    <TableCell>Data</TableCell>
                                    <TableCell>Criado em</TableCell>
                                    <TableCell>Atualizado em</TableCell>
                                    <TableCell>Editar</TableCell>
                                    <TableCell>Excluir</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {tables && tables.map((item,index) => (
                                <TableRow>
                                    <TableCell><input type="checkbox" id={index} ></input></TableCell>
                                    <TableCell>{item.numero || '--'}</TableCell>
                                    <TableCell>{new Date(item.data).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).toString() || '--'}</TableCell>
                                    <TableCell>{new Date(item.created_at).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).toString() || '--'}</TableCell>
                                    <TableCell>{new Date(item.updated_at).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).toString() || '--'}</TableCell>
                                    <TableCell><a target="_self" href={"boletim/editar/"+item.id}>< BiEdit /></a></TableCell>
                                    <TableCell><a target="_self" href="#" onClick={(e) => ExcluirBoletim(item.id)}>< MdDeleteForever /></a></TableCell>
                                </TableRow>
                            ))}                 
                                </TableBody>
                                </Table>                    
                        </TableContainer>
                    </div>    
                    
                    <div className="loading" >
                        <div id="boletim">{loading()}</div>
                    </div>
            </Sidebar>   
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                ><p id="mensagem" ></p>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </>   
    );
}

export default Boletim;